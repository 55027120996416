import {User, UserAttributes} from "model/user";
import {AuthObj} from "model/auth";
import {ActionType, NewUIErrorV2} from "service/cartaError";
import {EntityKind} from "model/BaseModel";

/**
 * @deprecated
 */
export function getAuthToken(): string {
  const token = localStorage.getItem("token");

  if (token == null) {
    return "";
  }

  return token;
}

export function getUser(): User {
  const user = localStorage.getItem("user");

  if (user == null) {
      throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "User not found in local storage")
  }

  return JSON.parse(user);
}

export function setUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
}

export function getUserId(): string {
  const id = localStorage.getItem("user_id");

  if (id == null) {
      throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "User ID not found in local storage")
  }

  return id;
}

export function storeAuthObjInLocalStorage(authObj: AuthObj) {
    localStorage.setItem("user_id", authObj.userId);
    localStorage.setItem("user", JSON.stringify(authObj.user));
}

export function removeAuthInfoFromLocalStorage() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user");
}

export function retrieveUserFromLocalStorage(): User | null {
    const user = localStorage.getItem("user");

    if (user == null) {
        return null;
    }

    return JSON.parse(user);
}