// source: language.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.language.FetchLanguageRequest', null, global);
goog.exportSymbol('proto.language.FetchLanguageResponse', null, global);
goog.exportSymbol('proto.language.LanguageDTO', null, global);
goog.exportSymbol('proto.language.ListLanguageByIdsRequest', null, global);
goog.exportSymbol('proto.language.ListLanguageByIdsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.language.LanguageDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.language.LanguageDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.language.LanguageDTO.displayName = 'proto.language.LanguageDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.language.FetchLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.language.FetchLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.language.FetchLanguageRequest.displayName = 'proto.language.FetchLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.language.FetchLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.language.FetchLanguageResponse.repeatedFields_, null);
};
goog.inherits(proto.language.FetchLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.language.FetchLanguageResponse.displayName = 'proto.language.FetchLanguageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.language.ListLanguageByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.language.ListLanguageByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.language.ListLanguageByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.language.ListLanguageByIdsRequest.displayName = 'proto.language.ListLanguageByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.language.ListLanguageByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.language.ListLanguageByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.language.ListLanguageByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.language.ListLanguageByIdsResponse.displayName = 'proto.language.ListLanguageByIdsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.language.LanguageDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.language.LanguageDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.language.LanguageDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.LanguageDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    isoName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isoCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    localName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.language.LanguageDTO}
 */
proto.language.LanguageDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.language.LanguageDTO;
  return proto.language.LanguageDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.language.LanguageDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.language.LanguageDTO}
 */
proto.language.LanguageDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalName(value);
      break;
    case 5:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 6:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.language.LanguageDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.language.LanguageDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.language.LanguageDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.LanguageDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getIsoName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsoCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocalName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.language.LanguageDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.language.LanguageDTO} returns this
*/
proto.language.LanguageDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.language.LanguageDTO} returns this
 */
proto.language.LanguageDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.language.LanguageDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string iso_name = 2;
 * @return {string}
 */
proto.language.LanguageDTO.prototype.getIsoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.language.LanguageDTO} returns this
 */
proto.language.LanguageDTO.prototype.setIsoName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iso_code = 3;
 * @return {string}
 */
proto.language.LanguageDTO.prototype.getIsoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.language.LanguageDTO} returns this
 */
proto.language.LanguageDTO.prototype.setIsoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string local_name = 4;
 * @return {string}
 */
proto.language.LanguageDTO.prototype.getLocalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.language.LanguageDTO} returns this
 */
proto.language.LanguageDTO.prototype.setLocalName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional utils.TimestampDTO createdOn = 5;
 * @return {?proto.utils.TimestampDTO}
 */
proto.language.LanguageDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 5));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.language.LanguageDTO} returns this
*/
proto.language.LanguageDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.language.LanguageDTO} returns this
 */
proto.language.LanguageDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.language.LanguageDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 6;
 * @return {?proto.utils.TimestampDTO}
 */
proto.language.LanguageDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 6));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.language.LanguageDTO} returns this
*/
proto.language.LanguageDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.language.LanguageDTO} returns this
 */
proto.language.LanguageDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.language.LanguageDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.language.FetchLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.language.FetchLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.language.FetchLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.FetchLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.language.FetchLanguageRequest}
 */
proto.language.FetchLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.language.FetchLanguageRequest;
  return proto.language.FetchLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.language.FetchLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.language.FetchLanguageRequest}
 */
proto.language.FetchLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.language.FetchLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.language.FetchLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.language.FetchLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.FetchLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.language.FetchLanguageRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.language.FetchLanguageRequest} returns this
*/
proto.language.FetchLanguageRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.language.FetchLanguageRequest} returns this
 */
proto.language.FetchLanguageRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.language.FetchLanguageRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.language.FetchLanguageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.language.FetchLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.language.FetchLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.language.FetchLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.FetchLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.language.LanguageDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.language.FetchLanguageResponse}
 */
proto.language.FetchLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.language.FetchLanguageResponse;
  return proto.language.FetchLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.language.FetchLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.language.FetchLanguageResponse}
 */
proto.language.FetchLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.language.LanguageDTO;
      reader.readMessage(value,proto.language.LanguageDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.language.FetchLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.language.FetchLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.language.FetchLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.FetchLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.language.LanguageDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LanguageDTO items = 1;
 * @return {!Array<!proto.language.LanguageDTO>}
 */
proto.language.FetchLanguageResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.language.LanguageDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.language.LanguageDTO, 1));
};


/**
 * @param {!Array<!proto.language.LanguageDTO>} value
 * @return {!proto.language.FetchLanguageResponse} returns this
*/
proto.language.FetchLanguageResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.language.LanguageDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.language.LanguageDTO}
 */
proto.language.FetchLanguageResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.language.LanguageDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.language.FetchLanguageResponse} returns this
 */
proto.language.FetchLanguageResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.language.FetchLanguageResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.language.FetchLanguageResponse} returns this
*/
proto.language.FetchLanguageResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.language.FetchLanguageResponse} returns this
 */
proto.language.FetchLanguageResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.language.FetchLanguageResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.language.ListLanguageByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.language.ListLanguageByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.language.ListLanguageByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.language.ListLanguageByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.ListLanguageByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.language.ListLanguageByIdsRequest}
 */
proto.language.ListLanguageByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.language.ListLanguageByIdsRequest;
  return proto.language.ListLanguageByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.language.ListLanguageByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.language.ListLanguageByIdsRequest}
 */
proto.language.ListLanguageByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.language.ListLanguageByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.language.ListLanguageByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.language.ListLanguageByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.ListLanguageByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.language.ListLanguageByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.language.ListLanguageByIdsRequest} returns this
*/
proto.language.ListLanguageByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.language.ListLanguageByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.language.ListLanguageByIdsRequest} returns this
 */
proto.language.ListLanguageByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.language.ListLanguageByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.language.ListLanguageByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.language.ListLanguageByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.language.ListLanguageByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.ListLanguageByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.language.LanguageDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.language.ListLanguageByIdsResponse}
 */
proto.language.ListLanguageByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.language.ListLanguageByIdsResponse;
  return proto.language.ListLanguageByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.language.ListLanguageByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.language.ListLanguageByIdsResponse}
 */
proto.language.ListLanguageByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.language.LanguageDTO;
      reader.readMessage(value,proto.language.LanguageDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.language.ListLanguageByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.language.ListLanguageByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.language.ListLanguageByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.language.ListLanguageByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.language.LanguageDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LanguageDTO items = 1;
 * @return {!Array<!proto.language.LanguageDTO>}
 */
proto.language.ListLanguageByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.language.LanguageDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.language.LanguageDTO, 1));
};


/**
 * @param {!Array<!proto.language.LanguageDTO>} value
 * @return {!proto.language.ListLanguageByIdsResponse} returns this
*/
proto.language.ListLanguageByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.language.LanguageDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.language.LanguageDTO}
 */
proto.language.ListLanguageByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.language.LanguageDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.language.ListLanguageByIdsResponse} returns this
 */
proto.language.ListLanguageByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.language.ListLanguageByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.language.ListLanguageByIdsResponse} returns this
*/
proto.language.ListLanguageByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.language.ListLanguageByIdsResponse} returns this
 */
proto.language.ListLanguageByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.language.ListLanguageByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.language);
