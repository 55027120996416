import {ProgressStateEnumDTO, UUID_DTO} from "proto/utils_pb";
import {InternalErrorTypes, isError, IUIError, NewUIError,} from "service/cartaError";
import {convertDateToTimestamp, convertOptTimestampToDate, convertTimestampToDate, ListItem,} from "utils/utils";
import {ReviewSM2CardDTO, ReviewSM2DTO} from "proto/reviewSM2_pb";
import {getUserId} from "service/AuthService";
import {ReviewSM2Card, ReviewSM2CardStat} from "./ReviewSM2Card";
import {IFromDTO, IReceiveOnlyModel} from "./model";
import {ReviewSM2StatDTO} from "proto/stats_pb";
import {IReview, IReviewStat} from "./Review";
import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {convertFromDTOToID} from "./CardLang";
import { ReviewKindEnumDTO } from "proto/reviewManual_pb";

export class ReviewSM2
    extends BaseModel<ReviewSM2, ReviewSM2DTO>
    implements IReview, IFromDTO<ReviewSM2DTO> {
    private _name?: string;
    private _description?: string;
    private _cards: ReviewSM2Card[];
    private _kind: ReviewKindEnumDTO = ReviewKindEnumDTO.SM2;
    private _progressState: ProgressStateEnumDTO;
    private _note: string;
    private _startAt?: Date;
    private _endAt?: Date;
    private _createdOn: Date;
    private _updatedOn: Date;
    private _archivedOn?: Date;

    constructor() {
        super();

        let now = new Date();

        this._name = undefined;
        this._description = undefined;
        this._note = "";
        this._startAt = undefined;
        this._endAt = undefined;
        this._createdOn = now;
        this["_updatedOn"] = now;
        this._archivedOn = undefined;
        this._cards = [];
        this._progressState = ProgressStateEnumDTO.NOT_STARTED;
    }

    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.name ? this.name : `SM2 Review: ${this.createdOn.toString()}`,
            metadata1: this._description,
        }
    }

    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }

    to1LineString(): String {
        throw new Error("Method not implemented.");
    }

    init(): ReviewSM2 {
        return new ReviewSM2();
    }

    TYPE: EntityKind = EntityKind.ReviewSM2;

    intoDTO(): ReviewSM2DTO | IUIError {
        let dto = new ReviewSM2DTO();

        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setCreatedon(convertDateToTimestamp(this._createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this._updatedOn));
        dto.setNote(this._note);
        dto.setProgressState(this._progressState);

        if (this._name) {
            dto.setName(this._name);
        }
        if (this._description) {
            dto.setDescription(this._description);
        }
        if (this._endAt) {
            dto.setEndAt(convertDateToTimestamp(this._endAt));
        }
        if (this._startAt) {
            dto.setStartAt(convertDateToTimestamp(this._startAt));
        }
        if (this._archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(this._archivedOn));
        }

        return dto;
    }

    validate(): ReviewSM2 | IUIError {
        if (this.name && this.name?.length > 100) {
            return NewUIError(
                "validate",
                InternalErrorTypes.ValidateReview,
                "name field > 100",
                "name cannot be greater than 100 characters"
            );
        }

        if (this.description && this.description?.length > 250) {
            return NewUIError(
                "validate",
                InternalErrorTypes.ValidateReview,
                "description field > 250",
                "description cannot be greater than 100 characters"
            );
        }

        return this;
    }

    sanitize(): ReviewSM2 {
        this._note = this._note.trim();
        if (this._name) {
            this._name = this._name.trim();
        }
        if (this._description) {
            this._description = this._description.trim();
        }
        this.userId = getUserId();

        return this;
    }

    fromDTO(dto: ReviewSM2DTO): void | IUIError {
        const id = convertFromDTOToID(this.TYPE, dto.getId());
        const userId = convertFromDTOToID(this.TYPE, dto.getUserId());

        if (!dto.getCreatedon()) {
            return NewUIError(
                "convertDTOToReviewSM2",
                InternalErrorTypes.InvalidReview,
                `review created date is empty '' - review: ${dto}"`
            );
        }

        if (!dto.getUpdatedon()) {
            return NewUIError(
                "convertDTOToReviewSM2",
                InternalErrorTypes.InvalidReview,
                `review updated date is empty '' - review: ${dto}"`
            );
        }

        const createdOn = convertTimestampToDate(dto.getCreatedon()!);
        const updatedOn = convertTimestampToDate(dto.getUpdatedon()!);
        const archivedOn: Date | undefined = convertOptTimestampToDate(
            dto.getArchivedon()
        );
        const startAt: Date | undefined = convertOptTimestampToDate(
            dto.getStartAt()
        );
        const endAt: Date | undefined = convertOptTimestampToDate(dto.getEndAt());

        let reviewCards: ReviewSM2Card[] = [];

        this.id = id;
        this.userId = userId;
        this._cards = reviewCards;
        this._createdOn = createdOn;
        this._kind = ReviewKindEnumDTO.SM2;
        this._description = dto.getDescription();
        this._endAt = endAt;
        this._startAt = startAt;
        this._name = dto.getName();
        this._note = dto.getNote();
        this._progressState = dto.getProgressState();
        this._updatedOn = updatedOn;
        this._archivedOn = archivedOn;
    }

    static init = (userId: string): ReviewSM2 => {
        const now = new Date();

        let review = new ReviewSM2();
        review.userId = userId;
        review.progressState = ProgressStateEnumDTO.NOT_STARTED;
        review.updatedOn = now;
        review.kind = ReviewKindEnumDTO.SM2;
        review.sanitize();

        return review;
    };

    convertReviewSM2ToDTO = (): ReviewSM2DTO => {
        let dto = new ReviewSM2DTO();
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setCreatedon(convertDateToTimestamp(this._createdOn));
        dto.setUpdatedon(convertDateToTimestamp(this._updatedOn));
        dto.setNote(this._note);
        dto.setProgressState(this._progressState);

        if (this._name) {
            dto.setName(this._name);
        }
        if (this._description) {
            dto.setDescription(this._description);
        }
        if (this._endAt) {
            dto.setEndAt(convertDateToTimestamp(this._endAt));
        }
        if (this._startAt) {
            dto.setStartAt(convertDateToTimestamp(this._startAt));
        }
        if (this._archivedOn) {
            dto.setArchivedon(convertDateToTimestamp(this._archivedOn));
        }

        let dtos: ReviewSM2CardDTO[] = [];

        this._cards.forEach((card) => {
            let dto = card.intoDTO();
            if (isError(dto)) {
                return dto as IUIError;
            }

            dtos.push(dto as ReviewSM2CardDTO);
        });

        // dto.setCardsList(dtos);

        return dto;
    };

    public clone(): ReviewSM2 {
        let n = new ReviewSM2();

        n.id = `${this.id}`;
        n.userId = `${this.userId}`;
        n.note = `${this.note}`;
        if (n.name) {
            n.name = `${this.name}`;
        }
        if (n.description) {
            n.description = `${this.description}`;
        }
        n.cards = [...this.cards];
        n.endAt = this.endAt;
        n.kind = ReviewKindEnumDTO.SM2;
        n.startAt = this.startAt;
        n.progressState = this.progressState;
        n.createdOn = this.createdOn;
        n.updatedOn = this.updatedOn;
        n.archivedOn = this.archivedOn;

        return n;
    }

    get kind(): ReviewKindEnumDTO {
        return this._kind;
    }

    set kind(value: ReviewKindEnumDTO) {
        this._kind = value;
    }

    get name(): string | undefined {
        return this._name;
    }

    set name(value: string | undefined) {
        this._name = value;
    }

    get description(): string | undefined {
        return this._description;
    }

    set description(value: string | undefined) {
        this._description = value;
    }

    get cards(): ReviewSM2Card[] {
        return this._cards;
    }

    set cards(value: ReviewSM2Card[]) {
        this._cards = value;
    }

    get progressState(): ProgressStateEnumDTO {
        return this._progressState;
    }

    set progressState(value: ProgressStateEnumDTO) {
        this._progressState = value;
    }

    get note(): string {
        return this._note;
    }

    set note(value: string) {
        this._note = value;
    }

    get startAt(): Date | undefined {
        return this._startAt;
    }

    set startAt(value: Date | undefined) {
        this._startAt = value;
    }

    get endAt(): Date | undefined {
        return this._endAt;
    }

    set endAt(value: Date | undefined) {
        this._endAt = value;
    }

    get archivedOn(): Date | undefined {
        return this._archivedOn;
    }

    set archivedOn(value: Date | undefined) {
        this._archivedOn = value;
    }
}

export class ReviewSM2Stat
    implements IReceiveOnlyModel<ReviewSM2Stat, ReviewSM2StatDTO>, IReviewStat {
    TYPE: EntityKind = EntityKind.ReviewSM2Stat;

    clone(): ReviewSM2Stat {
        let stat = new ReviewSM2Stat();

        stat.id = this.id;
        stat.strongestCards = [...this.strongestCards];
        stat.weakestCards = [...this.weakestCards];
        stat.avgCompletedQuality = this.avgCompletedQuality;
        stat.avgCompletedInterval = this.avgCompletedInterval;
        stat.avgCompletedRepetitions = this.avgCompletedRepetitions;
        stat.avgQuality = this.avgQuality;
        stat.avgInterval = this.avgInterval;
        stat.avgRepetition = this.avgRepetition;
        stat.mostRepeatedCard = this.mostRepeatedCard;
        if (this.reviewCards) {
            stat.reviewCards = [...this.reviewCards];
        }

        return stat;
    }

    id: string = "";
    strongestCards: string[] = [];
    weakestCards: string[] = [];
    avgCompletedQuality?: number;
    avgCompletedInterval?: number;
    avgCompletedRepetitions?: number;
    avgQuality?: number;
    avgInterval?: number;
    avgRepetition?: number;
    mostRepeatedCard?: string;
    reviewCards: ReviewSM2CardStat[] = [];

    fromDTO(review: ReviewSM2StatDTO): void | IUIError {
        if (review.getId()) {
            if (review.getId()!.getValue()) {
                this.id = review.getId()!.getValue();
            } else {
                return NewUIError(
                    "fromDTO",
                    InternalErrorTypes.InvalidReviewCard,
                    `getId is empty '' - reviewCard: ${review}"`
                );
            }
        } else {
            return NewUIError(
                "fromDTO",
                InternalErrorTypes.InvalidReviewCard,
                `getId is undefined '' - reviewCard: ${review}"`
            );
        }

        if (review.getMostrepeatedcard()) {
            this.mostRepeatedCard = review.getMostrepeatedcard()!.getValue();
        } else {
            // return NewUIError(
            //   "fromDTO",
            //   InternalErrorTypes.InvalidReviewCard,
            //   `getMostrepeatedcard is undefined '' - ${review}"`
            // );
        }

        this.strongestCards = review
            .getStrongestcardsList()
            .map((x) => x.getValue());
        this.weakestCards = review.getWeakestcardsList().map((x) => x.getValue());
        this.avgCompletedRepetitions = review.getAvgcompletedrepetition();
        this.avgRepetition = review.getAvgrepetition();
        this.avgQuality = review.getAvgquality();
        this.avgCompletedQuality = review.getAvgcompletedquality();
        this.avgCompletedInterval = review.getAvgcompletedinterval();
        this.avgInterval = review.getAvginterval();
        this.reviewCards = review.getReviewcardstatsList().map((x) => {
            let stat = new ReviewSM2CardStat();
            stat.fromDTO(x);
            return stat;
        });
    }
}

// Create a function that creates a ReviewSM2 object with dummy data, that takes in a userId and randomly generates
// short strings for the other string data
