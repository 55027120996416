// source: reviewSM2.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var card_pb = require('./card_pb.js');
goog.object.extend(proto, card_pb);
var cardLang_pb = require('./cardLang_pb.js');
goog.object.extend(proto, cardLang_pb);
var stats_pb = require('./stats_pb.js');
goog.object.extend(proto, stats_pb);
var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.reviewSM2.CompleteReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.CompleteReviewSM2Response', null, global);
goog.exportSymbol('proto.reviewSM2.CreateReviewSM2Metadata', null, global);
goog.exportSymbol('proto.reviewSM2.CreateReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.CreateReviewSM2Response', null, global);
goog.exportSymbol('proto.reviewSM2.DeleteReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.DeleteReviewSM2Response', null, global);
goog.exportSymbol('proto.reviewSM2.GetMostRecentReviewRequest', null, global);
goog.exportSymbol('proto.reviewSM2.GetMostRecentReviewResponse', null, global);
goog.exportSymbol('proto.reviewSM2.GetReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.GetReviewSM2Response', null, global);
goog.exportSymbol('proto.reviewSM2.GetReviewSM2StatsRequest', null, global);
goog.exportSymbol('proto.reviewSM2.GetReviewSM2StatsResponse', null, global);
goog.exportSymbol('proto.reviewSM2.ListCardsToReviewByConfigRequest', null, global);
goog.exportSymbol('proto.reviewSM2.ListCardsToReviewByConfigResponse', null, global);
goog.exportSymbol('proto.reviewSM2.ListReviewSM2ByIdsRequest', null, global);
goog.exportSymbol('proto.reviewSM2.ListReviewSM2ByIdsResponse', null, global);
goog.exportSymbol('proto.reviewSM2.ListReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.ListReviewSM2Response', null, global);
goog.exportSymbol('proto.reviewSM2.ListSM2CardsRequest', null, global);
goog.exportSymbol('proto.reviewSM2.ListSM2CardsResponse', null, global);
goog.exportSymbol('proto.reviewSM2.ListUnreviewedSM2CardsRequest', null, global);
goog.exportSymbol('proto.reviewSM2.ListUnreviewedSM2CardsResponse', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2CardCompositeDTO', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2CardDTO', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2CardLangCompositeDTO', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2CardListOptsDTO', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2CardOrderFieldsDTO', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2DTO', null, global);
goog.exportSymbol('proto.reviewSM2.ReviewSM2FilterConfigDTO', null, global);
goog.exportSymbol('proto.reviewSM2.SaveReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.SaveReviewSM2Response', null, global);
goog.exportSymbol('proto.reviewSM2.SaveReviewSM2SessionCardsRequest', null, global);
goog.exportSymbol('proto.reviewSM2.SaveReviewSM2SessionCardsResponse', null, global);
goog.exportSymbol('proto.reviewSM2.UpdateReviewSM2Request', null, global);
goog.exportSymbol('proto.reviewSM2.UpdateReviewSM2Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ReviewSM2CardListOptsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ReviewSM2CardListOptsDTO.displayName = 'proto.reviewSM2.ReviewSM2CardListOptsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ReviewSM2FilterConfigDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ReviewSM2FilterConfigDTO.displayName = 'proto.reviewSM2.ReviewSM2FilterConfigDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ReviewSM2DTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ReviewSM2DTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ReviewSM2DTO.displayName = 'proto.reviewSM2.ReviewSM2DTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ReviewSM2CardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ReviewSM2CardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ReviewSM2CardDTO.displayName = 'proto.reviewSM2.ReviewSM2CardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ReviewSM2CardCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ReviewSM2CardCompositeDTO.displayName = 'proto.reviewSM2.ReviewSM2CardCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ReviewSM2CardLangCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ReviewSM2CardLangCompositeDTO.displayName = 'proto.reviewSM2.ReviewSM2CardLangCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.DeleteReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.DeleteReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.DeleteReviewSM2Request.displayName = 'proto.reviewSM2.DeleteReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.DeleteReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.DeleteReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.DeleteReviewSM2Response.displayName = 'proto.reviewSM2.DeleteReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.GetMostRecentReviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.GetMostRecentReviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.GetMostRecentReviewRequest.displayName = 'proto.reviewSM2.GetMostRecentReviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.GetMostRecentReviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.GetMostRecentReviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.GetMostRecentReviewResponse.displayName = 'proto.reviewSM2.GetMostRecentReviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListSM2CardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ListSM2CardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListSM2CardsRequest.displayName = 'proto.reviewSM2.ListSM2CardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListSM2CardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.ListSM2CardsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.ListSM2CardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListSM2CardsResponse.displayName = 'proto.reviewSM2.ListSM2CardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.UpdateReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.UpdateReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.UpdateReviewSM2Request.displayName = 'proto.reviewSM2.UpdateReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.UpdateReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.UpdateReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.UpdateReviewSM2Response.displayName = 'proto.reviewSM2.UpdateReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.GetReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.GetReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.GetReviewSM2Request.displayName = 'proto.reviewSM2.GetReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.GetReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.GetReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.GetReviewSM2Response.displayName = 'proto.reviewSM2.GetReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.GetReviewSM2StatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.GetReviewSM2StatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.GetReviewSM2StatsRequest.displayName = 'proto.reviewSM2.GetReviewSM2StatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.GetReviewSM2StatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.GetReviewSM2StatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.GetReviewSM2StatsResponse.displayName = 'proto.reviewSM2.GetReviewSM2StatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.CompleteReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.CompleteReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.CompleteReviewSM2Request.displayName = 'proto.reviewSM2.CompleteReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.CompleteReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.CompleteReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.CompleteReviewSM2Response.displayName = 'proto.reviewSM2.CompleteReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.SaveReviewSM2SessionCardsRequest.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.SaveReviewSM2SessionCardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.SaveReviewSM2SessionCardsRequest.displayName = 'proto.reviewSM2.SaveReviewSM2SessionCardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.SaveReviewSM2SessionCardsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.SaveReviewSM2SessionCardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.SaveReviewSM2SessionCardsResponse.displayName = 'proto.reviewSM2.SaveReviewSM2SessionCardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.SaveReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.SaveReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.SaveReviewSM2Request.displayName = 'proto.reviewSM2.SaveReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.SaveReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.SaveReviewSM2Response.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.SaveReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.SaveReviewSM2Response.displayName = 'proto.reviewSM2.SaveReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ListCardsToReviewByConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListCardsToReviewByConfigRequest.displayName = 'proto.reviewSM2.ListCardsToReviewByConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.ListCardsToReviewByConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.ListCardsToReviewByConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListCardsToReviewByConfigResponse.displayName = 'proto.reviewSM2.ListCardsToReviewByConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ListUnreviewedSM2CardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListUnreviewedSM2CardsRequest.displayName = 'proto.reviewSM2.ListUnreviewedSM2CardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.ListUnreviewedSM2CardsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.ListUnreviewedSM2CardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListUnreviewedSM2CardsResponse.displayName = 'proto.reviewSM2.ListUnreviewedSM2CardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.CreateReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.CreateReviewSM2Request.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.CreateReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.CreateReviewSM2Request.displayName = 'proto.reviewSM2.CreateReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.CreateReviewSM2Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.CreateReviewSM2Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.CreateReviewSM2Metadata.displayName = 'proto.reviewSM2.CreateReviewSM2Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.CreateReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.CreateReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.CreateReviewSM2Response.displayName = 'proto.reviewSM2.CreateReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListReviewSM2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewSM2.ListReviewSM2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListReviewSM2Request.displayName = 'proto.reviewSM2.ListReviewSM2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListReviewSM2Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.ListReviewSM2Response.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.ListReviewSM2Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListReviewSM2Response.displayName = 'proto.reviewSM2.ListReviewSM2Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.ListReviewSM2ByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.ListReviewSM2ByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListReviewSM2ByIdsRequest.displayName = 'proto.reviewSM2.ListReviewSM2ByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewSM2.ListReviewSM2ByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewSM2.ListReviewSM2ByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewSM2.ListReviewSM2ByIdsResponse.displayName = 'proto.reviewSM2.ListReviewSM2ByIdsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ReviewSM2CardListOptsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ReviewSM2CardListOptsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderField: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderDirection: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ReviewSM2CardListOptsDTO}
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ReviewSM2CardListOptsDTO;
  return proto.reviewSM2.ReviewSM2CardListOptsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ReviewSM2CardListOptsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ReviewSM2CardListOptsDTO}
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.reviewSM2.ReviewSM2CardOrderFieldsDTO} */ (reader.readEnum());
      msg.setOrderField(value);
      break;
    case 2:
      var value = /** @type {!proto.utils.ListOptionsOrderDTO} */ (reader.readEnum());
      msg.setOrderDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ReviewSM2CardListOptsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ReviewSM2CardListOptsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrderDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ReviewSM2CardOrderFieldsDTO order_field = 1;
 * @return {!proto.reviewSM2.ReviewSM2CardOrderFieldsDTO}
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.prototype.getOrderField = function() {
  return /** @type {!proto.reviewSM2.ReviewSM2CardOrderFieldsDTO} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardOrderFieldsDTO} value
 * @return {!proto.reviewSM2.ReviewSM2CardListOptsDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.prototype.setOrderField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional utils.ListOptionsOrderDTO order_direction = 2;
 * @return {!proto.utils.ListOptionsOrderDTO}
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.prototype.getOrderDirection = function() {
  return /** @type {!proto.utils.ListOptionsOrderDTO} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.utils.ListOptionsOrderDTO} value
 * @return {!proto.reviewSM2.ReviewSM2CardListOptsDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardListOptsDTO.prototype.setOrderDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ReviewSM2FilterConfigDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ReviewSM2FilterConfigDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    istemplate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    cardkind: jspb.Message.getFieldWithDefault(msg, 4, 0),
    newnessfactor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ReviewSM2FilterConfigDTO;
  return proto.reviewSM2.ReviewSM2FilterConfigDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ReviewSM2FilterConfigDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstemplate(value);
      break;
    case 4:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardkind(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewnessfactor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ReviewSM2FilterConfigDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ReviewSM2FilterConfigDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getIstemplate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCardkind();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNewnessfactor();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
*/
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
*/
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool isTemplate = 3;
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getIstemplate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setIstemplate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional utils.EnumReviewCardKindDTO cardKind = 4;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getCardkind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setCardkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 newnessFactor = 5;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getNewnessfactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setNewnessfactor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 limit = 6;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 offset = 7;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2FilterConfigDTO} returns this
 */
proto.reviewSM2.ReviewSM2FilterConfigDTO.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ReviewSM2DTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ReviewSM2DTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2DTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    progressState: jspb.Message.getFieldWithDefault(msg, 6, 0),
    note: jspb.Message.getFieldWithDefault(msg, 8, ""),
    startAt: (f = msg.getStartAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endAt: (f = msg.getEndAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.ReviewSM2DTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ReviewSM2DTO;
  return proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ReviewSM2DTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.utils.ProgressStateEnumDTO} */ (reader.readEnum());
      msg.setProgressState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartAt(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndAt(value);
      break;
    case 11:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 13:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ReviewSM2DTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgressState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStartAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional utils.ProgressStateEnumDTO progress_state = 6;
 * @return {!proto.utils.ProgressStateEnumDTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getProgressState = function() {
  return /** @type {!proto.utils.ProgressStateEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.utils.ProgressStateEnumDTO} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.setProgressState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional utils.TimestampDTO start_at = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getStartAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearStartAt = function() {
  return this.setStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasStartAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO end_at = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getEndAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearEndAt = function() {
  return this.setEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasEndAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 11;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 11));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 13;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 13));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
*/
proto.reviewSM2.ReviewSM2DTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2DTO} returns this
 */
proto.reviewSM2.ReviewSM2DTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2DTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ReviewSM2CardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ReviewSM2CardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardkind: jspb.Message.getFieldWithDefault(msg, 5, 0),
    quality: jspb.Message.getFieldWithDefault(msg, 6, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 7, 0),
    easiness: jspb.Message.getFieldWithDefault(msg, 8, 0),
    repetitions: jspb.Message.getFieldWithDefault(msg, 9, 0),
    startAt: (f = msg.getStartAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endAt: (f = msg.getEndAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    nextReview: (f = msg.getNextReview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    composite: (f = msg.getComposite()) && proto.reviewSM2.ReviewSM2CardCompositeDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ReviewSM2CardDTO;
  return proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ReviewSM2CardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 5:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardkind(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEasiness(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetitions(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartAt(value);
      break;
    case 11:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndAt(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setNextReview(value);
      break;
    case 13:
      var value = new proto.reviewSM2.ReviewSM2CardCompositeDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 14:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 15:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 16:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ReviewSM2CardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardkind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getEasiness();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getRepetitions();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getStartAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getNextReview();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.reviewSM2.ReviewSM2CardCompositeDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO cardId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO reviewID = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional utils.EnumReviewCardKindDTO cardKind = 5;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getCardkind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.setCardkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int32 quality = 6;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.setQuality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearQuality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasQuality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 interval = 7;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 easiness = 8;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getEasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.setEasiness = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 repetitions = 9;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.setRepetitions = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional utils.TimestampDTO start_at = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getStartAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearStartAt = function() {
  return this.setStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasStartAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional utils.TimestampDTO end_at = 11;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getEndAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 11));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearEndAt = function() {
  return this.setEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasEndAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO next_review = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getNextReview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setNextReview = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearNextReview = function() {
  return this.setNextReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasNextReview = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ReviewSM2CardCompositeDTO composite = 13;
 * @return {?proto.reviewSM2.ReviewSM2CardCompositeDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getComposite = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2CardCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2CardCompositeDTO, 13));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2CardCompositeDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 14;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 14));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 15;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 15));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 16;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 16));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ReviewSM2CardCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ReviewSM2CardCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    supertypeId: (f = msg.getSupertypeId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ord: jspb.Message.getFieldWithDefault(msg, 2, 0),
    card: (f = msg.getCard()) && card_pb.CardDTO.toObject(includeInstance, f),
    cardLang: (f = msg.getCardLang()) && cardLang_pb.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ReviewSM2CardCompositeDTO;
  return proto.reviewSM2.ReviewSM2CardCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ReviewSM2CardCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setSupertypeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrd(value);
      break;
    case 3:
      var value = new card_pb.CardDTO;
      reader.readMessage(value,card_pb.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 4:
      var value = new cardLang_pb.CardLangDTO;
      reader.readMessage(value,cardLang_pb.CardLangDTO.deserializeBinaryFromReader);
      msg.setCardLang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ReviewSM2CardCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ReviewSM2CardCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupertypeId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOrd();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      card_pb.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardLang();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      cardLang_pb.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO supertype_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.getSupertypeId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.setSupertypeId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.clearSupertypeId = function() {
  return this.setSupertypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.hasSupertypeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 ord = 2;
 * @return {number}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.getOrd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.setOrd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional card.CardDTO card = 3;
 * @return {?proto.card.CardDTO}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, card_pb.CardDTO, 3));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional cardLang.CardLangDTO card_lang = 4;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.getCardLang = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, cardLang_pb.CardLangDTO, 4));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.setCardLang = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardCompositeDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.clearCardLang = function() {
  return this.setCardLang(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardCompositeDTO.prototype.hasCardLang = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ReviewSM2CardLangCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewcard: (f = msg.getReviewcard()) && proto.reviewSM2.ReviewSM2CardDTO.toObject(includeInstance, f),
    card: (f = msg.getCard()) && cardLang_pb.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ReviewSM2CardLangCompositeDTO;
  return proto.reviewSM2.ReviewSM2CardLangCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.setReviewcard(value);
      break;
    case 5:
      var value = new cardLang_pb.CardLangDTO;
      reader.readMessage(value,cardLang_pb.CardLangDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ReviewSM2CardLangCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewcard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      cardLang_pb.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2CardDTO reviewCard = 1;
 * @return {?proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.getReviewcard = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2CardDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2CardDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.setReviewcard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.clearReviewcard = function() {
  return this.setReviewcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.hasReviewcard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cardLang.CardLangDTO card = 5;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.getCard = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, cardLang_pb.CardLangDTO, 5));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} returns this
*/
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ReviewSM2CardLangCompositeDTO} returns this
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ReviewSM2CardLangCompositeDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.DeleteReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.DeleteReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.DeleteReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.DeleteReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.DeleteReviewSM2Request}
 */
proto.reviewSM2.DeleteReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.DeleteReviewSM2Request;
  return proto.reviewSM2.DeleteReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.DeleteReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.DeleteReviewSM2Request}
 */
proto.reviewSM2.DeleteReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.DeleteReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.DeleteReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.DeleteReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.DeleteReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.DeleteReviewSM2Request.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.DeleteReviewSM2Request} returns this
*/
proto.reviewSM2.DeleteReviewSM2Request.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.DeleteReviewSM2Request} returns this
 */
proto.reviewSM2.DeleteReviewSM2Request.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.DeleteReviewSM2Request.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.DeleteReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.DeleteReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.DeleteReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.DeleteReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.DeleteReviewSM2Response}
 */
proto.reviewSM2.DeleteReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.DeleteReviewSM2Response;
  return proto.reviewSM2.DeleteReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.DeleteReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.DeleteReviewSM2Response}
 */
proto.reviewSM2.DeleteReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.DeleteReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.DeleteReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.DeleteReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.DeleteReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.GetMostRecentReviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.GetMostRecentReviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.GetMostRecentReviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetMostRecentReviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.GetMostRecentReviewRequest}
 */
proto.reviewSM2.GetMostRecentReviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.GetMostRecentReviewRequest;
  return proto.reviewSM2.GetMostRecentReviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.GetMostRecentReviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.GetMostRecentReviewRequest}
 */
proto.reviewSM2.GetMostRecentReviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.GetMostRecentReviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.GetMostRecentReviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.GetMostRecentReviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetMostRecentReviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.GetMostRecentReviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.GetMostRecentReviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.GetMostRecentReviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetMostRecentReviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.GetMostRecentReviewResponse}
 */
proto.reviewSM2.GetMostRecentReviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.GetMostRecentReviewResponse;
  return proto.reviewSM2.GetMostRecentReviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.GetMostRecentReviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.GetMostRecentReviewResponse}
 */
proto.reviewSM2.GetMostRecentReviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.GetMostRecentReviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.GetMostRecentReviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.GetMostRecentReviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetMostRecentReviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.GetMostRecentReviewResponse.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.GetMostRecentReviewResponse} returns this
*/
proto.reviewSM2.GetMostRecentReviewResponse.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.GetMostRecentReviewResponse} returns this
 */
proto.reviewSM2.GetMostRecentReviewResponse.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.GetMostRecentReviewResponse.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListSM2CardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListSM2CardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListSM2CardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && proto.reviewSM2.ReviewSM2CardListOptsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListSM2CardsRequest}
 */
proto.reviewSM2.ListSM2CardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListSM2CardsRequest;
  return proto.reviewSM2.ListSM2CardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListSM2CardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListSM2CardsRequest}
 */
proto.reviewSM2.ListSM2CardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 2:
      var value = new proto.reviewSM2.ReviewSM2CardListOptsDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardListOptsDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListSM2CardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListSM2CardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListSM2CardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reviewSM2.ReviewSM2CardListOptsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ListSM2CardsRequest} returns this
*/
proto.reviewSM2.ListSM2CardsRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListSM2CardsRequest} returns this
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewSM2CardListOptsDTO opts = 2;
 * @return {?proto.reviewSM2.ReviewSM2CardListOptsDTO}
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.getOpts = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2CardListOptsDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2CardListOptsDTO, 2));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2CardListOptsDTO|undefined} value
 * @return {!proto.reviewSM2.ListSM2CardsRequest} returns this
*/
proto.reviewSM2.ListSM2CardsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListSM2CardsRequest} returns this
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListSM2CardsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.ListSM2CardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListSM2CardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListSM2CardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListSM2CardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance),
    responseinfo: (f = msg.getResponseinfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListSM2CardsResponse}
 */
proto.reviewSM2.ListSM2CardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListSM2CardsResponse;
  return proto.reviewSM2.ListSM2CardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListSM2CardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListSM2CardsResponse}
 */
proto.reviewSM2.ListSM2CardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setResponseinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListSM2CardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListSM2CardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListSM2CardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getResponseinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewSM2CardDTO cards = 1;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 1));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.ListSM2CardsResponse} returns this
*/
proto.reviewSM2.ListSM2CardsResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.ListSM2CardsResponse} returns this
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO responseInfo = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.getResponseinfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewSM2.ListSM2CardsResponse} returns this
*/
proto.reviewSM2.ListSM2CardsResponse.prototype.setResponseinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListSM2CardsResponse} returns this
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.clearResponseinfo = function() {
  return this.setResponseinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListSM2CardsResponse.prototype.hasResponseinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.UpdateReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.UpdateReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.UpdateReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.UpdateReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.UpdateReviewSM2Request}
 */
proto.reviewSM2.UpdateReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.UpdateReviewSM2Request;
  return proto.reviewSM2.UpdateReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.UpdateReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.UpdateReviewSM2Request}
 */
proto.reviewSM2.UpdateReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.UpdateReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.UpdateReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.UpdateReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.UpdateReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.UpdateReviewSM2Request.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.UpdateReviewSM2Request} returns this
*/
proto.reviewSM2.UpdateReviewSM2Request.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.UpdateReviewSM2Request} returns this
 */
proto.reviewSM2.UpdateReviewSM2Request.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.UpdateReviewSM2Request.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.UpdateReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.UpdateReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.UpdateReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.UpdateReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.UpdateReviewSM2Response}
 */
proto.reviewSM2.UpdateReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.UpdateReviewSM2Response;
  return proto.reviewSM2.UpdateReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.UpdateReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.UpdateReviewSM2Response}
 */
proto.reviewSM2.UpdateReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.UpdateReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.UpdateReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.UpdateReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.UpdateReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.UpdateReviewSM2Response.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.UpdateReviewSM2Response} returns this
*/
proto.reviewSM2.UpdateReviewSM2Response.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.UpdateReviewSM2Response} returns this
 */
proto.reviewSM2.UpdateReviewSM2Response.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.UpdateReviewSM2Response.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.GetReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.GetReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.GetReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.GetReviewSM2Request}
 */
proto.reviewSM2.GetReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.GetReviewSM2Request;
  return proto.reviewSM2.GetReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.GetReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.GetReviewSM2Request}
 */
proto.reviewSM2.GetReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.GetReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.GetReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.GetReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.GetReviewSM2Request.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.GetReviewSM2Request} returns this
*/
proto.reviewSM2.GetReviewSM2Request.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.GetReviewSM2Request} returns this
 */
proto.reviewSM2.GetReviewSM2Request.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.GetReviewSM2Request.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.GetReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.GetReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.GetReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.GetReviewSM2Response}
 */
proto.reviewSM2.GetReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.GetReviewSM2Response;
  return proto.reviewSM2.GetReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.GetReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.GetReviewSM2Response}
 */
proto.reviewSM2.GetReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.GetReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.GetReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.GetReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.GetReviewSM2Response.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.GetReviewSM2Response} returns this
*/
proto.reviewSM2.GetReviewSM2Response.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.GetReviewSM2Response} returns this
 */
proto.reviewSM2.GetReviewSM2Response.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.GetReviewSM2Response.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.GetReviewSM2StatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.GetReviewSM2StatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.GetReviewSM2StatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2StatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.GetReviewSM2StatsRequest}
 */
proto.reviewSM2.GetReviewSM2StatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.GetReviewSM2StatsRequest;
  return proto.reviewSM2.GetReviewSM2StatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.GetReviewSM2StatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.GetReviewSM2StatsRequest}
 */
proto.reviewSM2.GetReviewSM2StatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.GetReviewSM2StatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.GetReviewSM2StatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.GetReviewSM2StatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2StatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewID = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.GetReviewSM2StatsRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.GetReviewSM2StatsRequest} returns this
*/
proto.reviewSM2.GetReviewSM2StatsRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.GetReviewSM2StatsRequest} returns this
 */
proto.reviewSM2.GetReviewSM2StatsRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.GetReviewSM2StatsRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.GetReviewSM2StatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.GetReviewSM2StatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.GetReviewSM2StatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2StatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && stats_pb.ReviewSM2StatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.GetReviewSM2StatsResponse}
 */
proto.reviewSM2.GetReviewSM2StatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.GetReviewSM2StatsResponse;
  return proto.reviewSM2.GetReviewSM2StatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.GetReviewSM2StatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.GetReviewSM2StatsResponse}
 */
proto.reviewSM2.GetReviewSM2StatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stats_pb.ReviewSM2StatDTO;
      reader.readMessage(value,stats_pb.ReviewSM2StatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.GetReviewSM2StatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.GetReviewSM2StatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.GetReviewSM2StatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.GetReviewSM2StatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stats_pb.ReviewSM2StatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional stats.ReviewSM2StatDTO stats = 1;
 * @return {?proto.stats.ReviewSM2StatDTO}
 */
proto.reviewSM2.GetReviewSM2StatsResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.ReviewSM2StatDTO} */ (
    jspb.Message.getWrapperField(this, stats_pb.ReviewSM2StatDTO, 1));
};


/**
 * @param {?proto.stats.ReviewSM2StatDTO|undefined} value
 * @return {!proto.reviewSM2.GetReviewSM2StatsResponse} returns this
*/
proto.reviewSM2.GetReviewSM2StatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.GetReviewSM2StatsResponse} returns this
 */
proto.reviewSM2.GetReviewSM2StatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.GetReviewSM2StatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.CompleteReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.CompleteReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.CompleteReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CompleteReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.CompleteReviewSM2Request}
 */
proto.reviewSM2.CompleteReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.CompleteReviewSM2Request;
  return proto.reviewSM2.CompleteReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.CompleteReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.CompleteReviewSM2Request}
 */
proto.reviewSM2.CompleteReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.CompleteReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.CompleteReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.CompleteReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CompleteReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.CompleteReviewSM2Request.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.CompleteReviewSM2Request} returns this
*/
proto.reviewSM2.CompleteReviewSM2Request.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.CompleteReviewSM2Request} returns this
 */
proto.reviewSM2.CompleteReviewSM2Request.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.CompleteReviewSM2Request.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.CompleteReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.CompleteReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CompleteReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && stats_pb.ReviewSM2StatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.CompleteReviewSM2Response}
 */
proto.reviewSM2.CompleteReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.CompleteReviewSM2Response;
  return proto.reviewSM2.CompleteReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.CompleteReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.CompleteReviewSM2Response}
 */
proto.reviewSM2.CompleteReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 2:
      var value = new stats_pb.ReviewSM2StatDTO;
      reader.readMessage(value,stats_pb.ReviewSM2StatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.CompleteReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.CompleteReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CompleteReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stats_pb.ReviewSM2StatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.CompleteReviewSM2Response} returns this
*/
proto.reviewSM2.CompleteReviewSM2Response.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.CompleteReviewSM2Response} returns this
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional stats.ReviewSM2StatDTO stats = 2;
 * @return {?proto.stats.ReviewSM2StatDTO}
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.getStats = function() {
  return /** @type{?proto.stats.ReviewSM2StatDTO} */ (
    jspb.Message.getWrapperField(this, stats_pb.ReviewSM2StatDTO, 2));
};


/**
 * @param {?proto.stats.ReviewSM2StatDTO|undefined} value
 * @return {!proto.reviewSM2.CompleteReviewSM2Response} returns this
*/
proto.reviewSM2.CompleteReviewSM2Response.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.CompleteReviewSM2Response} returns this
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.CompleteReviewSM2Response.prototype.hasStats = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.SaveReviewSM2SessionCardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    sm2cardsList: jspb.Message.toObjectList(msg.getSm2cardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.SaveReviewSM2SessionCardsRequest;
  return proto.reviewSM2.SaveReviewSM2SessionCardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 2:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addSm2cards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.SaveReviewSM2SessionCardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getSm2cardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewID = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} returns this
*/
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} returns this
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReviewSM2CardDTO sm2Cards = 2;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.getSm2cardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 2));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} returns this
*/
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.setSm2cardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.addSm2cards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} returns this
 */
proto.reviewSM2.SaveReviewSM2SessionCardsRequest.prototype.clearSm2cardsList = function() {
  return this.setSm2cardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.SaveReviewSM2SessionCardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.SaveReviewSM2SessionCardsResponse;
  return proto.reviewSM2.SaveReviewSM2SessionCardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.SaveReviewSM2SessionCardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewSM2CardDTO cards = 1;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 1));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse} returns this
*/
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.SaveReviewSM2SessionCardsResponse} returns this
 */
proto.reviewSM2.SaveReviewSM2SessionCardsResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.SaveReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.SaveReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && proto.reviewSM2.ReviewSM2FilterConfigDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.SaveReviewSM2Request}
 */
proto.reviewSM2.SaveReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.SaveReviewSM2Request;
  return proto.reviewSM2.SaveReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.SaveReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.SaveReviewSM2Request}
 */
proto.reviewSM2.SaveReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 2:
      var value = new proto.reviewSM2.ReviewSM2FilterConfigDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2FilterConfigDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.SaveReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.SaveReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reviewSM2.ReviewSM2FilterConfigDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.SaveReviewSM2Request} returns this
*/
proto.reviewSM2.SaveReviewSM2Request.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.SaveReviewSM2Request} returns this
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewSM2FilterConfigDTO opts = 2;
 * @return {?proto.reviewSM2.ReviewSM2FilterConfigDTO}
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.getOpts = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2FilterConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2FilterConfigDTO, 2));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2FilterConfigDTO|undefined} value
 * @return {!proto.reviewSM2.SaveReviewSM2Request} returns this
*/
proto.reviewSM2.SaveReviewSM2Request.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.SaveReviewSM2Request} returns this
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.SaveReviewSM2Request.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.SaveReviewSM2Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.SaveReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.SaveReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f),
    availablecardsList: jspb.Message.toObjectList(msg.getAvailablecardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.SaveReviewSM2Response}
 */
proto.reviewSM2.SaveReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.SaveReviewSM2Response;
  return proto.reviewSM2.SaveReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.SaveReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.SaveReviewSM2Response}
 */
proto.reviewSM2.SaveReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 2:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addAvailablecards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.SaveReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.SaveReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.SaveReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getAvailablecardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.SaveReviewSM2Response} returns this
*/
proto.reviewSM2.SaveReviewSM2Response.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.SaveReviewSM2Response} returns this
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReviewSM2CardDTO availableCards = 2;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.getAvailablecardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 2));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.SaveReviewSM2Response} returns this
*/
proto.reviewSM2.SaveReviewSM2Response.prototype.setAvailablecardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.addAvailablecards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.SaveReviewSM2Response} returns this
 */
proto.reviewSM2.SaveReviewSM2Response.prototype.clearAvailablecardsList = function() {
  return this.setAvailablecardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListCardsToReviewByConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && proto.reviewSM2.ReviewSM2FilterConfigDTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigRequest}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListCardsToReviewByConfigRequest;
  return proto.reviewSM2.ListCardsToReviewByConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigRequest}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2FilterConfigDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2FilterConfigDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListCardsToReviewByConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2FilterConfigDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2FilterConfigDTO opts = 1;
 * @return {?proto.reviewSM2.ReviewSM2FilterConfigDTO}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.getOpts = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2FilterConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2FilterConfigDTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2FilterConfigDTO|undefined} value
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigRequest} returns this
*/
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigRequest} returns this
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigRequest} returns this
*/
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigRequest} returns this
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListCardsToReviewByConfigRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListCardsToReviewByConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance),
    responseinfo: (f = msg.getResponseinfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigResponse}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListCardsToReviewByConfigResponse;
  return proto.reviewSM2.ListCardsToReviewByConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigResponse}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setResponseinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListCardsToReviewByConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getResponseinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewSM2CardDTO cards = 1;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 1));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigResponse} returns this
*/
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigResponse} returns this
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO responseInfo = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.getResponseinfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigResponse} returns this
*/
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.setResponseinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListCardsToReviewByConfigResponse} returns this
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.clearResponseinfo = function() {
  return this.setResponseinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListCardsToReviewByConfigResponse.prototype.hasResponseinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListUnreviewedSM2CardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && proto.reviewSM2.ReviewSM2FilterConfigDTO.toObject(includeInstance, f),
    cardKind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListUnreviewedSM2CardsRequest;
  return proto.reviewSM2.ListUnreviewedSM2CardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2FilterConfigDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2FilterConfigDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    case 2:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardKind(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListUnreviewedSM2CardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2FilterConfigDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2FilterConfigDTO opts = 1;
 * @return {?proto.reviewSM2.ReviewSM2FilterConfigDTO}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.getOpts = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2FilterConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2FilterConfigDTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2FilterConfigDTO|undefined} value
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} returns this
*/
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} returns this
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.EnumReviewCardKindDTO card_kind = 2;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.getCardKind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} returns this
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.setCardKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional utils.UUID_DTO reviewId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} returns this
*/
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsRequest} returns this
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListUnreviewedSM2CardsRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListUnreviewedSM2CardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance),
    responseinfo: (f = msg.getResponseinfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsResponse}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListUnreviewedSM2CardsResponse;
  return proto.reviewSM2.ListUnreviewedSM2CardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsResponse}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setResponseinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListUnreviewedSM2CardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getResponseinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewSM2CardDTO cards = 1;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 1));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} returns this
*/
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} returns this
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO responseInfo = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.getResponseinfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} returns this
*/
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.setResponseinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListUnreviewedSM2CardsResponse} returns this
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.clearResponseinfo = function() {
  return this.setResponseinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListUnreviewedSM2CardsResponse.prototype.hasResponseinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.CreateReviewSM2Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.CreateReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.CreateReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CreateReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.reviewSM2.ReviewSM2CardDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.CreateReviewSM2Request}
 */
proto.reviewSM2.CreateReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.CreateReviewSM2Request;
  return proto.reviewSM2.CreateReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.CreateReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.CreateReviewSM2Request}
 */
proto.reviewSM2.CreateReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 2:
      var value = new proto.reviewSM2.ReviewSM2CardDTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2CardDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.CreateReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.CreateReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CreateReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reviewSM2.ReviewSM2CardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.CreateReviewSM2Request} returns this
*/
proto.reviewSM2.CreateReviewSM2Request.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.CreateReviewSM2Request} returns this
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ReviewSM2CardDTO cards = 2;
 * @return {!Array<!proto.reviewSM2.ReviewSM2CardDTO>}
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2CardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2CardDTO, 2));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2CardDTO>} value
 * @return {!proto.reviewSM2.CreateReviewSM2Request} returns this
*/
proto.reviewSM2.CreateReviewSM2Request.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2CardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2CardDTO}
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reviewSM2.ReviewSM2CardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.CreateReviewSM2Request} returns this
 */
proto.reviewSM2.CreateReviewSM2Request.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.CreateReviewSM2Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.CreateReviewSM2Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.CreateReviewSM2Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CreateReviewSM2Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    alreadyExists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.CreateReviewSM2Metadata}
 */
proto.reviewSM2.CreateReviewSM2Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.CreateReviewSM2Metadata;
  return proto.reviewSM2.CreateReviewSM2Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.CreateReviewSM2Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.CreateReviewSM2Metadata}
 */
proto.reviewSM2.CreateReviewSM2Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlreadyExists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.CreateReviewSM2Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.CreateReviewSM2Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.CreateReviewSM2Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CreateReviewSM2Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlreadyExists();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool already_exists = 1;
 * @return {boolean}
 */
proto.reviewSM2.CreateReviewSM2Metadata.prototype.getAlreadyExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reviewSM2.CreateReviewSM2Metadata} returns this
 */
proto.reviewSM2.CreateReviewSM2Metadata.prototype.setAlreadyExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.CreateReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.CreateReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CreateReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewSM2.ReviewSM2DTO.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && proto.reviewSM2.CreateReviewSM2Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.CreateReviewSM2Response}
 */
proto.reviewSM2.CreateReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.CreateReviewSM2Response;
  return proto.reviewSM2.CreateReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.CreateReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.CreateReviewSM2Response}
 */
proto.reviewSM2.CreateReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 2:
      var value = new proto.reviewSM2.CreateReviewSM2Metadata;
      reader.readMessage(value,proto.reviewSM2.CreateReviewSM2Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.CreateReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.CreateReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.CreateReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reviewSM2.CreateReviewSM2Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewSM2DTO review = 1;
 * @return {?proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.getReview = function() {
  return /** @type{?proto.reviewSM2.ReviewSM2DTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {?proto.reviewSM2.ReviewSM2DTO|undefined} value
 * @return {!proto.reviewSM2.CreateReviewSM2Response} returns this
*/
proto.reviewSM2.CreateReviewSM2Response.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.CreateReviewSM2Response} returns this
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CreateReviewSM2Metadata metadata = 2;
 * @return {?proto.reviewSM2.CreateReviewSM2Metadata}
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.getMetadata = function() {
  return /** @type{?proto.reviewSM2.CreateReviewSM2Metadata} */ (
    jspb.Message.getWrapperField(this, proto.reviewSM2.CreateReviewSM2Metadata, 2));
};


/**
 * @param {?proto.reviewSM2.CreateReviewSM2Metadata|undefined} value
 * @return {!proto.reviewSM2.CreateReviewSM2Response} returns this
*/
proto.reviewSM2.CreateReviewSM2Response.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.CreateReviewSM2Response} returns this
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.CreateReviewSM2Response.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListReviewSM2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListReviewSM2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListReviewSM2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListReviewSM2Request}
 */
proto.reviewSM2.ListReviewSM2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListReviewSM2Request;
  return proto.reviewSM2.ListReviewSM2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListReviewSM2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListReviewSM2Request}
 */
proto.reviewSM2.ListReviewSM2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListReviewSM2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListReviewSM2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListReviewSM2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.reviewSM2.ListReviewSM2Request.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.reviewSM2.ListReviewSM2Request} returns this
*/
proto.reviewSM2.ListReviewSM2Request.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListReviewSM2Request} returns this
 */
proto.reviewSM2.ListReviewSM2Request.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListReviewSM2Request.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.ListReviewSM2Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListReviewSM2Response.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListReviewSM2Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListReviewSM2Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reviewSM2.ReviewSM2DTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListReviewSM2Response}
 */
proto.reviewSM2.ListReviewSM2Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListReviewSM2Response;
  return proto.reviewSM2.ListReviewSM2Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListReviewSM2Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListReviewSM2Response}
 */
proto.reviewSM2.ListReviewSM2Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListReviewSM2Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListReviewSM2Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListReviewSM2Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewSM2DTO items = 1;
 * @return {!Array<!proto.reviewSM2.ReviewSM2DTO>}
 */
proto.reviewSM2.ListReviewSM2Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2DTO>} value
 * @return {!proto.reviewSM2.ListReviewSM2Response} returns this
*/
proto.reviewSM2.ListReviewSM2Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.ListReviewSM2Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewSM2.ReviewSM2DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.ListReviewSM2Response} returns this
 */
proto.reviewSM2.ListReviewSM2Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewSM2.ListReviewSM2Response.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewSM2.ListReviewSM2Response} returns this
*/
proto.reviewSM2.ListReviewSM2Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListReviewSM2Response} returns this
 */
proto.reviewSM2.ListReviewSM2Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListReviewSM2Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListReviewSM2ByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsRequest}
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListReviewSM2ByIdsRequest;
  return proto.reviewSM2.ListReviewSM2ByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsRequest}
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListReviewSM2ByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsRequest} returns this
*/
proto.reviewSM2.ListReviewSM2ByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsRequest} returns this
 */
proto.reviewSM2.ListReviewSM2ByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewSM2.ListReviewSM2ByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reviewSM2.ReviewSM2DTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsResponse}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewSM2.ListReviewSM2ByIdsResponse;
  return proto.reviewSM2.ListReviewSM2ByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsResponse}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewSM2.ReviewSM2DTO;
      reader.readMessage(value,proto.reviewSM2.ReviewSM2DTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewSM2.ListReviewSM2ByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewSM2.ReviewSM2DTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewSM2DTO items = 1;
 * @return {!Array<!proto.reviewSM2.ReviewSM2DTO>}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reviewSM2.ReviewSM2DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewSM2.ReviewSM2DTO, 1));
};


/**
 * @param {!Array<!proto.reviewSM2.ReviewSM2DTO>} value
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsResponse} returns this
*/
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewSM2.ReviewSM2DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewSM2.ReviewSM2DTO}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewSM2.ReviewSM2DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsResponse} returns this
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsResponse} returns this
*/
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewSM2.ListReviewSM2ByIdsResponse} returns this
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewSM2.ListReviewSM2ByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.reviewSM2.ReviewSM2CardOrderFieldsDTO = {
  REVIEW_CARD_ORDER_FIELD_NEXT_REVIEW: 0,
  REVIEW_CARD_ORDER_FIELD_CREATED_ON: 1
};

goog.object.extend(exports, proto.reviewSM2);
