import {BaseModel, EntityKind} from "../BaseModel";
import {IndustryIdentifierDTO, ResourceMetadataDTO} from "proto/resource_pb";
import {IDisplayItem} from "../interfaces";
import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {convertDateToTimestamp, convertTimestampToDate, ListItem, NewUUID} from "utils/utils";
import {IFromDTO, IIntoDTO, IModel} from "../model";
import {convertFromDTOToID} from "model/CardLang";
import {UUID_DTO} from "proto/utils_pb";

export interface IResourceMetadata extends IModel {
    resourceId: string;

    language?: string;
    doi?: string;
    edition?: string;
    version?: string;
    format?: string;
    smallThumbnail?: string;
    categories?: string[];
    publisher?: string;
    publishedOn?: Date;
    conference?: string;
    volume?: string;
    textSnippet?: string;
    industryIdentifiers?: IndustryIdentifier[];
    pageNumbers?: string;
    pageCount?: number;
    accessedOn?: Date;
    subtitle?: string;
    description?: string;
    isDefault: boolean;
}

export class ResourceMetadata extends BaseModel<ResourceMetadata, ResourceMetadataDTO> {
    private _language?: string;
    private _resourceId: string;
    private _doi?: string;
    private _edition?: string;
    private _version?: string;
    private _format?: string;
    private _small_thumbnail?: string;
    private _categories?: string[];
    private _publisher?: string;
    private _published_on?: Date;
    private _conference?: string;
    private _subtitle?: string;
    private _description?: string;
    private _volume?: string;
    private _text_snippet?: string;
    private _industry_identifiers?: IndustryIdentifier[];
    private _page_numbers?: string;
    private _page_count?: number;
    private _accessed_on?: Date;
    // We get this from the server, we never set it
    private _is_default: boolean = false;

    constructor() {
        super()
        this._industry_identifiers = [];
        this._categories = [];
        this._resourceId = "";
    }

    toListItem(): ListItem {
        return {
            id: this.doi ? this.doi : "",
            title: this._text_snippet ? this._text_snippet : "",
            metadata1: this.language ? this.language : "",
        }
    }

    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }

    to1LineString(): String {
        throw new Error("Method not implemented.");
    }

    init(): ResourceMetadata {
        throw new Error("Method not implemented.");
    }

    static fromParts(userId: string, resourceId: string): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();
        resourceMetadata.id = NewUUID();
        resourceMetadata.resourceId = resourceId;
        resourceMetadata.userId = userId;
        return resourceMetadata;
    }

    toJSON(): IResourceMetadata {
        return {
            resourceId: this.resourceId,
            id: this.id,
            userId: this.userId,
            createdOn: this.createdOn,
            updatedOn: this.updatedOn,
            language: this.language,
            doi: this.doi,
            edition: this.edition,
            version: this.version,
            format: this.format,
            smallThumbnail: this.small_thumbnail,
            categories: this.categories,
            publisher: this.publisher,
            publishedOn: this.published_on,
            conference: this.conference,
            volume: this.volume,
            textSnippet: this.text_snippet,
            industryIdentifiers: this.industry_identifiers,
            pageNumbers: this.page_numbers,
            pageCount: this.page_count,
            accessedOn: this.accessed_on,
            subtitle: this.subtitle,
            description: this.description,
            isDefault: this.is_default
        };
    }

    static fromJSON(temp: IResourceMetadata): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();

        console.log("temp: ", temp)

        resourceMetadata.id = temp.id;
        resourceMetadata.userId = temp.userId;
        resourceMetadata.createdOn = new Date(temp.createdOn);
        resourceMetadata.updatedOn = new Date(temp.updatedOn);

        resourceMetadata.accessed_on = temp.accessedOn ? new Date(temp.accessedOn) : undefined;
        resourceMetadata.categories = temp.categories;
        resourceMetadata.conference = temp.conference;
        resourceMetadata.doi = temp.doi;
        resourceMetadata.edition = temp.edition;
        resourceMetadata.format = temp.format;
        resourceMetadata.industry_identifiers = temp.industryIdentifiers;
        resourceMetadata.language = temp.language;
        resourceMetadata.page_count = temp.pageCount;
        resourceMetadata.page_numbers = temp.pageNumbers;
        resourceMetadata.published_on = temp.publishedOn ? new Date(temp.publishedOn) : undefined;
        resourceMetadata.publisher = temp.publisher;
        resourceMetadata.small_thumbnail = temp.smallThumbnail;
        resourceMetadata.text_snippet = temp.textSnippet;
        resourceMetadata.version = temp.version;
        resourceMetadata.volume = temp.volume;
        resourceMetadata.description = temp.description;
        resourceMetadata.subtitle = temp.subtitle;
        resourceMetadata.resourceId = temp.resourceId;

        return resourceMetadata;
    }

    private _TYPE: EntityKind = EntityKind.ResourceMetadata

    fromDTO(t: ResourceMetadataDTO): void | IUIError {
        let published_on;
        if (t.getPublishedOn() !== undefined) {
            published_on = convertTimestampToDate(t.getPublishedOn()!);
        }

        let accessed_on;
        if (t.getAccessedOn() !== undefined) {
            accessed_on = convertTimestampToDate(t.getAccessedOn()!);
        }

        const id = convertFromDTOToID(this.TYPE, t.getId());
        const userId = convertFromDTOToID(this.TYPE, t.getUserId());
        const resourceId = convertFromDTOToID(this.TYPE, t.getResourceId());

        this.language = t.getLanguage();
        this.doi = t.getDoi();
        this.edition = t.getEdition();
        this.version = t.getVersion();
        this.format = t.getFormat();
        this.small_thumbnail = t.getSmallThumbnail();
        this.categories = t.getCategoriesList();
        this.publisher = t.getPublisher();
        this.published_on = published_on;
        this.conference = t.getConference();
        this.volume = t.getVolume();
        this.subtitle = t.getSubtitle();
        this.description = t.getDescription();
        this.text_snippet = t.getTextSnippet();
        this.page_numbers = t.getPageNumbers();
        this.page_count = t.getPageCount();
        this.accessed_on = accessed_on;
        this.userId = userId;
        this.id = id;
        this.resourceId = resourceId;
        this.is_default = t.getIsdefault(); // We get this from the server,

        let identifiers: IndustryIdentifier[] = []
        if (t.getIndustryIdentifiersList() !== undefined) {
            t.getIndustryIdentifiersList().forEach((x) => {

                let type = x.getTypes() as IndustryIdentifierTypes;

                let y = new IndustryIdentifier(type, x.getIdentifier())
                identifiers.push(y)
            })
        }

        this.industry_identifiers = identifiers;
    }

    intoDTO(): IUIError | ResourceMetadataDTO {
        let published_on;
        if (this.published_on !== undefined) {
            published_on = convertDateToTimestamp(this.published_on);
        }

        let accessed_on;
        if (this.accessed_on !== undefined) {
            accessed_on = convertDateToTimestamp(this.accessed_on);
        }

        let identifiers: IndustryIdentifierDTO[] = []
        if (this.industry_identifiers !== undefined) {
            this.industry_identifiers.forEach((x) => {
                let y = x.intoDTO()
                if (isError(y)) {
                    return y
                }

                identifiers.push(y as IndustryIdentifierDTO)
            })
        }

        let dto = new ResourceMetadataDTO();
        dto.setLanguage(this.language ? this.language : '');
        dto.setDoi(this.doi ? this.doi : '');
        dto.setEdition(this.edition ? this.edition : '');
        dto.setVersion(this.version ? this.version : '');
        dto.setFormat(this.format ? this.format : '');
        dto.setSmallThumbnail(this.small_thumbnail ? this.small_thumbnail : '');
        dto.setCategoriesList(this.categories ? this.categories : []);
        dto.setPublisher(this.publisher ? this.publisher : '');
        dto.setPublishedOn(published_on);
        dto.setConference(this.conference ? this.conference : '');
        dto.setVolume(this.volume ? this.volume : '');
        dto.setTextSnippet(this.text_snippet ? this.text_snippet : '');
        dto.setIndustryIdentifiersList(identifiers);
        dto.setPageNumbers(this.page_numbers ? this.page_numbers : '');
        dto.setPageCount(this.page_count ? this.page_count : 0);
        dto.setAccessedOn(accessed_on);
        dto.setSubtitle(this.subtitle ? this.subtitle : '');
        dto.setDescription(this.description ? this.description : '');
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setResourceId(new UUID_DTO().setValue(this.resourceId));
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        return dto;
    }

    clone(): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();

        resourceMetadata.id = this.id;
        resourceMetadata.userId = this.userId;
        resourceMetadata.createdOn = this.createdOn;
        resourceMetadata.updatedOn = this.updatedOn;
        resourceMetadata.accessed_on = this.accessed_on;
        resourceMetadata.categories = this.categories;
        resourceMetadata.conference = this.conference;
        resourceMetadata.doi = this.doi;
        resourceMetadata.edition = this.edition;
        resourceMetadata.format = this.format;
        resourceMetadata.industry_identifiers = this.industry_identifiers ? [...this.industry_identifiers] : undefined;
        resourceMetadata.language = this.language;
        resourceMetadata.page_count = this.page_count;
        resourceMetadata.page_numbers = this.page_numbers;
        resourceMetadata.published_on = this.published_on;
        resourceMetadata.publisher = this.publisher;
        resourceMetadata.small_thumbnail = this.small_thumbnail;
        resourceMetadata.text_snippet = this.text_snippet;
        resourceMetadata.version = this.version;
        resourceMetadata.volume = this.volume;
        resourceMetadata.description = this.description;
        resourceMetadata.subtitle = this.subtitle;

        return resourceMetadata;
    }

    sanitize(): ResourceMetadata {
        this.language = this.language?.trim();
        this.doi = this.doi?.trim();
        this.edition = this.edition?.trim();
        this.version = this.version?.trim();
        this.format = this.format?.trim();
        this.small_thumbnail = this.small_thumbnail?.trim();
        this.categories = this.categories?.map((x) => x.trim());
        this.publisher = this.publisher?.trim();
        this.conference = this.conference?.trim();
        this.volume = this.volume?.trim();
        this.text_snippet = this.text_snippet?.trim();
        this.page_numbers = this.page_numbers?.trim();
        this.subtitle = this.subtitle?.trim();
        this.description = this.description?.trim();

        return this;
    }

    validate(): IUIError | ResourceMetadata {
        // I want the validation to ensure the fields if not empty are between 2 and 250 characters
        // and dont contain characters that may be harmful to the system. Use the NewUIErrorV2 function to return errors
        // and populate the message param with useful information.

        if (this.doi !== undefined) {
            if (this.doi.length < 2 || this.doi.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "DOI is not between 2 and 250 characters"
                )
            }
        }
        if (this.language !== undefined) {
            if (this.language.length < 2 || this.language.length > 25) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Language is not between 2 and 25 characters"
                )
            }
        }
        if (this.edition !== undefined) {
            if (this.edition.length < 2 || this.edition.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Edition is not between 2 and 250 characters"
                )
            }
        }
        if (this.version !== undefined) {
            if (this.version.length < 2 || this.version.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Version is not between 2 and 250 characters"
                )
            }
        }
        if (this.format !== undefined) {
            if (this.format.length < 2 || this.format.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Format is not between 2 and 250 characters"
                )
            }
        }
        if (this.small_thumbnail !== undefined) {
            if (this.small_thumbnail.length < 2 || this.small_thumbnail.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Small Thumbnail is not between 2 and 250 characters"
                )
            }
        }
        if (this.publisher !== undefined) {
            if (this.publisher.length < 2 || this.publisher.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Publisher is not between 2 and 250 characters"
                )
            }
        }
        if (this.conference !== undefined) {
            if (this.conference.length < 2 || this.conference.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Conference is not between 2 and 250 characters"
                )
            }
        }
        if (this.volume !== undefined) {
            if (this.volume.length < 2 || this.volume.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Volume is not between 2 and 250 characters"
                )
            }
        }
        if (this.text_snippet !== undefined) {
            if (this.text_snippet.length < 2 || this.text_snippet.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Text Snippet is not between 2 and 250 characters"
                )
            }
        }
        if (this.page_numbers !== undefined) {
            if (this.page_numbers.length < 2 || this.page_numbers.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Page Numbers is not between 2 and 250 characters"
                )
            }
        }
        if (this.page_count !== undefined) {
            if (this.page_count < 0 || this.page_count > 10000) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Page Count is not between 0 and 10000"
                )
            }
        }

        return this.sanitize()
    }

    get TYPE(): EntityKind {
        return this._TYPE;
    }

    set TYPE(value: EntityKind) {
        this._TYPE = value;
    }

    get language(): string | undefined {
        return this._language;
    }

    set language(value: string | undefined) {
        this._language = value;
    }

    get doi(): string | undefined {
        return this._doi;
    }

    set doi(value: string | undefined) {
        this._doi = value;
    }

    get edition(): string | undefined {
        return this._edition;
    }

    set edition(value: string | undefined) {
        this._edition = value;
    }

    get version(): string | undefined {
        return this._version;
    }

    set version(value: string | undefined) {
        this._version = value;
    }

    get format(): string | undefined {
        return this._format;
    }

    set format(value: string | undefined) {
        this._format = value;
    }

    get small_thumbnail(): string | undefined {
        return this._small_thumbnail;
    }

    set small_thumbnail(value: string | undefined) {
        this._small_thumbnail = value;
    }

    get categories(): string[] | undefined {
        return this._categories;
    }

    set categories(value: string[] | undefined) {
        this._categories = value;
    }

    get publisher(): string | undefined {
        return this._publisher;
    }

    set publisher(value: string | undefined) {
        this._publisher = value;
    }

    get published_on(): Date | undefined {
        return this._published_on;
    }

    set published_on(value: Date | undefined) {
        this._published_on = value;
    }

    get conference(): string | undefined {
        return this._conference;
    }

    set conference(value: string | undefined) {
        this._conference = value;
    }

    get volume(): string | undefined {
        return this._volume;
    }

    set volume(value: string | undefined) {
        this._volume = value;
    }

    get text_snippet(): string | undefined {
        return this._text_snippet;
    }

    set text_snippet(value: string | undefined) {
        this._text_snippet = value;
    }

    get industry_identifiers(): IndustryIdentifier[] | undefined {
        return this._industry_identifiers;
    }

    set industry_identifiers(value: IndustryIdentifier[] | undefined) {
        this._industry_identifiers = value;
    }

    get page_numbers(): string | undefined {
        return this._page_numbers;
    }

    set page_numbers(value: string | undefined) {
        this._page_numbers = value;
    }

    get page_count(): number | undefined {
        return this._page_count;
    }

    set page_count(value: number | undefined) {
        this._page_count = value;
    }

    get accessed_on(): Date | undefined {
        return this._accessed_on;
    }

    set accessed_on(value: Date | undefined) {
        this._accessed_on = value;
    }

    get subtitle(): string | undefined {
        return this._subtitle;
    }

    set subtitle(value: string | undefined) {
        this._subtitle = value;
    }

    get description(): string | undefined {
        return this._description;
    }

    set description(value: string | undefined) {
        this._description = value;
    }

    get resourceId(): string {
        return this._resourceId;
    }

    set resourceId(value: string) {
        this._resourceId = value;
    }

    get is_default(): boolean {
        return this._is_default;
    }

    set is_default(value: boolean) {
        this._is_default = value;
    }
}

export type IndustryIdentifierTypes = "isbn10" | "isbn13" | "issn" | "other";

export class IndustryIdentifier implements IIntoDTO<IndustryIdentifierDTO>, IFromDTO<IndustryIdentifierDTO> {
    private _type: IndustryIdentifierTypes;
    private _identifier: string;

    constructor(type: IndustryIdentifierTypes, identifier: string) {
        this._type = type;
        this._identifier = identifier;
    }

    fromDTO(t: IndustryIdentifierDTO): void | IUIError {
        this.type = t.getTypes() as IndustryIdentifierTypes;
        this.identifier = t.getIdentifier();
    }

    intoDTO(): IndustryIdentifierDTO | IUIError {
        let dto = new IndustryIdentifierDTO();
        dto.setTypes(this.type);
        dto.setIdentifier(this.identifier);
        return dto;
    }

    clone(): IndustryIdentifier {
        return new IndustryIdentifier(this.type, this.identifier);
    }

    get type(): IndustryIdentifierTypes {
        return this._type;
    }

    set type(value: IndustryIdentifierTypes) {
        this._type = value;
    }

    get identifier(): string {
        return this._identifier;
    }

    set identifier(value: string) {
        this._identifier = value;
    }

}