// source: resource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.resource.ArchiveResourceRequest', null, global);
goog.exportSymbol('proto.resource.ArchiveResourceResponse', null, global);
goog.exportSymbol('proto.resource.CreateResourceRequest', null, global);
goog.exportSymbol('proto.resource.CreateResourceResponse', null, global);
goog.exportSymbol('proto.resource.DeleteResourceMetadataRequest', null, global);
goog.exportSymbol('proto.resource.DeleteResourceMetadataResponse', null, global);
goog.exportSymbol('proto.resource.DeleteResourceRequest', null, global);
goog.exportSymbol('proto.resource.DeleteResourceResponse', null, global);
goog.exportSymbol('proto.resource.GetResourceRequest', null, global);
goog.exportSymbol('proto.resource.GetResourceResponse', null, global);
goog.exportSymbol('proto.resource.IndustryIdentifierDTO', null, global);
goog.exportSymbol('proto.resource.ListResourceByIdsRequest', null, global);
goog.exportSymbol('proto.resource.ListResourceByIdsResponse', null, global);
goog.exportSymbol('proto.resource.ListResourceRequest', null, global);
goog.exportSymbol('proto.resource.ListResourceResponse', null, global);
goog.exportSymbol('proto.resource.ResourceCompositeDTO', null, global);
goog.exportSymbol('proto.resource.ResourceDTO', null, global);
goog.exportSymbol('proto.resource.ResourceKind', null, global);
goog.exportSymbol('proto.resource.ResourceMetadataDTO', null, global);
goog.exportSymbol('proto.resource.ResourceOriginDTO', null, global);
goog.exportSymbol('proto.resource.SearchGoogleBooksRequest', null, global);
goog.exportSymbol('proto.resource.SearchGoogleBooksResponse', null, global);
goog.exportSymbol('proto.resource.UpdateResourceRequest', null, global);
goog.exportSymbol('proto.resource.UpdateResourceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ResourceDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.ResourceDTO.repeatedFields_, null);
};
goog.inherits(proto.resource.ResourceDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ResourceDTO.displayName = 'proto.resource.ResourceDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.IndustryIdentifierDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.IndustryIdentifierDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.IndustryIdentifierDTO.displayName = 'proto.resource.IndustryIdentifierDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.DeleteResourceMetadataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.DeleteResourceMetadataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.DeleteResourceMetadataRequest.displayName = 'proto.resource.DeleteResourceMetadataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.DeleteResourceMetadataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.DeleteResourceMetadataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.DeleteResourceMetadataResponse.displayName = 'proto.resource.DeleteResourceMetadataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ResourceMetadataDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.ResourceMetadataDTO.repeatedFields_, null);
};
goog.inherits(proto.resource.ResourceMetadataDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ResourceMetadataDTO.displayName = 'proto.resource.ResourceMetadataDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ResourceCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.ResourceCompositeDTO.repeatedFields_, null);
};
goog.inherits(proto.resource.ResourceCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ResourceCompositeDTO.displayName = 'proto.resource.ResourceCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.CreateResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.CreateResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.CreateResourceRequest.displayName = 'proto.resource.CreateResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.CreateResourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.CreateResourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.CreateResourceResponse.displayName = 'proto.resource.CreateResourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.GetResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.GetResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.GetResourceRequest.displayName = 'proto.resource.GetResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.GetResourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.GetResourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.GetResourceResponse.displayName = 'proto.resource.GetResourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ListResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.ListResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ListResourceRequest.displayName = 'proto.resource.ListResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ListResourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.ListResourceResponse.repeatedFields_, null);
};
goog.inherits(proto.resource.ListResourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ListResourceResponse.displayName = 'proto.resource.ListResourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ArchiveResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.ArchiveResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ArchiveResourceRequest.displayName = 'proto.resource.ArchiveResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ArchiveResourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.ArchiveResourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ArchiveResourceResponse.displayName = 'proto.resource.ArchiveResourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.DeleteResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.DeleteResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.DeleteResourceRequest.displayName = 'proto.resource.DeleteResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.DeleteResourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.DeleteResourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.DeleteResourceResponse.displayName = 'proto.resource.DeleteResourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ListResourceByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.ListResourceByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.resource.ListResourceByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ListResourceByIdsRequest.displayName = 'proto.resource.ListResourceByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.ListResourceByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.ListResourceByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.resource.ListResourceByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.ListResourceByIdsResponse.displayName = 'proto.resource.ListResourceByIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.UpdateResourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.UpdateResourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.UpdateResourceRequest.displayName = 'proto.resource.UpdateResourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.UpdateResourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.UpdateResourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.UpdateResourceResponse.displayName = 'proto.resource.UpdateResourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.SearchGoogleBooksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.resource.SearchGoogleBooksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.SearchGoogleBooksRequest.displayName = 'proto.resource.SearchGoogleBooksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.resource.SearchGoogleBooksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.resource.SearchGoogleBooksResponse.repeatedFields_, null);
};
goog.inherits(proto.resource.SearchGoogleBooksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.resource.SearchGoogleBooksResponse.displayName = 'proto.resource.SearchGoogleBooksResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.ResourceDTO.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ResourceDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ResourceDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ResourceDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ResourceDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    kind: jspb.Message.getFieldWithDefault(msg, 4, 0),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    authorsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    link: jspb.Message.getFieldWithDefault(msg, 9, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 10, ""),
    composite: (f = msg.getComposite()) && proto.resource.ResourceCompositeDTO.toObject(includeInstance, f),
    createdOn: (f = msg.getCreatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedOn: (f = msg.getUpdatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedOn: (f = msg.getArchivedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    origin: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ResourceDTO}
 */
proto.resource.ResourceDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ResourceDTO;
  return proto.resource.ResourceDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ResourceDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ResourceDTO}
 */
proto.resource.ResourceDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.resource.ResourceKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuthors(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 11:
      var value = new proto.resource.ResourceCompositeDTO;
      reader.readMessage(value,proto.resource.ResourceCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 14:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 15:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedOn(value);
      break;
    case 16:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedOn(value);
      break;
    case 17:
      var value = /** @type {!proto.resource.ResourceOriginDTO} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ResourceDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ResourceDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ResourceDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ResourceDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAuthorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.resource.ResourceCompositeDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedOn();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedOn();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.ResourceDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.ResourceDTO} returns this
*/
proto.resource.ResourceDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.ResourceDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.ResourceDTO} returns this
*/
proto.resource.ResourceDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ResourceKind kind = 4;
 * @return {!proto.resource.ResourceKind}
 */
proto.resource.ResourceDTO.prototype.getKind = function() {
  return /** @type {!proto.resource.ResourceKind} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.resource.ResourceKind} value
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.resource.ResourceDTO.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string authors = 6;
 * @return {!Array<string>}
 */
proto.resource.ResourceDTO.prototype.getAuthorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.setAuthorsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.addAuthors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearAuthorsList = function() {
  return this.setAuthorsList([]);
};


/**
 * optional string link = 9;
 * @return {string}
 */
proto.resource.ResourceDTO.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.setLink = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearLink = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasLink = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string thumbnail = 10;
 * @return {string}
 */
proto.resource.ResourceDTO.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.setThumbnail = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearThumbnail = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ResourceCompositeDTO composite = 11;
 * @return {?proto.resource.ResourceCompositeDTO}
 */
proto.resource.ResourceDTO.prototype.getComposite = function() {
  return /** @type{?proto.resource.ResourceCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.resource.ResourceCompositeDTO, 11));
};


/**
 * @param {?proto.resource.ResourceCompositeDTO|undefined} value
 * @return {!proto.resource.ResourceDTO} returns this
*/
proto.resource.ResourceDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO created_on = 14;
 * @return {?proto.utils.TimestampDTO}
 */
proto.resource.ResourceDTO.prototype.getCreatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 14));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.resource.ResourceDTO} returns this
*/
proto.resource.ResourceDTO.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional utils.TimestampDTO updated_on = 15;
 * @return {?proto.utils.TimestampDTO}
 */
proto.resource.ResourceDTO.prototype.getUpdatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 15));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.resource.ResourceDTO} returns this
*/
proto.resource.ResourceDTO.prototype.setUpdatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearUpdatedOn = function() {
  return this.setUpdatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasUpdatedOn = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional utils.TimestampDTO archived_on = 16;
 * @return {?proto.utils.TimestampDTO}
 */
proto.resource.ResourceDTO.prototype.getArchivedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 16));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.resource.ResourceDTO} returns this
*/
proto.resource.ResourceDTO.prototype.setArchivedOn = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.clearArchivedOn = function() {
  return this.setArchivedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceDTO.prototype.hasArchivedOn = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ResourceOriginDTO origin = 17;
 * @return {!proto.resource.ResourceOriginDTO}
 */
proto.resource.ResourceDTO.prototype.getOrigin = function() {
  return /** @type {!proto.resource.ResourceOriginDTO} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.resource.ResourceOriginDTO} value
 * @return {!proto.resource.ResourceDTO} returns this
 */
proto.resource.ResourceDTO.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.IndustryIdentifierDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.IndustryIdentifierDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.IndustryIdentifierDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.IndustryIdentifierDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    types: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identifier: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.IndustryIdentifierDTO}
 */
proto.resource.IndustryIdentifierDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.IndustryIdentifierDTO;
  return proto.resource.IndustryIdentifierDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.IndustryIdentifierDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.IndustryIdentifierDTO}
 */
proto.resource.IndustryIdentifierDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.IndustryIdentifierDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.IndustryIdentifierDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.IndustryIdentifierDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.IndustryIdentifierDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypes();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string types = 1;
 * @return {string}
 */
proto.resource.IndustryIdentifierDTO.prototype.getTypes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.IndustryIdentifierDTO} returns this
 */
proto.resource.IndustryIdentifierDTO.prototype.setTypes = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identifier = 2;
 * @return {string}
 */
proto.resource.IndustryIdentifierDTO.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.IndustryIdentifierDTO} returns this
 */
proto.resource.IndustryIdentifierDTO.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.DeleteResourceMetadataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.DeleteResourceMetadataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.DeleteResourceMetadataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceMetadataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.DeleteResourceMetadataRequest}
 */
proto.resource.DeleteResourceMetadataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.DeleteResourceMetadataRequest;
  return proto.resource.DeleteResourceMetadataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.DeleteResourceMetadataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.DeleteResourceMetadataRequest}
 */
proto.resource.DeleteResourceMetadataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.DeleteResourceMetadataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.DeleteResourceMetadataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.DeleteResourceMetadataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceMetadataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.DeleteResourceMetadataRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.DeleteResourceMetadataRequest} returns this
*/
proto.resource.DeleteResourceMetadataRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.DeleteResourceMetadataRequest} returns this
 */
proto.resource.DeleteResourceMetadataRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.DeleteResourceMetadataRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.DeleteResourceMetadataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.DeleteResourceMetadataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.DeleteResourceMetadataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceMetadataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.DeleteResourceMetadataResponse}
 */
proto.resource.DeleteResourceMetadataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.DeleteResourceMetadataResponse;
  return proto.resource.DeleteResourceMetadataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.DeleteResourceMetadataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.DeleteResourceMetadataResponse}
 */
proto.resource.DeleteResourceMetadataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.DeleteResourceMetadataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.DeleteResourceMetadataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.DeleteResourceMetadataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceMetadataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.ResourceMetadataDTO.repeatedFields_ = [10,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ResourceMetadataDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ResourceMetadataDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ResourceMetadataDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ResourceMetadataDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    resourceId: (f = msg.getResourceId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    language: jspb.Message.getFieldWithDefault(msg, 4, ""),
    doi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    edition: jspb.Message.getFieldWithDefault(msg, 6, ""),
    version: jspb.Message.getFieldWithDefault(msg, 7, ""),
    format: jspb.Message.getFieldWithDefault(msg, 8, ""),
    smallThumbnail: jspb.Message.getFieldWithDefault(msg, 9, ""),
    categoriesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    publisher: jspb.Message.getFieldWithDefault(msg, 11, ""),
    publishedOn: (f = msg.getPublishedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    conference: jspb.Message.getFieldWithDefault(msg, 13, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 14, ""),
    textSnippet: jspb.Message.getFieldWithDefault(msg, 15, ""),
    industryIdentifiersList: jspb.Message.toObjectList(msg.getIndustryIdentifiersList(),
    proto.resource.IndustryIdentifierDTO.toObject, includeInstance),
    pageNumbers: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pageCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    accessedOn: (f = msg.getAccessedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    subtitle: jspb.Message.getFieldWithDefault(msg, 20, ""),
    description: jspb.Message.getFieldWithDefault(msg, 21, ""),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 23, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ResourceMetadataDTO}
 */
proto.resource.ResourceMetadataDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ResourceMetadataDTO;
  return proto.resource.ResourceMetadataDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ResourceMetadataDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ResourceMetadataDTO}
 */
proto.resource.ResourceMetadataDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdition(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmallThumbnail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisher(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setPublishedOn(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setConference(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextSnippet(value);
      break;
    case 16:
      var value = new proto.resource.IndustryIdentifierDTO;
      reader.readMessage(value,proto.resource.IndustryIdentifierDTO.deserializeBinaryFromReader);
      msg.addIndustryIdentifiers(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageNumbers(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCount(value);
      break;
    case 19:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setAccessedOn(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 22:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ResourceMetadataDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ResourceMetadataDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ResourceMetadataDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ResourceMetadataDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getResourceId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPublishedOn();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIndustryIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.resource.IndustryIdentifierDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getAccessedOn();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getIsdefault();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.ResourceMetadataDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.ResourceMetadataDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO resource_id = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.ResourceMetadataDTO.prototype.getResourceId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setResourceId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearResourceId = function() {
  return this.setResourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasResourceId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string language = 4;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setLanguage = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearLanguage = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasLanguage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string doi = 5;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getDoi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setDoi = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearDoi = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasDoi = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string edition = 6;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getEdition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setEdition = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearEdition = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasEdition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string version = 7;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string format = 8;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setFormat = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearFormat = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasFormat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string small_thumbnail = 9;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getSmallThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setSmallThumbnail = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearSmallThumbnail = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasSmallThumbnail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string categories = 10;
 * @return {!Array<string>}
 */
proto.resource.ResourceMetadataDTO.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional string publisher = 11;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getPublisher = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setPublisher = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearPublisher = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasPublisher = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO published_on = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.resource.ResourceMetadataDTO.prototype.getPublishedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setPublishedOn = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearPublishedOn = function() {
  return this.setPublishedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasPublishedOn = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string conference = 13;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getConference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setConference = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearConference = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasConference = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string volume = 14;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setVolume = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearVolume = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasVolume = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string text_snippet = 15;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getTextSnippet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setTextSnippet = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearTextSnippet = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasTextSnippet = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated IndustryIdentifierDTO industry_identifiers = 16;
 * @return {!Array<!proto.resource.IndustryIdentifierDTO>}
 */
proto.resource.ResourceMetadataDTO.prototype.getIndustryIdentifiersList = function() {
  return /** @type{!Array<!proto.resource.IndustryIdentifierDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.resource.IndustryIdentifierDTO, 16));
};


/**
 * @param {!Array<!proto.resource.IndustryIdentifierDTO>} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setIndustryIdentifiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.resource.IndustryIdentifierDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.IndustryIdentifierDTO}
 */
proto.resource.ResourceMetadataDTO.prototype.addIndustryIdentifiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.resource.IndustryIdentifierDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearIndustryIdentifiersList = function() {
  return this.setIndustryIdentifiersList([]);
};


/**
 * optional string page_numbers = 17;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getPageNumbers = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setPageNumbers = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearPageNumbers = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasPageNumbers = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int32 page_count = 18;
 * @return {number}
 */
proto.resource.ResourceMetadataDTO.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setPageCount = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearPageCount = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasPageCount = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional utils.TimestampDTO accessed_on = 19;
 * @return {?proto.utils.TimestampDTO}
 */
proto.resource.ResourceMetadataDTO.prototype.getAccessedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 19));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setAccessedOn = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearAccessedOn = function() {
  return this.setAccessedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasAccessedOn = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string subtitle = 20;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setSubtitle = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearSubtitle = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasSubtitle = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string description = 21;
 * @return {string}
 */
proto.resource.ResourceMetadataDTO.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 22;
 * @return {?proto.utils.TimestampDTO}
 */
proto.resource.ResourceMetadataDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 22));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
*/
proto.resource.ResourceMetadataDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bool isDefault = 23;
 * @return {boolean}
 */
proto.resource.ResourceMetadataDTO.prototype.getIsdefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.resource.ResourceMetadataDTO} returns this
 */
proto.resource.ResourceMetadataDTO.prototype.setIsdefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.ResourceCompositeDTO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ResourceCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ResourceCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ResourceCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ResourceCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.resource.ResourceMetadataDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ResourceCompositeDTO}
 */
proto.resource.ResourceCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ResourceCompositeDTO;
  return proto.resource.ResourceCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ResourceCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ResourceCompositeDTO}
 */
proto.resource.ResourceCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceMetadataDTO;
      reader.readMessage(value,proto.resource.ResourceMetadataDTO.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ResourceCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ResourceCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ResourceCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ResourceCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.resource.ResourceMetadataDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResourceMetadataDTO metadata = 1;
 * @return {!Array<!proto.resource.ResourceMetadataDTO>}
 */
proto.resource.ResourceCompositeDTO.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.resource.ResourceMetadataDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.resource.ResourceMetadataDTO, 1));
};


/**
 * @param {!Array<!proto.resource.ResourceMetadataDTO>} value
 * @return {!proto.resource.ResourceCompositeDTO} returns this
*/
proto.resource.ResourceCompositeDTO.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.resource.ResourceMetadataDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceMetadataDTO}
 */
proto.resource.ResourceCompositeDTO.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.resource.ResourceMetadataDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ResourceCompositeDTO} returns this
 */
proto.resource.ResourceCompositeDTO.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.CreateResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.CreateResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.CreateResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.CreateResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.resource.ResourceDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.CreateResourceRequest}
 */
proto.resource.CreateResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.CreateResourceRequest;
  return proto.resource.CreateResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.CreateResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.CreateResourceRequest}
 */
proto.resource.CreateResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.CreateResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.CreateResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.CreateResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.CreateResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResourceDTO resource = 1;
 * @return {?proto.resource.ResourceDTO}
 */
proto.resource.CreateResourceRequest.prototype.getResource = function() {
  return /** @type{?proto.resource.ResourceDTO} */ (
    jspb.Message.getWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {?proto.resource.ResourceDTO|undefined} value
 * @return {!proto.resource.CreateResourceRequest} returns this
*/
proto.resource.CreateResourceRequest.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.CreateResourceRequest} returns this
 */
proto.resource.CreateResourceRequest.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.CreateResourceRequest.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.CreateResourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.CreateResourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.CreateResourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.CreateResourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.resource.ResourceDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.CreateResourceResponse}
 */
proto.resource.CreateResourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.CreateResourceResponse;
  return proto.resource.CreateResourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.CreateResourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.CreateResourceResponse}
 */
proto.resource.CreateResourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.CreateResourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.CreateResourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.CreateResourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.CreateResourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResourceDTO resource = 1;
 * @return {?proto.resource.ResourceDTO}
 */
proto.resource.CreateResourceResponse.prototype.getResource = function() {
  return /** @type{?proto.resource.ResourceDTO} */ (
    jspb.Message.getWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {?proto.resource.ResourceDTO|undefined} value
 * @return {!proto.resource.CreateResourceResponse} returns this
*/
proto.resource.CreateResourceResponse.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.CreateResourceResponse} returns this
 */
proto.resource.CreateResourceResponse.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.CreateResourceResponse.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.GetResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.GetResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.GetResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.GetResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: (f = msg.getResourceId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.GetResourceRequest}
 */
proto.resource.GetResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.GetResourceRequest;
  return proto.resource.GetResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.GetResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.GetResourceRequest}
 */
proto.resource.GetResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.GetResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.GetResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.GetResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.GetResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO resource_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.GetResourceRequest.prototype.getResourceId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.GetResourceRequest} returns this
*/
proto.resource.GetResourceRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.GetResourceRequest} returns this
 */
proto.resource.GetResourceRequest.prototype.clearResourceId = function() {
  return this.setResourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.GetResourceRequest.prototype.hasResourceId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.GetResourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.GetResourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.GetResourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.GetResourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resource: (f = msg.getResource()) && proto.resource.ResourceDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.GetResourceResponse}
 */
proto.resource.GetResourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.GetResourceResponse;
  return proto.resource.GetResourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.GetResourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.GetResourceResponse}
 */
proto.resource.GetResourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.setResource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.GetResourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.GetResourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.GetResourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.GetResourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResourceDTO resource = 1;
 * @return {?proto.resource.ResourceDTO}
 */
proto.resource.GetResourceResponse.prototype.getResource = function() {
  return /** @type{?proto.resource.ResourceDTO} */ (
    jspb.Message.getWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {?proto.resource.ResourceDTO|undefined} value
 * @return {!proto.resource.GetResourceResponse} returns this
*/
proto.resource.GetResourceResponse.prototype.setResource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.GetResourceResponse} returns this
 */
proto.resource.GetResourceResponse.prototype.clearResource = function() {
  return this.setResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.GetResourceResponse.prototype.hasResource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ListResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ListResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ListResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ListResourceRequest}
 */
proto.resource.ListResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ListResourceRequest;
  return proto.resource.ListResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ListResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ListResourceRequest}
 */
proto.resource.ListResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ListResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ListResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ListResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.resource.ListResourceRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.resource.ListResourceRequest} returns this
*/
proto.resource.ListResourceRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ListResourceRequest} returns this
 */
proto.resource.ListResourceRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ListResourceRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.ListResourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ListResourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ListResourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ListResourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.resource.ResourceDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ListResourceResponse}
 */
proto.resource.ListResourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ListResourceResponse;
  return proto.resource.ListResourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ListResourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ListResourceResponse}
 */
proto.resource.ListResourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ListResourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ListResourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ListResourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResourceDTO items = 1;
 * @return {!Array<!proto.resource.ResourceDTO>}
 */
proto.resource.ListResourceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.resource.ResourceDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {!Array<!proto.resource.ResourceDTO>} value
 * @return {!proto.resource.ListResourceResponse} returns this
*/
proto.resource.ListResourceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.resource.ResourceDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceDTO}
 */
proto.resource.ListResourceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.resource.ResourceDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ListResourceResponse} returns this
 */
proto.resource.ListResourceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.resource.ListResourceResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.resource.ListResourceResponse} returns this
*/
proto.resource.ListResourceResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ListResourceResponse} returns this
 */
proto.resource.ListResourceResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ListResourceResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ArchiveResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ArchiveResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ArchiveResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ArchiveResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: (f = msg.getResourceId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ArchiveResourceRequest}
 */
proto.resource.ArchiveResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ArchiveResourceRequest;
  return proto.resource.ArchiveResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ArchiveResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ArchiveResourceRequest}
 */
proto.resource.ArchiveResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ArchiveResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ArchiveResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ArchiveResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ArchiveResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO resource_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.ArchiveResourceRequest.prototype.getResourceId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.ArchiveResourceRequest} returns this
*/
proto.resource.ArchiveResourceRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ArchiveResourceRequest} returns this
 */
proto.resource.ArchiveResourceRequest.prototype.clearResourceId = function() {
  return this.setResourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ArchiveResourceRequest.prototype.hasResourceId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ArchiveResourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ArchiveResourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ArchiveResourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ArchiveResourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ArchiveResourceResponse}
 */
proto.resource.ArchiveResourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ArchiveResourceResponse;
  return proto.resource.ArchiveResourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ArchiveResourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ArchiveResourceResponse}
 */
proto.resource.ArchiveResourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ArchiveResourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ArchiveResourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ArchiveResourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ArchiveResourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.DeleteResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.DeleteResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.DeleteResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: (f = msg.getResourceId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.DeleteResourceRequest}
 */
proto.resource.DeleteResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.DeleteResourceRequest;
  return proto.resource.DeleteResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.DeleteResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.DeleteResourceRequest}
 */
proto.resource.DeleteResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.DeleteResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.DeleteResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.DeleteResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO resource_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.resource.DeleteResourceRequest.prototype.getResourceId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.resource.DeleteResourceRequest} returns this
*/
proto.resource.DeleteResourceRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.DeleteResourceRequest} returns this
 */
proto.resource.DeleteResourceRequest.prototype.clearResourceId = function() {
  return this.setResourceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.DeleteResourceRequest.prototype.hasResourceId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.DeleteResourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.DeleteResourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.DeleteResourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.DeleteResourceResponse}
 */
proto.resource.DeleteResourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.DeleteResourceResponse;
  return proto.resource.DeleteResourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.DeleteResourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.DeleteResourceResponse}
 */
proto.resource.DeleteResourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.DeleteResourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.DeleteResourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.DeleteResourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.DeleteResourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.ListResourceByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ListResourceByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ListResourceByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ListResourceByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ListResourceByIdsRequest}
 */
proto.resource.ListResourceByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ListResourceByIdsRequest;
  return proto.resource.ListResourceByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ListResourceByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ListResourceByIdsRequest}
 */
proto.resource.ListResourceByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ListResourceByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ListResourceByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ListResourceByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.resource.ListResourceByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.resource.ListResourceByIdsRequest} returns this
*/
proto.resource.ListResourceByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.resource.ListResourceByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ListResourceByIdsRequest} returns this
 */
proto.resource.ListResourceByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.ListResourceByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.ListResourceByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.ListResourceByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.ListResourceByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.resource.ResourceDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.ListResourceByIdsResponse}
 */
proto.resource.ListResourceByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.ListResourceByIdsResponse;
  return proto.resource.ListResourceByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.ListResourceByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.ListResourceByIdsResponse}
 */
proto.resource.ListResourceByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.ListResourceByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.ListResourceByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.ListResourceByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.ListResourceByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResourceDTO items = 1;
 * @return {!Array<!proto.resource.ResourceDTO>}
 */
proto.resource.ListResourceByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.resource.ResourceDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {!Array<!proto.resource.ResourceDTO>} value
 * @return {!proto.resource.ListResourceByIdsResponse} returns this
*/
proto.resource.ListResourceByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.resource.ResourceDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceDTO}
 */
proto.resource.ListResourceByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.resource.ResourceDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.ListResourceByIdsResponse} returns this
 */
proto.resource.ListResourceByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.resource.ListResourceByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.resource.ListResourceByIdsResponse} returns this
*/
proto.resource.ListResourceByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.ListResourceByIdsResponse} returns this
 */
proto.resource.ListResourceByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.ListResourceByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.UpdateResourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.UpdateResourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.UpdateResourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.UpdateResourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.resource.ResourceDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.UpdateResourceRequest}
 */
proto.resource.UpdateResourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.UpdateResourceRequest;
  return proto.resource.UpdateResourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.UpdateResourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.UpdateResourceRequest}
 */
proto.resource.UpdateResourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.UpdateResourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.UpdateResourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.UpdateResourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.UpdateResourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResourceDTO item = 1;
 * @return {?proto.resource.ResourceDTO}
 */
proto.resource.UpdateResourceRequest.prototype.getItem = function() {
  return /** @type{?proto.resource.ResourceDTO} */ (
    jspb.Message.getWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {?proto.resource.ResourceDTO|undefined} value
 * @return {!proto.resource.UpdateResourceRequest} returns this
*/
proto.resource.UpdateResourceRequest.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.UpdateResourceRequest} returns this
 */
proto.resource.UpdateResourceRequest.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.UpdateResourceRequest.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.UpdateResourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.UpdateResourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.UpdateResourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.UpdateResourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.resource.ResourceDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.UpdateResourceResponse}
 */
proto.resource.UpdateResourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.UpdateResourceResponse;
  return proto.resource.UpdateResourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.UpdateResourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.UpdateResourceResponse}
 */
proto.resource.UpdateResourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.UpdateResourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.UpdateResourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.UpdateResourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.UpdateResourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResourceDTO item = 1;
 * @return {?proto.resource.ResourceDTO}
 */
proto.resource.UpdateResourceResponse.prototype.getItem = function() {
  return /** @type{?proto.resource.ResourceDTO} */ (
    jspb.Message.getWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {?proto.resource.ResourceDTO|undefined} value
 * @return {!proto.resource.UpdateResourceResponse} returns this
*/
proto.resource.UpdateResourceResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.UpdateResourceResponse} returns this
 */
proto.resource.UpdateResourceResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.UpdateResourceResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.SearchGoogleBooksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.SearchGoogleBooksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.SearchGoogleBooksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.SearchGoogleBooksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.SearchGoogleBooksRequest}
 */
proto.resource.SearchGoogleBooksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.SearchGoogleBooksRequest;
  return proto.resource.SearchGoogleBooksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.SearchGoogleBooksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.SearchGoogleBooksRequest}
 */
proto.resource.SearchGoogleBooksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.SearchGoogleBooksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.SearchGoogleBooksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.SearchGoogleBooksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.SearchGoogleBooksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.resource.SearchGoogleBooksRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.resource.SearchGoogleBooksRequest} returns this
 */
proto.resource.SearchGoogleBooksRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.resource.SearchGoogleBooksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.resource.SearchGoogleBooksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.resource.SearchGoogleBooksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.resource.SearchGoogleBooksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.SearchGoogleBooksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.resource.ResourceDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.resource.SearchGoogleBooksResponse}
 */
proto.resource.SearchGoogleBooksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.resource.SearchGoogleBooksResponse;
  return proto.resource.SearchGoogleBooksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.resource.SearchGoogleBooksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.resource.SearchGoogleBooksResponse}
 */
proto.resource.SearchGoogleBooksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.resource.ResourceDTO;
      reader.readMessage(value,proto.resource.ResourceDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.resource.SearchGoogleBooksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.resource.SearchGoogleBooksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.resource.SearchGoogleBooksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.resource.SearchGoogleBooksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.resource.ResourceDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResourceDTO items = 1;
 * @return {!Array<!proto.resource.ResourceDTO>}
 */
proto.resource.SearchGoogleBooksResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.resource.ResourceDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.resource.ResourceDTO, 1));
};


/**
 * @param {!Array<!proto.resource.ResourceDTO>} value
 * @return {!proto.resource.SearchGoogleBooksResponse} returns this
*/
proto.resource.SearchGoogleBooksResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.resource.ResourceDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceDTO}
 */
proto.resource.SearchGoogleBooksResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.resource.ResourceDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.resource.SearchGoogleBooksResponse} returns this
 */
proto.resource.SearchGoogleBooksResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.resource.SearchGoogleBooksResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.resource.SearchGoogleBooksResponse} returns this
*/
proto.resource.SearchGoogleBooksResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.resource.SearchGoogleBooksResponse} returns this
 */
proto.resource.SearchGoogleBooksResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.resource.SearchGoogleBooksResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.resource.ResourceKind = {
  UNKNOWN: 0,
  WEBSITE: 1,
  BOOK: 2,
  JOURNAL: 3,
  TEXTBOOK: 4,
  MAGAZINE: 5
};

/**
 * @enum {number}
 */
proto.resource.ResourceOriginDTO = {
  LOCAL: 0,
  UNKNOWNORIGIN: 1,
  GOOGLEBOOKS: 2,
  ANKI: 3
};

goog.object.extend(exports, proto.resource);
