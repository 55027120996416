import {Tag} from "../model/tag";
import {CARTA_PROXY_URL, IsUUIDValid, sanitizeListOptions} from "../utils/utils";
import {getAuthToken, getUserId} from "../service/AuthService";
import {TagServicePromiseClient} from "../proto/tag_grpc_web_pb";
import BaseStore from "./BaseStore";
import {TagDTO} from "../proto/tag_pb";
import {TagGRPCImpl, TagService} from "../service/TagService";


export class TagStore extends BaseStore<Tag, TagDTO, TagGRPCImpl, TagService> {
	public service: TagService;
	
	constructor(service: TagService) {
		super(service, Tag);
		
		this.service = new TagService();
	}
	
	public newTagFromString = (tagStr: string): Tag => {
		let tag = new Tag();
		tag.userId = getUserId();
		tag.tag = tagStr;
		
		return tag
	}
}
