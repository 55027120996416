import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {ActionType, isError, isErrorUnd, IUIError, NewUIErrorV2} from "../service/cartaError";
import {TimestampDTO, UUID_DTO} from "../proto/utils_pb";
import {convertDateToTimestamp, convertTimestampToDate, ListItem} from "../utils/utils";
import {CardLangDTO, CardReviewFormatDTO} from "../proto/cardLang_pb";
import {ICard} from "./CardComposite";
import {CardLangComposite} from "./CardLangComposite";

export const convertFromDTOToID = (kind: EntityKind, val: UUID_DTO | undefined): string => {
	if (val) {
		if (val!.getValue()) {
			return val!.getValue()
		} else {
			throw new Error(NewUIErrorV2(
				ActionType.ConvertFromDTO,
				kind,
				undefined,
				`UUID_DTO_ID is empty"`
			).toString());
		}
	} else {
		throw new Error(NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`UUID_DTO_ID is empty"`
		).toString());
	}
}

export const convertFromDTOToString = (kind: EntityKind, val: string | undefined, canBeEmpty?: boolean): string | IUIError => {
	if (val) {
		return val
	} else {
		if (canBeEmpty) {
			return ""
		}
		throw NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`text value is empty"`
		);
	}
}

export const convertFromDTOToDate = (kind: EntityKind, val: TimestampDTO | undefined, canBeEmpty?: boolean): Date | undefined | IUIError => {
	if (val) {
		if (val!) {
			return convertTimestampToDate(val)
		} else {
			return NewUIErrorV2(
				ActionType.ConvertFromDTO,
				kind,
				undefined,
				`date is empty"`
			);
		}
	} else {
		if (canBeEmpty) {
			return undefined
		}
		return NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`DATE is empty"`
		);
	}
}

export const convertFromDTOToNumber = (kind: EntityKind, val: number | undefined, canBeEmpty?: boolean): number | IUIError => {
	if (val) {
		return val
	} else {
		if (canBeEmpty) {
			return 0
		}
		return NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`text value is empty"`
		);
	}
}

export class CardLang extends BaseModel<CardLang, CardLangDTO> implements ICard {
	lang1ID: string;
	lang2ID: string;
	front: string;
	back: string;
	reviewFormat: CardReviewFormatDTO;
	composite?: CardLangComposite
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.front,
			metadata1: this.back,
		}
	}
	
	constructor() {
		super();
		this.lang1ID = ""
		this.lang2ID = ""
		this.front = ""
		this.back = ""
		this.reviewFormat = CardReviewFormatDTO.CARDREVIEWFORMAT_BIDI
	}
	
	TYPE: EntityKind = EntityKind.CardLang
	
	fromDTO(dto: CardLangDTO): void | IUIError {
		let id = convertFromDTOToID(this.TYPE, dto.getId())
		
		let userId = convertFromDTOToID(this.TYPE, dto.getUserId())
		
		let lang1 = convertFromDTOToID(this.TYPE, dto.getLang1())
	
		let lang2 = convertFromDTOToID(this.TYPE, dto.getLang2())
		
		const lang1Text = convertFromDTOToString(this.TYPE,  dto.getLang1Text(), false)
		if (isError(lang1Text)) {
			return lang1Text as IUIError
		}
		
		const lang2Text = convertFromDTOToString(this.TYPE,  dto.getLang2Text(), false)
		if (isError(lang2Text)) {
			return lang2Text as IUIError
		}
		
		const createdOn = convertFromDTOToDate(this.TYPE, dto.getCreatedon(), false);
		if (isErrorUnd(createdOn)) {
			return createdOn as IUIError
		}
		
		const updatedOn = convertFromDTOToDate(this.TYPE, dto.getUpdatedon(), false);
		if (isErrorUnd(updatedOn)) {
			return updatedOn as IUIError
		}
		
		let composite : CardLangComposite | undefined = new CardLangComposite()
		if (dto.getComposite()) {
			composite.fromDTO(dto.getComposite()!)
		} else {
			composite = undefined
		}
		
		this.id = id as string;
		this.userId = userId as string;
		this.lang1ID = lang1 as string;
		this.lang2ID = lang2 as string;
		this.front = lang1Text as string;
		this.back = lang2Text as string;
		this.createdOn = createdOn as Date;
		this.updatedOn = updatedOn as Date;
		this.composite = composite
		
		console.log("CardLang.fromDTO", this)
		
		return;
	}
	
	intoDTO(): IUIError | CardLangDTO {
		let dto = new CardLangDTO();
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setUserId(new UUID_DTO().setValue(this.userId));
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
		dto.setLang1(new UUID_DTO().setValue(this.lang1ID));
		dto.setLang2(new UUID_DTO().setValue(this.lang2ID));
		dto.setLang1Text(this.front);
		dto.setLang2Text(this.back);
		dto.setReviewFormat(this.reviewFormat)
		
		return dto
	}
	
	clone(): CardLang {
		let temp = Object.assign({}, this);
		let newLang = new CardLang();
		
		newLang.id = temp.id;
		newLang.userId = temp.userId;
		newLang.createdOn = temp.createdOn;
		newLang.updatedOn = temp.updatedOn;
		newLang.lang1ID = temp.lang1ID;
		newLang.lang2ID = temp.lang2ID;
		newLang.front = temp.front;
		newLang.back = temp.back;
		newLang.reviewFormat = temp.reviewFormat;
		
		return newLang
	}
	
	sanitize():  CardLang {
		return this
	}
	
	validate(): IUIError | CardLang {
		if (this.lang1ID === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "lang1ID is empty")
		}
		if (this.lang2ID === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "lang2ID is empty")
		}
		if (this.front === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "lang1Text is empty")
		}
		if (this.back === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "lang2Text is empty")
		}
		
		return this.sanitize()
	}
	
	init(): CardLang {
		return new CardLang();
	}
	
	to1LineString(): String {
		return `${this.front} - ${this.back}`;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
		} as IDisplayItem;
	}
}