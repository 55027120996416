import {TagServicePromiseClient} from "../proto/tag_grpc_web_pb";
import {
    ListOptionsRequestDTO,
    ListOptionsResponseDTO,
    UUID_DTO,
} from "../proto/utils_pb";
import {
    CARTA_PROXY_URL,
    convertDateToTimestamp,
    DEFAULT_TAG_COLOR,
    SimpleDisplayItem,
} from "../utils/utils";
import {Tag} from "../model/tag";
import {
    DTOCreatorRequestType,
    DTOCreatorResponseType,
    IGRPCService,
    ListResponse,
} from "../model/BaseModel";
import {
    TagDTO,
    CreateTagRequest,
    DeleteTagRequest,
    ListTagRequest,
    UpdateTagRequest, ListTagByIdsRequest,
} from "../proto/tag_pb";
import grpcWeb from "grpc-web";
import {BaseService} from "./BaseService";
import {ReviewSM2DTO} from "../proto/reviewSM2_pb";
import {ListTopicByIdsRequest, TopicDTO} from "../proto/topic_pb";

const tagClient = new TagServicePromiseClient(CARTA_PROXY_URL!, null, {
    withCredentials: true,
});

export class TagGRPCImpl
    implements IGRPCService<TagDTO, DTOCreatorRequestType, DTOCreatorResponseType<TagDTO>> {
    setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
        let req = new ListTagByIdsRequest();
        req.setIdsList(ids);

        return req;
    }

    async listByIDs(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<TagDTO> | undefined> {
        let x = await tagClient.listByIds(req as ListTagByIdsRequest, meta);

        return {
            items: x.getItemsList() as TagDTO[],
            info: x.getInfo(),
        } as ListResponse<TagDTO>;
    }

    async create(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<TagDTO | undefined> {
        let x = await tagClient.create(req as CreateTagRequest, meta);
        return x.getTag() as TagDTO;
    }

    setupCreateReq(dto: TagDTO): DTOCreatorRequestType {
        let req = new CreateTagRequest();
        req.setTag(dto);

        return req;
    }

    async list(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<TagDTO> | undefined> {
        let x = await tagClient.list(req as ListTagRequest, meta);

        return {
            items: x.getItemsList() as TagDTO[],
            info: x.getInfo(),
        } as ListResponse<TagDTO>;
    }

    setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
        let req = new ListTagRequest();
        req.setOpts(dto);

        return req;
    }

    async delete(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<void> {
        const x = await tagClient.delete(req as DeleteTagRequest, meta);
        return;
    }

    setupDeleteReq(id: string): DTOCreatorRequestType {
        let req = new DeleteTagRequest();
        req.setTagId(new UUID_DTO().setValue(id));

        return req;
    }

    get(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<TagDTO | undefined> {
        return Promise.resolve(undefined);
    }

    setupGetReq(id: string): DTOCreatorRequestType {
        return {};
    }

    setupUpdateReq(dto: TagDTO): DTOCreatorRequestType {
        let req = new UpdateTagRequest();
        req.setTag(dto);

        return req;
    }

    async update(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<TagDTO | undefined> {
        const x = await tagClient.update(req as UpdateTagRequest, meta);
        return x.getTag() as TagDTO;
    }
}

export class TagService extends BaseService<Tag, TagDTO, TagGRPCImpl> {
    constructor() {
        super(new TagGRPCImpl(), Tag);
    }
}

export const convertTagToSimpleDisplayItem = (tag: Tag): SimpleDisplayItem => {
    return {
        id: tag.id,
        title: tag.tag,
        color: tag.color,
    };
};

export const convertTagToDTO = (tag: Tag): TagDTO => {
    let dto = new TagDTO();

    dto.setTag(tag.tag);
    dto.setId(new UUID_DTO().setValue(tag.id));
    dto.setUserid(new UUID_DTO().setValue(tag.id));
    dto.setCreatedon(convertDateToTimestamp(tag.createdOn));
    dto.setUpdatedon(convertDateToTimestamp(tag.createdOn));
    dto.setColor(tag.color ? tag.color : DEFAULT_TAG_COLOR);

    return dto;
};
