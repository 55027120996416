import {BaseModel, EntityKind} from "./BaseModel";
import {CardMediaDTO, CardMediaKindDTO, CardMediaOriginDTO, CardMediaSignedURLDTO} from "proto/cardMedia_pb";
import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {IDisplayItem} from "./interfaces";
import {UUID_DTO} from "proto/utils_pb";
import {convertDateToTimestamp, ListItem, NewUUID} from "utils/utils";
import {convertFromDTOToDate, convertFromDTOToID, convertFromDTOToString} from "./CardLang";
import {IFromDTO} from "./model";
import {getUserId} from "service/AuthService";

/**
 * ImageMeta is a data structure that represents an image file uploaded by the user via the UI
 */
export interface ImageMeta {
    url: string;
    name: string;
    size: number; // Size in bytes
    hash: string; // Hash of the image file
    extension: string; // File extension
    file: File; // The image file
}

export function convertImageMetaToCardMedia(images: ImageMeta[], cardId: string): CardMedia[] {
    return images.map((v, index) => {
        let media = new CardMedia()
        media.url = v.url
        media.hash = v.hash
        media.size = v.size
        media.caption = v.name
        media.kind = CardMediaKindDTO.KIND_IMAGE
        media.origin = CardMediaOriginDTO.ORIGIN_UPLOAD
        media.position = index
        media.file = v.file
        media.extension = v.extension
        media.cardId = cardId
        media.id = NewUUID()
        media.userId = getUserId()

        return media

    });
}

interface CardMediaFile extends File {

}

export class CardMedia extends BaseModel<CardMedia, CardMediaDTO> {
    private _position: number;
    private _cardId: string;
    private _kind: CardMediaKindDTO
    private _origin: CardMediaOriginDTO
    private _hash: string;
    private _caption: string;
    private _size: number;
    private _url: string;
    private _file: File;
    private _extension: string;
    private _mime: string;


    get mime(): string {
        return this._mime;
    }

    set mime(value: string) {
        this._mime = value;
    }

    constructor() {
        super();
        this._position = 0;
        this._cardId = "";
        this._kind = CardMediaKindDTO.KIND_IMAGE;
        this._origin = CardMediaOriginDTO.ORIGIN_UPLOAD;
        this._hash = "";
        this._caption = "";
        this._size = 0;
        this._url = "";
        this._file = new File([], "");
        this._extension = "";
        this._mime = "";
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.caption,
            content: this.url,
            createdOn: this.createdOn,
            updatedOn: this.updatedOn,
        } as ListItem
    }

    to1LineString(): String {
        throw new Error("Method not implemented.");
    }

    init(): CardMedia {
        throw new Error("Method not implemented.");
    }

    private _TYPE: EntityKind = EntityKind.CardMedia;

    fromDTO(dto: CardMediaDTO): void | IUIError {
        const id = convertFromDTOToID(this.TYPE, dto.getId());
        const userId = convertFromDTOToID(this.TYPE, dto.getUserId());
        const cardId = convertFromDTOToID(this.TYPE, dto.getCardId());
        const hash = convertFromDTOToString(this.TYPE, dto.getHash());
        const caption = convertFromDTOToString(this.TYPE, dto.getCaption());
        const position = dto.getPosition();
        const size = dto.getSize();
        const url = convertFromDTOToString(this.TYPE, dto.getUrl(), true);
        const kind = dto.getKind();
        const origin = dto.getOrigin();
        const extension = convertFromDTOToString(this.TYPE, dto.getFileext());
        const createdOn = convertFromDTOToDate(this.TYPE, dto.getCreatedOn());
        const updatedOn = convertFromDTOToDate(this.TYPE, dto.getUpdatedOn());

        this.id = id;
        this.userId = userId;
        this.cardId = cardId;
        if (!isError(hash)) {
            this.hash = hash as string;
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, hash as IUIError)
        }
        if (!isError(caption)) {
            this.caption = caption as string;
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, caption as IUIError)
        }
        if (!isError(url)) {
            this.url = url as string;
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, url as IUIError)
        }
        if (!isError(extension)) {
            this.extension = extension as string;
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, extension as IUIError)
        }
        this.position = position;
        this.size = size;
        this.kind = kind;
        this.origin = origin;
        if (createdOn === undefined) {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, undefined, `createdOn is undefined`)
        } else {
            if (!isError(createdOn)) {
                this.createdOn = createdOn as Date;
            } else {
                throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, createdOn as IUIError)
            }
        }

        if (updatedOn === undefined) {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, undefined, `updatedOn is undefined`)
        } else {
            if (!isError(updatedOn)) {
                this.updatedOn = updatedOn as Date;
            } else {
                throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, updatedOn as IUIError)
            }

        }
    }

    intoDTO(): IUIError | CardMediaDTO {
        let dto = new CardMediaDTO();

        dto.setCardId(new UUID_DTO().setValue(this.cardId));
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setCreatedOn(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedOn(convertDateToTimestamp(this.updatedOn));
        dto.setHash(this.hash);
        dto.setCaption(this.caption);
        dto.setPosition(this.position);
        dto.setKind(this.kind);
        dto.setSize(this.size);
        dto.setOrigin(this.origin);
        dto.setFileext(this.extension);

        return dto
    }

    clone(): CardMedia {
        const cloned = new CardMedia();

        cloned.id = this.id;
        cloned.userId = this.userId;
        cloned.position = this._position;
        cloned.cardId = this._cardId;
        cloned.kind = this._kind;
        cloned.origin = this._origin;
        cloned.hash = this._hash;
        cloned.caption = this._caption;
        cloned.size = this._size;
        cloned.url = this._url;
        cloned.file = new File([this._file], this._file.name, { type: this._file.type, lastModified: this._file.lastModified, });
        cloned.extension = this._extension;
        return cloned;
    }

    sanitize(): CardMedia {
        this.caption = this.caption.trim();
        if (this.userId === "") {
            this.userId = getUserId();
        }
        if (this.id === "") {
            this.id = NewUUID();
        }

        return this
    }

    validate(): IUIError | CardMedia {
        if (this.hash === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "hash is empty")
        }
        if (this.size === 0) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, "size is 0")
        }
        if (this._kind !== CardMediaKindDTO.KIND_IMAGE && this._kind !== CardMediaKindDTO.KIND_VIDEO) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, `unsupported media kind: ${this._kind} `)
        }
        if (this._origin !== CardMediaOriginDTO.ORIGIN_UPLOAD) {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, undefined, `unsupported media origin: ${this._origin}`)
        }


        this.caption = this.caption.trim();

        return this.sanitize()
    }

    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.caption,
            metadata1: this.url,
        }
    }

    get extension(): string {
        return this._extension;
    }

    set extension(value: string) {
        this._extension = value;
    }

    get file(): File {
        return this._file;
    }

    set file(value: File) {
        this._file = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get cardId(): string {
        return this._cardId;
    }

    set cardId(value: string) {
        this._cardId = value;
    }

    get kind(): CardMediaKindDTO {
        return this._kind;
    }

    set kind(value: CardMediaKindDTO) {
        this._kind = value;
    }

    get origin(): CardMediaOriginDTO {
        return this._origin;
    }

    set origin(value: CardMediaOriginDTO) {
        this._origin = value;
    }

    get hash(): string {
        return this._hash;
    }

    set hash(value: string) {
        this._hash = value;
    }

    get caption(): string {
        return this._caption;
    }

    set caption(value: string) {
        this._caption = value;
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    get TYPE(): EntityKind {
        return this._TYPE;
    }

    set TYPE(value: EntityKind) {
        this._TYPE = value;
    }
}

export class CardMediaSignedURL implements IFromDTO<CardMediaSignedURLDTO> {
    private _mediaId: string;
    private _cardId: string;
    private _signedUrl: string;

    constructor() {
        this._mediaId = "";
        this._cardId = "";
        this._signedUrl = "";
    }

    fromDTO(dto: CardMediaSignedURLDTO): void | IUIError {
        const id = convertFromDTOToID(EntityKind.CardMediaSignedUrl, dto.getMediaId());
        const cardId = convertFromDTOToID(EntityKind.CardMediaSignedUrl, dto.getCardId());
        const signedUrl = dto.getSignedUrl();

        this._mediaId = id;
        this._cardId = cardId;
        this._signedUrl = signedUrl;
    }

    get mediaId(): string {
        return this._mediaId;
    }

    set mediaId(value: string) {
        this._mediaId = value;
    }

    get cardId(): string {
        return this._cardId;
    }

    set cardId(value: string) {
        this._cardId = value;
    }

    get signedUrl(): string {
        return this._signedUrl;
    }

    set signedUrl(value: string) {
        this._signedUrl = value;
    }

}