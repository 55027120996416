import {InternalErrorTypes, IUIError, NewUIError} from "../service/cartaError";
import {convertDateToTimestamp, convertTimestampToDate, ListItem, NewUUID,} from "../utils/utils";
import {ReviewManualCard, ReviewManualCardStat} from "./ReviewManualCard";
import {v4 as uuidv4} from "uuid";
import {ProgressStateEnumDTO, UUID_DTO} from "../proto/utils_pb";
import {ReviewKindEnumDTO, ReviewManualDTO,} from "../proto/reviewManual_pb";
import {getUserId} from "../service/AuthService";
import {IFromDTO, IModel, IReceiveOnlyModel} from "./model";
import {ReviewManualStatDTO} from "../proto/stats_pb";
import {IReview, IReviewStat} from "./Review";
import {BaseModel, EntityKind} from "./BaseModel";
import {IDisplayItem} from "./interfaces";
import {ReviewManualConfig} from "./ReviewManualConfig";
import {now} from "d3";
import {Error} from "@mui/icons-material";
import {id} from "date-fns/locale";

export class Fetchable<T extends IModel> {
	private _model: T;
	private _lastFetched?: Date;
	
	constructor(obj: T) {
		this._model = obj
	}
	
	get model(): T {
		return this._model;
	}
	
	set model(value: T) {
		this._model = value;
	}
	
	get lastFetched(): Date | undefined {
		return this._lastFetched;
	}
	
	set lastFetched(value: Date | undefined) {
		this._lastFetched = value;
	}
}

export class ReviewManual extends BaseModel<ReviewManual, ReviewManualDTO> implements IReview {
	private _name?: string;
	private _description?: string;
	private _cards: ReviewManualCard[];
	private _progressState: ProgressStateEnumDTO;
	private _currentCard: string;
	private _note: string;
	private _config_id: string;
	private _kind: ReviewKindEnumDTO;
	private _startAt?: Date;
	private _endAt?: Date;
	private _createdOn: Date;
	private _updatedOn: Date;
	private _archivedOn?: Date;
	private _cardOrderList: string[] = []

	constructor() {
		super();
		let now = new Date();
		this._kind = ReviewKindEnumDTO.MANUAL;
		this._createdOn = now;
		this._currentCard = "";
		this.id = uuidv4();
		this._note = "";
		this._progressState = ProgressStateEnumDTO.NOT_STARTED;
		this._updatedOn = now;
		this.userId = getUserId();
		this._cards = [];
		this._config_id = ""
	}
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.name ? this.name : `Review: ${this.createdOn.toString()}`,
			metadata1: this._description,
		}
	}
	
	static fromConfig = (configId: string): ReviewManual => {
		let review = new ReviewManual();
		review._config_id = configId;
		
		return review;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.name ? this.name : "Untitled",
			description: this.description,
		};
	}
	to1LineString(): String {
		return `${this.name} - ${this.description}`;
	}
	init(): ReviewManual {
		return new ReviewManual()
	}
	TYPE: EntityKind = EntityKind.ReviewManual;

	clone(): ReviewManual {
		let review = new ReviewManual();
		review._cards = [...this._cards];
		review.id = `${this.id}`;
		review.userId = `${this.userId}`;
		if (review.name) {
			review.name = `${this.name}`;
		}
		if (review.description) {
			review.description = `${this.description}`;
		}
		review._progressState = this._progressState;
		review._currentCard = `${this._currentCard}`;
		review._note = `${this._note}`;
		review._kind = this._kind;
		review._startAt = this._startAt;
		review._endAt = this._endAt;
		review._createdOn = this._createdOn;
		review._updatedOn = this._updatedOn;
		review._archivedOn = this._archivedOn;
		review._config_id = this._config_id;

		return review;
	}

	fromDTO = (review: ReviewManualDTO): void | IUIError => {
		if (review.getId()) {
			if (review.getId()!.getValue()) {
				this.id = review.getId()!.getValue();
			} else {
				return NewUIError(
					"validateReviewManualDTO",
					InternalErrorTypes.InvalidReview,
					`reviewID is empty '' - review: ${review}"`
				);
			}
		} else {
			return NewUIError(
				"validateReviewManualDTO",
				InternalErrorTypes.InvalidReview,
				`reviewID is undefined '' - review: ${review}"`
			);
		}

		if (review.getUserid()) {
			if (review.getUserid()!.getValue()) {
				this.userId = review.getUserid()!.getValue();
			} else {
				return NewUIError(
					"validateReviewManualDTO",
					InternalErrorTypes.InvalidReview,
					`review userID is empty '' - review: ${review}"`
				);
			}
		} else {
			return NewUIError(
				"validateReviewManualDTO",
				InternalErrorTypes.InvalidReview,
				`review userID is undefined '' - review: ${review}"`
			);
		}
		
		if (review.getReviewconfigid()) {
			if (review.getReviewconfigid()!.getValue()) {
				this.config_id = review.getReviewconfigid()!.getValue();
			} else {
				return NewUIError(
					"validateReviewManualDTO",
					InternalErrorTypes.InvalidReview,
					`review config ID is empty '' - review: ${review}"`
				);
			}
		} else {
			return NewUIError(
				"validateReviewManualDTO",
				InternalErrorTypes.InvalidReview,
				`review config ID is undefined '' - review: ${review}"`
			);
		}


		if (!review.getCreatedon()) {
			return NewUIError(
				"validateReviewManualDTO",
				InternalErrorTypes.InvalidReview,
				`review created date is empty '' - review: ${review}"`
			);
		}

		if (!review.getUpdatedon()) {
			return NewUIError(
				"validateReviewManualDTO",
				InternalErrorTypes.InvalidReview,
				`review updated date is empty '' - review: ${review}"`
			);
		}

		let currentCard: string = "";


		const createdOn = convertTimestampToDate(review.getCreatedon()!);
		const updatedOn = convertTimestampToDate(review.getUpdatedon()!);
		const archivedOn: Date | undefined = review.getArchivedon()
			? convertTimestampToDate(review.getArchivedon()!)
			: undefined;
		const startAt: Date | undefined = review.getStartAt()
			? convertTimestampToDate(review.getStartAt()!)
			: undefined;
		const endAt: Date | undefined = review.getEndAt()
			? convertTimestampToDate(review.getEndAt()!)
			: undefined;

		this._cardOrderList = review.getCardOrderListList().map((x) => x.getValue())

		this._archivedOn = archivedOn;

		this._createdOn = createdOn;
		this._currentCard = currentCard;
		this._description = review.getDescription();
		this._endAt = endAt;
		this._startAt = startAt;
		this._name = review.getName();
		this._note = review.getNote();
		this._progressState = review.getProgressState();
		this._updatedOn = updatedOn;
	};

	validate = (): ReviewManual | IUIError => {
		if (this.name && this.name?.length > 100) {
			return NewUIError(
				"validate",
				InternalErrorTypes.ValidateReview,
				"name field > 100",
				"name cannot be greater than 100 characters"
			);
		}
		if (this.description && this.description?.length > 250) {
			return NewUIError(
				"validate",
				InternalErrorTypes.ValidateReview,
				"description field > 250",
				"description cannot be greater than 100 characters"
			);
		}
		if (this._cards.length > 100) {
			return NewUIError(
				"validate",
				InternalErrorTypes.ValidateReview,
				"cards field > 100",
				"Cannot have more than 100 cards in a review. Split your reviews."
			);
		}
		
		if (this._config_id.length === 0) {
			return NewUIError(
				"validate",
				InternalErrorTypes.ValidateReview,
				"config_id field empty",
				"config_id cannot be empty"
			);
		}

		return this.sanitize()
	};
	
	

	sanitize = (): ReviewManual  => {
		if (!this.id) {
			this.id = NewUUID();
		}
		if (!this.userId) {
			this.userId = getUserId();
		}
		this.createdOn = new Date();
		this.updatedOn = new Date();

		return this
	};

	intoDTO = (): ReviewManualDTO | IUIError => {
		let dto = new ReviewManualDTO();

		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setUserid(new UUID_DTO().setValue(this.userId));
		dto.setReviewconfigid(new UUID_DTO().setValue(this.config_id));

		if (this.name) {
			dto.setName(this.name);
		}
		if (this.description) {
			dto.setDescription(this.description);
		}

		dto.setNote(this.note);
		dto.setProgressState(this.progressState);

		
		if (this.currentCard) {
			dto.setCurrentCardId(new UUID_DTO().setValue(this.currentCard));
		}
		
		dto.setCardOrderListList(this.cardOrderList.map((id) => {return new UUID_DTO().setValue(id) }))

		if (this.endAt) {
			dto.setEndAt(convertDateToTimestamp(this.endAt));
		}
		if (this.startAt) {
			dto.setStartAt(convertDateToTimestamp(this.startAt));
		}
		if (this.archivedOn) {
			dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
		}

		// let cards: ReviewManualCardDTO[] = []
		// this.cards.forEach((card) => {
		// 	const cardDTO = card.intoDTO();
		// 	if (isError(cardDTO)) {
		// 		return cardDTO as IUIError
		// 	}
		// 	cards.push(cardDTO as ReviewManualCardDTO)
		// })

		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));


		return dto;
	};
	
	get config_id(): string {
		return this._config_id;
	}
	
	set config_id(value: string) {
		this._config_id = value;
	}

	get name(): string | undefined {
		return this._name;
	}

	set name(value: string | undefined) {
		this._name = value;
	}

	get description(): string | undefined {
		return this._description;
	}

	set description(value: string | undefined) {
		this._description = value;
	}

	get progressState(): ProgressStateEnumDTO {
		return this._progressState;
	}

	set progressState(value: ProgressStateEnumDTO) {
		this._progressState = value;
	}

	get currentCard(): string {
		return this._currentCard;
	}

	set currentCard(value: string) {
		this._currentCard = value;
	}

	get note(): string {
		return this._note;
	}

	get kind(): ReviewKindEnumDTO {
		return this._kind;
	}

	set kind(value: ReviewKindEnumDTO) {
		this._kind = value;
	}

	set note(value: string) {
		this._note = value;
	}

	get startAt(): Date | undefined {
		return this._startAt;
	}

	set startAt(value: Date | undefined) {
		this._startAt = value;
	}

	get endAt(): Date | undefined {
		return this._endAt;
	}

	set endAt(value: Date | undefined) {
		this._endAt = value;
	}

	get archivedOn(): Date | undefined {
		return this._archivedOn;
	}

	set archivedOn(value: Date | undefined) {
		this._archivedOn = value;
	}

	get cardOrderList(): string[] {
		return this._cardOrderList;
	}

	set cardOrderList(value: string[]) {
		this._cardOrderList = value;
	}
}

export class ReviewManualStat implements  IReceiveOnlyModel<ReviewManualStat, ReviewManualStatDTO>, IReviewStat {
	id: string = "";
	userId: string = "";
	strongestCards: string[] = [];
	weakestCards: string[] = [];
	avgCompletedQuality?: number;
	avgCompletedInterval?: number;
	avgCompletedRepetitions?: number;
	avgQuality?: number;
	avgInterval?: number;
	avgRepetition?: number;
	mostRepeatedCard?: string;
	reviewCards?: ReviewManualCardStat[] = [];

	constructor() {}
	
	TYPE: EntityKind = EntityKind.ReviewManualStat;

    clone(): ReviewManualStat {
		let stat = new ReviewManualStat();
		stat.id = this.id;
		stat.strongestCards = [...this.strongestCards];
		stat.weakestCards = [...this.weakestCards];
		stat.avgCompletedQuality = this.avgCompletedQuality;
		stat.avgCompletedInterval = this.avgCompletedInterval;
		stat.avgCompletedRepetitions = this.avgCompletedRepetitions;
		stat.avgQuality = this.avgQuality;
		stat.avgInterval = this.avgInterval;
		stat.avgRepetition = this.avgRepetition;
		stat.mostRepeatedCard = this.mostRepeatedCard;
		if (this.reviewCards) {
			stat.reviewCards = [...this.reviewCards];
		}
		
		return stat;
    }

	fromDTO(review: ReviewManualStatDTO): void | IUIError {
		if (review.getId()) {
			if (review.getId()!.getValue()) {
				this.id = review.getId()!.getValue();
			} else {
				return NewUIError(
					"fromDTO",
					InternalErrorTypes.InvalidReviewCard,
					`getId is empty '' - reviewCard: ${review}"`
				);
			}
		} else {
			return NewUIError(
				"fromDTO",
				InternalErrorTypes.InvalidReviewCard,
				`getId is undefined '' - reviewCard: ${review}"`
			);
		}

		this.strongestCards = review
			.getStrongestcardsList()
			.map((x) => x.getValue());
		this.weakestCards = review.getWeakestcardsList().map((x) => x.getValue());
		this.avgCompletedRepetitions = review.getAvgcompletedrepetition();
		this.avgRepetition = review.getAvgrepetition();
		this.avgQuality = review.getAvgquality();
		this.avgCompletedQuality = review.getAvgcompletedquality();
		this.avgCompletedInterval = review.getAvgcompletedinterval();
		this.avgInterval = review.getAvginterval();
		this.reviewCards = review.getReviewcardstatsList().map((x) => {
			let stat = new ReviewManualCardStat();
			stat.fromDTO(x);
			return stat;
		});
	}

	reviewId: string = ""
}
