// source: cardMedia.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.cardMedia.CardMediaDTO', null, global);
goog.exportSymbol('proto.cardMedia.CardMediaKindDTO', null, global);
goog.exportSymbol('proto.cardMedia.CardMediaOriginDTO', null, global);
goog.exportSymbol('proto.cardMedia.CardMediaSignedURLDTO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardMedia.CardMediaDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardMedia.CardMediaDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardMedia.CardMediaDTO.displayName = 'proto.cardMedia.CardMediaDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardMedia.CardMediaSignedURLDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardMedia.CardMediaSignedURLDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardMedia.CardMediaSignedURLDTO.displayName = 'proto.cardMedia.CardMediaSignedURLDTO';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardMedia.CardMediaDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cardMedia.CardMediaDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardMedia.CardMediaDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardMedia.CardMediaDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardId: (f = msg.getCardId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    position: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 5, 0),
    origin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    caption: jspb.Message.getFieldWithDefault(msg, 7, ""),
    size: jspb.Message.getFieldWithDefault(msg, 8, 0),
    hash: jspb.Message.getFieldWithDefault(msg, 9, ""),
    url: jspb.Message.getFieldWithDefault(msg, 10, ""),
    fileext: jspb.Message.getFieldWithDefault(msg, 11, ""),
    receivedAt: (f = msg.getReceivedAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    processedAt: (f = msg.getProcessedAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 14, ""),
    createdOn: (f = msg.getCreatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedOn: (f = msg.getUpdatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardMedia.CardMediaDTO}
 */
proto.cardMedia.CardMediaDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardMedia.CardMediaDTO;
  return proto.cardMedia.CardMediaDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardMedia.CardMediaDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardMedia.CardMediaDTO}
 */
proto.cardMedia.CardMediaDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {!proto.cardMedia.CardMediaKindDTO} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 6:
      var value = /** @type {!proto.cardMedia.CardMediaOriginDTO} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileext(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setReceivedAt(value);
      break;
    case 13:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setProcessedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 15:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 16:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardMedia.CardMediaDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardMedia.CardMediaDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardMedia.CardMediaDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardMedia.CardMediaDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFileext();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReceivedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getProcessedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedOn();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO card_id = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getCardId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setCardId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearCardId = function() {
  return this.setCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasCardId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 position = 4;
 * @return {number}
 */
proto.cardMedia.CardMediaDTO.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional CardMediaKindDTO kind = 5;
 * @return {!proto.cardMedia.CardMediaKindDTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getKind = function() {
  return /** @type {!proto.cardMedia.CardMediaKindDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.cardMedia.CardMediaKindDTO} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CardMediaOriginDTO origin = 6;
 * @return {!proto.cardMedia.CardMediaOriginDTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getOrigin = function() {
  return /** @type {!proto.cardMedia.CardMediaOriginDTO} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.cardMedia.CardMediaOriginDTO} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string caption = 7;
 * @return {string}
 */
proto.cardMedia.CardMediaDTO.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 size = 8;
 * @return {number}
 */
proto.cardMedia.CardMediaDTO.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string hash = 9;
 * @return {string}
 */
proto.cardMedia.CardMediaDTO.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string url = 10;
 * @return {string}
 */
proto.cardMedia.CardMediaDTO.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string fileExt = 11;
 * @return {string}
 */
proto.cardMedia.CardMediaDTO.prototype.getFileext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setFileext = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional utils.TimestampDTO received_at = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getReceivedAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setReceivedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearReceivedAt = function() {
  return this.setReceivedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasReceivedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional utils.TimestampDTO processed_at = 13;
 * @return {?proto.utils.TimestampDTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getProcessedAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 13));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setProcessedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearProcessedAt = function() {
  return this.setProcessedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasProcessedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string error = 14;
 * @return {string}
 */
proto.cardMedia.CardMediaDTO.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional utils.TimestampDTO created_on = 15;
 * @return {?proto.utils.TimestampDTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getCreatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 15));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional utils.TimestampDTO updated_on = 16;
 * @return {?proto.utils.TimestampDTO}
 */
proto.cardMedia.CardMediaDTO.prototype.getUpdatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 16));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.cardMedia.CardMediaDTO} returns this
*/
proto.cardMedia.CardMediaDTO.prototype.setUpdatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaDTO} returns this
 */
proto.cardMedia.CardMediaDTO.prototype.clearUpdatedOn = function() {
  return this.setUpdatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaDTO.prototype.hasUpdatedOn = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cardMedia.CardMediaSignedURLDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardMedia.CardMediaSignedURLDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardMedia.CardMediaSignedURLDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    mediaId: (f = msg.getMediaId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardId: (f = msg.getCardId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    signedUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardMedia.CardMediaSignedURLDTO}
 */
proto.cardMedia.CardMediaSignedURLDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardMedia.CardMediaSignedURLDTO;
  return proto.cardMedia.CardMediaSignedURLDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardMedia.CardMediaSignedURLDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardMedia.CardMediaSignedURLDTO}
 */
proto.cardMedia.CardMediaSignedURLDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setMediaId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardMedia.CardMediaSignedURLDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardMedia.CardMediaSignedURLDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardMedia.CardMediaSignedURLDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMediaId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getSignedUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO media_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.getMediaId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardMedia.CardMediaSignedURLDTO} returns this
*/
proto.cardMedia.CardMediaSignedURLDTO.prototype.setMediaId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaSignedURLDTO} returns this
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.clearMediaId = function() {
  return this.setMediaId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.hasMediaId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO card_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.getCardId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardMedia.CardMediaSignedURLDTO} returns this
*/
proto.cardMedia.CardMediaSignedURLDTO.prototype.setCardId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardMedia.CardMediaSignedURLDTO} returns this
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.clearCardId = function() {
  return this.setCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.hasCardId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string signed_url = 3;
 * @return {string}
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.getSignedUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardMedia.CardMediaSignedURLDTO} returns this
 */
proto.cardMedia.CardMediaSignedURLDTO.prototype.setSignedUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.cardMedia.CardMediaKindDTO = {
  KIND_OTHER: 0,
  KIND_IMAGE: 1,
  KIND_VIDEO: 2,
  KIND_AUDIO: 3,
  KIND_DOCUMENT: 4
};

/**
 * @enum {number}
 */
proto.cardMedia.CardMediaOriginDTO = {
  ORIGIN_UNSPECIFIED: 0,
  ORIGIN_UPLOAD: 1,
  ORIGIN_EXTERNAL: 2
};

goog.object.extend(exports, proto.cardMedia);
