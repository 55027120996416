// source: topic.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var stats_pb = require('./stats_pb.js');
goog.object.extend(proto, stats_pb);
goog.exportSymbol('proto.topic.ArchiveTopicRequest', null, global);
goog.exportSymbol('proto.topic.ArchiveTopicResponse', null, global);
goog.exportSymbol('proto.topic.CreateCardRelationRequest', null, global);
goog.exportSymbol('proto.topic.CreateCardRelationResponse', null, global);
goog.exportSymbol('proto.topic.CreateResourceRelationRequest', null, global);
goog.exportSymbol('proto.topic.CreateResourceRelationResponse', null, global);
goog.exportSymbol('proto.topic.CreateTagRelationRequest', null, global);
goog.exportSymbol('proto.topic.CreateTagRelationResponse', null, global);
goog.exportSymbol('proto.topic.CreateTopicRelationshipRequest', null, global);
goog.exportSymbol('proto.topic.CreateTopicRelationshipResponse', null, global);
goog.exportSymbol('proto.topic.CreateTopicRequest', null, global);
goog.exportSymbol('proto.topic.CreateTopicResponse', null, global);
goog.exportSymbol('proto.topic.DeleteCardRelationRequest', null, global);
goog.exportSymbol('proto.topic.DeleteCardRelationResponse', null, global);
goog.exportSymbol('proto.topic.DeleteResourceRelationRequest', null, global);
goog.exportSymbol('proto.topic.DeleteResourceRelationResponse', null, global);
goog.exportSymbol('proto.topic.DeleteTagRelationRequest', null, global);
goog.exportSymbol('proto.topic.DeleteTagRelationResponse', null, global);
goog.exportSymbol('proto.topic.DeleteTopicRelationshipRequest', null, global);
goog.exportSymbol('proto.topic.DeleteTopicRelationshipResponse', null, global);
goog.exportSymbol('proto.topic.DeleteTopicRequest', null, global);
goog.exportSymbol('proto.topic.DeleteTopicResponse', null, global);
goog.exportSymbol('proto.topic.GetTopicChildrenRequest', null, global);
goog.exportSymbol('proto.topic.GetTopicChildrenResponse', null, global);
goog.exportSymbol('proto.topic.GetTopicGlobalStatsRequest', null, global);
goog.exportSymbol('proto.topic.GetTopicGlobalStatsResponse', null, global);
goog.exportSymbol('proto.topic.GetTopicGraphRequest', null, global);
goog.exportSymbol('proto.topic.GetTopicGraphResponse', null, global);
goog.exportSymbol('proto.topic.GetTopicRequest', null, global);
goog.exportSymbol('proto.topic.GetTopicResponse', null, global);
goog.exportSymbol('proto.topic.GetTopicStatRequest', null, global);
goog.exportSymbol('proto.topic.GetTopicStatResponse', null, global);
goog.exportSymbol('proto.topic.ListTopicByIdsRequest', null, global);
goog.exportSymbol('proto.topic.ListTopicByIdsResponse', null, global);
goog.exportSymbol('proto.topic.ListTopicForCardRequest', null, global);
goog.exportSymbol('proto.topic.ListTopicForCardResponse', null, global);
goog.exportSymbol('proto.topic.ListTopicRelationshipRequest', null, global);
goog.exportSymbol('proto.topic.ListTopicRelationshipResponse', null, global);
goog.exportSymbol('proto.topic.ListTopicRequest', null, global);
goog.exportSymbol('proto.topic.ListTopicResponse', null, global);
goog.exportSymbol('proto.topic.ListTopicRootsRequest', null, global);
goog.exportSymbol('proto.topic.ListTopicRootsResponse', null, global);
goog.exportSymbol('proto.topic.RelTopicDSDTO', null, global);
goog.exportSymbol('proto.topic.RelTopicDTO', null, global);
goog.exportSymbol('proto.topic.RelTopicDepthDTO', null, global);
goog.exportSymbol('proto.topic.StatPerformanceTopicDTO', null, global);
goog.exportSymbol('proto.topic.TopicDTO', null, global);
goog.exportSymbol('proto.topic.TopicRelationshipEnumDTO', null, global);
goog.exportSymbol('proto.topic.TopicRelationshipEnumOptsDto', null, global);
goog.exportSymbol('proto.topic.TopicRelationshipGraphDTO', null, global);
goog.exportSymbol('proto.topic.TopicRelationshipMapDTO', null, global);
goog.exportSymbol('proto.topic.TopicRelationshipsDTO', null, global);
goog.exportSymbol('proto.topic.UpdateTopicRequest', null, global);
goog.exportSymbol('proto.topic.UpdateTopicResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.TopicDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.TopicDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.TopicDTO.displayName = 'proto.topic.TopicDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.RelTopicDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.RelTopicDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.RelTopicDTO.displayName = 'proto.topic.RelTopicDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.RelTopicDSDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.RelTopicDSDTO.repeatedFields_, null);
};
goog.inherits(proto.topic.RelTopicDSDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.RelTopicDSDTO.displayName = 'proto.topic.RelTopicDSDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.RelTopicDepthDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.RelTopicDepthDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.RelTopicDepthDTO.displayName = 'proto.topic.RelTopicDepthDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.StatPerformanceTopicDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.StatPerformanceTopicDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.StatPerformanceTopicDTO.displayName = 'proto.topic.StatPerformanceTopicDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicGlobalStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicGlobalStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicGlobalStatsRequest.displayName = 'proto.topic.GetTopicGlobalStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicGlobalStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.GetTopicGlobalStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.topic.GetTopicGlobalStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicGlobalStatsResponse.displayName = 'proto.topic.GetTopicGlobalStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicForCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.ListTopicForCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicForCardRequest.displayName = 'proto.topic.ListTopicForCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicForCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.ListTopicForCardResponse.repeatedFields_, null);
};
goog.inherits(proto.topic.ListTopicForCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicForCardResponse.displayName = 'proto.topic.ListTopicForCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.ListTopicByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.topic.ListTopicByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicByIdsRequest.displayName = 'proto.topic.ListTopicByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.ListTopicByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.topic.ListTopicByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicByIdsResponse.displayName = 'proto.topic.ListTopicByIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicRelationshipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.ListTopicRelationshipRequest.repeatedFields_, null);
};
goog.inherits(proto.topic.ListTopicRelationshipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicRelationshipRequest.displayName = 'proto.topic.ListTopicRelationshipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicRelationshipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.ListTopicRelationshipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicRelationshipResponse.displayName = 'proto.topic.ListTopicRelationshipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateCardRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateCardRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateCardRelationRequest.displayName = 'proto.topic.CreateCardRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateCardRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateCardRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateCardRelationResponse.displayName = 'proto.topic.CreateCardRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateResourceRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateResourceRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateResourceRelationRequest.displayName = 'proto.topic.CreateResourceRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateResourceRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateResourceRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateResourceRelationResponse.displayName = 'proto.topic.CreateResourceRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateTagRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateTagRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateTagRelationRequest.displayName = 'proto.topic.CreateTagRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateTagRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateTagRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateTagRelationResponse.displayName = 'proto.topic.CreateTagRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteResourceRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteResourceRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteResourceRelationRequest.displayName = 'proto.topic.DeleteResourceRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteResourceRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteResourceRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteResourceRelationResponse.displayName = 'proto.topic.DeleteResourceRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteCardRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteCardRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteCardRelationRequest.displayName = 'proto.topic.DeleteCardRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteCardRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteCardRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteCardRelationResponse.displayName = 'proto.topic.DeleteCardRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteTagRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteTagRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteTagRelationRequest.displayName = 'proto.topic.DeleteTagRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteTagRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteTagRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteTagRelationResponse.displayName = 'proto.topic.DeleteTagRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicStatRequest.displayName = 'proto.topic.GetTopicStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicStatResponse.displayName = 'proto.topic.GetTopicStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.UpdateTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.UpdateTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.UpdateTopicRequest.displayName = 'proto.topic.UpdateTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.UpdateTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.UpdateTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.UpdateTopicResponse.displayName = 'proto.topic.UpdateTopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteTopicRelationshipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteTopicRelationshipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteTopicRelationshipRequest.displayName = 'proto.topic.DeleteTopicRelationshipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteTopicRelationshipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteTopicRelationshipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteTopicRelationshipResponse.displayName = 'proto.topic.DeleteTopicRelationshipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateTopicRelationshipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateTopicRelationshipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateTopicRelationshipRequest.displayName = 'proto.topic.CreateTopicRelationshipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateTopicRelationshipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateTopicRelationshipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateTopicRelationshipResponse.displayName = 'proto.topic.CreateTopicRelationshipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicGraphRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicGraphRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicGraphRequest.displayName = 'proto.topic.GetTopicGraphRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicGraphResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicGraphResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicGraphResponse.displayName = 'proto.topic.GetTopicGraphResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicChildrenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicChildrenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicChildrenRequest.displayName = 'proto.topic.GetTopicChildrenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicChildrenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicChildrenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicChildrenResponse.displayName = 'proto.topic.GetTopicChildrenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.TopicRelationshipGraphDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.TopicRelationshipGraphDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.TopicRelationshipGraphDTO.displayName = 'proto.topic.TopicRelationshipGraphDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.TopicRelationshipsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.TopicRelationshipsDTO.repeatedFields_, null);
};
goog.inherits(proto.topic.TopicRelationshipsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.TopicRelationshipsDTO.displayName = 'proto.topic.TopicRelationshipsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.TopicRelationshipMapDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.TopicRelationshipMapDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.TopicRelationshipMapDTO.displayName = 'proto.topic.TopicRelationshipMapDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicRootsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.ListTopicRootsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicRootsRequest.displayName = 'proto.topic.ListTopicRootsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicRootsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.ListTopicRootsResponse.repeatedFields_, null);
};
goog.inherits(proto.topic.ListTopicRootsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicRootsResponse.displayName = 'proto.topic.ListTopicRootsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateTopicRequest.displayName = 'proto.topic.CreateTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.CreateTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.CreateTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.CreateTopicResponse.displayName = 'proto.topic.CreateTopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicRequest.displayName = 'proto.topic.GetTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.GetTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.GetTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.GetTopicResponse.displayName = 'proto.topic.GetTopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.ListTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicRequest.displayName = 'proto.topic.ListTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ListTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.topic.ListTopicResponse.repeatedFields_, null);
};
goog.inherits(proto.topic.ListTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ListTopicResponse.displayName = 'proto.topic.ListTopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ArchiveTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.ArchiveTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ArchiveTopicRequest.displayName = 'proto.topic.ArchiveTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.ArchiveTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.ArchiveTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.ArchiveTopicResponse.displayName = 'proto.topic.ArchiveTopicResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteTopicRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteTopicRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteTopicRequest.displayName = 'proto.topic.DeleteTopicRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.topic.DeleteTopicResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.topic.DeleteTopicResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.topic.DeleteTopicResponse.displayName = 'proto.topic.DeleteTopicResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.TopicDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.TopicDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.TopicDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    topic: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    color: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.TopicDTO}
 */
proto.topic.TopicDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.TopicDTO;
  return proto.topic.TopicDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.TopicDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.TopicDTO}
 */
proto.topic.TopicDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 5:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 6:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 7:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.TopicDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.TopicDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.TopicDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.TopicDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.TopicDTO} returns this
*/
proto.topic.TopicDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.TopicDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.TopicDTO} returns this
*/
proto.topic.TopicDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string topic = 3;
 * @return {string}
 */
proto.topic.TopicDTO.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional utils.TimestampDTO createdOn = 5;
 * @return {?proto.utils.TimestampDTO}
 */
proto.topic.TopicDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 5));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.topic.TopicDTO} returns this
*/
proto.topic.TopicDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 6;
 * @return {?proto.utils.TimestampDTO}
 */
proto.topic.TopicDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 6));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.topic.TopicDTO} returns this
*/
proto.topic.TopicDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 7;
 * @return {?proto.utils.TimestampDTO}
 */
proto.topic.TopicDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 7));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.topic.TopicDTO} returns this
*/
proto.topic.TopicDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string color = 8;
 * @return {string}
 */
proto.topic.TopicDTO.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.topic.TopicDTO} returns this
 */
proto.topic.TopicDTO.prototype.clearColor = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicDTO.prototype.hasColor = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.RelTopicDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.RelTopicDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.RelTopicDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.RelTopicDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    topic1id: (f = msg.getTopic1id()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    topic2id: (f = msg.getTopic2id()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    relationship: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.RelTopicDTO}
 */
proto.topic.RelTopicDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.RelTopicDTO;
  return proto.topic.RelTopicDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.RelTopicDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.RelTopicDTO}
 */
proto.topic.RelTopicDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopic1id(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopic2id(value);
      break;
    case 5:
      var value = /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (reader.readEnum());
      msg.setRelationship(value);
      break;
    case 6:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 7:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.RelTopicDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.RelTopicDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.RelTopicDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.RelTopicDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTopic1id();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTopic2id();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getRelationship();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.RelTopicDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.RelTopicDTO} returns this
*/
proto.topic.RelTopicDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.RelTopicDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.RelTopicDTO} returns this
*/
proto.topic.RelTopicDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO topic1ID = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.RelTopicDTO.prototype.getTopic1id = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.RelTopicDTO} returns this
*/
proto.topic.RelTopicDTO.prototype.setTopic1id = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.clearTopic1id = function() {
  return this.setTopic1id(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDTO.prototype.hasTopic1id = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO topic2ID = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.RelTopicDTO.prototype.getTopic2id = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.RelTopicDTO} returns this
*/
proto.topic.RelTopicDTO.prototype.setTopic2id = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.clearTopic2id = function() {
  return this.setTopic2id(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDTO.prototype.hasTopic2id = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TopicRelationshipEnumDTO relationship = 5;
 * @return {!proto.topic.TopicRelationshipEnumDTO}
 */
proto.topic.RelTopicDTO.prototype.getRelationship = function() {
  return /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.topic.TopicRelationshipEnumDTO} value
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.setRelationship = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional utils.TimestampDTO createdOn = 6;
 * @return {?proto.utils.TimestampDTO}
 */
proto.topic.RelTopicDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 6));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.topic.RelTopicDTO} returns this
*/
proto.topic.RelTopicDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 7;
 * @return {?proto.utils.TimestampDTO}
 */
proto.topic.RelTopicDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 7));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.topic.RelTopicDTO} returns this
*/
proto.topic.RelTopicDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDTO} returns this
 */
proto.topic.RelTopicDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.RelTopicDSDTO.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.RelTopicDSDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.RelTopicDSDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.RelTopicDSDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.RelTopicDSDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootidsList: jspb.Message.toObjectList(msg.getRootidsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    reltopicsList: jspb.Message.toObjectList(msg.getReltopicsList(),
    proto.topic.RelTopicDepthDTO.toObject, includeInstance),
    topicmapMap: (f = msg.getTopicmapMap()) ? f.toObject(includeInstance, proto.topic.TopicDTO.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.RelTopicDSDTO}
 */
proto.topic.RelTopicDSDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.RelTopicDSDTO;
  return proto.topic.RelTopicDSDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.RelTopicDSDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.RelTopicDSDTO}
 */
proto.topic.RelTopicDSDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addRootids(value);
      break;
    case 2:
      var value = new proto.topic.RelTopicDepthDTO;
      reader.readMessage(value,proto.topic.RelTopicDepthDTO.deserializeBinaryFromReader);
      msg.addReltopics(value);
      break;
    case 3:
      var value = msg.getTopicmapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.topic.TopicDTO.deserializeBinaryFromReader, "", new proto.topic.TopicDTO());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.RelTopicDSDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.RelTopicDSDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.RelTopicDSDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.RelTopicDSDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReltopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.topic.RelTopicDepthDTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicmapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.topic.TopicDTO.serializeBinaryToWriter);
  }
};


/**
 * repeated utils.UUID_DTO rootIds = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.topic.RelTopicDSDTO.prototype.getRootidsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.topic.RelTopicDSDTO} returns this
*/
proto.topic.RelTopicDSDTO.prototype.setRootidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.topic.RelTopicDSDTO.prototype.addRootids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.RelTopicDSDTO} returns this
 */
proto.topic.RelTopicDSDTO.prototype.clearRootidsList = function() {
  return this.setRootidsList([]);
};


/**
 * repeated RelTopicDepthDTO relTopics = 2;
 * @return {!Array<!proto.topic.RelTopicDepthDTO>}
 */
proto.topic.RelTopicDSDTO.prototype.getReltopicsList = function() {
  return /** @type{!Array<!proto.topic.RelTopicDepthDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.RelTopicDepthDTO, 2));
};


/**
 * @param {!Array<!proto.topic.RelTopicDepthDTO>} value
 * @return {!proto.topic.RelTopicDSDTO} returns this
*/
proto.topic.RelTopicDSDTO.prototype.setReltopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.topic.RelTopicDepthDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.RelTopicDepthDTO}
 */
proto.topic.RelTopicDSDTO.prototype.addReltopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.topic.RelTopicDepthDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.RelTopicDSDTO} returns this
 */
proto.topic.RelTopicDSDTO.prototype.clearReltopicsList = function() {
  return this.setReltopicsList([]);
};


/**
 * map<string, TopicDTO> topicMap = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.topic.TopicDTO>}
 */
proto.topic.RelTopicDSDTO.prototype.getTopicmapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.topic.TopicDTO>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.topic.TopicDTO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.topic.RelTopicDSDTO} returns this
 */
proto.topic.RelTopicDSDTO.prototype.clearTopicmapMap = function() {
  this.getTopicmapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.RelTopicDepthDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.RelTopicDepthDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.RelTopicDepthDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.RelTopicDepthDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    originaltopic: (f = msg.getOriginaltopic()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reltopics: (f = msg.getReltopics()) && proto.topic.RelTopicDTO.toObject(includeInstance, f),
    depth: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.RelTopicDepthDTO}
 */
proto.topic.RelTopicDepthDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.RelTopicDepthDTO;
  return proto.topic.RelTopicDepthDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.RelTopicDepthDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.RelTopicDepthDTO}
 */
proto.topic.RelTopicDepthDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setOriginaltopic(value);
      break;
    case 2:
      var value = new proto.topic.RelTopicDTO;
      reader.readMessage(value,proto.topic.RelTopicDTO.deserializeBinaryFromReader);
      msg.setReltopics(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.RelTopicDepthDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.RelTopicDepthDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.RelTopicDepthDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.RelTopicDepthDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginaltopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReltopics();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.topic.RelTopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO originalTopic = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.RelTopicDepthDTO.prototype.getOriginaltopic = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.RelTopicDepthDTO} returns this
*/
proto.topic.RelTopicDepthDTO.prototype.setOriginaltopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDepthDTO} returns this
 */
proto.topic.RelTopicDepthDTO.prototype.clearOriginaltopic = function() {
  return this.setOriginaltopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDepthDTO.prototype.hasOriginaltopic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RelTopicDTO relTopics = 2;
 * @return {?proto.topic.RelTopicDTO}
 */
proto.topic.RelTopicDepthDTO.prototype.getReltopics = function() {
  return /** @type{?proto.topic.RelTopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.RelTopicDTO, 2));
};


/**
 * @param {?proto.topic.RelTopicDTO|undefined} value
 * @return {!proto.topic.RelTopicDepthDTO} returns this
*/
proto.topic.RelTopicDepthDTO.prototype.setReltopics = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.RelTopicDepthDTO} returns this
 */
proto.topic.RelTopicDepthDTO.prototype.clearReltopics = function() {
  return this.setReltopics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.RelTopicDepthDTO.prototype.hasReltopics = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 depth = 3;
 * @return {number}
 */
proto.topic.RelTopicDepthDTO.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.RelTopicDepthDTO} returns this
 */
proto.topic.RelTopicDepthDTO.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.StatPerformanceTopicDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.StatPerformanceTopicDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.StatPerformanceTopicDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.StatPerformanceTopicDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && proto.topic.TopicDTO.toObject(includeInstance, f),
    totalreviewcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalmanualreviewcount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalsm2reviewcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgconfidence: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.StatPerformanceTopicDTO}
 */
proto.topic.StatPerformanceTopicDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.StatPerformanceTopicDTO;
  return proto.topic.StatPerformanceTopicDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.StatPerformanceTopicDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.StatPerformanceTopicDTO}
 */
proto.topic.StatPerformanceTopicDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalreviewcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalmanualreviewcount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsm2reviewcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgconfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.StatPerformanceTopicDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.StatPerformanceTopicDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.StatPerformanceTopicDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.StatPerformanceTopicDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getTotalreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalmanualreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTotalsm2reviewcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvgconfidence();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.topic.StatPerformanceTopicDTO.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.topic.StatPerformanceTopicDTO} returns this
*/
proto.topic.StatPerformanceTopicDTO.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.StatPerformanceTopicDTO} returns this
 */
proto.topic.StatPerformanceTopicDTO.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.StatPerformanceTopicDTO.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 totalReviewCount = 2;
 * @return {number}
 */
proto.topic.StatPerformanceTopicDTO.prototype.getTotalreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.StatPerformanceTopicDTO} returns this
 */
proto.topic.StatPerformanceTopicDTO.prototype.setTotalreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 totalManualReviewCount = 3;
 * @return {number}
 */
proto.topic.StatPerformanceTopicDTO.prototype.getTotalmanualreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.StatPerformanceTopicDTO} returns this
 */
proto.topic.StatPerformanceTopicDTO.prototype.setTotalmanualreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 totalSM2ReviewCount = 4;
 * @return {number}
 */
proto.topic.StatPerformanceTopicDTO.prototype.getTotalsm2reviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.StatPerformanceTopicDTO} returns this
 */
proto.topic.StatPerformanceTopicDTO.prototype.setTotalsm2reviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 avgConfidence = 5;
 * @return {number}
 */
proto.topic.StatPerformanceTopicDTO.prototype.getAvgconfidence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.StatPerformanceTopicDTO} returns this
 */
proto.topic.StatPerformanceTopicDTO.prototype.setAvgconfidence = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicGlobalStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicGlobalStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGlobalStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && stats_pb.StatOptsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicGlobalStatsRequest}
 */
proto.topic.GetTopicGlobalStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicGlobalStatsRequest;
  return proto.topic.GetTopicGlobalStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicGlobalStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicGlobalStatsRequest}
 */
proto.topic.GetTopicGlobalStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new stats_pb.StatOptsDTO;
      reader.readMessage(value,stats_pb.StatOptsDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicGlobalStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicGlobalStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGlobalStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stats_pb.StatOptsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO userId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.GetTopicGlobalStatsRequest} returns this
*/
proto.topic.GetTopicGlobalStatsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicGlobalStatsRequest} returns this
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional stats.StatOptsDTO opts = 2;
 * @return {?proto.stats.StatOptsDTO}
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.getOpts = function() {
  return /** @type{?proto.stats.StatOptsDTO} */ (
    jspb.Message.getWrapperField(this, stats_pb.StatOptsDTO, 2));
};


/**
 * @param {?proto.stats.StatOptsDTO|undefined} value
 * @return {!proto.topic.GetTopicGlobalStatsRequest} returns this
*/
proto.topic.GetTopicGlobalStatsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicGlobalStatsRequest} returns this
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicGlobalStatsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.GetTopicGlobalStatsResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicGlobalStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicGlobalStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGlobalStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mostreviewedList: jspb.Message.toObjectList(msg.getMostreviewedList(),
    proto.topic.StatPerformanceTopicDTO.toObject, includeInstance),
    strongestList: jspb.Message.toObjectList(msg.getStrongestList(),
    proto.topic.StatPerformanceTopicDTO.toObject, includeInstance),
    weakestList: jspb.Message.toObjectList(msg.getWeakestList(),
    proto.topic.StatPerformanceTopicDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicGlobalStatsResponse}
 */
proto.topic.GetTopicGlobalStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicGlobalStatsResponse;
  return proto.topic.GetTopicGlobalStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicGlobalStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicGlobalStatsResponse}
 */
proto.topic.GetTopicGlobalStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.StatPerformanceTopicDTO;
      reader.readMessage(value,proto.topic.StatPerformanceTopicDTO.deserializeBinaryFromReader);
      msg.addMostreviewed(value);
      break;
    case 2:
      var value = new proto.topic.StatPerformanceTopicDTO;
      reader.readMessage(value,proto.topic.StatPerformanceTopicDTO.deserializeBinaryFromReader);
      msg.addStrongest(value);
      break;
    case 3:
      var value = new proto.topic.StatPerformanceTopicDTO;
      reader.readMessage(value,proto.topic.StatPerformanceTopicDTO.deserializeBinaryFromReader);
      msg.addWeakest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicGlobalStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicGlobalStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGlobalStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMostreviewedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.topic.StatPerformanceTopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.topic.StatPerformanceTopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.topic.StatPerformanceTopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatPerformanceTopicDTO mostReviewed = 1;
 * @return {!Array<!proto.topic.StatPerformanceTopicDTO>}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.getMostreviewedList = function() {
  return /** @type{!Array<!proto.topic.StatPerformanceTopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.StatPerformanceTopicDTO, 1));
};


/**
 * @param {!Array<!proto.topic.StatPerformanceTopicDTO>} value
 * @return {!proto.topic.GetTopicGlobalStatsResponse} returns this
*/
proto.topic.GetTopicGlobalStatsResponse.prototype.setMostreviewedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.topic.StatPerformanceTopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.StatPerformanceTopicDTO}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.addMostreviewed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.topic.StatPerformanceTopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.GetTopicGlobalStatsResponse} returns this
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.clearMostreviewedList = function() {
  return this.setMostreviewedList([]);
};


/**
 * repeated StatPerformanceTopicDTO strongest = 2;
 * @return {!Array<!proto.topic.StatPerformanceTopicDTO>}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.getStrongestList = function() {
  return /** @type{!Array<!proto.topic.StatPerformanceTopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.StatPerformanceTopicDTO, 2));
};


/**
 * @param {!Array<!proto.topic.StatPerformanceTopicDTO>} value
 * @return {!proto.topic.GetTopicGlobalStatsResponse} returns this
*/
proto.topic.GetTopicGlobalStatsResponse.prototype.setStrongestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.topic.StatPerformanceTopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.StatPerformanceTopicDTO}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.addStrongest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.topic.StatPerformanceTopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.GetTopicGlobalStatsResponse} returns this
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.clearStrongestList = function() {
  return this.setStrongestList([]);
};


/**
 * repeated StatPerformanceTopicDTO weakest = 3;
 * @return {!Array<!proto.topic.StatPerformanceTopicDTO>}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.getWeakestList = function() {
  return /** @type{!Array<!proto.topic.StatPerformanceTopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.StatPerformanceTopicDTO, 3));
};


/**
 * @param {!Array<!proto.topic.StatPerformanceTopicDTO>} value
 * @return {!proto.topic.GetTopicGlobalStatsResponse} returns this
*/
proto.topic.GetTopicGlobalStatsResponse.prototype.setWeakestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.topic.StatPerformanceTopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.StatPerformanceTopicDTO}
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.addWeakest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.topic.StatPerformanceTopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.GetTopicGlobalStatsResponse} returns this
 */
proto.topic.GetTopicGlobalStatsResponse.prototype.clearWeakestList = function() {
  return this.setWeakestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicForCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicForCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicForCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicForCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: (f = msg.getCardId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicForCardRequest}
 */
proto.topic.ListTopicForCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicForCardRequest;
  return proto.topic.ListTopicForCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicForCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicForCardRequest}
 */
proto.topic.ListTopicForCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicForCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicForCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicForCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicForCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO card_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.ListTopicForCardRequest.prototype.getCardId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.ListTopicForCardRequest} returns this
*/
proto.topic.ListTopicForCardRequest.prototype.setCardId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicForCardRequest} returns this
 */
proto.topic.ListTopicForCardRequest.prototype.clearCardId = function() {
  return this.setCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicForCardRequest.prototype.hasCardId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.ListTopicForCardResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicForCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicForCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicForCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicForCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    proto.topic.TopicDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicForCardResponse}
 */
proto.topic.ListTopicForCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicForCardResponse;
  return proto.topic.ListTopicForCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicForCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicForCardResponse}
 */
proto.topic.ListTopicForCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicForCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicForCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicForCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicForCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TopicDTO topics = 2;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.topic.ListTopicForCardResponse.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.TopicDTO, 2));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.topic.ListTopicForCardResponse} returns this
*/
proto.topic.ListTopicForCardResponse.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.topic.ListTopicForCardResponse.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.ListTopicForCardResponse} returns this
 */
proto.topic.ListTopicForCardResponse.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.ListTopicByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicByIdsRequest}
 */
proto.topic.ListTopicByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicByIdsRequest;
  return proto.topic.ListTopicByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicByIdsRequest}
 */
proto.topic.ListTopicByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.topic.ListTopicByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.topic.ListTopicByIdsRequest} returns this
*/
proto.topic.ListTopicByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.topic.ListTopicByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.ListTopicByIdsRequest} returns this
 */
proto.topic.ListTopicByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.ListTopicByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.topic.TopicDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicByIdsResponse}
 */
proto.topic.ListTopicByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicByIdsResponse;
  return proto.topic.ListTopicByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicByIdsResponse}
 */
proto.topic.ListTopicByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TopicDTO items = 1;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.topic.ListTopicByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.topic.ListTopicByIdsResponse} returns this
*/
proto.topic.ListTopicByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.topic.ListTopicByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.ListTopicByIdsResponse} returns this
 */
proto.topic.ListTopicByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.topic.ListTopicByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.topic.ListTopicByIdsResponse} returns this
*/
proto.topic.ListTopicByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicByIdsResponse} returns this
 */
proto.topic.ListTopicByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.ListTopicRelationshipRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicRelationshipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicRelationshipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicRelationshipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRelationshipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicidsList: jspb.Message.toObjectList(msg.getTopicidsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    depth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f),
    relationship: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicRelationshipRequest}
 */
proto.topic.ListTopicRelationshipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicRelationshipRequest;
  return proto.topic.ListTopicRelationshipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicRelationshipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicRelationshipRequest}
 */
proto.topic.ListTopicRelationshipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addTopicids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    case 3:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    case 4:
      var value = /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (reader.readEnum());
      msg.setRelationship(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicRelationshipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicRelationshipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicRelationshipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRelationshipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * repeated utils.UUID_DTO topicIds = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.topic.ListTopicRelationshipRequest.prototype.getTopicidsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
*/
proto.topic.ListTopicRelationshipRequest.prototype.setTopicidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.topic.ListTopicRelationshipRequest.prototype.addTopicids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
 */
proto.topic.ListTopicRelationshipRequest.prototype.clearTopicidsList = function() {
  return this.setTopicidsList([]);
};


/**
 * optional int32 depth = 2;
 * @return {number}
 */
proto.topic.ListTopicRelationshipRequest.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
 */
proto.topic.ListTopicRelationshipRequest.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional utils.ListOptionsRequestDTO opts = 3;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.topic.ListTopicRelationshipRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 3));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
*/
proto.topic.ListTopicRelationshipRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
 */
proto.topic.ListTopicRelationshipRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicRelationshipRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TopicRelationshipEnumDTO relationship = 4;
 * @return {!proto.topic.TopicRelationshipEnumDTO}
 */
proto.topic.ListTopicRelationshipRequest.prototype.getRelationship = function() {
  return /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.topic.TopicRelationshipEnumDTO} value
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
 */
proto.topic.ListTopicRelationshipRequest.prototype.setRelationship = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.topic.ListTopicRelationshipRequest} returns this
 */
proto.topic.ListTopicRelationshipRequest.prototype.clearRelationship = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicRelationshipRequest.prototype.hasRelationship = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicRelationshipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicRelationshipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicRelationshipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRelationshipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    relationships: (f = msg.getRelationships()) && proto.topic.RelTopicDSDTO.toObject(includeInstance, f),
    responseinfo: (f = msg.getResponseinfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicRelationshipResponse}
 */
proto.topic.ListTopicRelationshipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicRelationshipResponse;
  return proto.topic.ListTopicRelationshipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicRelationshipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicRelationshipResponse}
 */
proto.topic.ListTopicRelationshipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.RelTopicDSDTO;
      reader.readMessage(value,proto.topic.RelTopicDSDTO.deserializeBinaryFromReader);
      msg.setRelationships(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setResponseinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicRelationshipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicRelationshipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicRelationshipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRelationshipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelationships();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.RelTopicDSDTO.serializeBinaryToWriter
    );
  }
  f = message.getResponseinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional RelTopicDSDTO relationships = 1;
 * @return {?proto.topic.RelTopicDSDTO}
 */
proto.topic.ListTopicRelationshipResponse.prototype.getRelationships = function() {
  return /** @type{?proto.topic.RelTopicDSDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.RelTopicDSDTO, 1));
};


/**
 * @param {?proto.topic.RelTopicDSDTO|undefined} value
 * @return {!proto.topic.ListTopicRelationshipResponse} returns this
*/
proto.topic.ListTopicRelationshipResponse.prototype.setRelationships = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicRelationshipResponse} returns this
 */
proto.topic.ListTopicRelationshipResponse.prototype.clearRelationships = function() {
  return this.setRelationships(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicRelationshipResponse.prototype.hasRelationships = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.ListOptionsResponseDTO responseInfo = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.topic.ListTopicRelationshipResponse.prototype.getResponseinfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.topic.ListTopicRelationshipResponse} returns this
*/
proto.topic.ListTopicRelationshipResponse.prototype.setResponseinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicRelationshipResponse} returns this
 */
proto.topic.ListTopicRelationshipResponse.prototype.clearResponseinfo = function() {
  return this.setResponseinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicRelationshipResponse.prototype.hasResponseinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateCardRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateCardRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateCardRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateCardRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateCardRelationRequest}
 */
proto.topic.CreateCardRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateCardRelationRequest;
  return proto.topic.CreateCardRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateCardRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateCardRelationRequest}
 */
proto.topic.CreateCardRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateCardRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateCardRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateCardRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateCardRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.CreateCardRelationRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.CreateCardRelationRequest} returns this
*/
proto.topic.CreateCardRelationRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateCardRelationRequest} returns this
 */
proto.topic.CreateCardRelationRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateCardRelationRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO cardId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.CreateCardRelationRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.CreateCardRelationRequest} returns this
*/
proto.topic.CreateCardRelationRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateCardRelationRequest} returns this
 */
proto.topic.CreateCardRelationRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateCardRelationRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateCardRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateCardRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateCardRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateCardRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateCardRelationResponse}
 */
proto.topic.CreateCardRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateCardRelationResponse;
  return proto.topic.CreateCardRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateCardRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateCardRelationResponse}
 */
proto.topic.CreateCardRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateCardRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateCardRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateCardRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateCardRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateResourceRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateResourceRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateResourceRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateResourceRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    tagid: (f = msg.getTagid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateResourceRelationRequest}
 */
proto.topic.CreateResourceRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateResourceRelationRequest;
  return proto.topic.CreateResourceRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateResourceRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateResourceRelationRequest}
 */
proto.topic.CreateResourceRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTagid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateResourceRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateResourceRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateResourceRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateResourceRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTagid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.CreateResourceRelationRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.CreateResourceRelationRequest} returns this
*/
proto.topic.CreateResourceRelationRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateResourceRelationRequest} returns this
 */
proto.topic.CreateResourceRelationRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateResourceRelationRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO tagId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.CreateResourceRelationRequest.prototype.getTagid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.CreateResourceRelationRequest} returns this
*/
proto.topic.CreateResourceRelationRequest.prototype.setTagid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateResourceRelationRequest} returns this
 */
proto.topic.CreateResourceRelationRequest.prototype.clearTagid = function() {
  return this.setTagid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateResourceRelationRequest.prototype.hasTagid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateResourceRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateResourceRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateResourceRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateResourceRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateResourceRelationResponse}
 */
proto.topic.CreateResourceRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateResourceRelationResponse;
  return proto.topic.CreateResourceRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateResourceRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateResourceRelationResponse}
 */
proto.topic.CreateResourceRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateResourceRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateResourceRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateResourceRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateResourceRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateTagRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateTagRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateTagRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTagRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    tagid: (f = msg.getTagid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateTagRelationRequest}
 */
proto.topic.CreateTagRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateTagRelationRequest;
  return proto.topic.CreateTagRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateTagRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateTagRelationRequest}
 */
proto.topic.CreateTagRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTagid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateTagRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateTagRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateTagRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTagRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTagid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.CreateTagRelationRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.CreateTagRelationRequest} returns this
*/
proto.topic.CreateTagRelationRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateTagRelationRequest} returns this
 */
proto.topic.CreateTagRelationRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateTagRelationRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO tagId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.CreateTagRelationRequest.prototype.getTagid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.CreateTagRelationRequest} returns this
*/
proto.topic.CreateTagRelationRequest.prototype.setTagid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateTagRelationRequest} returns this
 */
proto.topic.CreateTagRelationRequest.prototype.clearTagid = function() {
  return this.setTagid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateTagRelationRequest.prototype.hasTagid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateTagRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateTagRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateTagRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTagRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateTagRelationResponse}
 */
proto.topic.CreateTagRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateTagRelationResponse;
  return proto.topic.CreateTagRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateTagRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateTagRelationResponse}
 */
proto.topic.CreateTagRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateTagRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateTagRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateTagRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTagRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteResourceRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteResourceRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteResourceRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteResourceRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    resourceid: (f = msg.getResourceid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteResourceRelationRequest}
 */
proto.topic.DeleteResourceRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteResourceRelationRequest;
  return proto.topic.DeleteResourceRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteResourceRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteResourceRelationRequest}
 */
proto.topic.DeleteResourceRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setResourceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteResourceRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteResourceRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteResourceRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteResourceRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getResourceid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteResourceRelationRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteResourceRelationRequest} returns this
*/
proto.topic.DeleteResourceRelationRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteResourceRelationRequest} returns this
 */
proto.topic.DeleteResourceRelationRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteResourceRelationRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO resourceId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteResourceRelationRequest.prototype.getResourceid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteResourceRelationRequest} returns this
*/
proto.topic.DeleteResourceRelationRequest.prototype.setResourceid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteResourceRelationRequest} returns this
 */
proto.topic.DeleteResourceRelationRequest.prototype.clearResourceid = function() {
  return this.setResourceid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteResourceRelationRequest.prototype.hasResourceid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteResourceRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteResourceRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteResourceRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteResourceRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteResourceRelationResponse}
 */
proto.topic.DeleteResourceRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteResourceRelationResponse;
  return proto.topic.DeleteResourceRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteResourceRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteResourceRelationResponse}
 */
proto.topic.DeleteResourceRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteResourceRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteResourceRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteResourceRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteResourceRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteCardRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteCardRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteCardRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteCardRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteCardRelationRequest}
 */
proto.topic.DeleteCardRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteCardRelationRequest;
  return proto.topic.DeleteCardRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteCardRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteCardRelationRequest}
 */
proto.topic.DeleteCardRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteCardRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteCardRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteCardRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteCardRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteCardRelationRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteCardRelationRequest} returns this
*/
proto.topic.DeleteCardRelationRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteCardRelationRequest} returns this
 */
proto.topic.DeleteCardRelationRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteCardRelationRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO cardId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteCardRelationRequest.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteCardRelationRequest} returns this
*/
proto.topic.DeleteCardRelationRequest.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteCardRelationRequest} returns this
 */
proto.topic.DeleteCardRelationRequest.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteCardRelationRequest.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteCardRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteCardRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteCardRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteCardRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteCardRelationResponse}
 */
proto.topic.DeleteCardRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteCardRelationResponse;
  return proto.topic.DeleteCardRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteCardRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteCardRelationResponse}
 */
proto.topic.DeleteCardRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteCardRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteCardRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteCardRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteCardRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteTagRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteTagRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteTagRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTagRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    tagid: (f = msg.getTagid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteTagRelationRequest}
 */
proto.topic.DeleteTagRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteTagRelationRequest;
  return proto.topic.DeleteTagRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteTagRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteTagRelationRequest}
 */
proto.topic.DeleteTagRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTagid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteTagRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteTagRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteTagRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTagRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTagid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteTagRelationRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteTagRelationRequest} returns this
*/
proto.topic.DeleteTagRelationRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteTagRelationRequest} returns this
 */
proto.topic.DeleteTagRelationRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteTagRelationRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO tagId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteTagRelationRequest.prototype.getTagid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteTagRelationRequest} returns this
*/
proto.topic.DeleteTagRelationRequest.prototype.setTagid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteTagRelationRequest} returns this
 */
proto.topic.DeleteTagRelationRequest.prototype.clearTagid = function() {
  return this.setTagid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteTagRelationRequest.prototype.hasTagid = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteTagRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteTagRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteTagRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTagRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteTagRelationResponse}
 */
proto.topic.DeleteTagRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteTagRelationResponse;
  return proto.topic.DeleteTagRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteTagRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteTagRelationResponse}
 */
proto.topic.DeleteTagRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteTagRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteTagRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteTagRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTagRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid: (f = msg.getTopicid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicStatRequest}
 */
proto.topic.GetTopicStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicStatRequest;
  return proto.topic.GetTopicStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicStatRequest}
 */
proto.topic.GetTopicStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topicId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.GetTopicStatRequest.prototype.getTopicid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.GetTopicStatRequest} returns this
*/
proto.topic.GetTopicStatRequest.prototype.setTopicid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicStatRequest} returns this
 */
proto.topic.GetTopicStatRequest.prototype.clearTopicid = function() {
  return this.setTopicid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicStatRequest.prototype.hasTopicid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && stats_pb.TopicStatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicStatResponse}
 */
proto.topic.GetTopicStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicStatResponse;
  return proto.topic.GetTopicStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicStatResponse}
 */
proto.topic.GetTopicStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stats_pb.TopicStatDTO;
      reader.readMessage(value,stats_pb.TopicStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stats_pb.TopicStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional stats.TopicStatDTO stats = 1;
 * @return {?proto.stats.TopicStatDTO}
 */
proto.topic.GetTopicStatResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.TopicStatDTO} */ (
    jspb.Message.getWrapperField(this, stats_pb.TopicStatDTO, 1));
};


/**
 * @param {?proto.stats.TopicStatDTO|undefined} value
 * @return {!proto.topic.GetTopicStatResponse} returns this
*/
proto.topic.GetTopicStatResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicStatResponse} returns this
 */
proto.topic.GetTopicStatResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicStatResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.UpdateTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.UpdateTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.UpdateTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.UpdateTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && proto.topic.TopicDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.UpdateTopicRequest}
 */
proto.topic.UpdateTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.UpdateTopicRequest;
  return proto.topic.UpdateTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.UpdateTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.UpdateTopicRequest}
 */
proto.topic.UpdateTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.UpdateTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.UpdateTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.UpdateTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.UpdateTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.topic.UpdateTopicRequest.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.topic.UpdateTopicRequest} returns this
*/
proto.topic.UpdateTopicRequest.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.UpdateTopicRequest} returns this
 */
proto.topic.UpdateTopicRequest.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.UpdateTopicRequest.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.UpdateTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.UpdateTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.UpdateTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.UpdateTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && proto.topic.TopicDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.UpdateTopicResponse}
 */
proto.topic.UpdateTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.UpdateTopicResponse;
  return proto.topic.UpdateTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.UpdateTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.UpdateTopicResponse}
 */
proto.topic.UpdateTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.UpdateTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.UpdateTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.UpdateTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.UpdateTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.topic.UpdateTopicResponse.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.topic.UpdateTopicResponse} returns this
*/
proto.topic.UpdateTopicResponse.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.UpdateTopicResponse} returns this
 */
proto.topic.UpdateTopicResponse.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.UpdateTopicResponse.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteTopicRelationshipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteTopicRelationshipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicRelationshipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicid1: (f = msg.getTopicid1()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    topicid2: (f = msg.getTopicid2()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    relationship: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteTopicRelationshipRequest}
 */
proto.topic.DeleteTopicRelationshipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteTopicRelationshipRequest;
  return proto.topic.DeleteTopicRelationshipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteTopicRelationshipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteTopicRelationshipRequest}
 */
proto.topic.DeleteTopicRelationshipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid1(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicid2(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (reader.readEnum());
      msg.setRelationship(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteTopicRelationshipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteTopicRelationshipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicRelationshipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicid1();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicid2();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getRelationship();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO topicId1 = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.getTopicid1 = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
*/
proto.topic.DeleteTopicRelationshipRequest.prototype.setTopicid1 = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.clearTopicid1 = function() {
  return this.setTopicid1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.hasTopicid1 = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO topicId2 = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.getTopicid2 = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
*/
proto.topic.DeleteTopicRelationshipRequest.prototype.setTopicid2 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.clearTopicid2 = function() {
  return this.setTopicid2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.hasTopicid2 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO userId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
*/
proto.topic.DeleteTopicRelationshipRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TopicRelationshipEnumDTO relationship = 4;
 * @return {!proto.topic.TopicRelationshipEnumDTO}
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.getRelationship = function() {
  return /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.topic.TopicRelationshipEnumDTO} value
 * @return {!proto.topic.DeleteTopicRelationshipRequest} returns this
 */
proto.topic.DeleteTopicRelationshipRequest.prototype.setRelationship = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteTopicRelationshipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteTopicRelationshipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteTopicRelationshipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicRelationshipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteTopicRelationshipResponse}
 */
proto.topic.DeleteTopicRelationshipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteTopicRelationshipResponse;
  return proto.topic.DeleteTopicRelationshipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteTopicRelationshipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteTopicRelationshipResponse}
 */
proto.topic.DeleteTopicRelationshipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteTopicRelationshipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteTopicRelationshipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteTopicRelationshipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicRelationshipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateTopicRelationshipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateTopicRelationshipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateTopicRelationshipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicRelationshipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    graph: (f = msg.getGraph()) && proto.topic.TopicRelationshipGraphDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateTopicRelationshipRequest}
 */
proto.topic.CreateTopicRelationshipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateTopicRelationshipRequest;
  return proto.topic.CreateTopicRelationshipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateTopicRelationshipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateTopicRelationshipRequest}
 */
proto.topic.CreateTopicRelationshipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicRelationshipGraphDTO;
      reader.readMessage(value,proto.topic.TopicRelationshipGraphDTO.deserializeBinaryFromReader);
      msg.setGraph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateTopicRelationshipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateTopicRelationshipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateTopicRelationshipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicRelationshipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraph();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicRelationshipGraphDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicRelationshipGraphDTO graph = 1;
 * @return {?proto.topic.TopicRelationshipGraphDTO}
 */
proto.topic.CreateTopicRelationshipRequest.prototype.getGraph = function() {
  return /** @type{?proto.topic.TopicRelationshipGraphDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicRelationshipGraphDTO, 1));
};


/**
 * @param {?proto.topic.TopicRelationshipGraphDTO|undefined} value
 * @return {!proto.topic.CreateTopicRelationshipRequest} returns this
*/
proto.topic.CreateTopicRelationshipRequest.prototype.setGraph = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateTopicRelationshipRequest} returns this
 */
proto.topic.CreateTopicRelationshipRequest.prototype.clearGraph = function() {
  return this.setGraph(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateTopicRelationshipRequest.prototype.hasGraph = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateTopicRelationshipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateTopicRelationshipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateTopicRelationshipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicRelationshipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    graph: (f = msg.getGraph()) && proto.topic.TopicRelationshipGraphDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateTopicRelationshipResponse}
 */
proto.topic.CreateTopicRelationshipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateTopicRelationshipResponse;
  return proto.topic.CreateTopicRelationshipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateTopicRelationshipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateTopicRelationshipResponse}
 */
proto.topic.CreateTopicRelationshipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicRelationshipGraphDTO;
      reader.readMessage(value,proto.topic.TopicRelationshipGraphDTO.deserializeBinaryFromReader);
      msg.setGraph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateTopicRelationshipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateTopicRelationshipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateTopicRelationshipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicRelationshipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraph();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicRelationshipGraphDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicRelationshipGraphDTO graph = 1;
 * @return {?proto.topic.TopicRelationshipGraphDTO}
 */
proto.topic.CreateTopicRelationshipResponse.prototype.getGraph = function() {
  return /** @type{?proto.topic.TopicRelationshipGraphDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicRelationshipGraphDTO, 1));
};


/**
 * @param {?proto.topic.TopicRelationshipGraphDTO|undefined} value
 * @return {!proto.topic.CreateTopicRelationshipResponse} returns this
*/
proto.topic.CreateTopicRelationshipResponse.prototype.setGraph = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateTopicRelationshipResponse} returns this
 */
proto.topic.CreateTopicRelationshipResponse.prototype.clearGraph = function() {
  return this.setGraph(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateTopicRelationshipResponse.prototype.hasGraph = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicGraphRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicGraphRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicGraphRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGraphRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicGraphRequest}
 */
proto.topic.GetTopicGraphRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicGraphRequest;
  return proto.topic.GetTopicGraphRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicGraphRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicGraphRequest}
 */
proto.topic.GetTopicGraphRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicGraphRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicGraphRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicGraphRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGraphRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.GetTopicGraphRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.GetTopicGraphRequest} returns this
*/
proto.topic.GetTopicGraphRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicGraphRequest} returns this
 */
proto.topic.GetTopicGraphRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicGraphRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 offset = 2;
 * @return {number}
 */
proto.topic.GetTopicGraphRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.GetTopicGraphRequest} returns this
 */
proto.topic.GetTopicGraphRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 limit = 3;
 * @return {number}
 */
proto.topic.GetTopicGraphRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.GetTopicGraphRequest} returns this
 */
proto.topic.GetTopicGraphRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicGraphResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicGraphResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicGraphResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGraphResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    graph: (f = msg.getGraph()) && proto.topic.TopicRelationshipGraphDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicGraphResponse}
 */
proto.topic.GetTopicGraphResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicGraphResponse;
  return proto.topic.GetTopicGraphResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicGraphResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicGraphResponse}
 */
proto.topic.GetTopicGraphResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicRelationshipGraphDTO;
      reader.readMessage(value,proto.topic.TopicRelationshipGraphDTO.deserializeBinaryFromReader);
      msg.setGraph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicGraphResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicGraphResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicGraphResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicGraphResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraph();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicRelationshipGraphDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicRelationshipGraphDTO graph = 1;
 * @return {?proto.topic.TopicRelationshipGraphDTO}
 */
proto.topic.GetTopicGraphResponse.prototype.getGraph = function() {
  return /** @type{?proto.topic.TopicRelationshipGraphDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicRelationshipGraphDTO, 1));
};


/**
 * @param {?proto.topic.TopicRelationshipGraphDTO|undefined} value
 * @return {!proto.topic.GetTopicGraphResponse} returns this
*/
proto.topic.GetTopicGraphResponse.prototype.setGraph = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicGraphResponse} returns this
 */
proto.topic.GetTopicGraphResponse.prototype.clearGraph = function() {
  return this.setGraph(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicGraphResponse.prototype.hasGraph = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicChildrenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicChildrenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicChildrenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicChildrenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentId: (f = msg.getParentId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    depth: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicChildrenRequest}
 */
proto.topic.GetTopicChildrenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicChildrenRequest;
  return proto.topic.GetTopicChildrenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicChildrenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicChildrenRequest}
 */
proto.topic.GetTopicChildrenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setParentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicChildrenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicChildrenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicChildrenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicChildrenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO parent_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.GetTopicChildrenRequest.prototype.getParentId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.GetTopicChildrenRequest} returns this
*/
proto.topic.GetTopicChildrenRequest.prototype.setParentId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicChildrenRequest} returns this
 */
proto.topic.GetTopicChildrenRequest.prototype.clearParentId = function() {
  return this.setParentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicChildrenRequest.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 depth = 2;
 * @return {number}
 */
proto.topic.GetTopicChildrenRequest.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.GetTopicChildrenRequest} returns this
 */
proto.topic.GetTopicChildrenRequest.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicChildrenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicChildrenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicChildrenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicChildrenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    graph: (f = msg.getGraph()) && proto.topic.TopicRelationshipGraphDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicChildrenResponse}
 */
proto.topic.GetTopicChildrenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicChildrenResponse;
  return proto.topic.GetTopicChildrenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicChildrenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicChildrenResponse}
 */
proto.topic.GetTopicChildrenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicRelationshipGraphDTO;
      reader.readMessage(value,proto.topic.TopicRelationshipGraphDTO.deserializeBinaryFromReader);
      msg.setGraph(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicChildrenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicChildrenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicChildrenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicChildrenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraph();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicRelationshipGraphDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicRelationshipGraphDTO graph = 1;
 * @return {?proto.topic.TopicRelationshipGraphDTO}
 */
proto.topic.GetTopicChildrenResponse.prototype.getGraph = function() {
  return /** @type{?proto.topic.TopicRelationshipGraphDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicRelationshipGraphDTO, 1));
};


/**
 * @param {?proto.topic.TopicRelationshipGraphDTO|undefined} value
 * @return {!proto.topic.GetTopicChildrenResponse} returns this
*/
proto.topic.GetTopicChildrenResponse.prototype.setGraph = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicChildrenResponse} returns this
 */
proto.topic.GetTopicChildrenResponse.prototype.clearGraph = function() {
  return this.setGraph(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicChildrenResponse.prototype.hasGraph = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.TopicRelationshipGraphDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.TopicRelationshipGraphDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.TopicRelationshipGraphDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicRelationshipGraphDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    graphMap: (f = msg.getGraphMap()) ? f.toObject(includeInstance, proto.topic.TopicRelationshipsDTO.toObject) : [],
    topicsMap: (f = msg.getTopicsMap()) ? f.toObject(includeInstance, proto.topic.TopicDTO.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.TopicRelationshipGraphDTO}
 */
proto.topic.TopicRelationshipGraphDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.TopicRelationshipGraphDTO;
  return proto.topic.TopicRelationshipGraphDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.TopicRelationshipGraphDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.TopicRelationshipGraphDTO}
 */
proto.topic.TopicRelationshipGraphDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGraphMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.topic.TopicRelationshipsDTO.deserializeBinaryFromReader, "", new proto.topic.TopicRelationshipsDTO());
         });
      break;
    case 2:
      var value = msg.getTopicsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.topic.TopicDTO.deserializeBinaryFromReader, "", new proto.topic.TopicDTO());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.TopicRelationshipGraphDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.TopicRelationshipGraphDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.TopicRelationshipGraphDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicRelationshipGraphDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGraphMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.topic.TopicRelationshipsDTO.serializeBinaryToWriter);
  }
  f = message.getTopicsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.topic.TopicDTO.serializeBinaryToWriter);
  }
};


/**
 * map<string, TopicRelationshipsDTO> graph = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.topic.TopicRelationshipsDTO>}
 */
proto.topic.TopicRelationshipGraphDTO.prototype.getGraphMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.topic.TopicRelationshipsDTO>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.topic.TopicRelationshipsDTO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.topic.TopicRelationshipGraphDTO} returns this
 */
proto.topic.TopicRelationshipGraphDTO.prototype.clearGraphMap = function() {
  this.getGraphMap().clear();
  return this;};


/**
 * map<string, TopicDTO> topics = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.topic.TopicDTO>}
 */
proto.topic.TopicRelationshipGraphDTO.prototype.getTopicsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.topic.TopicDTO>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.topic.TopicDTO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.topic.TopicRelationshipGraphDTO} returns this
 */
proto.topic.TopicRelationshipGraphDTO.prototype.clearTopicsMap = function() {
  this.getTopicsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.TopicRelationshipsDTO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.TopicRelationshipsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.TopicRelationshipsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.TopicRelationshipsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicRelationshipsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    mapList: jspb.Message.toObjectList(msg.getMapList(),
    proto.topic.TopicRelationshipMapDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.TopicRelationshipsDTO}
 */
proto.topic.TopicRelationshipsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.TopicRelationshipsDTO;
  return proto.topic.TopicRelationshipsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.TopicRelationshipsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.TopicRelationshipsDTO}
 */
proto.topic.TopicRelationshipsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicRelationshipMapDTO;
      reader.readMessage(value,proto.topic.TopicRelationshipMapDTO.deserializeBinaryFromReader);
      msg.addMap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.TopicRelationshipsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.TopicRelationshipsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.TopicRelationshipsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicRelationshipsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMapList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.topic.TopicRelationshipMapDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TopicRelationshipMapDTO map = 1;
 * @return {!Array<!proto.topic.TopicRelationshipMapDTO>}
 */
proto.topic.TopicRelationshipsDTO.prototype.getMapList = function() {
  return /** @type{!Array<!proto.topic.TopicRelationshipMapDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.TopicRelationshipMapDTO, 1));
};


/**
 * @param {!Array<!proto.topic.TopicRelationshipMapDTO>} value
 * @return {!proto.topic.TopicRelationshipsDTO} returns this
*/
proto.topic.TopicRelationshipsDTO.prototype.setMapList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.topic.TopicRelationshipMapDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicRelationshipMapDTO}
 */
proto.topic.TopicRelationshipsDTO.prototype.addMap = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.topic.TopicRelationshipMapDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.TopicRelationshipsDTO} returns this
 */
proto.topic.TopicRelationshipsDTO.prototype.clearMapList = function() {
  return this.setMapList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.TopicRelationshipMapDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.TopicRelationshipMapDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.TopicRelationshipMapDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicRelationshipMapDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    relationship: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.TopicRelationshipMapDTO}
 */
proto.topic.TopicRelationshipMapDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.TopicRelationshipMapDTO;
  return proto.topic.TopicRelationshipMapDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.TopicRelationshipMapDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.TopicRelationshipMapDTO}
 */
proto.topic.TopicRelationshipMapDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (reader.readEnum());
      msg.setRelationship(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.TopicRelationshipMapDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.TopicRelationshipMapDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.TopicRelationshipMapDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.TopicRelationshipMapDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getRelationship();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.TopicRelationshipMapDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.TopicRelationshipMapDTO} returns this
*/
proto.topic.TopicRelationshipMapDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.TopicRelationshipMapDTO} returns this
 */
proto.topic.TopicRelationshipMapDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.TopicRelationshipMapDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TopicRelationshipEnumDTO relationship = 2;
 * @return {!proto.topic.TopicRelationshipEnumDTO}
 */
proto.topic.TopicRelationshipMapDTO.prototype.getRelationship = function() {
  return /** @type {!proto.topic.TopicRelationshipEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.topic.TopicRelationshipEnumDTO} value
 * @return {!proto.topic.TopicRelationshipMapDTO} returns this
 */
proto.topic.TopicRelationshipMapDTO.prototype.setRelationship = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicRootsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicRootsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicRootsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRootsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicRootsRequest}
 */
proto.topic.ListTopicRootsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicRootsRequest;
  return proto.topic.ListTopicRootsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicRootsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicRootsRequest}
 */
proto.topic.ListTopicRootsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicRootsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicRootsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicRootsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRootsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 offset = 1;
 * @return {number}
 */
proto.topic.ListTopicRootsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.ListTopicRootsRequest} returns this
 */
proto.topic.ListTopicRootsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.topic.ListTopicRootsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.topic.ListTopicRootsRequest} returns this
 */
proto.topic.ListTopicRootsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.ListTopicRootsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicRootsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicRootsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicRootsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRootsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootsList: jspb.Message.toObjectList(msg.getRootsList(),
    proto.topic.TopicDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicRootsResponse}
 */
proto.topic.ListTopicRootsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicRootsResponse;
  return proto.topic.ListTopicRootsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicRootsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicRootsResponse}
 */
proto.topic.ListTopicRootsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.addRoots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicRootsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicRootsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicRootsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRootsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TopicDTO roots = 1;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.topic.ListTopicRootsResponse.prototype.getRootsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.topic.ListTopicRootsResponse} returns this
*/
proto.topic.ListTopicRootsResponse.prototype.setRootsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.topic.ListTopicRootsResponse.prototype.addRoots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.ListTopicRootsResponse} returns this
 */
proto.topic.ListTopicRootsResponse.prototype.clearRootsList = function() {
  return this.setRootsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && proto.topic.TopicDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateTopicRequest}
 */
proto.topic.CreateTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateTopicRequest;
  return proto.topic.CreateTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateTopicRequest}
 */
proto.topic.CreateTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.topic.CreateTopicRequest.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.topic.CreateTopicRequest} returns this
*/
proto.topic.CreateTopicRequest.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateTopicRequest} returns this
 */
proto.topic.CreateTopicRequest.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateTopicRequest.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.CreateTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.CreateTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.CreateTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && proto.topic.TopicDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.CreateTopicResponse}
 */
proto.topic.CreateTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.CreateTopicResponse;
  return proto.topic.CreateTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.CreateTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.CreateTopicResponse}
 */
proto.topic.CreateTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.CreateTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.CreateTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.CreateTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.CreateTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.topic.CreateTopicResponse.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.topic.CreateTopicResponse} returns this
*/
proto.topic.CreateTopicResponse.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.CreateTopicResponse} returns this
 */
proto.topic.CreateTopicResponse.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.CreateTopicResponse.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicId: (f = msg.getTopicId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicRequest}
 */
proto.topic.GetTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicRequest;
  return proto.topic.GetTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicRequest}
 */
proto.topic.GetTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topic_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.GetTopicRequest.prototype.getTopicId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.GetTopicRequest} returns this
*/
proto.topic.GetTopicRequest.prototype.setTopicId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicRequest} returns this
 */
proto.topic.GetTopicRequest.prototype.clearTopicId = function() {
  return this.setTopicId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicRequest.prototype.hasTopicId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.GetTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.GetTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.GetTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: (f = msg.getTopic()) && proto.topic.TopicDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.GetTopicResponse}
 */
proto.topic.GetTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.GetTopicResponse;
  return proto.topic.GetTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.GetTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.GetTopicResponse}
 */
proto.topic.GetTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.GetTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.GetTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.GetTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.GetTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TopicDTO topic = 1;
 * @return {?proto.topic.TopicDTO}
 */
proto.topic.GetTopicResponse.prototype.getTopic = function() {
  return /** @type{?proto.topic.TopicDTO} */ (
    jspb.Message.getWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {?proto.topic.TopicDTO|undefined} value
 * @return {!proto.topic.GetTopicResponse} returns this
*/
proto.topic.GetTopicResponse.prototype.setTopic = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.GetTopicResponse} returns this
 */
proto.topic.GetTopicResponse.prototype.clearTopic = function() {
  return this.setTopic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.GetTopicResponse.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicRequest}
 */
proto.topic.ListTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicRequest;
  return proto.topic.ListTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicRequest}
 */
proto.topic.ListTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.topic.ListTopicRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.topic.ListTopicRequest} returns this
*/
proto.topic.ListTopicRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicRequest} returns this
 */
proto.topic.ListTopicRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.topic.ListTopicResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ListTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ListTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ListTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.topic.TopicDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ListTopicResponse}
 */
proto.topic.ListTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ListTopicResponse;
  return proto.topic.ListTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ListTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ListTopicResponse}
 */
proto.topic.ListTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.topic.TopicDTO;
      reader.readMessage(value,proto.topic.TopicDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ListTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ListTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ListTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ListTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.topic.TopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TopicDTO items = 1;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.topic.ListTopicResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.topic.TopicDTO, 1));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.topic.ListTopicResponse} returns this
*/
proto.topic.ListTopicResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.topic.ListTopicResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.topic.ListTopicResponse} returns this
 */
proto.topic.ListTopicResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.topic.ListTopicResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.topic.ListTopicResponse} returns this
*/
proto.topic.ListTopicResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ListTopicResponse} returns this
 */
proto.topic.ListTopicResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ListTopicResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ArchiveTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ArchiveTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ArchiveTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ArchiveTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicId: (f = msg.getTopicId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ArchiveTopicRequest}
 */
proto.topic.ArchiveTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ArchiveTopicRequest;
  return proto.topic.ArchiveTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ArchiveTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ArchiveTopicRequest}
 */
proto.topic.ArchiveTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ArchiveTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ArchiveTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ArchiveTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ArchiveTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topic_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.ArchiveTopicRequest.prototype.getTopicId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.ArchiveTopicRequest} returns this
*/
proto.topic.ArchiveTopicRequest.prototype.setTopicId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.ArchiveTopicRequest} returns this
 */
proto.topic.ArchiveTopicRequest.prototype.clearTopicId = function() {
  return this.setTopicId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.ArchiveTopicRequest.prototype.hasTopicId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.ArchiveTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.ArchiveTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.ArchiveTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ArchiveTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.ArchiveTopicResponse}
 */
proto.topic.ArchiveTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.ArchiveTopicResponse;
  return proto.topic.ArchiveTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.ArchiveTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.ArchiveTopicResponse}
 */
proto.topic.ArchiveTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.ArchiveTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.ArchiveTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.ArchiveTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.ArchiveTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteTopicRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteTopicRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteTopicRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    topicId: (f = msg.getTopicId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteTopicRequest}
 */
proto.topic.DeleteTopicRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteTopicRequest;
  return proto.topic.DeleteTopicRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteTopicRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteTopicRequest}
 */
proto.topic.DeleteTopicRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setTopicId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteTopicRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteTopicRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteTopicRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopicId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO topic_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.topic.DeleteTopicRequest.prototype.getTopicId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.topic.DeleteTopicRequest} returns this
*/
proto.topic.DeleteTopicRequest.prototype.setTopicId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.topic.DeleteTopicRequest} returns this
 */
proto.topic.DeleteTopicRequest.prototype.clearTopicId = function() {
  return this.setTopicId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.topic.DeleteTopicRequest.prototype.hasTopicId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.topic.DeleteTopicResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.topic.DeleteTopicResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.topic.DeleteTopicResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.topic.DeleteTopicResponse}
 */
proto.topic.DeleteTopicResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.topic.DeleteTopicResponse;
  return proto.topic.DeleteTopicResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.topic.DeleteTopicResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.topic.DeleteTopicResponse}
 */
proto.topic.DeleteTopicResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.topic.DeleteTopicResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.topic.DeleteTopicResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.topic.DeleteTopicResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.topic.DeleteTopicResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.topic.TopicRelationshipEnumDTO = {
  PARENTCHILD: 0,
  GENERIC: 1
};

/**
 * @enum {number}
 */
proto.topic.TopicRelationshipEnumOptsDto = {
  OPTNONE: 0,
  OPTPARENTCHILD: 1,
  OPTGENERIC: 2,
  OPTBOTH: 3
};

goog.object.extend(exports, proto.topic);
