// source: reviewManual.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var card_pb = require('./card_pb.js');
goog.object.extend(proto, card_pb);
var cardLang_pb = require('./cardLang_pb.js');
goog.object.extend(proto, cardLang_pb);
var stats_pb = require('./stats_pb.js');
goog.object.extend(proto, stats_pb);
var topic_pb = require('./topic_pb.js');
goog.object.extend(proto, topic_pb);
goog.exportSymbol('proto.reviewManual.CardFilterResultDTO', null, global);
goog.exportSymbol('proto.reviewManual.CardLangFilterResultDTO', null, global);
goog.exportSymbol('proto.reviewManual.ComparatorConfigIntDTO', null, global);
goog.exportSymbol('proto.reviewManual.ComparatorConfigTimeDTO', null, global);
goog.exportSymbol('proto.reviewManual.ComparatorDTO', null, global);
goog.exportSymbol('proto.reviewManual.CompleteManualReviewRequest', null, global);
goog.exportSymbol('proto.reviewManual.CompleteManualReviewResponse', null, global);
goog.exportSymbol('proto.reviewManual.CreateCardItemsDTO', null, global);
goog.exportSymbol('proto.reviewManual.CreateReviewManualConfigRequest', null, global);
goog.exportSymbol('proto.reviewManual.CreateReviewManualConfigResponse', null, global);
goog.exportSymbol('proto.reviewManual.CreateReviewManualRequest', null, global);
goog.exportSymbol('proto.reviewManual.CreateReviewManualResponse', null, global);
goog.exportSymbol('proto.reviewManual.DeleteReviewManualRequest', null, global);
goog.exportSymbol('proto.reviewManual.DeleteReviewManualResponse', null, global);
goog.exportSymbol('proto.reviewManual.FilterManualCardsRequest', null, global);
goog.exportSymbol('proto.reviewManual.FilterManualCardsResponse', null, global);
goog.exportSymbol('proto.reviewManual.GetReviewManualRequest', null, global);
goog.exportSymbol('proto.reviewManual.GetReviewManualResponse', null, global);
goog.exportSymbol('proto.reviewManual.GetReviewManualStatsRequest', null, global);
goog.exportSymbol('proto.reviewManual.GetReviewManualStatsResponse', null, global);
goog.exportSymbol('proto.reviewManual.InclusionModifierEnumDTO', null, global);
goog.exportSymbol('proto.reviewManual.InclusionUUIDListOptsDTO', null, global);
goog.exportSymbol('proto.reviewManual.ListCardsToReviewByConfigRequest', null, global);
goog.exportSymbol('proto.reviewManual.ListCardsToReviewByConfigResponse', null, global);
goog.exportSymbol('proto.reviewManual.ListManualCardsRequest', null, global);
goog.exportSymbol('proto.reviewManual.ListManualCardsResponse', null, global);
goog.exportSymbol('proto.reviewManual.ListReviewManualByIdsRequest', null, global);
goog.exportSymbol('proto.reviewManual.ListReviewManualByIdsResponse', null, global);
goog.exportSymbol('proto.reviewManual.ListReviewManualRequest', null, global);
goog.exportSymbol('proto.reviewManual.ListReviewManualResponse', null, global);
goog.exportSymbol('proto.reviewManual.ResumeReviewManualRequest', null, global);
goog.exportSymbol('proto.reviewManual.ResumeReviewManualResponse', null, global);
goog.exportSymbol('proto.reviewManual.ReviewFilterKindEnumDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewKindEnumDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewManualCardCompositeDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewManualCardDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewManualCardOrderDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewManualConfigDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewManualDTO', null, global);
goog.exportSymbol('proto.reviewManual.ReviewManualOptionsDTO', null, global);
goog.exportSymbol('proto.reviewManual.SaveManualReviewSessionCardRequest', null, global);
goog.exportSymbol('proto.reviewManual.SaveManualReviewSessionCardResponse', null, global);
goog.exportSymbol('proto.reviewManual.SaveReviewManualRequest', null, global);
goog.exportSymbol('proto.reviewManual.SaveReviewManualResponse', null, global);
goog.exportSymbol('proto.reviewManual.UpdateManualReviewRequest', null, global);
goog.exportSymbol('proto.reviewManual.UpdateManualReviewResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ReviewManualDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ReviewManualDTO.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ReviewManualDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ReviewManualDTO.displayName = 'proto.reviewManual.ReviewManualDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ReviewManualCardOrderDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ReviewManualCardOrderDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ReviewManualCardOrderDTO.displayName = 'proto.reviewManual.ReviewManualCardOrderDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ReviewManualConfigDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ReviewManualConfigDTO.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ReviewManualConfigDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ReviewManualConfigDTO.displayName = 'proto.reviewManual.ReviewManualConfigDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ReviewManualCardDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ReviewManualCardDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ReviewManualCardDTO.displayName = 'proto.reviewManual.ReviewManualCardDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ReviewManualCardCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ReviewManualCardCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ReviewManualCardCompositeDTO.displayName = 'proto.reviewManual.ReviewManualCardCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CardFilterResultDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CardFilterResultDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CardFilterResultDTO.displayName = 'proto.reviewManual.CardFilterResultDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CardLangFilterResultDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CardLangFilterResultDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CardLangFilterResultDTO.displayName = 'proto.reviewManual.CardLangFilterResultDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.InclusionUUIDListOptsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.InclusionUUIDListOptsDTO.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.InclusionUUIDListOptsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.InclusionUUIDListOptsDTO.displayName = 'proto.reviewManual.InclusionUUIDListOptsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CreateReviewManualConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CreateReviewManualConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CreateReviewManualConfigRequest.displayName = 'proto.reviewManual.CreateReviewManualConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CreateReviewManualConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CreateReviewManualConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CreateReviewManualConfigResponse.displayName = 'proto.reviewManual.CreateReviewManualConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.FilterManualCardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.FilterManualCardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.FilterManualCardsRequest.displayName = 'proto.reviewManual.FilterManualCardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.FilterManualCardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.FilterManualCardsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.FilterManualCardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.FilterManualCardsResponse.displayName = 'proto.reviewManual.FilterManualCardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListManualCardsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ListManualCardsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListManualCardsRequest.displayName = 'proto.reviewManual.ListManualCardsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListManualCardsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ListManualCardsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ListManualCardsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListManualCardsResponse.displayName = 'proto.reviewManual.ListManualCardsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.SaveManualReviewSessionCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.SaveManualReviewSessionCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.SaveManualReviewSessionCardRequest.displayName = 'proto.reviewManual.SaveManualReviewSessionCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.SaveManualReviewSessionCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.SaveManualReviewSessionCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.SaveManualReviewSessionCardResponse.displayName = 'proto.reviewManual.SaveManualReviewSessionCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.UpdateManualReviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.UpdateManualReviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.UpdateManualReviewRequest.displayName = 'proto.reviewManual.UpdateManualReviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.UpdateManualReviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.UpdateManualReviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.UpdateManualReviewResponse.displayName = 'proto.reviewManual.UpdateManualReviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.GetReviewManualStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.GetReviewManualStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.GetReviewManualStatsRequest.displayName = 'proto.reviewManual.GetReviewManualStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.GetReviewManualStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.GetReviewManualStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.GetReviewManualStatsResponse.displayName = 'proto.reviewManual.GetReviewManualStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CompleteManualReviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CompleteManualReviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CompleteManualReviewRequest.displayName = 'proto.reviewManual.CompleteManualReviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CompleteManualReviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CompleteManualReviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CompleteManualReviewResponse.displayName = 'proto.reviewManual.CompleteManualReviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ComparatorConfigTimeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ComparatorConfigTimeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ComparatorConfigTimeDTO.displayName = 'proto.reviewManual.ComparatorConfigTimeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ComparatorConfigIntDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ComparatorConfigIntDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ComparatorConfigIntDTO.displayName = 'proto.reviewManual.ComparatorConfigIntDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CreateCardItemsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CreateCardItemsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CreateCardItemsDTO.displayName = 'proto.reviewManual.CreateCardItemsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CreateReviewManualRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.CreateReviewManualRequest.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.CreateReviewManualRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CreateReviewManualRequest.displayName = 'proto.reviewManual.CreateReviewManualRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.CreateReviewManualResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.CreateReviewManualResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.CreateReviewManualResponse.displayName = 'proto.reviewManual.CreateReviewManualResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.GetReviewManualRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.GetReviewManualRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.GetReviewManualRequest.displayName = 'proto.reviewManual.GetReviewManualRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.GetReviewManualResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.GetReviewManualResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.GetReviewManualResponse.displayName = 'proto.reviewManual.GetReviewManualResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListReviewManualRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ListReviewManualRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListReviewManualRequest.displayName = 'proto.reviewManual.ListReviewManualRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListReviewManualResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ListReviewManualResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ListReviewManualResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListReviewManualResponse.displayName = 'proto.reviewManual.ListReviewManualResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ResumeReviewManualRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ResumeReviewManualRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ResumeReviewManualRequest.displayName = 'proto.reviewManual.ResumeReviewManualRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ResumeReviewManualResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ResumeReviewManualResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ResumeReviewManualResponse.displayName = 'proto.reviewManual.ResumeReviewManualResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.SaveReviewManualRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.SaveReviewManualRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.SaveReviewManualRequest.displayName = 'proto.reviewManual.SaveReviewManualRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.SaveReviewManualResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.SaveReviewManualResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.SaveReviewManualResponse.displayName = 'proto.reviewManual.SaveReviewManualResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.DeleteReviewManualRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.DeleteReviewManualRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.DeleteReviewManualRequest.displayName = 'proto.reviewManual.DeleteReviewManualRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.DeleteReviewManualResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.DeleteReviewManualResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.DeleteReviewManualResponse.displayName = 'proto.reviewManual.DeleteReviewManualResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListCardsToReviewByConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reviewManual.ListCardsToReviewByConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListCardsToReviewByConfigRequest.displayName = 'proto.reviewManual.ListCardsToReviewByConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListCardsToReviewByConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ListCardsToReviewByConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ListCardsToReviewByConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListCardsToReviewByConfigResponse.displayName = 'proto.reviewManual.ListCardsToReviewByConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ReviewManualOptionsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ReviewManualOptionsDTO.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ReviewManualOptionsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ReviewManualOptionsDTO.displayName = 'proto.reviewManual.ReviewManualOptionsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListReviewManualByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ListReviewManualByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ListReviewManualByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListReviewManualByIdsRequest.displayName = 'proto.reviewManual.ListReviewManualByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reviewManual.ListReviewManualByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reviewManual.ListReviewManualByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.reviewManual.ListReviewManualByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reviewManual.ListReviewManualByIdsResponse.displayName = 'proto.reviewManual.ListReviewManualByIdsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ReviewManualDTO.repeatedFields_ = [22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ReviewManualDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ReviewManualDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ReviewManualDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewconfigid: (f = msg.getReviewconfigid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentCardId: (f = msg.getCurrentCardId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    progressState: jspb.Message.getFieldWithDefault(msg, 8, 0),
    reviewCardKind: jspb.Message.getFieldWithDefault(msg, 9, 0),
    startAt: (f = msg.getStartAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endAt: (f = msg.getEndAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    cardOrderListList: jspb.Message.toObjectList(msg.getCardOrderListList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.ReviewManualDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ReviewManualDTO;
  return proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ReviewManualDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewconfigid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCurrentCardId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = /** @type {!proto.utils.ProgressStateEnumDTO} */ (reader.readEnum());
      msg.setProgressState(value);
      break;
    case 9:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setReviewCardKind(value);
      break;
    case 17:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartAt(value);
      break;
    case 18:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndAt(value);
      break;
    case 19:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 20:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 21:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    case 22:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addCardOrderList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ReviewManualDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ReviewManualDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewconfigid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentCardId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProgressState();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getReviewCardKind();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getStartAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndAt();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardOrderListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO reviewConfigId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getReviewconfigid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setReviewconfigid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearReviewconfigid = function() {
  return this.setReviewconfigid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasReviewconfigid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.reviewManual.ReviewManualDTO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.setName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.reviewManual.ReviewManualDTO.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional utils.UUID_DTO current_card_id = 6;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getCurrentCardId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 6));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setCurrentCardId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearCurrentCardId = function() {
  return this.setCurrentCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasCurrentCardId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.reviewManual.ReviewManualDTO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.setNote = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearNote = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasNote = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional utils.ProgressStateEnumDTO progress_state = 8;
 * @return {!proto.utils.ProgressStateEnumDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getProgressState = function() {
  return /** @type {!proto.utils.ProgressStateEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.utils.ProgressStateEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.setProgressState = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional utils.EnumReviewCardKindDTO review_card_kind = 9;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getReviewCardKind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.setReviewCardKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional utils.TimestampDTO start_at = 17;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getStartAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 17));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearStartAt = function() {
  return this.setStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasStartAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional utils.TimestampDTO end_at = 18;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getEndAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 18));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearEndAt = function() {
  return this.setEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasEndAt = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 19;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 19));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 20;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 20));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 21;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 21));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated utils.UUID_DTO card_order_list = 22;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualDTO.prototype.getCardOrderListList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 22));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
*/
proto.reviewManual.ReviewManualDTO.prototype.setCardOrderListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualDTO.prototype.addCardOrderList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualDTO} returns this
 */
proto.reviewManual.ReviewManualDTO.prototype.clearCardOrderListList = function() {
  return this.setCardOrderListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ReviewManualCardOrderDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ReviewManualCardOrderDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualCardOrderDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardkind: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO}
 */
proto.reviewManual.ReviewManualCardOrderDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ReviewManualCardOrderDTO;
  return proto.reviewManual.ReviewManualCardOrderDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ReviewManualCardOrderDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO}
 */
proto.reviewManual.ReviewManualCardOrderDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 4:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardkind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ReviewManualCardOrderDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ReviewManualCardOrderDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualCardOrderDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardkind();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
*/
proto.reviewManual.ReviewManualCardOrderDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
*/
proto.reviewManual.ReviewManualCardOrderDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO cardId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
*/
proto.reviewManual.ReviewManualCardOrderDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.EnumReviewCardKindDTO cardKind = 4;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.getCardkind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewManual.ReviewManualCardOrderDTO} returns this
 */
proto.reviewManual.ReviewManualCardOrderDTO.prototype.setCardkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ReviewManualConfigDTO.repeatedFields_ = [10,12,14,16,18,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ReviewManualConfigDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ReviewManualConfigDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualConfigDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    isTemplate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isRandom: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    filterkind: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timeFrom: (f = msg.getTimeFrom()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    timeTo: (f = msg.getTimeTo()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    avgquality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    avgqualitycomparator: jspb.Message.getFieldWithDefault(msg, 9, 0),
    languagesList: jspb.Message.toObjectList(msg.getLanguagesList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    languageFiltersModifier: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    cardsModifier: jspb.Message.getFieldWithDefault(msg, 13, 0),
    cardLangsList: jspb.Message.toObjectList(msg.getCardLangsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    cardsLangsModifier: jspb.Message.getFieldWithDefault(msg, 15, 0),
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    topicsModifier: jspb.Message.getFieldWithDefault(msg, 17, 0),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    tagsModifier: jspb.Message.getFieldWithDefault(msg, 19, 0),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    resourcesModifier: jspb.Message.getFieldWithDefault(msg, 21, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 22, 0),
    reviewCardKind: jspb.Message.getFieldWithDefault(msg, 23, 0),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    topicrelationshipopts: jspb.Message.getFieldWithDefault(msg, 25, 0),
    topicrelationshipdepth: jspb.Message.getFieldWithDefault(msg, 26, 0),
    name: jspb.Message.getFieldWithDefault(msg, 27, ""),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ReviewManualConfigDTO;
  return proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ReviewManualConfigDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTemplate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRandom(value);
      break;
    case 5:
      var value = /** @type {!proto.reviewManual.ReviewFilterKindEnumDTO} */ (reader.readEnum());
      msg.setFilterkind(value);
      break;
    case 6:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setTimeFrom(value);
      break;
    case 7:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setTimeTo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    case 9:
      var value = /** @type {!proto.reviewManual.ComparatorDTO} */ (reader.readEnum());
      msg.setAvgqualitycomparator(value);
      break;
    case 10:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addLanguages(value);
      break;
    case 11:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setLanguageFiltersModifier(value);
      break;
    case 12:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 13:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setCardsModifier(value);
      break;
    case 14:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addCardLangs(value);
      break;
    case 15:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setCardsLangsModifier(value);
      break;
    case 16:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    case 17:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setTopicsModifier(value);
      break;
    case 18:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 19:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setTagsModifier(value);
      break;
    case 20:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    case 21:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setResourcesModifier(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 23:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setReviewCardKind(value);
      break;
    case 24:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 25:
      var value = /** @type {!proto.topic.TopicRelationshipEnumOptsDto} */ (reader.readEnum());
      msg.setTopicrelationshipopts(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTopicrelationshipdepth(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 28:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ReviewManualConfigDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getIsTemplate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsRandom();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {!proto.reviewManual.ReviewFilterKindEnumDTO} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTimeFrom();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getTimeTo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {!proto.reviewManual.ComparatorDTO} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getLanguageFiltersModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardsModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getCardLangsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardsLangsModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicsModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getTagsModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getResourcesModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getReviewCardKind();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicrelationshipopts();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getTopicrelationshipdepth();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_template = 3;
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getIsTemplate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setIsTemplate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_random = 4;
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getIsRandom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setIsRandom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional ReviewFilterKindEnumDTO filterKind = 5;
 * @return {!proto.reviewManual.ReviewFilterKindEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getFilterkind = function() {
  return /** @type {!proto.reviewManual.ReviewFilterKindEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.reviewManual.ReviewFilterKindEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setFilterkind = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearFilterkind = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasFilterkind = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional utils.TimestampDTO time_from = 6;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTimeFrom = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 6));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setTimeFrom = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearTimeFrom = function() {
  return this.setTimeFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasTimeFrom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional utils.TimestampDTO time_to = 7;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTimeTo = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 7));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setTimeTo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearTimeTo = function() {
  return this.setTimeTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasTimeTo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 avgQuality = 8;
 * @return {number}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ComparatorDTO avgQualityComparator = 9;
 * @return {!proto.reviewManual.ComparatorDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getAvgqualitycomparator = function() {
  return /** @type {!proto.reviewManual.ComparatorDTO} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.reviewManual.ComparatorDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setAvgqualitycomparator = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearAvgqualitycomparator = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasAvgqualitycomparator = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated utils.UUID_DTO languages = 10;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getLanguagesList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 10));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setLanguagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.addLanguages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearLanguagesList = function() {
  return this.setLanguagesList([]);
};


/**
 * optional InclusionModifierEnumDTO language_filters_modifier = 11;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getLanguageFiltersModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setLanguageFiltersModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated utils.UUID_DTO cards = 12;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 12));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional InclusionModifierEnumDTO cards_modifier = 13;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getCardsModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setCardsModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * repeated utils.UUID_DTO card_langs = 14;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getCardLangsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 14));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setCardLangsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.addCardLangs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearCardLangsList = function() {
  return this.setCardLangsList([]);
};


/**
 * optional InclusionModifierEnumDTO cards_langs_modifier = 15;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getCardsLangsModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setCardsLangsModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated utils.UUID_DTO topics = 16;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 16));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};


/**
 * optional InclusionModifierEnumDTO topics_modifier = 17;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTopicsModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setTopicsModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated utils.UUID_DTO tags = 18;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 18));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional InclusionModifierEnumDTO tags_modifier = 19;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTagsModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setTagsModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * repeated utils.UUID_DTO resources = 20;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 20));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};


/**
 * optional InclusionModifierEnumDTO resources_modifier = 21;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getResourcesModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setResourcesModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional int32 limit = 22;
 * @return {number}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional utils.EnumReviewCardKindDTO review_card_kind = 23;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getReviewCardKind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setReviewCardKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional utils.TimestampDTO createdOn = 24;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 24));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional topic.TopicRelationshipEnumOptsDto topicRelationshipOpts = 25;
 * @return {!proto.topic.TopicRelationshipEnumOptsDto}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTopicrelationshipopts = function() {
  return /** @type {!proto.topic.TopicRelationshipEnumOptsDto} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.topic.TopicRelationshipEnumOptsDto} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setTopicrelationshipopts = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional int32 topicRelationshipDepth = 26;
 * @return {number}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getTopicrelationshipdepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setTopicrelationshipdepth = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string name = 27;
 * @return {string}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional utils.TimestampDTO updatedOn = 28;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 28));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
*/
proto.reviewManual.ReviewManualConfigDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualConfigDTO} returns this
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualConfigDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 28) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ReviewManualCardDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ReviewManualCardDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualCardDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardkind: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    quality: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startAt: (f = msg.getStartAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endAt: (f = msg.getEndAt()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    composite: (f = msg.getComposite()) && proto.reviewManual.ReviewManualCardCompositeDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedon: (f = msg.getArchivedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ReviewManualCardDTO}
 */
proto.reviewManual.ReviewManualCardDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ReviewManualCardDTO;
  return proto.reviewManual.ReviewManualCardDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ReviewManualCardDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ReviewManualCardDTO}
 */
proto.reviewManual.ReviewManualCardDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 4:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardkind(value);
      break;
    case 5:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 7:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartAt(value);
      break;
    case 8:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndAt(value);
      break;
    case 9:
      var value = new proto.reviewManual.ReviewManualCardCompositeDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualCardCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 11:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ReviewManualCardDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ReviewManualCardDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualCardDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardkind();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStartAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.reviewManual.ReviewManualCardCompositeDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedon();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO userId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO cardId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.EnumReviewCardKindDTO cardKind = 4;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getCardkind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.setCardkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional utils.UUID_DTO reviewID = 5;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 5));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 quality = 6;
 * @return {number}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.setQuality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearQuality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasQuality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional utils.TimestampDTO start_at = 7;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getStartAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 7));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setStartAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearStartAt = function() {
  return this.setStartAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasStartAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional utils.TimestampDTO end_at = 8;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getEndAt = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 8));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setEndAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearEndAt = function() {
  return this.setEndAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasEndAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ReviewManualCardCompositeDTO composite = 9;
 * @return {?proto.reviewManual.ReviewManualCardCompositeDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getComposite = function() {
  return /** @type{?proto.reviewManual.ReviewManualCardCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualCardCompositeDTO, 9));
};


/**
 * @param {?proto.reviewManual.ReviewManualCardCompositeDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 11;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 11));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO archivedOn = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.getArchivedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
*/
proto.reviewManual.ReviewManualCardDTO.prototype.setArchivedon = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardDTO} returns this
 */
proto.reviewManual.ReviewManualCardDTO.prototype.clearArchivedon = function() {
  return this.setArchivedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardDTO.prototype.hasArchivedon = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ReviewManualCardCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ReviewManualCardCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualCardCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    supertypeId: (f = msg.getSupertypeId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ord: jspb.Message.getFieldWithDefault(msg, 2, 0),
    card: (f = msg.getCard()) && card_pb.CardDTO.toObject(includeInstance, f),
    cardLang: (f = msg.getCardLang()) && cardLang_pb.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ReviewManualCardCompositeDTO;
  return proto.reviewManual.ReviewManualCardCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ReviewManualCardCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setSupertypeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrd(value);
      break;
    case 3:
      var value = new card_pb.CardDTO;
      reader.readMessage(value,card_pb.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 4:
      var value = new cardLang_pb.CardLangDTO;
      reader.readMessage(value,cardLang_pb.CardLangDTO.deserializeBinaryFromReader);
      msg.setCardLang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ReviewManualCardCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ReviewManualCardCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualCardCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupertypeId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOrd();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      card_pb.CardDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardLang();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      cardLang_pb.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO supertype_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.getSupertypeId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
*/
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.setSupertypeId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.clearSupertypeId = function() {
  return this.setSupertypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.hasSupertypeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 ord = 2;
 * @return {number}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.getOrd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.setOrd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional card.CardDTO card = 3;
 * @return {?proto.card.CardDTO}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, card_pb.CardDTO, 3));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
*/
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional cardLang.CardLangDTO card_lang = 4;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.getCardLang = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, cardLang_pb.CardLangDTO, 4));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
*/
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.setCardLang = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualCardCompositeDTO} returns this
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.clearCardLang = function() {
  return this.setCardLang(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualCardCompositeDTO.prototype.hasCardLang = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CardFilterResultDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CardFilterResultDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CardFilterResultDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CardFilterResultDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && card_pb.CardDTO.toObject(includeInstance, f),
    avgquality: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CardFilterResultDTO}
 */
proto.reviewManual.CardFilterResultDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CardFilterResultDTO;
  return proto.reviewManual.CardFilterResultDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CardFilterResultDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CardFilterResultDTO}
 */
proto.reviewManual.CardFilterResultDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new card_pb.CardDTO;
      reader.readMessage(value,card_pb.CardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CardFilterResultDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CardFilterResultDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CardFilterResultDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CardFilterResultDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      card_pb.CardDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional card.CardDTO card = 3;
 * @return {?proto.card.CardDTO}
 */
proto.reviewManual.CardFilterResultDTO.prototype.getCard = function() {
  return /** @type{?proto.card.CardDTO} */ (
    jspb.Message.getWrapperField(this, card_pb.CardDTO, 3));
};


/**
 * @param {?proto.card.CardDTO|undefined} value
 * @return {!proto.reviewManual.CardFilterResultDTO} returns this
*/
proto.reviewManual.CardFilterResultDTO.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CardFilterResultDTO} returns this
 */
proto.reviewManual.CardFilterResultDTO.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CardFilterResultDTO.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 avgQuality = 4;
 * @return {number}
 */
proto.reviewManual.CardFilterResultDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.CardFilterResultDTO} returns this
 */
proto.reviewManual.CardFilterResultDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.CardFilterResultDTO} returns this
 */
proto.reviewManual.CardFilterResultDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CardFilterResultDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CardLangFilterResultDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CardLangFilterResultDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CardLangFilterResultDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardlang: (f = msg.getCardlang()) && cardLang_pb.CardLangDTO.toObject(includeInstance, f),
    avgquality: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CardLangFilterResultDTO}
 */
proto.reviewManual.CardLangFilterResultDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CardLangFilterResultDTO;
  return proto.reviewManual.CardLangFilterResultDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CardLangFilterResultDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CardLangFilterResultDTO}
 */
proto.reviewManual.CardLangFilterResultDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new cardLang_pb.CardLangDTO;
      reader.readMessage(value,cardLang_pb.CardLangDTO.deserializeBinaryFromReader);
      msg.setCardlang(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CardLangFilterResultDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CardLangFilterResultDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CardLangFilterResultDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardlang();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      cardLang_pb.CardLangDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional cardLang.CardLangDTO cardLang = 1;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.getCardlang = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, cardLang_pb.CardLangDTO, 1));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.reviewManual.CardLangFilterResultDTO} returns this
*/
proto.reviewManual.CardLangFilterResultDTO.prototype.setCardlang = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CardLangFilterResultDTO} returns this
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.clearCardlang = function() {
  return this.setCardlang(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.hasCardlang = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 avgQuality = 4;
 * @return {number}
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.CardLangFilterResultDTO} returns this
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reviewManual.CardLangFilterResultDTO} returns this
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CardLangFilterResultDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.InclusionUUIDListOptsDTO.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.InclusionUUIDListOptsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.InclusionUUIDListOptsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.InclusionUUIDListOptsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    modifier: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.InclusionUUIDListOptsDTO}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.InclusionUUIDListOptsDTO;
  return proto.reviewManual.InclusionUUIDListOptsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.InclusionUUIDListOptsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.InclusionUUIDListOptsDTO}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (reader.readEnum());
      msg.setModifier(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.InclusionUUIDListOptsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.InclusionUUIDListOptsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.InclusionUUIDListOptsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModifier();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional InclusionModifierEnumDTO modifier = 1;
 * @return {!proto.reviewManual.InclusionModifierEnumDTO}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.getModifier = function() {
  return /** @type {!proto.reviewManual.InclusionModifierEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.reviewManual.InclusionModifierEnumDTO} value
 * @return {!proto.reviewManual.InclusionUUIDListOptsDTO} returns this
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.setModifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated utils.UUID_DTO items = 2;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.InclusionUUIDListOptsDTO} returns this
*/
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.InclusionUUIDListOptsDTO} returns this
 */
proto.reviewManual.InclusionUUIDListOptsDTO.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CreateReviewManualConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CreateReviewManualConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CreateReviewManualConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && proto.reviewManual.ReviewManualConfigDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CreateReviewManualConfigRequest}
 */
proto.reviewManual.CreateReviewManualConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CreateReviewManualConfigRequest;
  return proto.reviewManual.CreateReviewManualConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CreateReviewManualConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CreateReviewManualConfigRequest}
 */
proto.reviewManual.CreateReviewManualConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualConfigDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CreateReviewManualConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CreateReviewManualConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CreateReviewManualConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualConfigDTO config = 1;
 * @return {?proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.CreateReviewManualConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.reviewManual.ReviewManualConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualConfigDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualConfigDTO|undefined} value
 * @return {!proto.reviewManual.CreateReviewManualConfigRequest} returns this
*/
proto.reviewManual.CreateReviewManualConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateReviewManualConfigRequest} returns this
 */
proto.reviewManual.CreateReviewManualConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateReviewManualConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CreateReviewManualConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CreateReviewManualConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CreateReviewManualConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && proto.reviewManual.ReviewManualConfigDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CreateReviewManualConfigResponse}
 */
proto.reviewManual.CreateReviewManualConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CreateReviewManualConfigResponse;
  return proto.reviewManual.CreateReviewManualConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CreateReviewManualConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CreateReviewManualConfigResponse}
 */
proto.reviewManual.CreateReviewManualConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualConfigDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CreateReviewManualConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CreateReviewManualConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CreateReviewManualConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualConfigDTO config = 1;
 * @return {?proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.CreateReviewManualConfigResponse.prototype.getConfig = function() {
  return /** @type{?proto.reviewManual.ReviewManualConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualConfigDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualConfigDTO|undefined} value
 * @return {!proto.reviewManual.CreateReviewManualConfigResponse} returns this
*/
proto.reviewManual.CreateReviewManualConfigResponse.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateReviewManualConfigResponse} returns this
 */
proto.reviewManual.CreateReviewManualConfigResponse.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateReviewManualConfigResponse.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.FilterManualCardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.FilterManualCardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.FilterManualCardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.FilterManualCardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.reviewManual.ReviewManualConfigDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.FilterManualCardsRequest}
 */
proto.reviewManual.FilterManualCardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.FilterManualCardsRequest;
  return proto.reviewManual.FilterManualCardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.FilterManualCardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.FilterManualCardsRequest}
 */
proto.reviewManual.FilterManualCardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualConfigDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.FilterManualCardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.FilterManualCardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.FilterManualCardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.FilterManualCardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualConfigDTO filter = 1;
 * @return {?proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.FilterManualCardsRequest.prototype.getFilter = function() {
  return /** @type{?proto.reviewManual.ReviewManualConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualConfigDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualConfigDTO|undefined} value
 * @return {!proto.reviewManual.FilterManualCardsRequest} returns this
*/
proto.reviewManual.FilterManualCardsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.FilterManualCardsRequest} returns this
 */
proto.reviewManual.FilterManualCardsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.FilterManualCardsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.FilterManualCardsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.FilterManualCardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.FilterManualCardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.FilterManualCardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.FilterManualCardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardList: jspb.Message.toObjectList(msg.getCardList(),
    proto.reviewManual.CardFilterResultDTO.toObject, includeInstance),
    cardlangList: jspb.Message.toObjectList(msg.getCardlangList(),
    proto.reviewManual.CardLangFilterResultDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.FilterManualCardsResponse}
 */
proto.reviewManual.FilterManualCardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.FilterManualCardsResponse;
  return proto.reviewManual.FilterManualCardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.FilterManualCardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.FilterManualCardsResponse}
 */
proto.reviewManual.FilterManualCardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.CardFilterResultDTO;
      reader.readMessage(value,proto.reviewManual.CardFilterResultDTO.deserializeBinaryFromReader);
      msg.addCard(value);
      break;
    case 2:
      var value = new proto.reviewManual.CardLangFilterResultDTO;
      reader.readMessage(value,proto.reviewManual.CardLangFilterResultDTO.deserializeBinaryFromReader);
      msg.addCardlang(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.FilterManualCardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.FilterManualCardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.FilterManualCardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.FilterManualCardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewManual.CardFilterResultDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardlangList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reviewManual.CardLangFilterResultDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardFilterResultDTO card = 1;
 * @return {!Array<!proto.reviewManual.CardFilterResultDTO>}
 */
proto.reviewManual.FilterManualCardsResponse.prototype.getCardList = function() {
  return /** @type{!Array<!proto.reviewManual.CardFilterResultDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.CardFilterResultDTO, 1));
};


/**
 * @param {!Array<!proto.reviewManual.CardFilterResultDTO>} value
 * @return {!proto.reviewManual.FilterManualCardsResponse} returns this
*/
proto.reviewManual.FilterManualCardsResponse.prototype.setCardList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewManual.CardFilterResultDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.CardFilterResultDTO}
 */
proto.reviewManual.FilterManualCardsResponse.prototype.addCard = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewManual.CardFilterResultDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.FilterManualCardsResponse} returns this
 */
proto.reviewManual.FilterManualCardsResponse.prototype.clearCardList = function() {
  return this.setCardList([]);
};


/**
 * repeated CardLangFilterResultDTO cardLang = 2;
 * @return {!Array<!proto.reviewManual.CardLangFilterResultDTO>}
 */
proto.reviewManual.FilterManualCardsResponse.prototype.getCardlangList = function() {
  return /** @type{!Array<!proto.reviewManual.CardLangFilterResultDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.CardLangFilterResultDTO, 2));
};


/**
 * @param {!Array<!proto.reviewManual.CardLangFilterResultDTO>} value
 * @return {!proto.reviewManual.FilterManualCardsResponse} returns this
*/
proto.reviewManual.FilterManualCardsResponse.prototype.setCardlangList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reviewManual.CardLangFilterResultDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.CardLangFilterResultDTO}
 */
proto.reviewManual.FilterManualCardsResponse.prototype.addCardlang = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reviewManual.CardLangFilterResultDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.FilterManualCardsResponse} returns this
 */
proto.reviewManual.FilterManualCardsResponse.prototype.clearCardlangList = function() {
  return this.setCardlangList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListManualCardsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListManualCardsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListManualCardsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListManualCardsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListManualCardsRequest}
 */
proto.reviewManual.ListManualCardsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListManualCardsRequest;
  return proto.reviewManual.ListManualCardsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListManualCardsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListManualCardsRequest}
 */
proto.reviewManual.ListManualCardsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListManualCardsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListManualCardsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListManualCardsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListManualCardsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ListManualCardsRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ListManualCardsRequest} returns this
*/
proto.reviewManual.ListManualCardsRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListManualCardsRequest} returns this
 */
proto.reviewManual.ListManualCardsRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListManualCardsRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ListManualCardsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListManualCardsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListManualCardsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListManualCardsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListManualCardsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    manualcardsList: jspb.Message.toObjectList(msg.getManualcardsList(),
    proto.reviewManual.ReviewManualCardDTO.toObject, includeInstance),
    responseinfo: (f = msg.getResponseinfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListManualCardsResponse}
 */
proto.reviewManual.ListManualCardsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListManualCardsResponse;
  return proto.reviewManual.ListManualCardsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListManualCardsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListManualCardsResponse}
 */
proto.reviewManual.ListManualCardsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualCardDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualCardDTO.deserializeBinaryFromReader);
      msg.addManualcards(value);
      break;
    case 4:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setResponseinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListManualCardsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListManualCardsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListManualCardsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListManualCardsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManualcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewManual.ReviewManualCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getResponseinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewManualCardDTO manualCards = 1;
 * @return {!Array<!proto.reviewManual.ReviewManualCardDTO>}
 */
proto.reviewManual.ListManualCardsResponse.prototype.getManualcardsList = function() {
  return /** @type{!Array<!proto.reviewManual.ReviewManualCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.ReviewManualCardDTO, 1));
};


/**
 * @param {!Array<!proto.reviewManual.ReviewManualCardDTO>} value
 * @return {!proto.reviewManual.ListManualCardsResponse} returns this
*/
proto.reviewManual.ListManualCardsResponse.prototype.setManualcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewManual.ReviewManualCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.ReviewManualCardDTO}
 */
proto.reviewManual.ListManualCardsResponse.prototype.addManualcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewManual.ReviewManualCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ListManualCardsResponse} returns this
 */
proto.reviewManual.ListManualCardsResponse.prototype.clearManualcardsList = function() {
  return this.setManualcardsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO responseInfo = 4;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewManual.ListManualCardsResponse.prototype.getResponseinfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 4));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewManual.ListManualCardsResponse} returns this
*/
proto.reviewManual.ListManualCardsResponse.prototype.setResponseinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListManualCardsResponse} returns this
 */
proto.reviewManual.ListManualCardsResponse.prototype.clearResponseinfo = function() {
  return this.setResponseinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListManualCardsResponse.prototype.hasResponseinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.SaveManualReviewSessionCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.SaveManualReviewSessionCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    card: (f = msg.getCard()) && proto.reviewManual.ReviewManualCardDTO.toObject(includeInstance, f),
    nextReviewCardId: (f = msg.getNextReviewCardId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.SaveManualReviewSessionCardRequest;
  return proto.reviewManual.SaveManualReviewSessionCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.SaveManualReviewSessionCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new proto.reviewManual.ReviewManualCardDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualCardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setNextReviewCardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.SaveManualReviewSessionCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.SaveManualReviewSessionCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reviewManual.ReviewManualCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getNextReviewCardId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest} returns this
*/
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest} returns this
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewManualCardDTO card = 2;
 * @return {?proto.reviewManual.ReviewManualCardDTO}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.getCard = function() {
  return /** @type{?proto.reviewManual.ReviewManualCardDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualCardDTO, 2));
};


/**
 * @param {?proto.reviewManual.ReviewManualCardDTO|undefined} value
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest} returns this
*/
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest} returns this
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.hasCard = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO next_review_card_id = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.getNextReviewCardId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest} returns this
*/
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.setNextReviewCardId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardRequest} returns this
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.clearNextReviewCardId = function() {
  return this.setNextReviewCardId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.SaveManualReviewSessionCardRequest.prototype.hasNextReviewCardId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.SaveManualReviewSessionCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.SaveManualReviewSessionCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.reviewManual.ReviewManualCardDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardResponse}
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.SaveManualReviewSessionCardResponse;
  return proto.reviewManual.SaveManualReviewSessionCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.SaveManualReviewSessionCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardResponse}
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualCardDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualCardDTO.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.SaveManualReviewSessionCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.SaveManualReviewSessionCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualCardDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualCardDTO card = 1;
 * @return {?proto.reviewManual.ReviewManualCardDTO}
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.prototype.getCard = function() {
  return /** @type{?proto.reviewManual.ReviewManualCardDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualCardDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualCardDTO|undefined} value
 * @return {!proto.reviewManual.SaveManualReviewSessionCardResponse} returns this
*/
proto.reviewManual.SaveManualReviewSessionCardResponse.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.SaveManualReviewSessionCardResponse} returns this
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.SaveManualReviewSessionCardResponse.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.UpdateManualReviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.UpdateManualReviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.UpdateManualReviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.UpdateManualReviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.UpdateManualReviewRequest}
 */
proto.reviewManual.UpdateManualReviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.UpdateManualReviewRequest;
  return proto.reviewManual.UpdateManualReviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.UpdateManualReviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.UpdateManualReviewRequest}
 */
proto.reviewManual.UpdateManualReviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.UpdateManualReviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.UpdateManualReviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.UpdateManualReviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.UpdateManualReviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO review = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.UpdateManualReviewRequest.prototype.getReview = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.UpdateManualReviewRequest} returns this
*/
proto.reviewManual.UpdateManualReviewRequest.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.UpdateManualReviewRequest} returns this
 */
proto.reviewManual.UpdateManualReviewRequest.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.UpdateManualReviewRequest.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.UpdateManualReviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.UpdateManualReviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.UpdateManualReviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.UpdateManualReviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.UpdateManualReviewResponse}
 */
proto.reviewManual.UpdateManualReviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.UpdateManualReviewResponse;
  return proto.reviewManual.UpdateManualReviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.UpdateManualReviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.UpdateManualReviewResponse}
 */
proto.reviewManual.UpdateManualReviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.UpdateManualReviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.UpdateManualReviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.UpdateManualReviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.UpdateManualReviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO review = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.UpdateManualReviewResponse.prototype.getReview = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.UpdateManualReviewResponse} returns this
*/
proto.reviewManual.UpdateManualReviewResponse.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.UpdateManualReviewResponse} returns this
 */
proto.reviewManual.UpdateManualReviewResponse.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.UpdateManualReviewResponse.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.GetReviewManualStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.GetReviewManualStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.GetReviewManualStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.GetReviewManualStatsRequest}
 */
proto.reviewManual.GetReviewManualStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.GetReviewManualStatsRequest;
  return proto.reviewManual.GetReviewManualStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.GetReviewManualStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.GetReviewManualStatsRequest}
 */
proto.reviewManual.GetReviewManualStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.GetReviewManualStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.GetReviewManualStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.GetReviewManualStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.GetReviewManualStatsRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.GetReviewManualStatsRequest} returns this
*/
proto.reviewManual.GetReviewManualStatsRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.GetReviewManualStatsRequest} returns this
 */
proto.reviewManual.GetReviewManualStatsRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.GetReviewManualStatsRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.GetReviewManualStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.GetReviewManualStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.GetReviewManualStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && stats_pb.ReviewManualStatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.GetReviewManualStatsResponse}
 */
proto.reviewManual.GetReviewManualStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.GetReviewManualStatsResponse;
  return proto.reviewManual.GetReviewManualStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.GetReviewManualStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.GetReviewManualStatsResponse}
 */
proto.reviewManual.GetReviewManualStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stats_pb.ReviewManualStatDTO;
      reader.readMessage(value,stats_pb.ReviewManualStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.GetReviewManualStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.GetReviewManualStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.GetReviewManualStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stats_pb.ReviewManualStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional stats.ReviewManualStatDTO stats = 1;
 * @return {?proto.stats.ReviewManualStatDTO}
 */
proto.reviewManual.GetReviewManualStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.ReviewManualStatDTO} */ (
    jspb.Message.getWrapperField(this, stats_pb.ReviewManualStatDTO, 1));
};


/**
 * @param {?proto.stats.ReviewManualStatDTO|undefined} value
 * @return {!proto.reviewManual.GetReviewManualStatsResponse} returns this
*/
proto.reviewManual.GetReviewManualStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.GetReviewManualStatsResponse} returns this
 */
proto.reviewManual.GetReviewManualStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.GetReviewManualStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CompleteManualReviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CompleteManualReviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CompleteManualReviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CompleteManualReviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CompleteManualReviewRequest}
 */
proto.reviewManual.CompleteManualReviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CompleteManualReviewRequest;
  return proto.reviewManual.CompleteManualReviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CompleteManualReviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CompleteManualReviewRequest}
 */
proto.reviewManual.CompleteManualReviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CompleteManualReviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CompleteManualReviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CompleteManualReviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CompleteManualReviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO review = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.CompleteManualReviewRequest.prototype.getReview = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.CompleteManualReviewRequest} returns this
*/
proto.reviewManual.CompleteManualReviewRequest.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CompleteManualReviewRequest} returns this
 */
proto.reviewManual.CompleteManualReviewRequest.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CompleteManualReviewRequest.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CompleteManualReviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CompleteManualReviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CompleteManualReviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    review: (f = msg.getReview()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && stats_pb.ReviewManualStatDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CompleteManualReviewResponse}
 */
proto.reviewManual.CompleteManualReviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CompleteManualReviewResponse;
  return proto.reviewManual.CompleteManualReviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CompleteManualReviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CompleteManualReviewResponse}
 */
proto.reviewManual.CompleteManualReviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 2:
      var value = new stats_pb.ReviewManualStatDTO;
      reader.readMessage(value,stats_pb.ReviewManualStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CompleteManualReviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CompleteManualReviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CompleteManualReviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stats_pb.ReviewManualStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO review = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.getReview = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.CompleteManualReviewResponse} returns this
*/
proto.reviewManual.CompleteManualReviewResponse.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CompleteManualReviewResponse} returns this
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.hasReview = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional stats.ReviewManualStatDTO stats = 2;
 * @return {?proto.stats.ReviewManualStatDTO}
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.ReviewManualStatDTO} */ (
    jspb.Message.getWrapperField(this, stats_pb.ReviewManualStatDTO, 2));
};


/**
 * @param {?proto.stats.ReviewManualStatDTO|undefined} value
 * @return {!proto.reviewManual.CompleteManualReviewResponse} returns this
*/
proto.reviewManual.CompleteManualReviewResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CompleteManualReviewResponse} returns this
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CompleteManualReviewResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ComparatorConfigTimeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ComparatorConfigTimeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ComparatorConfigTimeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    to: (f = msg.getTo()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ComparatorConfigTimeDTO}
 */
proto.reviewManual.ComparatorConfigTimeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ComparatorConfigTimeDTO;
  return proto.reviewManual.ComparatorConfigTimeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ComparatorConfigTimeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ComparatorConfigTimeDTO}
 */
proto.reviewManual.ComparatorConfigTimeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ComparatorConfigTimeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ComparatorConfigTimeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ComparatorConfigTimeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.TimestampDTO from = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.getFrom = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ComparatorConfigTimeDTO} returns this
*/
proto.reviewManual.ComparatorConfigTimeDTO.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ComparatorConfigTimeDTO} returns this
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.TimestampDTO to = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.getTo = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.reviewManual.ComparatorConfigTimeDTO} returns this
*/
proto.reviewManual.ComparatorConfigTimeDTO.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ComparatorConfigTimeDTO} returns this
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ComparatorConfigTimeDTO.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ComparatorConfigIntDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ComparatorConfigIntDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ComparatorConfigIntDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ComparatorConfigIntDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    comparator: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ComparatorConfigIntDTO}
 */
proto.reviewManual.ComparatorConfigIntDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ComparatorConfigIntDTO;
  return proto.reviewManual.ComparatorConfigIntDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ComparatorConfigIntDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ComparatorConfigIntDTO}
 */
proto.reviewManual.ComparatorConfigIntDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.reviewManual.ComparatorDTO} */ (reader.readEnum());
      msg.setComparator(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ComparatorConfigIntDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ComparatorConfigIntDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ComparatorConfigIntDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ComparatorConfigIntDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComparator();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional ComparatorDTO Comparator = 1;
 * @return {!proto.reviewManual.ComparatorDTO}
 */
proto.reviewManual.ComparatorConfigIntDTO.prototype.getComparator = function() {
  return /** @type {!proto.reviewManual.ComparatorDTO} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.reviewManual.ComparatorDTO} value
 * @return {!proto.reviewManual.ComparatorConfigIntDTO} returns this
 */
proto.reviewManual.ComparatorConfigIntDTO.prototype.setComparator = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.reviewManual.ComparatorConfigIntDTO.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reviewManual.ComparatorConfigIntDTO} returns this
 */
proto.reviewManual.ComparatorConfigIntDTO.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CreateCardItemsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CreateCardItemsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CreateCardItemsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateCardItemsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardkind: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CreateCardItemsDTO}
 */
proto.reviewManual.CreateCardItemsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CreateCardItemsDTO;
  return proto.reviewManual.CreateCardItemsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CreateCardItemsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CreateCardItemsDTO}
 */
proto.reviewManual.CreateCardItemsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 2:
      var value = /** @type {!proto.utils.EnumReviewCardKindDTO} */ (reader.readEnum());
      msg.setCardkind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CreateCardItemsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CreateCardItemsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CreateCardItemsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateCardItemsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardkind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO cardId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.CreateCardItemsDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.CreateCardItemsDTO} returns this
*/
proto.reviewManual.CreateCardItemsDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateCardItemsDTO} returns this
 */
proto.reviewManual.CreateCardItemsDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateCardItemsDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.EnumReviewCardKindDTO cardKind = 2;
 * @return {!proto.utils.EnumReviewCardKindDTO}
 */
proto.reviewManual.CreateCardItemsDTO.prototype.getCardkind = function() {
  return /** @type {!proto.utils.EnumReviewCardKindDTO} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.utils.EnumReviewCardKindDTO} value
 * @return {!proto.reviewManual.CreateCardItemsDTO} returns this
 */
proto.reviewManual.CreateCardItemsDTO.prototype.setCardkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.CreateReviewManualRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CreateReviewManualRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CreateReviewManualRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.reviewManual.CreateCardItemsDTO.toObject, includeInstance),
    config: (f = msg.getConfig()) && proto.reviewManual.ReviewManualConfigDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CreateReviewManualRequest}
 */
proto.reviewManual.CreateReviewManualRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CreateReviewManualRequest;
  return proto.reviewManual.CreateReviewManualRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CreateReviewManualRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CreateReviewManualRequest}
 */
proto.reviewManual.CreateReviewManualRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = new proto.reviewManual.CreateCardItemsDTO;
      reader.readMessage(value,proto.reviewManual.CreateCardItemsDTO.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 3:
      var value = new proto.reviewManual.ReviewManualConfigDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CreateReviewManualRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CreateReviewManualRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reviewManual.CreateCardItemsDTO.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO session = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.getSession = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.CreateReviewManualRequest} returns this
*/
proto.reviewManual.CreateReviewManualRequest.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateReviewManualRequest} returns this
 */
proto.reviewManual.CreateReviewManualRequest.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CreateCardItemsDTO cards = 2;
 * @return {!Array<!proto.reviewManual.CreateCardItemsDTO>}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.reviewManual.CreateCardItemsDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.CreateCardItemsDTO, 2));
};


/**
 * @param {!Array<!proto.reviewManual.CreateCardItemsDTO>} value
 * @return {!proto.reviewManual.CreateReviewManualRequest} returns this
*/
proto.reviewManual.CreateReviewManualRequest.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reviewManual.CreateCardItemsDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.CreateCardItemsDTO}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reviewManual.CreateCardItemsDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.CreateReviewManualRequest} returns this
 */
proto.reviewManual.CreateReviewManualRequest.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional ReviewManualConfigDTO config = 3;
 * @return {?proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.getConfig = function() {
  return /** @type{?proto.reviewManual.ReviewManualConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualConfigDTO, 3));
};


/**
 * @param {?proto.reviewManual.ReviewManualConfigDTO|undefined} value
 * @return {!proto.reviewManual.CreateReviewManualRequest} returns this
*/
proto.reviewManual.CreateReviewManualRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateReviewManualRequest} returns this
 */
proto.reviewManual.CreateReviewManualRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateReviewManualRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.CreateReviewManualResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.CreateReviewManualResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.CreateReviewManualResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f),
    config: (f = msg.getConfig()) && proto.reviewManual.ReviewManualConfigDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.CreateReviewManualResponse}
 */
proto.reviewManual.CreateReviewManualResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.CreateReviewManualResponse;
  return proto.reviewManual.CreateReviewManualResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.CreateReviewManualResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.CreateReviewManualResponse}
 */
proto.reviewManual.CreateReviewManualResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = new proto.reviewManual.ReviewManualConfigDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualConfigDTO.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.CreateReviewManualResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.CreateReviewManualResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.CreateReviewManualResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.CreateReviewManualResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reviewManual.ReviewManualConfigDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO session = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.CreateReviewManualResponse.prototype.getSession = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.CreateReviewManualResponse} returns this
*/
proto.reviewManual.CreateReviewManualResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateReviewManualResponse} returns this
 */
proto.reviewManual.CreateReviewManualResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateReviewManualResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewManualConfigDTO config = 2;
 * @return {?proto.reviewManual.ReviewManualConfigDTO}
 */
proto.reviewManual.CreateReviewManualResponse.prototype.getConfig = function() {
  return /** @type{?proto.reviewManual.ReviewManualConfigDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualConfigDTO, 2));
};


/**
 * @param {?proto.reviewManual.ReviewManualConfigDTO|undefined} value
 * @return {!proto.reviewManual.CreateReviewManualResponse} returns this
*/
proto.reviewManual.CreateReviewManualResponse.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.CreateReviewManualResponse} returns this
 */
proto.reviewManual.CreateReviewManualResponse.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.CreateReviewManualResponse.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.GetReviewManualRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.GetReviewManualRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.GetReviewManualRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.GetReviewManualRequest}
 */
proto.reviewManual.GetReviewManualRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.GetReviewManualRequest;
  return proto.reviewManual.GetReviewManualRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.GetReviewManualRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.GetReviewManualRequest}
 */
proto.reviewManual.GetReviewManualRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.GetReviewManualRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.GetReviewManualRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.GetReviewManualRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.GetReviewManualRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.GetReviewManualRequest} returns this
*/
proto.reviewManual.GetReviewManualRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.GetReviewManualRequest} returns this
 */
proto.reviewManual.GetReviewManualRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.GetReviewManualRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.GetReviewManualResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.GetReviewManualResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.GetReviewManualResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.GetReviewManualResponse}
 */
proto.reviewManual.GetReviewManualResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.GetReviewManualResponse;
  return proto.reviewManual.GetReviewManualResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.GetReviewManualResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.GetReviewManualResponse}
 */
proto.reviewManual.GetReviewManualResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.GetReviewManualResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.GetReviewManualResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.GetReviewManualResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.GetReviewManualResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO session = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.GetReviewManualResponse.prototype.getSession = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.GetReviewManualResponse} returns this
*/
proto.reviewManual.GetReviewManualResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.GetReviewManualResponse} returns this
 */
proto.reviewManual.GetReviewManualResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.GetReviewManualResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListReviewManualRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListReviewManualRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListReviewManualRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListReviewManualRequest}
 */
proto.reviewManual.ListReviewManualRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListReviewManualRequest;
  return proto.reviewManual.ListReviewManualRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListReviewManualRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListReviewManualRequest}
 */
proto.reviewManual.ListReviewManualRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListReviewManualRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListReviewManualRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListReviewManualRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.reviewManual.ListReviewManualRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.reviewManual.ListReviewManualRequest} returns this
*/
proto.reviewManual.ListReviewManualRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListReviewManualRequest} returns this
 */
proto.reviewManual.ListReviewManualRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListReviewManualRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ListReviewManualResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListReviewManualResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListReviewManualResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListReviewManualResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reviewManual.ReviewManualDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListReviewManualResponse}
 */
proto.reviewManual.ListReviewManualResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListReviewManualResponse;
  return proto.reviewManual.ListReviewManualResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListReviewManualResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListReviewManualResponse}
 */
proto.reviewManual.ListReviewManualResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListReviewManualResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListReviewManualResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListReviewManualResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewManualDTO items = 1;
 * @return {!Array<!proto.reviewManual.ReviewManualDTO>}
 */
proto.reviewManual.ListReviewManualResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reviewManual.ReviewManualDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {!Array<!proto.reviewManual.ReviewManualDTO>} value
 * @return {!proto.reviewManual.ListReviewManualResponse} returns this
*/
proto.reviewManual.ListReviewManualResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewManual.ReviewManualDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.ListReviewManualResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewManual.ReviewManualDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ListReviewManualResponse} returns this
 */
proto.reviewManual.ListReviewManualResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewManual.ListReviewManualResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewManual.ListReviewManualResponse} returns this
*/
proto.reviewManual.ListReviewManualResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListReviewManualResponse} returns this
 */
proto.reviewManual.ListReviewManualResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListReviewManualResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ResumeReviewManualRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ResumeReviewManualRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ResumeReviewManualRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ResumeReviewManualRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ResumeReviewManualRequest}
 */
proto.reviewManual.ResumeReviewManualRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ResumeReviewManualRequest;
  return proto.reviewManual.ResumeReviewManualRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ResumeReviewManualRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ResumeReviewManualRequest}
 */
proto.reviewManual.ResumeReviewManualRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ResumeReviewManualRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ResumeReviewManualRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ResumeReviewManualRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ResumeReviewManualRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ResumeReviewManualResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ResumeReviewManualResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ResumeReviewManualResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ResumeReviewManualResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ResumeReviewManualResponse}
 */
proto.reviewManual.ResumeReviewManualResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ResumeReviewManualResponse;
  return proto.reviewManual.ResumeReviewManualResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ResumeReviewManualResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ResumeReviewManualResponse}
 */
proto.reviewManual.ResumeReviewManualResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ResumeReviewManualResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ResumeReviewManualResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ResumeReviewManualResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ResumeReviewManualResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.SaveReviewManualRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.SaveReviewManualRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.SaveReviewManualRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveReviewManualRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.SaveReviewManualRequest}
 */
proto.reviewManual.SaveReviewManualRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.SaveReviewManualRequest;
  return proto.reviewManual.SaveReviewManualRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.SaveReviewManualRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.SaveReviewManualRequest}
 */
proto.reviewManual.SaveReviewManualRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.SaveReviewManualRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.SaveReviewManualRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.SaveReviewManualRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveReviewManualRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO session = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.SaveReviewManualRequest.prototype.getSession = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.SaveReviewManualRequest} returns this
*/
proto.reviewManual.SaveReviewManualRequest.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.SaveReviewManualRequest} returns this
 */
proto.reviewManual.SaveReviewManualRequest.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.SaveReviewManualRequest.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.SaveReviewManualResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.SaveReviewManualResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.SaveReviewManualResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveReviewManualResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.reviewManual.ReviewManualDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.SaveReviewManualResponse}
 */
proto.reviewManual.SaveReviewManualResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.SaveReviewManualResponse;
  return proto.reviewManual.SaveReviewManualResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.SaveReviewManualResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.SaveReviewManualResponse}
 */
proto.reviewManual.SaveReviewManualResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.SaveReviewManualResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.SaveReviewManualResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.SaveReviewManualResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.SaveReviewManualResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualDTO session = 1;
 * @return {?proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.SaveReviewManualResponse.prototype.getSession = function() {
  return /** @type{?proto.reviewManual.ReviewManualDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualDTO|undefined} value
 * @return {!proto.reviewManual.SaveReviewManualResponse} returns this
*/
proto.reviewManual.SaveReviewManualResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.SaveReviewManualResponse} returns this
 */
proto.reviewManual.SaveReviewManualResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.SaveReviewManualResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.DeleteReviewManualRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.DeleteReviewManualRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.DeleteReviewManualRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.DeleteReviewManualRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.DeleteReviewManualRequest}
 */
proto.reviewManual.DeleteReviewManualRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.DeleteReviewManualRequest;
  return proto.reviewManual.DeleteReviewManualRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.DeleteReviewManualRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.DeleteReviewManualRequest}
 */
proto.reviewManual.DeleteReviewManualRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.DeleteReviewManualRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.DeleteReviewManualRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.DeleteReviewManualRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.DeleteReviewManualRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO reviewID = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.DeleteReviewManualRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.DeleteReviewManualRequest} returns this
*/
proto.reviewManual.DeleteReviewManualRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.DeleteReviewManualRequest} returns this
 */
proto.reviewManual.DeleteReviewManualRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.DeleteReviewManualRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.DeleteReviewManualResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.DeleteReviewManualResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.DeleteReviewManualResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.DeleteReviewManualResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.DeleteReviewManualResponse}
 */
proto.reviewManual.DeleteReviewManualResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.DeleteReviewManualResponse;
  return proto.reviewManual.DeleteReviewManualResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.DeleteReviewManualResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.DeleteReviewManualResponse}
 */
proto.reviewManual.DeleteReviewManualResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.DeleteReviewManualResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.DeleteReviewManualResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.DeleteReviewManualResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.DeleteReviewManualResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListCardsToReviewByConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListCardsToReviewByConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    options: (f = msg.getOptions()) && proto.reviewManual.ReviewManualOptionsDTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigRequest}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListCardsToReviewByConfigRequest;
  return proto.reviewManual.ListCardsToReviewByConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListCardsToReviewByConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigRequest}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualOptionsDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualOptionsDTO.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListCardsToReviewByConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListCardsToReviewByConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ReviewManualOptionsDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReviewManualOptionsDTO options = 1;
 * @return {?proto.reviewManual.ReviewManualOptionsDTO}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.getOptions = function() {
  return /** @type{?proto.reviewManual.ReviewManualOptionsDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ReviewManualOptionsDTO, 1));
};


/**
 * @param {?proto.reviewManual.ReviewManualOptionsDTO|undefined} value
 * @return {!proto.reviewManual.ListCardsToReviewByConfigRequest} returns this
*/
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigRequest} returns this
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.reviewManual.ListCardsToReviewByConfigRequest} returns this
*/
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigRequest} returns this
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListCardsToReviewByConfigRequest.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListCardsToReviewByConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListCardsToReviewByConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardList: jspb.Message.toObjectList(msg.getCardList(),
    proto.reviewManual.ReviewManualCardDTO.toObject, includeInstance),
    responseinfo: (f = msg.getResponseinfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigResponse}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListCardsToReviewByConfigResponse;
  return proto.reviewManual.ListCardsToReviewByConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListCardsToReviewByConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigResponse}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualCardDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualCardDTO.deserializeBinaryFromReader);
      msg.addCard(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setResponseinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListCardsToReviewByConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListCardsToReviewByConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewManual.ReviewManualCardDTO.serializeBinaryToWriter
    );
  }
  f = message.getResponseinfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewManualCardDTO card = 1;
 * @return {!Array<!proto.reviewManual.ReviewManualCardDTO>}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.getCardList = function() {
  return /** @type{!Array<!proto.reviewManual.ReviewManualCardDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.ReviewManualCardDTO, 1));
};


/**
 * @param {!Array<!proto.reviewManual.ReviewManualCardDTO>} value
 * @return {!proto.reviewManual.ListCardsToReviewByConfigResponse} returns this
*/
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.setCardList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewManual.ReviewManualCardDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.ReviewManualCardDTO}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.addCard = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewManual.ReviewManualCardDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigResponse} returns this
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.clearCardList = function() {
  return this.setCardList([]);
};


/**
 * optional utils.ListOptionsResponseDTO responseInfo = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.getResponseinfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewManual.ListCardsToReviewByConfigResponse} returns this
*/
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.setResponseinfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListCardsToReviewByConfigResponse} returns this
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.clearResponseinfo = function() {
  return this.setResponseinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListCardsToReviewByConfigResponse.prototype.hasResponseinfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ReviewManualOptionsDTO.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ReviewManualOptionsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ReviewManualOptionsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualOptionsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    configTime: (f = msg.getConfigTime()) && proto.reviewManual.ComparatorConfigTimeDTO.toObject(includeInstance, f),
    configConfidence: (f = msg.getConfigConfidence()) && proto.reviewManual.ComparatorConfigIntDTO.toObject(includeInstance, f),
    configViewed: (f = msg.getConfigViewed()) && proto.reviewManual.ComparatorConfigIntDTO.toObject(includeInstance, f),
    configRandom: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    configTopicsList: jspb.Message.toObjectList(msg.getConfigTopicsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    configTagsList: jspb.Message.toObjectList(msg.getConfigTagsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    configResourcesList: jspb.Message.toObjectList(msg.getConfigResourcesList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ReviewManualOptionsDTO;
  return proto.reviewManual.ReviewManualOptionsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ReviewManualOptionsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ComparatorConfigTimeDTO;
      reader.readMessage(value,proto.reviewManual.ComparatorConfigTimeDTO.deserializeBinaryFromReader);
      msg.setConfigTime(value);
      break;
    case 2:
      var value = new proto.reviewManual.ComparatorConfigIntDTO;
      reader.readMessage(value,proto.reviewManual.ComparatorConfigIntDTO.deserializeBinaryFromReader);
      msg.setConfigConfidence(value);
      break;
    case 3:
      var value = new proto.reviewManual.ComparatorConfigIntDTO;
      reader.readMessage(value,proto.reviewManual.ComparatorConfigIntDTO.deserializeBinaryFromReader);
      msg.setConfigViewed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfigRandom(value);
      break;
    case 5:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addConfigTopics(value);
      break;
    case 6:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addConfigTags(value);
      break;
    case 7:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addConfigResources(value);
      break;
    case 8:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ReviewManualOptionsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ReviewManualOptionsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ReviewManualOptionsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reviewManual.ComparatorConfigTimeDTO.serializeBinaryToWriter
    );
  }
  f = message.getConfigConfidence();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reviewManual.ComparatorConfigIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getConfigViewed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.reviewManual.ComparatorConfigIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getConfigRandom();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getConfigTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getConfigTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getConfigResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComparatorConfigTimeDTO config_time = 1;
 * @return {?proto.reviewManual.ComparatorConfigTimeDTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigTime = function() {
  return /** @type{?proto.reviewManual.ComparatorConfigTimeDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ComparatorConfigTimeDTO, 1));
};


/**
 * @param {?proto.reviewManual.ComparatorConfigTimeDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearConfigTime = function() {
  return this.setConfigTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.hasConfigTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComparatorConfigIntDTO config_confidence = 2;
 * @return {?proto.reviewManual.ComparatorConfigIntDTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigConfidence = function() {
  return /** @type{?proto.reviewManual.ComparatorConfigIntDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ComparatorConfigIntDTO, 2));
};


/**
 * @param {?proto.reviewManual.ComparatorConfigIntDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigConfidence = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearConfigConfidence = function() {
  return this.setConfigConfidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.hasConfigConfidence = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ComparatorConfigIntDTO config_viewed = 3;
 * @return {?proto.reviewManual.ComparatorConfigIntDTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigViewed = function() {
  return /** @type{?proto.reviewManual.ComparatorConfigIntDTO} */ (
    jspb.Message.getWrapperField(this, proto.reviewManual.ComparatorConfigIntDTO, 3));
};


/**
 * @param {?proto.reviewManual.ComparatorConfigIntDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigViewed = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearConfigViewed = function() {
  return this.setConfigViewed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.hasConfigViewed = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool config_random = 4;
 * @return {boolean}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigRandom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigRandom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated utils.UUID_DTO config_topics = 5;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigTopicsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 5));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.addConfigTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearConfigTopicsList = function() {
  return this.setConfigTopicsList([]);
};


/**
 * repeated utils.UUID_DTO config_tags = 6;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigTagsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 6));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.addConfigTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearConfigTagsList = function() {
  return this.setConfigTagsList([]);
};


/**
 * repeated utils.UUID_DTO config_resources = 7;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getConfigResourcesList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 7));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setConfigResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.addConfigResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearConfigResourcesList = function() {
  return this.setConfigResourcesList([]);
};


/**
 * optional utils.ListOptionsRequestDTO opts = 8;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 8));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
*/
proto.reviewManual.ReviewManualOptionsDTO.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ReviewManualOptionsDTO} returns this
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ReviewManualOptionsDTO.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ListReviewManualByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListReviewManualByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListReviewManualByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListReviewManualByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListReviewManualByIdsRequest}
 */
proto.reviewManual.ListReviewManualByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListReviewManualByIdsRequest;
  return proto.reviewManual.ListReviewManualByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListReviewManualByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListReviewManualByIdsRequest}
 */
proto.reviewManual.ListReviewManualByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListReviewManualByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListReviewManualByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListReviewManualByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.reviewManual.ListReviewManualByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.reviewManual.ListReviewManualByIdsRequest} returns this
*/
proto.reviewManual.ListReviewManualByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.reviewManual.ListReviewManualByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ListReviewManualByIdsRequest} returns this
 */
proto.reviewManual.ListReviewManualByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reviewManual.ListReviewManualByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reviewManual.ListReviewManualByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reviewManual.ListReviewManualByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reviewManual.ReviewManualDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reviewManual.ListReviewManualByIdsResponse}
 */
proto.reviewManual.ListReviewManualByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reviewManual.ListReviewManualByIdsResponse;
  return proto.reviewManual.ListReviewManualByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reviewManual.ListReviewManualByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reviewManual.ListReviewManualByIdsResponse}
 */
proto.reviewManual.ListReviewManualByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reviewManual.ReviewManualDTO;
      reader.readMessage(value,proto.reviewManual.ReviewManualDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reviewManual.ListReviewManualByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reviewManual.ListReviewManualByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reviewManual.ListReviewManualByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reviewManual.ReviewManualDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReviewManualDTO items = 1;
 * @return {!Array<!proto.reviewManual.ReviewManualDTO>}
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reviewManual.ReviewManualDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reviewManual.ReviewManualDTO, 1));
};


/**
 * @param {!Array<!proto.reviewManual.ReviewManualDTO>} value
 * @return {!proto.reviewManual.ListReviewManualByIdsResponse} returns this
*/
proto.reviewManual.ListReviewManualByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reviewManual.ReviewManualDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reviewManual.ReviewManualDTO}
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reviewManual.ReviewManualDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reviewManual.ListReviewManualByIdsResponse} returns this
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.reviewManual.ListReviewManualByIdsResponse} returns this
*/
proto.reviewManual.ListReviewManualByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reviewManual.ListReviewManualByIdsResponse} returns this
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reviewManual.ListReviewManualByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.reviewManual.ReviewKindEnumDTO = {
  MANUAL: 0,
  SM2: 1
};

/**
 * @enum {number}
 */
proto.reviewManual.ReviewFilterKindEnumDTO = {
  INTERSECTION: 0,
  UNION: 1
};

/**
 * @enum {number}
 */
proto.reviewManual.InclusionModifierEnumDTO = {
  INCLUDE: 0,
  EXCLUDE: 1,
  ONLY: 2
};

/**
 * @enum {number}
 */
proto.reviewManual.ComparatorDTO = {
  LT: 0,
  GT: 1,
  LTE: 2,
  GTE: 3,
  EQ: 4,
  NOT: 5,
  BT: 6
};

goog.object.extend(exports, proto.reviewManual);
