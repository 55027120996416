/**
 * @fileoverview gRPC-Web generated client stub for user
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')
const proto = {};
proto.user = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.user.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.GetMeRequest,
 *   !proto.user.GetMeResponse>}
 */
const methodDescriptor_UserService_getMe = new grpc.web.MethodDescriptor(
  '/user.UserService/getMe',
  grpc.web.MethodType.UNARY,
  proto.user.GetMeRequest,
  proto.user.GetMeResponse,
  /**
   * @param {!proto.user.GetMeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.GetMeResponse.deserializeBinary
);


/**
 * @param {!proto.user.GetMeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.GetMeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.GetMeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserServiceClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserService/getMe',
      request,
      metadata || {},
      methodDescriptor_UserService_getMe,
      callback);
};


/**
 * @param {!proto.user.GetMeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.GetMeResponse>}
 *     Promise that resolves to the response
 */
proto.user.UserServicePromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserService/getMe',
      request,
      metadata || {},
      methodDescriptor_UserService_getMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.user.UserAttributeDTO,
 *   !proto.user.UserDTO>}
 */
const methodDescriptor_UserService_updateUserAttr = new grpc.web.MethodDescriptor(
  '/user.UserService/updateUserAttr',
  grpc.web.MethodType.UNARY,
  proto.user.UserAttributeDTO,
  proto.user.UserDTO,
  /**
   * @param {!proto.user.UserAttributeDTO} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.user.UserDTO.deserializeBinary
);


/**
 * @param {!proto.user.UserAttributeDTO} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.user.UserDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.user.UserDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.user.UserServiceClient.prototype.updateUserAttr =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/user.UserService/updateUserAttr',
      request,
      metadata || {},
      methodDescriptor_UserService_updateUserAttr,
      callback);
};


/**
 * @param {!proto.user.UserAttributeDTO} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.user.UserDTO>}
 *     Promise that resolves to the response
 */
proto.user.UserServicePromiseClient.prototype.updateUserAttr =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/user.UserService/updateUserAttr',
      request,
      metadata || {},
      methodDescriptor_UserService_updateUserAttr);
};


module.exports = proto.user;

