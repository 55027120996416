// third-party
import {FormattedMessage} from 'react-intl';

// project import

// assets
import {BulbFilled, DashboardOutlined, GoldOutlined, LoadingOutlined, CreditCardOutlined, OpenAIFilled, BookTwoTone, CodepenCircleFilled, SettingTwoTone} from '@ant-design/icons';

// type
import {NavItemType} from 'types/menu';

import {useGetMenu} from 'api/menu';

const icons = {
    dashboard: DashboardOutlined,
    components: GoldOutlined,
    loading: LoadingOutlined,
    topic: BulbFilled,
    card: CreditCardOutlined,
    tag: OpenAIFilled,
    resource: BookTwoTone,
    customReview: CodepenCircleFilled,
    sm2Review: CodepenCircleFilled,
    reviewConfig: CodepenCircleFilled,
};

const loadingMenu: NavItemType = {
    id: 'group-dashboard-loading',
    title: <FormattedMessage id="Analytics"/>,
    type: 'group',
    icon: icons.loading,
    children: [
        {
            id: 'dashboard1',
            title: <FormattedMessage id="Dashboard"/>,
            icon: icons.dashboard,
            type: 'item',
            url: '/dashboard',
            breadcrumbs: false
        }
    ]
};

const knowledgeBaseMenu: NavItemType = {
    id: 'nav-group-knowledge-base',
    title: <FormattedMessage id="Knowledge Base"/>,
    type: 'group',
    icon: icons.dashboard,
    children: [
        {
            id: 'topic1',
            title: <FormattedMessage id="Topics"/>,
            icon: icons.topic,
            type: 'item',
            url: '/topics',
            breadcrumbs: true
        },
        {
            id: 'cards1',
            title: <FormattedMessage id="Cards"/>,
            icon: icons.card,
            type: 'item',
            url: '/cards',
            breadcrumbs: true
        },
        // {
        //     id: 'topic1',
        //     title: <FormattedMessage id="Tags"/>,
        //     icon: icons.tag,
        //     type: 'item',
        //     url: '/tags',
        //     breadcrumbs: true
        // },
        // {
        //     id: 'resources',
        //     title: <FormattedMessage id="Resources"/>,
        //     icon: icons.resource,
        //     type: 'item',
        //     url: '/resources',
        //     breadcrumbs: true
        // },
    ]
};

const reviewMenu: NavItemType = {
    id: 'nav-group-reviews',
    title: <FormattedMessage id="Reviews"/>,
    type: 'group',
    icon: icons.customReview,
    children: [
        {
            id: 'manual-reviews',
            title: <FormattedMessage id="Custom Reviews"/>,
            icon: icons.customReview,
            caption: 'Custom Reviews 123',
            type: 'item',
            url: '/reviews/manual',
            breadcrumbs: true
        },
        {
            id: 'sm2-reviews',
            title: <FormattedMessage id="SM2 Reviews"/>,
            icon: icons.sm2Review,
            type: 'item',
            url: '/reviews/sm2',
            breadcrumbs: true
        },
        // {
        //     id: 'review-configurations',
        //     title: <FormattedMessage id="Review Configurations"/>,
        //     icon: icons.reviewConfig,
        //     type: 'item',
        //     url: '/review-config',
        //     breadcrumbs: true
        // },
    ]
};


// ==============================|| MENU ITEMS - API ||============================== //

export const MenuFromAPI = (): NavItemType[] => {
    const {menu, menuLoading} = useGetMenu();

    return [loadingMenu, knowledgeBaseMenu, reviewMenu];

    const subChildrenList = (children: NavItemType[]) => {
        return children?.map((subList: NavItemType) => {
            return fillItem(subList);
        });
    };

    const itemList = (subList: NavItemType) => {
        let list = fillItem(subList);

        // if collapsible item, we need to feel its children as well
        if (subList.type === 'collapse') {
            list.children = subChildrenList(subList.children!);
        }
        return list;
    };

    const childrenList: NavItemType[] | undefined = menu?.children?.map((subList: NavItemType) => {
        return itemList(subList);
    });

    // let menuList = fillItem(menu, childrenList);
    // return menuList;
};

function fillItem(item: NavItemType, children?: NavItemType[] | undefined) {
    return {
        ...item,
        title: <FormattedMessage id={`${item?.title}`}/>,
        // @ts-ignore
        icon: icons[item?.icon],
        ...(children && {children})
    };
}
