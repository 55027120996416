import {EntityKind} from "../model/BaseModel";

export enum InternalErrorTypes {
    Unimplemented,
    Authentication,

    AuthenticationGoogle,
    Internal,
    Unknown,
    InvalidCard,
    InvalidCardDTO,
    InvalidTopic,
    InvalidRelTopic,
    InvalidTag,
    InvalidTagDTO,
    InvalidResource,
    InvalidResourceDTO,
    InvalidReview,
    InvalidReviewCard,
    InvalidReviewCardDTO,
    InvalidReviewSM2Card,
    InvalidReviewSM2CardDTO,
    InvalidUUID,
    InvalidLanguageDTO,

    InvalidTopicGraph,

    GetTopic,
    GetTag,
    GetCard,
    GetResources,
    GetReview,
    ListTopics,
    ListTopicsByIds,
    ListTopicsForCard,
    ListTags,
    ListCards,
    ListCardsByIds,
    ListReviews,
    ListResources,
    CreateCard,
    CreateReview,
    CreateTag,
    CreateTopic,
    CreateResource,
    UpdateCard,
    UpdateReview,
    UpdateTopic,
    UpdateTag,
    LocalNotFound,
    SaveManualReview,
    SaveReviewManualCard,
    SaveReviewCard,
    GetTopicGraph,
    ConvertTopicGraph,
    SearchTopics,
    SearchCards,
    SearchTags,
    SearchResources,
    ArchiveCard,
    DeleteCard,
    CreateTopicGraph,
    DeleteTopic,
    ListResourcesForTopics,
    CreateReviewSM2,
    CompleteReviewSM2,
    GetReviewSM2,
    DeleteReview,
    DeleteReviewSM2,
    ListReviewSM2,
    ListSM2CardsForNewReview,
    SaveReviewSM2,
    SaveReviewSM2Card,
    StartReviewSM2,
    GetTopicStat,
    ValidateReview,
    GetReviewSM2Stats,
    CardTagRelation,
    CardResourceRelation,
    TopicCardRelation,
    TopicResourceRelation,
    TopicTagRelation,
    TopicRelation,
    FunctionNotImplemented,
    ListReviewCards,
    OrderCards,
    GetCardComposite,
    InvalidCardComposite,
    UpdateCardComposite,
    ListCardComposite,
    MethodUndefined,
    InvalidReviewManualConfig,
    InvalidReviewSM2Config,
    InvalidReviewManualFilterResult,
    CardFilterResult,
    CardLangFilterResult,
    ResumeReviewSM2,
    ResumeReviewManual,
    UpdateCardInReviewManualCard
}

export enum ActionType {
    InternetRequest = "internet request",
    Sanitize = "sanitize",
    Create = "create",
    Update = "update",
    Delete = "delete",
    List = "list",
    ListByIDs = "listByIDs",
    Get = "get",
    Search = "search",
    Archive = "archive",
    Save = "save",
    Start = "start",
    Complete = "complete",
    Resume = "resume",
    Convert = "convert",
    Validate = "validate",
    ConvertToDTO = "convert to dto",
    ConvertFromDTO = "convert from dto",
    Unimplemented = "unimplemented",
    Combine = "combine",
    ListReviewCards = "list review cards",
    SaveReviewCards = "save review cards",
    GetStats = "get stats",
    UpdateCardInReviewManualCard = "update card in review manual card",
    UpdateCardInReviewSM2Card = "update card in review snm2 card",
    SetOngoingReview = "set ongoing review",
    SetSelectedReview = "set selected review",
    FilterCards = "filter cards",
    UploadS3 = "upload s3",
    Authenticate = "Authenticate",
    HeartBeat = "HeartBeat",
    Logout = "Logout",
}

export enum ErrorCode {
    DTOValidation = 0,
    UndefinedResponse = 1,
}

export interface CartaError extends Error {
    origin?: string;
    errorCode: InternalErrorTypes | ActionType;
    message: string;
}

export interface InternalError extends CartaError {
}

export interface IUIError extends CartaError {
    userMessage?: string;
    description?: string;
}

export class UIError implements IUIError {
    constructor(
        errorCode: InternalErrorTypes,
        message: string,
        err?: any,
        userMessage?: string,
        origin?: string
    ) {
        this.errorCode = errorCode;
        this.message = message;
        this.name = errorCode.toString();
        this.origin = origin;
        this.err = err;
        this.userMessage = userMessage;
    }

    errorCode: InternalErrorTypes;
    message: string;
    name: string;
    err: any;
    origin?: string;
    userMessage?: string;
}

/**
 * This error will be sent to the UI for the user to view / respond to. DO NOT LEAK SENSITIVE INFORMATION HERE
 * @param origin
 * @param code
 * @param logMessage - This is not shown to the user but is used for internal logging
 * @param userMessage - This optional message can be shown to the user. Ideally the component should decide the message.
 * @param err
 * @constructor
 */
export const NewUIError = (
    origin: string,
    code: InternalErrorTypes,
    logMessage: string,
    userMessage?: string,
    err?: CartaError,
    e?: any
): IUIError => {
    const ie = {
        name: origin,
        origin: origin,
        errorCode: code,
        message: logMessage,
        userMessage: userMessage,
        trace: err,
    } as IUIError;

    // LogU(ie);

    return ie;
};

/**
 * This error will be sent to the UI for the user to view / respond to. DO NOT LEAK SENSITIVE INFORMATION HERE
 * @param origin
 * @param code
 * @param logMessage - This is not shown to the user but is used for internal logging
 * @param userMessage - This optional message can be shown to the user. Ideally the component should decide the message.
 * @param err
 * @constructor
 */

export class UIErrorV2 extends Error {
    errorCode: ActionType;
    kind: EntityKind;
    userMessage?: string;
    e?: any;
    additionalErrors: any[];


    constructor(code: ActionType, kind: EntityKind, e?: any, message?: string, userMessage?: string) {
        super();

        this.kind = kind;
        this.errorCode = code;
        this.name = convertErrorCodeToName(code);
        if (message != null) {
            this.message = message;
        }
        this.e = e;
        this.additionalErrors = []; // Initialize the array
        this.userMessage = userMessage;

        // Captures stack trace, excluding constructor call from stack
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UIErrorV2);
        }

        // Append original error message and stack if present
        if (e instanceof Error) {
            this.message += ` | Original error: ${e.message}`;
            this.stack += `\nOriginal error stack:\n${e.stack}`;
        }
    }

    toString(): string {
        let formattedMessage = `UIErrorV2: ${this.errorCode} - ${this.name} - ${this.message}`;
        if (this.additionalErrors.length > 0) {
            const additionalErrorsFormatted = this.additionalErrors.map((error, index) => {
                // Format each additional error, could be adjusted based on the structure of these errors
                return `\nAdditional Error ${index + 1}: ${error.toString()}`;
            }).join('');
            formattedMessage += additionalErrorsFormatted;
        }
        return formattedMessage;
    }

    appendError(error: any): void {
        this.additionalErrors.push(error);
    }

    appendMessage(message: string): void {
        this.message += ` | ${message}`;
    }
}

export const NewUIErrorV2 = (code: ActionType, kind: EntityKind, e?: any, message?: string, userMessage?: string): UIErrorV2 => {
    return new UIErrorV2(code, kind, e, message, userMessage)
}

const convertErrorCodeToName = (code: ActionType): string => {
    return "";
};

/**
 * These errors will be sent to the internal logger for triage
 * @param origin - This is the method/component/class from which the error originated
 * @param code
 * @param message
 * @constructor
 */
export const NewInternalError = (
    origin: string,
    code: InternalErrorTypes,
    message: string,
    err?: InternalError
): InternalError => {
    const ie = {
        name: origin,
        origin: origin,
        errorCode: code,
        message: message,
        trace: err,
    } as InternalError;

    LogE(ie);

    return ie;
};

export const isError = (obj: any): boolean => {
    return obj.hasOwnProperty("errorCode");
};

export const isErrorUnd = (obj: Object | undefined): boolean => {
    if (obj) {
        return obj.hasOwnProperty("errorCode");
    }

    return false;
};

/**
 * These errors will be sent to the internal logger for triage
 * @param origin - This is the method/component/class from which the error originated
 * @param code
 * @param message
 * @constructor
 */
export const LogError = (
    origin: string,
    code: InternalErrorTypes,
    message: string,
    err?: InternalError | any
) => {
};

/**
 * These errors will be sent to the internal logger for triage
 * @param origin - This is the method/component/class from which the error originated
 * @param code
 * @param message
 * @constructor
 */
export const LogE = (err: InternalError) => {
    console.debug("InternalError: ", JSON.stringify(err));
};

/**
 * These errors will be sent to the internal logger for triage
 * @param err
 * @param message
 * @constructor
 */
export const LogU = (err: IUIError, message?: string) => {
    console.error(err);
};

