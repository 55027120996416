// source: stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.stats.CardStatDTO', null, global);
goog.exportSymbol('proto.stats.GeneralStatDTO', null, global);
goog.exportSymbol('proto.stats.GetGeneralStatsRequest', null, global);
goog.exportSymbol('proto.stats.GetGeneralStatsResponse', null, global);
goog.exportSymbol('proto.stats.ReviewManualCardStatDTO', null, global);
goog.exportSymbol('proto.stats.ReviewManualStatDTO', null, global);
goog.exportSymbol('proto.stats.ReviewSM2CardStatDTO', null, global);
goog.exportSymbol('proto.stats.ReviewSM2StatDTO', null, global);
goog.exportSymbol('proto.stats.ReviewTimelineStatsDTO', null, global);
goog.exportSymbol('proto.stats.StatOptsDTO', null, global);
goog.exportSymbol('proto.stats.TopicStatDTO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetGeneralStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetGeneralStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetGeneralStatsRequest.displayName = 'proto.stats.GetGeneralStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GetGeneralStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.GetGeneralStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GetGeneralStatsResponse.displayName = 'proto.stats.GetGeneralStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.StatOptsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.StatOptsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.StatOptsDTO.displayName = 'proto.stats.StatOptsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewTimelineStatsDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.ReviewTimelineStatsDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.ReviewTimelineStatsDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewTimelineStatsDTO.displayName = 'proto.stats.ReviewTimelineStatsDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.GeneralStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.GeneralStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.GeneralStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.GeneralStatDTO.displayName = 'proto.stats.GeneralStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewSM2StatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.ReviewSM2StatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.ReviewSM2StatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewSM2StatDTO.displayName = 'proto.stats.ReviewSM2StatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewSM2CardStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.ReviewSM2CardStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewSM2CardStatDTO.displayName = 'proto.stats.ReviewSM2CardStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewManualStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.ReviewManualStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.ReviewManualStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewManualStatDTO.displayName = 'proto.stats.ReviewManualStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.ReviewManualCardStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.ReviewManualCardStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.ReviewManualCardStatDTO.displayName = 'proto.stats.ReviewManualCardStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.CardStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.CardStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.CardStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.CardStatDTO.displayName = 'proto.stats.CardStatDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.TopicStatDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stats.TopicStatDTO.repeatedFields_, null);
};
goog.inherits(proto.stats.TopicStatDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.TopicStatDTO.displayName = 'proto.stats.TopicStatDTO';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetGeneralStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetGeneralStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetGeneralStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: (f = msg.getUserid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    opts: (f = msg.getOpts()) && proto.stats.StatOptsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetGeneralStatsRequest}
 */
proto.stats.GetGeneralStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetGeneralStatsRequest;
  return proto.stats.GetGeneralStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetGeneralStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetGeneralStatsRequest}
 */
proto.stats.GetGeneralStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.stats.StatOptsDTO;
      reader.readMessage(value,proto.stats.StatOptsDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetGeneralStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetGeneralStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetGeneralStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stats.StatOptsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO userId = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.GetGeneralStatsRequest.prototype.getUserid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
*/
proto.stats.GetGeneralStatsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
 */
proto.stats.GetGeneralStatsRequest.prototype.clearUserid = function() {
  return this.setUserid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsRequest.prototype.hasUserid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatOptsDTO opts = 2;
 * @return {?proto.stats.StatOptsDTO}
 */
proto.stats.GetGeneralStatsRequest.prototype.getOpts = function() {
  return /** @type{?proto.stats.StatOptsDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.StatOptsDTO, 2));
};


/**
 * @param {?proto.stats.StatOptsDTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
*/
proto.stats.GetGeneralStatsRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsRequest} returns this
 */
proto.stats.GetGeneralStatsRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GetGeneralStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GetGeneralStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GetGeneralStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stats: (f = msg.getStats()) && proto.stats.GeneralStatDTO.toObject(includeInstance, f),
    reviewtimelinestats: (f = msg.getReviewtimelinestats()) && proto.stats.ReviewTimelineStatsDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GetGeneralStatsResponse}
 */
proto.stats.GetGeneralStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GetGeneralStatsResponse;
  return proto.stats.GetGeneralStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GetGeneralStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GetGeneralStatsResponse}
 */
proto.stats.GetGeneralStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.GeneralStatDTO;
      reader.readMessage(value,proto.stats.GeneralStatDTO.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 2:
      var value = new proto.stats.ReviewTimelineStatsDTO;
      reader.readMessage(value,proto.stats.ReviewTimelineStatsDTO.deserializeBinaryFromReader);
      msg.setReviewtimelinestats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GetGeneralStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GetGeneralStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GetGeneralStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GetGeneralStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stats.GeneralStatDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewtimelinestats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stats.ReviewTimelineStatsDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralStatDTO stats = 1;
 * @return {?proto.stats.GeneralStatDTO}
 */
proto.stats.GetGeneralStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.stats.GeneralStatDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.GeneralStatDTO, 1));
};


/**
 * @param {?proto.stats.GeneralStatDTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
*/
proto.stats.GetGeneralStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
 */
proto.stats.GetGeneralStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ReviewTimelineStatsDTO reviewTimelineStats = 2;
 * @return {?proto.stats.ReviewTimelineStatsDTO}
 */
proto.stats.GetGeneralStatsResponse.prototype.getReviewtimelinestats = function() {
  return /** @type{?proto.stats.ReviewTimelineStatsDTO} */ (
    jspb.Message.getWrapperField(this, proto.stats.ReviewTimelineStatsDTO, 2));
};


/**
 * @param {?proto.stats.ReviewTimelineStatsDTO|undefined} value
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
*/
proto.stats.GetGeneralStatsResponse.prototype.setReviewtimelinestats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.GetGeneralStatsResponse} returns this
 */
proto.stats.GetGeneralStatsResponse.prototype.clearReviewtimelinestats = function() {
  return this.setReviewtimelinestats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.GetGeneralStatsResponse.prototype.hasReviewtimelinestats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.StatOptsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.StatOptsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.StatOptsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatOptsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    to: (f = msg.getTo()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.StatOptsDTO}
 */
proto.stats.StatOptsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.StatOptsDTO;
  return proto.stats.StatOptsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.StatOptsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.StatOptsDTO}
 */
proto.stats.StatOptsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.StatOptsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.StatOptsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.StatOptsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatOptsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.TimestampDTO from = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.StatOptsDTO.prototype.getFrom = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.StatOptsDTO} returns this
*/
proto.stats.StatOptsDTO.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatOptsDTO} returns this
 */
proto.stats.StatOptsDTO.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatOptsDTO.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.TimestampDTO to = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.StatOptsDTO.prototype.getTo = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.StatOptsDTO} returns this
*/
proto.stats.StatOptsDTO.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatOptsDTO} returns this
 */
proto.stats.StatOptsDTO.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatOptsDTO.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.ReviewTimelineStatsDTO.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewTimelineStatsDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewTimelineStatsDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewTimelineStatsDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewsm2reviewedtimelineList: jspb.Message.toObjectList(msg.getReviewsm2reviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewsm2cardreviewedtimelineList: jspb.Message.toObjectList(msg.getReviewsm2cardreviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewmanualreviewedtimelineList: jspb.Message.toObjectList(msg.getReviewmanualreviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewmanualcardreviewedtimelineList: jspb.Message.toObjectList(msg.getReviewmanualcardreviewedtimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewManualQualityTimelineList: jspb.Message.toObjectList(msg.getReviewManualQualityTimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewSm2QualityTimelineList: jspb.Message.toObjectList(msg.getReviewSm2QualityTimelineList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewTimelineStatsDTO}
 */
proto.stats.ReviewTimelineStatsDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewTimelineStatsDTO;
  return proto.stats.ReviewTimelineStatsDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewTimelineStatsDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewTimelineStatsDTO}
 */
proto.stats.ReviewTimelineStatsDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewsm2reviewedtimeline(value);
      break;
    case 2:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewsm2cardreviewedtimeline(value);
      break;
    case 3:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewmanualreviewedtimeline(value);
      break;
    case 4:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewmanualcardreviewedtimeline(value);
      break;
    case 5:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewManualQualityTimeline(value);
      break;
    case 6:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewSm2QualityTimeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewTimelineStatsDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewTimelineStatsDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewTimelineStatsDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewsm2reviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewsm2cardreviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewmanualreviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewmanualcardreviewedtimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewManualQualityTimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewSm2QualityTimelineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.TimelineIntDTO reviewSM2ReviewedTimeline = 1;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewsm2reviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 1));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewsm2reviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewsm2reviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewsm2reviewedtimelineList = function() {
  return this.setReviewsm2reviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewSM2CardReviewedTimeline = 2;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewsm2cardreviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 2));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewsm2cardreviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewsm2cardreviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewsm2cardreviewedtimelineList = function() {
  return this.setReviewsm2cardreviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewManualReviewedTimeline = 3;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewmanualreviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 3));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewmanualreviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewmanualreviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewmanualreviewedtimelineList = function() {
  return this.setReviewmanualreviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewManualCardReviewedTimeline = 4;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewmanualcardreviewedtimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 4));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewmanualcardreviewedtimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewmanualcardreviewedtimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewmanualcardreviewedtimelineList = function() {
  return this.setReviewmanualcardreviewedtimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO review_manual_quality_timeline = 5;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewManualQualityTimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 5));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewManualQualityTimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewManualQualityTimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewManualQualityTimelineList = function() {
  return this.setReviewManualQualityTimelineList([]);
};


/**
 * repeated utils.TimelineIntDTO review_sm2_quality_timeline = 6;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.getReviewSm2QualityTimelineList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 6));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
*/
proto.stats.ReviewTimelineStatsDTO.prototype.setReviewSm2QualityTimelineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.ReviewTimelineStatsDTO.prototype.addReviewSm2QualityTimeline = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewTimelineStatsDTO} returns this
 */
proto.stats.ReviewTimelineStatsDTO.prototype.clearReviewSm2QualityTimelineList = function() {
  return this.setReviewSm2QualityTimelineList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.GeneralStatDTO.repeatedFields_ = [10,11,12,13,14,15,16,17,18,19,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.GeneralStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.GeneralStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.GeneralStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GeneralStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    numTopics: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numCards: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numCardLangs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numTopicRelationships: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numResources: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numSm2Reviews: jspb.Message.getFieldWithDefault(msg, 6, 0),
    numSm2ReviewCards: jspb.Message.getFieldWithDefault(msg, 7, 0),
    numManualReviews: jspb.Message.getFieldWithDefault(msg, 8, 0),
    numManualReviewCards: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createdTopicsList: jspb.Message.toObjectList(msg.getCreatedTopicsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdCardsList: jspb.Message.toObjectList(msg.getCreatedCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdCardLangsList: jspb.Message.toObjectList(msg.getCreatedCardLangsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdTopicRelationshipsList: jspb.Message.toObjectList(msg.getCreatedTopicRelationshipsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdResourcesList: jspb.Message.toObjectList(msg.getCreatedResourcesList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdSm2ReviewsList: jspb.Message.toObjectList(msg.getCreatedSm2ReviewsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdSm2ReviewCardsList: jspb.Message.toObjectList(msg.getCreatedSm2ReviewCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdManualReviewsList: jspb.Message.toObjectList(msg.getCreatedManualReviewsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    createdManualReviewCardsList: jspb.Message.toObjectList(msg.getCreatedManualReviewCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewedCardsList: jspb.Message.toObjectList(msg.getReviewedCardsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance),
    reviewedTopicsList: jspb.Message.toObjectList(msg.getReviewedTopicsList(),
    utils_pb.TimelineIntDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.GeneralStatDTO}
 */
proto.stats.GeneralStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.GeneralStatDTO;
  return proto.stats.GeneralStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.GeneralStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.GeneralStatDTO}
 */
proto.stats.GeneralStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumTopics(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumCards(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumCardLangs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumTopicRelationships(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumResources(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSm2Reviews(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumSm2ReviewCards(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumManualReviews(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumManualReviewCards(value);
      break;
    case 10:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedTopics(value);
      break;
    case 11:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedCards(value);
      break;
    case 12:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedCardLangs(value);
      break;
    case 13:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedTopicRelationships(value);
      break;
    case 14:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedResources(value);
      break;
    case 15:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedSm2Reviews(value);
      break;
    case 16:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedSm2ReviewCards(value);
      break;
    case 17:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedManualReviews(value);
      break;
    case 18:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addCreatedManualReviewCards(value);
      break;
    case 19:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewedCards(value);
      break;
    case 20:
      var value = new utils_pb.TimelineIntDTO;
      reader.readMessage(value,utils_pb.TimelineIntDTO.deserializeBinaryFromReader);
      msg.addReviewedTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.GeneralStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.GeneralStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.GeneralStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.GeneralStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumTopics();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumCards();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumCardLangs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumTopicRelationships();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumResources();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNumSm2Reviews();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNumSm2ReviewCards();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNumManualReviews();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNumManualReviewCards();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreatedTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedCardLangsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedTopicRelationshipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedSm2ReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedSm2ReviewCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedManualReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedManualReviewCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewedCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewedTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      utils_pb.TimelineIntDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 num_topics = 1;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumTopics = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumTopics = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 num_cards = 2;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumCards = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 num_card_langs = 3;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumCardLangs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumCardLangs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 num_topic_relationships = 4;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumTopicRelationships = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumTopicRelationships = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 num_resources = 5;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumResources = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumResources = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 num_SM2_reviews = 6;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumSm2Reviews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumSm2Reviews = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 num_SM2_review_cards = 7;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumSm2ReviewCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumSm2ReviewCards = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 num_manual_reviews = 8;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumManualReviews = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumManualReviews = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 num_manual_review_cards = 9;
 * @return {number}
 */
proto.stats.GeneralStatDTO.prototype.getNumManualReviewCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.setNumManualReviewCards = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated utils.TimelineIntDTO created_topics = 10;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedTopicsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 10));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedTopicsList = function() {
  return this.setCreatedTopicsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_cards = 11;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 11));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedCardsList = function() {
  return this.setCreatedCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_card_langs = 12;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedCardLangsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 12));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedCardLangsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedCardLangs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedCardLangsList = function() {
  return this.setCreatedCardLangsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_topic_relationships = 13;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedTopicRelationshipsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 13));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedTopicRelationshipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedTopicRelationships = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedTopicRelationshipsList = function() {
  return this.setCreatedTopicRelationshipsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_resources = 14;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedResourcesList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 14));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedResourcesList = function() {
  return this.setCreatedResourcesList([]);
};


/**
 * repeated utils.TimelineIntDTO created_sm2_reviews = 15;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedSm2ReviewsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 15));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedSm2ReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedSm2Reviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedSm2ReviewsList = function() {
  return this.setCreatedSm2ReviewsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_sm2_review_cards = 16;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedSm2ReviewCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 16));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedSm2ReviewCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedSm2ReviewCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedSm2ReviewCardsList = function() {
  return this.setCreatedSm2ReviewCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_manual_reviews = 17;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedManualReviewsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 17));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedManualReviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedManualReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedManualReviewsList = function() {
  return this.setCreatedManualReviewsList([]);
};


/**
 * repeated utils.TimelineIntDTO created_manual_review_cards = 18;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getCreatedManualReviewCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 18));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setCreatedManualReviewCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addCreatedManualReviewCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearCreatedManualReviewCardsList = function() {
  return this.setCreatedManualReviewCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewed_cards = 19;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getReviewedCardsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 19));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setReviewedCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addReviewedCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearReviewedCardsList = function() {
  return this.setReviewedCardsList([]);
};


/**
 * repeated utils.TimelineIntDTO reviewed_topics = 20;
 * @return {!Array<!proto.utils.TimelineIntDTO>}
 */
proto.stats.GeneralStatDTO.prototype.getReviewedTopicsList = function() {
  return /** @type{!Array<!proto.utils.TimelineIntDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.TimelineIntDTO, 20));
};


/**
 * @param {!Array<!proto.utils.TimelineIntDTO>} value
 * @return {!proto.stats.GeneralStatDTO} returns this
*/
proto.stats.GeneralStatDTO.prototype.setReviewedTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.utils.TimelineIntDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.stats.GeneralStatDTO.prototype.addReviewedTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.utils.TimelineIntDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.GeneralStatDTO} returns this
 */
proto.stats.GeneralStatDTO.prototype.clearReviewedTopicsList = function() {
  return this.setReviewedTopicsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.ReviewSM2StatDTO.repeatedFields_ = [2,3,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewSM2StatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewSM2StatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewSM2StatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewSM2StatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    strongestcardsList: jspb.Message.toObjectList(msg.getStrongestcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    weakestcardsList: jspb.Message.toObjectList(msg.getWeakestcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    avgcompletedquality: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgcompletedeasiness: jspb.Message.getFieldWithDefault(msg, 5, 0),
    avgcompletedinterval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    avgcompletedrepetition: jspb.Message.getFieldWithDefault(msg, 7, 0),
    avgquality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    avgeasiness: jspb.Message.getFieldWithDefault(msg, 9, 0),
    avginterval: jspb.Message.getFieldWithDefault(msg, 10, 0),
    avgrepetition: jspb.Message.getFieldWithDefault(msg, 11, 0),
    mostrepeatedcard: (f = msg.getMostrepeatedcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewcardstatsList: jspb.Message.toObjectList(msg.getReviewcardstatsList(),
    proto.stats.ReviewSM2CardStatDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewSM2StatDTO}
 */
proto.stats.ReviewSM2StatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewSM2StatDTO;
  return proto.stats.ReviewSM2StatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewSM2StatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewSM2StatDTO}
 */
proto.stats.ReviewSM2StatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addStrongestcards(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addWeakestcards(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedquality(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedeasiness(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedinterval(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedrepetition(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgeasiness(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvginterval(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgrepetition(value);
      break;
    case 12:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setMostrepeatedcard(value);
      break;
    case 13:
      var value = new proto.stats.ReviewSM2CardStatDTO;
      reader.readMessage(value,proto.stats.ReviewSM2CardStatDTO.deserializeBinaryFromReader);
      msg.addReviewcardstats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewSM2StatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewSM2StatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewSM2StatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewSM2StatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMostrepeatedcard();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewcardstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.stats.ReviewSM2CardStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2StatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
*/
proto.stats.ReviewSM2StatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated utils.UUID_DTO strongestCards = 2;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewSM2StatDTO.prototype.getStrongestcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
*/
proto.stats.ReviewSM2StatDTO.prototype.setStrongestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2StatDTO.prototype.addStrongestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearStrongestcardsList = function() {
  return this.setStrongestcardsList([]);
};


/**
 * repeated utils.UUID_DTO weakestCards = 3;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewSM2StatDTO.prototype.getWeakestcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
*/
proto.stats.ReviewSM2StatDTO.prototype.setWeakestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2StatDTO.prototype.addWeakestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearWeakestcardsList = function() {
  return this.setWeakestcardsList([]);
};


/**
 * optional int32 avgCompletedQuality = 4;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgcompletedquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgcompletedquality = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgcompletedquality = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgcompletedquality = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 avgCompletedEasiness = 5;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgcompletedeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgcompletedeasiness = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgcompletedeasiness = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgcompletedeasiness = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 avgCompletedInterval = 6;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgcompletedinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgcompletedinterval = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgcompletedinterval = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgcompletedinterval = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 avgCompletedRepetition = 7;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgcompletedrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgcompletedrepetition = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgcompletedrepetition = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgcompletedrepetition = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 avgQuality = 8;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 avgEasiness = 9;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgeasiness = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgeasiness = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgeasiness = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 avgInterval = 10;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvginterval = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvginterval = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvginterval = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 avgRepetition = 11;
 * @return {number}
 */
proto.stats.ReviewSM2StatDTO.prototype.getAvgrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.setAvgrepetition = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearAvgrepetition = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasAvgrepetition = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.UUID_DTO mostRepeatedCard = 12;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2StatDTO.prototype.getMostrepeatedcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 12));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
*/
proto.stats.ReviewSM2StatDTO.prototype.setMostrepeatedcard = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearMostrepeatedcard = function() {
  return this.setMostrepeatedcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2StatDTO.prototype.hasMostrepeatedcard = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated ReviewSM2CardStatDTO reviewCardStats = 13;
 * @return {!Array<!proto.stats.ReviewSM2CardStatDTO>}
 */
proto.stats.ReviewSM2StatDTO.prototype.getReviewcardstatsList = function() {
  return /** @type{!Array<!proto.stats.ReviewSM2CardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.ReviewSM2CardStatDTO, 13));
};


/**
 * @param {!Array<!proto.stats.ReviewSM2CardStatDTO>} value
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
*/
proto.stats.ReviewSM2StatDTO.prototype.setReviewcardstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.stats.ReviewSM2CardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.ReviewSM2CardStatDTO}
 */
proto.stats.ReviewSM2StatDTO.prototype.addReviewcardstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.stats.ReviewSM2CardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewSM2StatDTO} returns this
 */
proto.stats.ReviewSM2StatDTO.prototype.clearReviewcardstatsList = function() {
  return this.setReviewcardstatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewSM2CardStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewSM2CardStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewSM2CardStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    easiness: jspb.Message.getFieldWithDefault(msg, 4, 0),
    quality: jspb.Message.getFieldWithDefault(msg, 5, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    repetitions: jspb.Message.getFieldWithDefault(msg, 7, 0),
    startat: (f = msg.getStartat()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endat: (f = msg.getEndat()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewSM2CardStatDTO}
 */
proto.stats.ReviewSM2CardStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewSM2CardStatDTO;
  return proto.stats.ReviewSM2CardStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewSM2CardStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewSM2CardStatDTO}
 */
proto.stats.ReviewSM2CardStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEasiness(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetitions(value);
      break;
    case 8:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewSM2CardStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewSM2CardStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewSM2CardStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
*/
proto.stats.ReviewSM2CardStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO cardId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
*/
proto.stats.ReviewSM2CardStatDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
*/
proto.stats.ReviewSM2CardStatDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 easiness = 4;
 * @return {number}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getEasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.setEasiness = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearEasiness = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasEasiness = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 quality = 5;
 * @return {number}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.setQuality = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearQuality = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasQuality = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 interval = 6;
 * @return {number}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.setInterval = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearInterval = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 repetitions = 7;
 * @return {number}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.setRepetitions = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearRepetitions = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasRepetitions = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional utils.TimestampDTO startAt = 8;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getStartat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 8));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
*/
proto.stats.ReviewSM2CardStatDTO.prototype.setStartat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearStartat = function() {
  return this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO endAt = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.getEndat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
*/
proto.stats.ReviewSM2CardStatDTO.prototype.setEndat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewSM2CardStatDTO} returns this
 */
proto.stats.ReviewSM2CardStatDTO.prototype.clearEndat = function() {
  return this.setEndat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewSM2CardStatDTO.prototype.hasEndat = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.ReviewManualStatDTO.repeatedFields_ = [2,3,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewManualStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewManualStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewManualStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewManualStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    strongestcardsList: jspb.Message.toObjectList(msg.getStrongestcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    weakestcardsList: jspb.Message.toObjectList(msg.getWeakestcardsList(),
    utils_pb.UUID_DTO.toObject, includeInstance),
    avgcompletedquality: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgcompletedinterval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    avgcompletedrepetition: jspb.Message.getFieldWithDefault(msg, 7, 0),
    avgquality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    avginterval: jspb.Message.getFieldWithDefault(msg, 10, 0),
    avgrepetition: jspb.Message.getFieldWithDefault(msg, 11, 0),
    mostrepeatedcard: (f = msg.getMostrepeatedcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewcardstatsList: jspb.Message.toObjectList(msg.getReviewcardstatsList(),
    proto.stats.ReviewManualCardStatDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewManualStatDTO}
 */
proto.stats.ReviewManualStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewManualStatDTO;
  return proto.stats.ReviewManualStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewManualStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewManualStatDTO}
 */
proto.stats.ReviewManualStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addStrongestcards(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addWeakestcards(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedquality(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedinterval(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgcompletedrepetition(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvginterval(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgrepetition(value);
      break;
    case 12:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setMostrepeatedcard(value);
      break;
    case 13:
      var value = new proto.stats.ReviewManualCardStatDTO;
      reader.readMessage(value,proto.stats.ReviewManualCardStatDTO.deserializeBinaryFromReader);
      msg.addReviewcardstats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewManualStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewManualStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewManualStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewManualStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestcardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMostrepeatedcard();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewcardstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.stats.ReviewManualCardStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
*/
proto.stats.ReviewManualStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated utils.UUID_DTO strongestCards = 2;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewManualStatDTO.prototype.getStrongestcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
*/
proto.stats.ReviewManualStatDTO.prototype.setStrongestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualStatDTO.prototype.addStrongestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearStrongestcardsList = function() {
  return this.setStrongestcardsList([]);
};


/**
 * repeated utils.UUID_DTO weakestCards = 3;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.stats.ReviewManualStatDTO.prototype.getWeakestcardsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
*/
proto.stats.ReviewManualStatDTO.prototype.setWeakestcardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualStatDTO.prototype.addWeakestcards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearWeakestcardsList = function() {
  return this.setWeakestcardsList([]);
};


/**
 * optional int32 avgCompletedQuality = 4;
 * @return {number}
 */
proto.stats.ReviewManualStatDTO.prototype.getAvgcompletedquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.setAvgcompletedquality = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearAvgcompletedquality = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasAvgcompletedquality = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 avgCompletedInterval = 6;
 * @return {number}
 */
proto.stats.ReviewManualStatDTO.prototype.getAvgcompletedinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.setAvgcompletedinterval = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearAvgcompletedinterval = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasAvgcompletedinterval = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 avgCompletedRepetition = 7;
 * @return {number}
 */
proto.stats.ReviewManualStatDTO.prototype.getAvgcompletedrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.setAvgcompletedrepetition = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearAvgcompletedrepetition = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasAvgcompletedrepetition = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 avgQuality = 8;
 * @return {number}
 */
proto.stats.ReviewManualStatDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 avgInterval = 10;
 * @return {number}
 */
proto.stats.ReviewManualStatDTO.prototype.getAvginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.setAvginterval = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearAvginterval = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasAvginterval = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 avgRepetition = 11;
 * @return {number}
 */
proto.stats.ReviewManualStatDTO.prototype.getAvgrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.setAvgrepetition = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearAvgrepetition = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasAvgrepetition = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.UUID_DTO mostRepeatedCard = 12;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualStatDTO.prototype.getMostrepeatedcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 12));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
*/
proto.stats.ReviewManualStatDTO.prototype.setMostrepeatedcard = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearMostrepeatedcard = function() {
  return this.setMostrepeatedcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualStatDTO.prototype.hasMostrepeatedcard = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated ReviewManualCardStatDTO reviewCardStats = 13;
 * @return {!Array<!proto.stats.ReviewManualCardStatDTO>}
 */
proto.stats.ReviewManualStatDTO.prototype.getReviewcardstatsList = function() {
  return /** @type{!Array<!proto.stats.ReviewManualCardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.ReviewManualCardStatDTO, 13));
};


/**
 * @param {!Array<!proto.stats.ReviewManualCardStatDTO>} value
 * @return {!proto.stats.ReviewManualStatDTO} returns this
*/
proto.stats.ReviewManualStatDTO.prototype.setReviewcardstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.stats.ReviewManualCardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.ReviewManualCardStatDTO}
 */
proto.stats.ReviewManualStatDTO.prototype.addReviewcardstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.stats.ReviewManualCardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.ReviewManualStatDTO} returns this
 */
proto.stats.ReviewManualStatDTO.prototype.clearReviewcardstatsList = function() {
  return this.setReviewcardstatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.ReviewManualCardStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.ReviewManualCardStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.ReviewManualCardStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewManualCardStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    cardid: (f = msg.getCardid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    reviewid: (f = msg.getReviewid()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    quality: jspb.Message.getFieldWithDefault(msg, 5, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 6, 0),
    repetitions: jspb.Message.getFieldWithDefault(msg, 7, 0),
    startat: (f = msg.getStartat()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endat: (f = msg.getEndat()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.ReviewManualCardStatDTO}
 */
proto.stats.ReviewManualCardStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.ReviewManualCardStatDTO;
  return proto.stats.ReviewManualCardStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.ReviewManualCardStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.ReviewManualCardStatDTO}
 */
proto.stats.ReviewManualCardStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setCardid(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setReviewid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuality(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRepetitions(value);
      break;
    case 8:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.ReviewManualCardStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.ReviewManualCardStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.ReviewManualCardStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.ReviewManualCardStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getCardid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getReviewid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
*/
proto.stats.ReviewManualCardStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO cardId = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getCardid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
*/
proto.stats.ReviewManualCardStatDTO.prototype.setCardid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearCardid = function() {
  return this.setCardid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasCardid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO reviewId = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getReviewid = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
*/
proto.stats.ReviewManualCardStatDTO.prototype.setReviewid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearReviewid = function() {
  return this.setReviewid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasReviewid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 quality = 5;
 * @return {number}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.setQuality = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearQuality = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasQuality = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 interval = 6;
 * @return {number}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.setInterval = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearInterval = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 repetitions = 7;
 * @return {number}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getRepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.setRepetitions = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearRepetitions = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasRepetitions = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional utils.TimestampDTO startAt = 8;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getStartat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 8));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
*/
proto.stats.ReviewManualCardStatDTO.prototype.setStartat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearStartat = function() {
  return this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO endAt = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.ReviewManualCardStatDTO.prototype.getEndat = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
*/
proto.stats.ReviewManualCardStatDTO.prototype.setEndat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.ReviewManualCardStatDTO} returns this
 */
proto.stats.ReviewManualCardStatDTO.prototype.clearEndat = function() {
  return this.setEndat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.ReviewManualCardStatDTO.prototype.hasEndat = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.CardStatDTO.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.CardStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.CardStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.CardStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.CardStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    sm2reviewavgeasiness: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sm2reviewavgquality: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sm2reviewavginterval: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sm2reviewavgrepetitions: jspb.Message.getFieldWithDefault(msg, 5, 0),
    manualreviewavgquality: jspb.Message.getFieldWithDefault(msg, 6, 0),
    manualreviewavginterval: jspb.Message.getFieldWithDefault(msg, 7, 0),
    manualreviewavgrepetitions: jspb.Message.getFieldWithDefault(msg, 8, 0),
    earliestreview: (f = msg.getEarliestreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    recentreview: (f = msg.getRecentreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    sm2statsList: jspb.Message.toObjectList(msg.getSm2statsList(),
    proto.stats.ReviewSM2CardStatDTO.toObject, includeInstance),
    manualstatsList: jspb.Message.toObjectList(msg.getManualstatsList(),
    proto.stats.ReviewManualCardStatDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.CardStatDTO}
 */
proto.stats.CardStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.CardStatDTO;
  return proto.stats.CardStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.CardStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.CardStatDTO}
 */
proto.stats.CardStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavgeasiness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavgquality(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavginterval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSm2reviewavgrepetitions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualreviewavgquality(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualreviewavginterval(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualreviewavgrepetitions(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEarliestreview(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setRecentreview(value);
      break;
    case 11:
      var value = new proto.stats.ReviewSM2CardStatDTO;
      reader.readMessage(value,proto.stats.ReviewSM2CardStatDTO.deserializeBinaryFromReader);
      msg.addSm2stats(value);
      break;
    case 12:
      var value = new proto.stats.ReviewManualCardStatDTO;
      reader.readMessage(value,proto.stats.ReviewManualCardStatDTO.deserializeBinaryFromReader);
      msg.addManualstats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.CardStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.CardStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.CardStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.CardStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEarliestreview();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getRecentreview();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getSm2statsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.stats.ReviewSM2CardStatDTO.serializeBinaryToWriter
    );
  }
  f = message.getManualstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.stats.ReviewManualCardStatDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.CardStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 sm2ReviewAvgEasiness = 2;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavgeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavgeasiness = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavgeasiness = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavgeasiness = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 sm2ReviewAvgQuality = 3;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavgquality = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavgquality = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavgquality = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 sm2ReviewAvgInterval = 4;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavginterval = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavginterval = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavginterval = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 sm2ReviewAvgRepetitions = 5;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getSm2reviewavgrepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setSm2reviewavgrepetitions = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2reviewavgrepetitions = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasSm2reviewavgrepetitions = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 manualReviewAvgQuality = 6;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getManualreviewavgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setManualreviewavgquality = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualreviewavgquality = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasManualreviewavgquality = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 manualReviewAvgInterval = 7;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getManualreviewavginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setManualreviewavginterval = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualreviewavginterval = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasManualreviewavginterval = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 manualReviewAvgRepetitions = 8;
 * @return {number}
 */
proto.stats.CardStatDTO.prototype.getManualreviewavgrepetitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.setManualreviewavgrepetitions = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualreviewavgrepetitions = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasManualreviewavgrepetitions = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO earliestReview = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.CardStatDTO.prototype.getEarliestreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setEarliestreview = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearEarliestreview = function() {
  return this.setEarliestreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasEarliestreview = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO recentReview = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.CardStatDTO.prototype.getRecentreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setRecentreview = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearRecentreview = function() {
  return this.setRecentreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.CardStatDTO.prototype.hasRecentreview = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated ReviewSM2CardStatDTO sm2Stats = 11;
 * @return {!Array<!proto.stats.ReviewSM2CardStatDTO>}
 */
proto.stats.CardStatDTO.prototype.getSm2statsList = function() {
  return /** @type{!Array<!proto.stats.ReviewSM2CardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.ReviewSM2CardStatDTO, 11));
};


/**
 * @param {!Array<!proto.stats.ReviewSM2CardStatDTO>} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setSm2statsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.stats.ReviewSM2CardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.ReviewSM2CardStatDTO}
 */
proto.stats.CardStatDTO.prototype.addSm2stats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.stats.ReviewSM2CardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearSm2statsList = function() {
  return this.setSm2statsList([]);
};


/**
 * repeated ReviewManualCardStatDTO manualStats = 12;
 * @return {!Array<!proto.stats.ReviewManualCardStatDTO>}
 */
proto.stats.CardStatDTO.prototype.getManualstatsList = function() {
  return /** @type{!Array<!proto.stats.ReviewManualCardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.ReviewManualCardStatDTO, 12));
};


/**
 * @param {!Array<!proto.stats.ReviewManualCardStatDTO>} value
 * @return {!proto.stats.CardStatDTO} returns this
*/
proto.stats.CardStatDTO.prototype.setManualstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.stats.ReviewManualCardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.ReviewManualCardStatDTO}
 */
proto.stats.CardStatDTO.prototype.addManualstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.stats.ReviewManualCardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.CardStatDTO} returns this
 */
proto.stats.CardStatDTO.prototype.clearManualstatsList = function() {
  return this.setManualstatsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stats.TopicStatDTO.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.TopicStatDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.TopicStatDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.TopicStatDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.TopicStatDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    numrelationships: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mostreviewedcard: (f = msg.getMostreviewedcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    leastreviewedcard: (f = msg.getLeastreviewedcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    weakestcard: (f = msg.getWeakestcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    strongestcard: (f = msg.getStrongestcard()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    numresources: jspb.Message.getFieldWithDefault(msg, 7, 0),
    avgquality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    avginterval: jspb.Message.getFieldWithDefault(msg, 9, 0),
    avgrepetition: jspb.Message.getFieldWithDefault(msg, 10, 0),
    avgeasiness: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nextcardreview: (f = msg.getNextcardreview()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    nextreview: (f = msg.getNextreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    recentreview: (f = msg.getRecentreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    earliestreview: (f = msg.getEarliestreview()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    cardstatsList: jspb.Message.toObjectList(msg.getCardstatsList(),
    proto.stats.CardStatDTO.toObject, includeInstance),
    totalreviewcount: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.TopicStatDTO}
 */
proto.stats.TopicStatDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.TopicStatDTO;
  return proto.stats.TopicStatDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.TopicStatDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.TopicStatDTO}
 */
proto.stats.TopicStatDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumrelationships(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setMostreviewedcard(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setLeastreviewedcard(value);
      break;
    case 5:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setWeakestcard(value);
      break;
    case 6:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setStrongestcard(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumresources(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgquality(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvginterval(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgrepetition(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgeasiness(value);
      break;
    case 12:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setNextcardreview(value);
      break;
    case 13:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setNextreview(value);
      break;
    case 14:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setRecentreview(value);
      break;
    case 15:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEarliestreview(value);
      break;
    case 16:
      var value = new proto.stats.CardStatDTO;
      reader.readMessage(value,proto.stats.CardStatDTO.deserializeBinaryFromReader);
      msg.addCardstats(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalreviewcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.TopicStatDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.TopicStatDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.TopicStatDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.TopicStatDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMostreviewedcard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getLeastreviewedcard();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getWeakestcard();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStrongestcard();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getNextcardreview();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getNextreview();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getRecentreview();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEarliestreview();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCardstatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.stats.CardStatDTO.serializeBinaryToWriter
    );
  }
  f = message.getTotalreviewcount();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 numRelationships = 2;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getNumrelationships = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setNumrelationships = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNumrelationships = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNumrelationships = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO mostReviewedCard = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getMostreviewedcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setMostreviewedcard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearMostreviewedcard = function() {
  return this.setMostreviewedcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasMostreviewedcard = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO leastReviewedCard = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getLeastreviewedcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setLeastreviewedcard = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearLeastreviewedcard = function() {
  return this.setLeastreviewedcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasLeastreviewedcard = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional utils.UUID_DTO weakestCard = 5;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getWeakestcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 5));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setWeakestcard = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearWeakestcard = function() {
  return this.setWeakestcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasWeakestcard = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional utils.UUID_DTO strongestCard = 6;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getStrongestcard = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 6));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setStrongestcard = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearStrongestcard = function() {
  return this.setStrongestcard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasStrongestcard = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 numResources = 7;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getNumresources = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setNumresources = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNumresources = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNumresources = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 avgQuality = 8;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvgquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvgquality = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvgquality = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvgquality = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 avgInterval = 9;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvginterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvginterval = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvginterval = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvginterval = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 avgRepetition = 10;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvgrepetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvgrepetition = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvgrepetition = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvgrepetition = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 avgEasiness = 11;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getAvgeasiness = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setAvgeasiness = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearAvgeasiness = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasAvgeasiness = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.UUID_DTO nextCardReview = 12;
 * @return {?proto.utils.UUID_DTO}
 */
proto.stats.TopicStatDTO.prototype.getNextcardreview = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 12));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setNextcardreview = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNextcardreview = function() {
  return this.setNextcardreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNextcardreview = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional utils.TimestampDTO nextReview = 13;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.TopicStatDTO.prototype.getNextreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 13));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setNextreview = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearNextreview = function() {
  return this.setNextreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasNextreview = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional utils.TimestampDTO recentReview = 14;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.TopicStatDTO.prototype.getRecentreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 14));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setRecentreview = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearRecentreview = function() {
  return this.setRecentreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasRecentreview = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional utils.TimestampDTO earliestReview = 15;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stats.TopicStatDTO.prototype.getEarliestreview = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 15));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setEarliestreview = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearEarliestreview = function() {
  return this.setEarliestreview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.TopicStatDTO.prototype.hasEarliestreview = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated CardStatDTO cardStats = 16;
 * @return {!Array<!proto.stats.CardStatDTO>}
 */
proto.stats.TopicStatDTO.prototype.getCardstatsList = function() {
  return /** @type{!Array<!proto.stats.CardStatDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stats.CardStatDTO, 16));
};


/**
 * @param {!Array<!proto.stats.CardStatDTO>} value
 * @return {!proto.stats.TopicStatDTO} returns this
*/
proto.stats.TopicStatDTO.prototype.setCardstatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.stats.CardStatDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stats.CardStatDTO}
 */
proto.stats.TopicStatDTO.prototype.addCardstats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.stats.CardStatDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.clearCardstatsList = function() {
  return this.setCardstatsList([]);
};


/**
 * optional int32 totalReviewCount = 17;
 * @return {number}
 */
proto.stats.TopicStatDTO.prototype.getTotalreviewcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.TopicStatDTO} returns this
 */
proto.stats.TopicStatDTO.prototype.setTotalreviewcount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


goog.object.extend(exports, proto.stats);
