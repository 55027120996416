import {IFromDTO} from "./model";
import {InternalErrorTypes, IUIError, NewUIError} from "../service/cartaError";
import {RelTopicDepthDTO, RelTopicDTO, TopicRelationshipEnumDTO,} from "../proto/topic_pb";
import {Topic} from "./topic";
import {convertTimestampToDate} from "../utils/utils";

/**
 * This is a special kind of RelTopic that contains extra parameters such as depth, that can give an indication the actual
 * depth of the graph from the `parent_id` of that particular rel_topic
 */
export class RelTopicDepth implements IFromDTO<RelTopicDepthDTO> {
  private _parent_id: string = "";
  private _relTopic: RelTopic = new RelTopic();
  private _depth: number = -1;

  fromDTO(dto: RelTopicDepthDTO): void | IUIError {
    let relTopic = new RelTopic();
    if (dto.getReltopics()) {
      relTopic.fromDTO(dto.getReltopics()!);
    }

    this._relTopic = relTopic;
    this._depth = dto.getDepth();
    if (dto.getOriginaltopic()) {
      this._parent_id = dto.getOriginaltopic()!.getValue();
    }
  }

  get parent_id(): string {
    return this._parent_id;
  }

  set parent_id(value: string) {
    this._parent_id = value;
  }

  get relTopic(): RelTopic {
    return this._relTopic;
  }

  set relTopic(value: RelTopic) {
    this._relTopic = value;
  }

  get depth(): number {
    return this._depth;
  }

  set depth(value: number) {
    this._depth = value;
  }
}

export class RelTopic implements IFromDTO<RelTopicDTO> {
  private _id: string = "";
  private _topic1Id: string = "";
  private _topic2Id: string = "";
  private _userId: string = "";
  private _relationship: TopicRelationshipEnumDTO =
    TopicRelationshipEnumDTO.PARENTCHILD;
  private _createdOn: Date;
  private _updatedOn: Date;
  private _topic2: Topic;
  private _topic1: Topic;

  constructor() {
    this._topic1 = new Topic();
    this._topic2 = new Topic();
    this._createdOn = new Date();
    this._updatedOn = new Date();
  }

  fromDTO(relTopic: RelTopicDTO): void | IUIError {
    if (relTopic.getId()) {
      if (relTopic.getId()!.getValue()) {
        this._id = relTopic.getId()!.getValue();
      } else {
        return NewUIError(
          "validateRelTopicDTO",
          InternalErrorTypes.InvalidRelTopic,
          `relTopicID is empty '' - relTopic: ${relTopic}"`
        );
      }
    } else {
      return NewUIError(
        "validateRelTopicDTO",
        InternalErrorTypes.InvalidRelTopic,
        `relTopicID is undefined '' - relTopic: ${relTopic}"`
      );
    }

    if (relTopic.getUserid()) {
      if (relTopic.getUserid()!.getValue()) {
        this._userId = relTopic.getUserid()!.getValue();
      } else {
        return NewUIError(
          "validateRelTopicDTO",
          InternalErrorTypes.InvalidRelTopic,
          `relTopic userID is empty '' - relTopic: ${relTopic}"`
        );
      }
    } else {
      return NewUIError(
        "validateRelTopicDTO",
        InternalErrorTypes.InvalidRelTopic,
        `relTopic userID is undefined '' - relTopic: ${relTopic}"`
      );
    }

    if (relTopic.getTopic1id()) {
      if (relTopic.getTopic1id()!.getValue()) {
        this._topic1Id = relTopic.getTopic1id()!.getValue();
      } else {
        return NewUIError(
          "validateRelTopicDTO",
          InternalErrorTypes.InvalidRelTopic,
          `relTopic getTopic1id is empty '' - relTopic: ${relTopic}"`
        );
      }
    } else {
      return NewUIError(
        "validateRelTopicDTO",
        InternalErrorTypes.InvalidRelTopic,
        `relTopic getTopic1id is undefined '' - relTopic: ${relTopic}"`
      );
    }

    if (relTopic.getTopic2id()) {
      if (relTopic.getTopic2id()!.getValue()) {
        this._topic2Id = relTopic.getTopic2id()!.getValue();
      } else {
        return NewUIError(
          "validateRelTopicDTO",
          InternalErrorTypes.InvalidRelTopic,
          `relTopic userID is empty '' - relTopic: ${relTopic}"`
        );
      }
    } else {
      return NewUIError(
        "validateRelTopicDTO",
        InternalErrorTypes.InvalidRelTopic,
        `relTopic userID is undefined '' - relTopic: ${relTopic}"`
      );
    }

    const createdOn = convertTimestampToDate(relTopic.getCreatedon()!);
    const updatedOn = convertTimestampToDate(relTopic.getUpdatedon()!);

    this._createdOn = createdOn;
    this._updatedOn = updatedOn;
    this._relationship = relTopic.getRelationship();
  }

  public static fromParts(id: string, userId: string, topic1: Topic, topic2: Topic, relationship: TopicRelationshipEnumDTO): RelTopic {
    let topic = new RelTopic();
    topic._id = id;
    topic.userId = userId;
    topic.topic1 = topic1;
    topic.topic2 = topic2;
    topic.relationship = relationship;
    topic._topic1Id = topic1.id;
    topic._topic2Id = topic2.id;
    return topic;
  }

  public setTopics(topicMap: Map<string, Topic>) {
    const topic1 = topicMap.get(this._topic1Id);
    if (topic1) {
      this._topic1 = topic1;
    }

    const topic2 = topicMap.get(this._topic2Id);
    if (topic2) {
      this._topic2 = topic2;
    }
  }

  get topic1(): Topic {
    return this._topic1;
  }

  set topic1(value: Topic) {
    this._topic1 = value;
  }

  get topic2(): Topic {
    return this._topic2;
  }

  set topic2(value: Topic) {
    this._topic2 = value;
  }

  get topic1Id(): string {
    return this._topic1Id;
  }

  set topic1Id(value: string) {
    this._topic1Id = value;
  }

  get topic2Id(): string {
    return this._topic2Id;
  }

  set topic2Id(value: string) {
    this._topic2Id = value;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  get relationship(): TopicRelationshipEnumDTO {
    return this._relationship;
  }

  set relationship(value: TopicRelationshipEnumDTO) {
    this._relationship = value;
  }

  get createdOn(): Date {
    return this._createdOn;
  }

  set createdOn(value: Date) {
    this._createdOn = value;
  }

  get updatedOn(): Date {
    return this._updatedOn;
  }

  set updatedOn(value: Date) {
    this._updatedOn = value;
  }
}
