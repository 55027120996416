/**
 * Result mimics the Rust `Result` type.
 */
// export interface Result<T, E extends Error> {
// 	Ok?: T,
// 	Err?: E,
// }

export type Result<T, E extends Error> = { ok: true, value: T }
	| { ok: false, error: E };

export const Ok = <T>(data: T): Result<T, never> => {
	return { ok: true, value: data };
};

export const Err = <E extends Error>(error: E): Result<never, E> => {
	return { ok: false, error };
};

export const ErrM = <E extends Error>(msg: string, error?: E): Result<never, E> => {
	let e: E
	if (error) {
		e = error
		e.message = msg
	} else {
		e = new Error(msg) as E
	}
	
	return { ok: false, error: e};
};

// class Result<T, E extends Error> {
// 	Ok: T
// 	Err?: Error
//
// 	constructor(t: T) {
// 		this.Ok = t
// 	}
//
// 	withError<T>(msg: string, err?: Error): Result<T> {
// 		let err = new Result(void);
// 		err.Err = NewUIErrorV2()
// 	}
//
// 	isError(): boolean {
// 		return !this.Err
// 	}
// }

// export const ResultErrUnimplemented = <T>(): Result<void, Error> => {
// 	return {Ok: undefined, Err: new Error("unimplemented")}
// }
// export const ResultVoid = <T>(value: T): Result<void, Error> => {
// 	return {Ok: undefined}
// }
// export const Ok = <T, E extends Error>(value: T): Result<T, E> => {
// 	return {Ok: value}
// }
// export const Err = <E extends Error>(value: E): Result<void, E> => {
// 	return {Ok: undefined, Err: value}
// }

// export const ErrM = <T, E extends Error>(errMsg: string): Result<T, E> => {
// 	return {Ok: undefined, Err: new Error(errMsg) as E}
// }

// TODO: We need an ErrMU function that includes a separate message for the user that can be bubbled up.

export const ErrUnimplemented: Error  = {name: "unimplemented", message: "function/code unimplemented"}
