import {Tag} from "./tag";
import {Topic} from "./topic";
import {Resource} from "./resource/Resource";
import {IFromDTO, IIntoDTO} from "./model";
import {ActionType, InternalErrorTypes, isError, IUIError, LogError, NewUIErrorV2} from "../service/cartaError";
import {TopicDTO} from "../proto/topic_pb";
import {TagDTO} from "../proto/tag_pb";
import {ResourceDTO} from "../proto/resource_pb";
import {EntityKind} from "./BaseModel";
import {CardLangCompositeDTO} from "../proto/cardLang_pb";
import {ICardComposite} from "./CardComposite";
import {CardMedia} from "model/CardMedia";

export class CardLangComposite
	implements ICardComposite,
		IIntoDTO<CardLangCompositeDTO>, IFromDTO<CardLangCompositeDTO> {

	
	private _id: string = "";
	private _ord: number = 0;
	private _tags: Tag[] = [];
	private _topics: Topic[] = [];
	private _resources: Resource[] = [];
	private _media: CardMedia[] = [];
	
	constructor() {
	}
	
	fromDTO(t: CardLangCompositeDTO): void | IUIError {
		// TOPICS
		t.getTopicsList().forEach((dto) => {
			let topic = new Topic()
			const err = topic.fromDTO(dto);
			
			if (err) {
				return NewUIErrorV2(ActionType.ConvertFromDTO, EntityKind.CardLangComposite, err, `failed to convert topic`)
			}
			
			this.topics.push(topic)
		})
		
		// TAGS
		t.getTagsList().forEach((dto) => {
			let tag = new Tag()
			const err = tag.fromDTO(dto);
			
			if (err) {
				return NewUIErrorV2(ActionType.ConvertFromDTO, EntityKind.CardLangComposite, err, `failed to convert tag`)
			}
			this.tags.push(tag)
		})
		
		// RESOURCES
		t.getResourcesList().forEach((dto) => {
			let resource = new Resource()
			const err = resource.fromDTO(dto);
			
			if (err) {
				LogError(origin, InternalErrorTypes.InvalidCardComposite, `failed to convert resource`, err)
			}
			this.resources.push(resource)
		})
	}
	
	intoDTO(): IUIError | CardLangCompositeDTO {
		let topics_dto: TopicDTO[] = []
		let tags_dto: TagDTO[] = []
		let resources_dto: ResourceDTO[] = []
		
		this.topics.forEach((topic) => {
			const dto = topic.intoDTO();
			if (isError(dto)) {
				return NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.CardLangComposite, undefined, "invalid topic")
			}
			topics_dto.push(dto as TopicDTO)
		})
		
		this.tags.forEach((tag) => {
			const dto = tag.intoDTO();
			if (isError(dto)) {
				return NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.CardLangComposite, undefined, "invalid tag")
			}
			tags_dto.push(dto as TagDTO)
		})
		
		this.resources.forEach((resource) => {
			const dto = resource.intoDTO();
			if (isError(dto)) {
				return NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.CardLangComposite, undefined, "invalid resource")
			}
			resources_dto.push(dto as ResourceDTO)
		})
		
		let dto = new CardLangCompositeDTO();
		
		dto.setOrd(0)
		dto.setTopicsList(topics_dto)
		dto.setTagsList(tags_dto)
		dto.setResourcesList(resources_dto)
		
		return dto
	}
	
	get id() {
		return this._id
	}
	
	set id(value: string) {
		this._id = value;
	}
	
	get tags(): Tag[] {
		return this._tags;
	}
	
	set tags(value: Tag[]) {
		this._tags = value;
	}
	
	get topics(): Topic[] {
		return this._topics;
	}
	
	set topics(value: Topic[]) {
		this._topics = value;
	}
	
	get resources(): Resource[] {
		return this._resources;
	}
	
	set resources(value: Resource[]) {
		this._resources = value;
	}
	
	get ord(): number {
		return this._ord;
	}
	
	set ord(value: number) {
		this._ord = value;
	}

	get media(): CardMedia[] {
		return this._media;
	}

	set media(value: CardMedia[]) {
		this._media = value;
	}
}
