import {
  BaseModel,
  DTOCreatorRequestType,
  DTOCreatorResponseType,
  IGRPCReviewService,
} from "../model/BaseModel";
import { IReceiveOnlyModel } from "../model/model";
import { ReviewBaseService } from "../service/ReviewBaseService";
import {
  action,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
} from "mobx";
import { Err, Ok, Result } from "../utils/result";
import {
  ActionType,
  InternalErrorTypes,
  IUIError,
  LogU,
  NewUIError,
  NewUIErrorV2,
} from "../service/cartaError";
import { IsUUIDValid } from "../utils/utils";
import BaseStore from "./BaseStore";
import { IReview, IReviewCard } from "../model/Review";
import { ProgressStateEnumDTO } from "../proto/utils_pb";
import { ICard } from "../model/CardComposite";
import { ReviewManualCardFilterResult } from "./ReviewManualStore";

export interface IReviewPackage<
  MODEL extends BaseModel<MODEL, MODEL_DTO> & IReview,
  MODEL_DTO,
  SUB_MODEL extends BaseModel<SUB_MODEL, SUB_MODEL_DTO> & IReviewCard,
  SUB_MODEL_DTO
> {
  review: MODEL;
  cards: SUB_MODEL[];
}

export interface IReviewBaseStore<Model, MODEL_SUB, MODEL_STAT> {
  Complete(model: Model): Promise<Result<Model, IUIError>>;

  // ListCards allows the user to list the cards associated with the review
  ListCards(review_id: string): Promise<Result<MODEL_SUB[], IUIError>>;

  // SaveCard allows the user to save the card (usually used to update the card metadata)
  SaveCard(
    review_id: string,
    card: MODEL_SUB
  ): Promise<Result<MODEL_SUB, IUIError>>;

  // GetStats allows the user to get the stats associated with the review
  GetStat(review_id: string): Promise<Result<MODEL_STAT | undefined, IUIError>>;
}

export abstract class ReviewBaseStore<
    MODEL extends BaseModel<MODEL, MODEL_DTO> & IReview,
    MODEL_DTO,
    SUB_MODEL extends BaseModel<SUB_MODEL, SUB_MODEL_DTO> & IReviewCard,
    SUB_MODEL_DTO,
    MODEL_STAT extends IReceiveOnlyModel<MODEL_STAT, MODEL_STAT_DTO>,
    MODEL_STAT_DTO,
    CONFIG_DTO,
    REVIEW_CLIENT extends IGRPCReviewService<
      MODEL_DTO,
      SUB_MODEL_DTO,
      MODEL_STAT_DTO,
      CONFIG_DTO,
      DTOCreatorRequestType,
      DTOCreatorResponseType<MODEL_DTO>
    >,
    REVIEW_SERVICE extends ReviewBaseService<
      MODEL,
      MODEL_DTO,
      SUB_MODEL,
      SUB_MODEL_DTO,
      MODEL_STAT,
      MODEL_STAT_DTO,
      CONFIG_DTO,
      REVIEW_CLIENT
    >
  >
  extends BaseStore<MODEL, MODEL_DTO, REVIEW_CLIENT, REVIEW_SERVICE>
  implements IReviewBaseStore<MODEL, SUB_MODEL, MODEL_STAT>
{
  reviewCardMap: ObservableMap<string, SUB_MODEL[]> = new ObservableMap<
    string,
    SUB_MODEL[]
  >();

  reviewStatMap: ObservableMap<string, MODEL_STAT> = new ObservableMap<
    string,
    MODEL_STAT
  >();

  // public cardsForNewReview: [reviewId: string, cards: SUB_MODEL_DTO[]] = ["", []];
  public filterResults: ReviewManualCardFilterResult<ICard>[] | undefined;
  // ongoingReviewCardQueue: IReviewCard[] = [];
  // ongoingReviewId: string | undefined;

  constructor(
    private reviewService: REVIEW_SERVICE,
    modelConstructor: new () => MODEL,
    private cardConstructor: new () => SUB_MODEL,
    private statConstructor: new () => MODEL_STAT
  ) {
    super(reviewService, modelConstructor);

    makeObservable(this, {
      reviewCardMap: observable,
      reviewStatMap: observable,
      // cardsForNewReview: observable,
      // ongoingReviewId: observable,
      // ongoingReviewCardQueue: observable,

      Start: action,
      Complete: action,
      SaveCard: action,
      ListCards: action,
      GetStat: action,
      // SetOngoingCardQueue: action,
      // PopOngoingCardQueue: action,

      // OngoingReview: computed,
      // CardsQueue: computed,
    });

    this.service = reviewService;
  }

  public GetReviewCards = (review_id: string): SUB_MODEL[] | undefined => {
    return this.reviewCardMap.get(review_id);
  };

  public Start = async (id: string): Promise<Result<MODEL, IUIError>> => {
    const review = await this.GetOneOrFetch(id);

    if (review.ok) {
      if (review.value === undefined) {
        return Err(
          NewUIError(
            "Start",
            InternalErrorTypes.GetReview,
            `failed to fetch review: ${id} - undefined`
          )
        );
      }

      let startedReview = review.value.clone();
      startedReview.progressState = ProgressStateEnumDTO.IN_PROGRESS;
      startedReview.startAt = new Date();
      startedReview.updatedOn = new Date();

      return this.Update(startedReview);
    } else {
      return Err(review.error as IUIError);
    }
  };

  // Resume should preferably be implemented by the children. This is a generic implementation
  public Resume = async (
    id: string
  ): Promise<
    Result<IReviewPackage<MODEL, MODEL_DTO, SUB_MODEL, SUB_MODEL_DTO>, IUIError>
  > => {
    const review = await this.GetOneOrFetch(id);

    if (review.ok) {
      if (review.value === undefined) {
        return Err(
          NewUIError(
            "Start",
            InternalErrorTypes.GetReview,
            `failed to fetch review: ${id} - undefined`
          )
        );
      }

      const updateAction = this.Update(review.value);
      const listCardAction = this.ListCards(id);

      return Promise.all([updateAction, listCardAction])
        .then((res) => {
          if (res[0].ok && res[1].ok) {
            return Ok({ review: res[0].value, cards: res[1].value });
          } else {
            if (!res[0].ok) {
              return Err(res[0].error as IUIError);
            }
            if (!res[1].ok) {
              return Err(res[1].error as IUIError);
            }
          }

          return Err(
            NewUIError(
              "Resume",
              InternalErrorTypes.GetReview,
              `failed to fetch review + cards to resume: ${id} - undefined`
            )
          );
        })
        .catch((err) => {
          return Err(
            NewUIError(
              "Resume",
              InternalErrorTypes.GetReview,
              `failed to fetch review: ${id} - undefined`,
              undefined,
              err
            )
          );
        });
    } else {
      return Err(review.error as IUIError);
    }
  };

  // public async SetOngoingReview(reviewId: string): Promise<void | IUIError> {
  // 	const review = await this.GetOneOrFetch(reviewId)
  // 	if (review === undefined) {
  // 		return NewUIError(
  // 			"SetOngoingReview",
  // 			InternalErrorTypes.GetReview,
  // 			`failed to fetch review: ${reviewId} - undefined`
  // 		);
  // 	}
  //
  // 	console.log("xxx review: ", review)
  //
  // 	this.ongoingReviewId = reviewId;
  //
  // 	console.log("review haha", this.OngoingReview)
  //
  // 	// Fetch Cards for Review
  // 	if (this.OngoingReviewCardQueue.length === 0) {
  // 		const res = await this.ListCards(reviewId);
  // 		if (res.ok) {
  // 			this.ongoingReviewCardQueue = res.value;
  // 		}
  // 	}
  // }

  async Complete(model: MODEL): Promise<Result<MODEL, IUIError>> {
    const actionType = ActionType.Complete;

    try {
      let res = await this.reviewService.Complete(model);

      if (!res.ok) {
        return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
      }

      const mRes = res.value as MODEL;

      // Completing a review should update the local store
      runInAction(() => {
        this.map.set(mRes.id, mRes);
      });

      return Ok(mRes);
    } catch (err) {
      return Err(NewUIErrorV2(actionType, model.TYPE, err));
    }
  }

  async ListCards(review_id: string): Promise<Result<SUB_MODEL[], IUIError>> {
    if (!IsUUIDValid(review_id)) {
      return Promise.reject("id is not valid");
    }

    const res = await this.reviewService.ListCards(review_id);
    if (res.ok) {
      runInAction(() => {
        this.reviewCardMap.set(review_id, res.value);
      });
    }
    if (!res.ok) {
      return Err(
        NewUIErrorV2(
          ActionType.ListReviewCards,
          new this.cardConstructor().TYPE,
          res.error
        )
      );
    }
    return Ok(res.value);
  }

  async SaveCard(
    review_id: string,
    card: SUB_MODEL
  ): Promise<Result<SUB_MODEL, IUIError>> {
    try {
      const res = await this.reviewService.SaveCard(review_id, card);

      if (res.ok) {
        if (this.reviewCardMap.has(review_id)) {
          let cardIndex = this.findCardIndexInArray(
            review_id,
            this.reviewCardMap.get(review_id) || []
          );
          if (cardIndex !== -1) {
            runInAction(() => {
              this.reviewCardMap.get(review_id)![cardIndex] = card;
            });
          }
        } else {
          
          LogU(
            NewUIErrorV2(
              ActionType.SaveReviewCards,
              new this.cardConstructor().TYPE,
              undefined,
              `review card map does not contain review_id: ${review_id} - cannot save card`
            )
          );
        }

        return Ok(card);
      } else {
        
        return Err(
          NewUIErrorV2(
            ActionType.SaveReviewCards,
            new this.cardConstructor().TYPE,
            res.error
          )
        );
      }
    } catch (err) {
      return Err(
        NewUIErrorV2(
          ActionType.SaveReviewCards,
          new this.cardConstructor().TYPE,
          err
        )
      );
    }
  }

  async GetStat(
    review_id: string,
    invalidate?: boolean
  ): Promise<Result<MODEL_STAT | undefined, IUIError>> {
    if (this.reviewStatMap.has(review_id) && !invalidate) {
      return Promise.resolve(Ok(this.reviewStatMap.get(review_id)));
    }

    return this.reviewService.GetStat(review_id).then((res) => {
      if (res.ok && res.value !== undefined) {
        runInAction(() => {
          this.reviewStatMap.set(review_id, res.value!);
        });
      }
      if (!res.ok) {
        return Err(
          NewUIErrorV2(
            ActionType.GetStats,
            new this.statConstructor().TYPE,
            res.error
          )
        );
      }
      return Ok(res.value);
    });
  }

  ListCardsForNewReview = async (
    review_id: string,
    config: CONFIG_DTO
  ): Promise<Result<SUB_MODEL[], IUIError>> => {
    const res = await this.reviewService.ListCardsByConfig(review_id, config);
    if (res.ok) {
      // this.cardsForNewReview = [review_id, res.value];
      return Ok(res.value);
    }

    return Err(
      NewUIErrorV2(
        ActionType.ListReviewCards,
        new this.cardConstructor().TYPE,
        res.error
      )
    );
  };

  findCardIndexInArray = (id: string, cards: SUB_MODEL[]): number => {
    let index = -1;

    cards.forEach((c, i) => {
      if (c.id === id) {
        index = i;
      }
    });

    return index;
  };
}
