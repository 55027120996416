import {lazy} from 'react';

// project import
import ErrorBoundary from './ErrorBoundary';
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import SimpleLayout from 'layout/Simple';

// import {loader as productsLoader, productLoader} from 'api/products';

// types
import {SimpleLayoutType} from 'types/config';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/analytics')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));


// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

const TopicPage = Loadable(lazy(() => import('pages/topic/TopicPage')));
const CardLandingPage = Loadable(lazy(() => import('pages/card/CardLandingPage')));
const ReviewLandingPage = Loadable(lazy(() => import('pages/review/landing/ReviewLandingPage')));

// const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
// const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <DashboardLayout/>,
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardAnalytics/>
                    // children: [
                    //     {
                    //         path: 'default',
                    //         element: <DashboardDefault/>
                    //     },
                    //     {
                    //         path: 'analytics',
                    //         element: <DashboardAnalytics/>
                    //     }
                    // ]
                },
                {
                    path: 'topics',
                    element: <TopicPage/>,
                    children: [
                        {
                            path: ':topicId',
                            element: <TopicPage/>
                        }
                    ]
                },
                {
                    path: 'cards',
                    children: [
                        {
                            path: '',
                            element: <CardLandingPage/>,
                            children: [
                                {
                                    path: ':cardId',
                                    element: <CardLandingPage/>
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'reviews',
                    element: <ReviewLandingPage/>,
                    children: [
                        {
                            path: 'sm2',
                            children: [
                                {
                                    path: 'session/:sessionId',
                                    element: <ReviewLandingPage/>
                                },
                                {
                                    path: '*',
                                    element: <ReviewLandingPage/>
                                }
                            ],
                            element: <ReviewLandingPage/>
                        },
                        {
                            path: 'manual',
                            children: [
                                {
                                    path: 'session/:sessionId',
                                    element: <ReviewLandingPage/>
                                },
                                {
                                    path: '*',
                                    element: <ReviewLandingPage/>
                                }
                            ],
                            element: <ReviewLandingPage/>
                        },
                        {
                            path: '*',
                            element: <ReviewLandingPage/>
                        }
                    ]
                },

                {
                    path: '/maintenance',
                    element: <PagesLayout/>,
                    children: [
                        {
                            path: '404',
                            element: <MaintenanceError/>
                        },
                        {
                            path: '500',
                            element: <MaintenanceError500/>
                        },
                        {
                            path: 'under-construction',
                            element: <MaintenanceUnderConstruction/>
                        },
                        {
                            path: 'coming-soon',
                            element: <MaintenanceComingSoon/>
                        }
                    ]
                },
            ]
        }
    ]
}

export default MainRoutes;
