import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

// project import
import {APP_DEFAULT_PATH} from 'config';
import useAuth from 'hooks/useAuth';

// types
import {GuardProps} from 'types/auth';
import {AuthenticationStateEnum} from "stores/AuthenticationStore";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { authenticationState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();


  console.log("GuestGuard: ", authenticationState.state)
  useEffect(() => {
    if (authenticationState.state === AuthenticationStateEnum.LoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [authenticationState, navigate, location]);

  return children;
};

export default GuestGuard;
