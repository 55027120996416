import {
    ActionType,
    InternalErrorTypes,
    isError,
    IUIError,
    NewUIError,
    NewUIErrorV2,
    UIError,
} from "service/cartaError";
import {
    containsIllegalChars,
    convertDateToTimestamp,
    convertTimestampToDate,
    ListItem,
    NewUUID,
    ResourceKindObj,
} from "utils/utils";
import {ResourceCompositeDTO, ResourceDTO, ResourceKind, ResourceOriginDTO} from "proto/resource_pb";
import {UUID_DTO} from "proto/utils_pb";
import {IDisplayItem, Listable} from "../interfaces";
import {BaseModel, EntityKind, IOwnedModel, ValidListable} from "../BaseModel";
import {convertFromDTOToID} from "../CardLang";
import {ResourceComposite} from "model/resource/ResourceComposite";
import {Err, Ok, Result} from "utils/result";

export const UNKNOWN_TITLE_DEFAULT = "Unknown Title";
export const UNKNOWN_AUTHOR_DEFAULT = "Unknown";

export const convertStringToDate = (dateStr: string): Date | undefined => {
    let ans = Date.parse(dateStr);

    if (ans === 0) {
        return undefined;
    }
    return new Date(ans);
};

export class StringListable implements ValidListable {
    private _id: string;
    private _title: string;

    constructor(value: string) {
        this._id = NewUUID();
        this._title = value;
    }

    toListItem(): ListItem {
        return {
            id: this._id,
            title: this.title,
        };
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }
}

export interface IResource extends IOwnedModel<any> {
    _title: string;
    _authors: string[];
    _kind: ResourceKind;
    _createdOn: Date;
    _updatedOn: Date;
    _subtitle?: string;
    _thumbnail?: string;
    _archivedOn?: Date;
    _description?: string;
    _link?: string;
    composite?: ResourceComposite;
}

export class Resource extends BaseModel<Resource, ResourceDTO> implements Listable {
    private _title: string;
    private _authors: string[];
    private _kind: ResourceKind;
    private _createdOn: Date;
    private _updatedOn: Date;
    private _origin: ResourceOriginDTO;
    private _thumbnail?: string;
    private _archivedOn?: Date;
    private _link?: string;
    // private _metadata?: ResourceMetadata
    private _composite?: ResourceComposite

    constructor() {
        super();

        const now = new Date();

        this._origin = ResourceOriginDTO.LOCAL;
        this._authors = [];
        this._createdOn = now;
        this.id = NewUUID();
        this._kind = ResourceKind.UNKNOWN;
        this._title = "";
        this._updatedOn = now;
        this.userId = "";
    }

    toListItem(): ListItem {
        let thumbnail = "";

        if (this.composite) {
            if (this.composite.metadata.length > 0) {
                const metadata = this.composite.metadata[0];

                if (metadata.small_thumbnail) {
                    thumbnail = metadata.small_thumbnail;
                }

                return {
                    id: this.id,
                    title: this.title,
                    imageUrl: thumbnail,
                    metadata1: this.authors.join(", "),
                    metadata2: ResourceKindMap.get(this.kind) as string,
                    metadata3: this.link,
                };
            } else {
                if (this._thumbnail) {
                    thumbnail = this._thumbnail;
                }
            }
        }

        return {
            id: this.id,
            title: this.title,
            imageUrl: thumbnail,
            metadata1: this.authors.join(", "),
            metadata2: ResourceKindMap.get(this.kind) as string,
            metadata3: this.link,
        };
    }

    init(): Resource {
        return new Resource();
    }

    private _TYPE: EntityKind = EntityKind.Resource;

    to1LineString(): String {
        return `${this.title} - ${this.authors}`;
    }

    toDisplayable(): IDisplayItem {
        let thumbnail = "";

        if (this.composite) {
            if (this.composite.metadata.length > 0) {
                const metadata = this.composite.metadata[0];

                if (metadata.small_thumbnail) {
                    thumbnail = metadata.small_thumbnail;
                }

                return {
                    id: this.id,
                    title: this.title,
                    imageUrl: thumbnail,
                    metadata1: this.authors.join(", "),
                    metadata2: ResourceKindMap.get(this.kind) as string,
                };
            } else {
                if (this._thumbnail) {
                    thumbnail = this._thumbnail;
                }
            }
        }

        return {
            id: this.id,
            imageUrl: thumbnail,
            title: this.title,
            metadata1: this.authors.join(", "),
        } as IDisplayItem;
    }

    clone(): Resource {
        let temp = Object.assign({}, this);
        let newCard = new Resource();

        newCard.id = temp.id;
        newCard.userId = temp.userId;
        newCard._title = temp._title;
        newCard._authors = temp._authors;
        newCard._kind = temp._kind;
        newCard._title = temp._title;
        newCard._link = temp._link;
        if (temp._composite) {
            newCard._composite = temp._composite.clone();
        }
        newCard._thumbnail = temp._thumbnail;
        newCard._createdOn = temp._createdOn;
        newCard._updatedOn = temp._updatedOn;
        newCard._archivedOn = temp._archivedOn;

        return newCard;
    }

    static fromJSON(temp: IResource): Resource {
        let resource = new Resource();

        resource.id = temp.id;
        resource.userId = temp.userId;
        resource._title = temp._title;
        resource._authors = temp._authors;
        resource._title = temp._title;
        resource._kind = temp._kind;
        resource._link = temp._link;
        // resource._metadata. = temp._metadata; // TODO: We should just be storing IDs and fetching from the DB
        resource._thumbnail = temp._thumbnail;
        resource._createdOn = new Date(temp._createdOn);
        resource._updatedOn = new Date(temp._updatedOn);
        resource._archivedOn = temp._archivedOn
        if (temp.composite) {
            resource._composite = temp.composite.clone();
        }

        return resource;
    }

    fromDTO(dto: ResourceDTO): void | IUIError {
        const origin = "fromDTO";
        const errorKind = InternalErrorTypes.InvalidResourceDTO;

        try {
            const id = convertFromDTOToID(this._TYPE, dto.getId());
            const userId = convertFromDTOToID(this._TYPE, dto.getUserId());

            if (!dto.getTitle()) {
                return NewUIError(
                    origin,
                    errorKind,
                    `resource title is empty '' - resource: ${dto}"`
                );
            }

            if (!dto.getCreatedOn()) {
                return NewUIError(
                    origin,
                    errorKind,
                    `resource created date is empty '' - resource: ${dto}"`
                );
            }

            if (!dto.getUpdatedOn()) {
                return NewUIError(
                    origin,
                    errorKind,
                    `resource updated date is empty '' - resource: ${dto}"`
                );
            }

            const createdOn = convertTimestampToDate(dto.getCreatedOn()!);
            const updatedOn = convertTimestampToDate(dto.getUpdatedOn()!);
            const archivedOn: Date | undefined = dto.getArchivedOn()
                ? convertTimestampToDate(dto.getArchivedOn()!)
                : undefined;

            let composite;
            if (dto.getComposite()) {
                composite = new ResourceComposite();
                composite.fromDTO(dto.getComposite()!);
            }

            this.id = id;
            this.userId = userId;
            this._authors = dto.getAuthorsList();
            this._kind = dto.getKind();
            this._title = dto.getTitle();
            this._link = dto.getLink();
            this._thumbnail = dto.getThumbnail();
            this._composite = composite;
            this._createdOn = createdOn;
            this._updatedOn = updatedOn;
            this._archivedOn = archivedOn;
        } catch (e) {
            return NewUIErrorV2(
                ActionType.ConvertFromDTO,
                EntityKind.Resource,
                e,
                `failed to convert resource dto to resource - resource: ${dto}`,
            );
        }
    }

    intoDTO(): IUIError | ResourceDTO {
        const resourceValidate = this.validate();
        if (isError!(resourceValidate)) {
            throw new UIError(
                InternalErrorTypes.InvalidResource,
                "failed to validate resource"
            );
        }

        let resource = resourceValidate as Resource;

        let dto = new ResourceDTO();
        dto.setId(new UUID_DTO().setValue(resource.id));
        dto.setUserId(new UUID_DTO().setValue(resource.userId));

        dto.setAuthorsList(resource._authors);
        dto.setKind(resource._kind);
        dto.setTitle(resource._title);
        dto.setLink(resource._link ? resource._link : "");
        dto.setThumbnail(resource._thumbnail ? resource._thumbnail : "");

        if (resource._composite) {
            let compositeDTO = resource._composite.intoDTO();
            if (isError(compositeDTO)) {
                return compositeDTO as IUIError
            }

            dto.setComposite(compositeDTO as ResourceCompositeDTO);
        }

        if (resource._archivedOn) {
            dto.setArchivedOn(convertDateToTimestamp(resource._archivedOn));
        }

        if (resource._updatedOn) {
            dto.setUpdatedOn(convertDateToTimestamp(resource._updatedOn));
        }
        if (resource._createdOn) {
            dto.setCreatedOn(convertDateToTimestamp(resource._createdOn));
        }

        return dto;
    }

    sanitize(): Resource {
        return this;
    }

    validate(): Resource | IUIError {
        return this.sanitize();
    }

    static ResourceTitleValidator(item: string): Result<void, IUIError> {
        if (item.length === 0) {
            const errMsg = "title is empty";
            return Err<IUIError>(NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Resource,
                errMsg, errMsg,errMsg
            ))
        }

        if (item.length > 200) {
            const errMsg = "title cannot exceed 200 characters";
            return Err<IUIError>(NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Resource,
                errMsg, errMsg, errMsg
            ))
        }

        return Ok(undefined)
    }

    static ResourceAuthorsValidator(item: string[]): Result<void, IUIError> {
        if (item.length != 0) {

            for (let i = 0; i < item.length; i++) {
                const element = item[i];
                if (element.length === 0) {
                    const errMsg = "individual author is empty";
                    return Err<IUIError>(NewUIErrorV2(
                        ActionType.Validate,
                        EntityKind.Resource,
                        errMsg, errMsg, errMsg
                    ))
                }

                if (element.length > 100) {
                    const errMsg = "individual author cannot exceed 100 characters";
                    return Err<IUIError>(NewUIErrorV2(
                        ActionType.Validate,
                        EntityKind.Resource,
                        errMsg, errMsg, errMsg
                    ))
                }

                if (containsIllegalChars(element)) {
                    const errMsg = "individual author contains illegal characters";
                    return Err<IUIError>(NewUIErrorV2(
                        ActionType.Validate,
                        EntityKind.Resource,
                        errMsg, errMsg, errMsg
                    ))
                }
            }

            const errMsg = "title is empty";
            return Err<IUIError>(NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Resource,
                errMsg, errMsg,errMsg
            ))
        }

        return Ok(undefined)
    }

    static ResourceLinkValidator(item: string): Result<void, IUIError> {
        if (item.length > 200) {
            const errMsg = "link cannot exceed 200 characters - use a link shortener if necessary";
            return Err<IUIError>(NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Resource,
                errMsg, errMsg, errMsg
            ))
        }

        return Ok(undefined)
    }


    public static Validators: Array<(item: any) => Result<void, IUIError>> = [
        Resource.ResourceTitleValidator,
        Resource.ResourceAuthorsValidator,
        Resource.ResourceLinkValidator,
    ]

    get link(): string | undefined {
        return this._link;
    }

    set link(value: string | undefined) {
        this._link = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get authors(): string[] {
        return this._authors;
    }

    set authors(value: string[]) {
        this._authors = value;
    }

    get kind(): ResourceKind {
        return this._kind;
    }

    set kind(value: ResourceKind) {
        this._kind = value;
    }

    get archivedOn(): Date | undefined {
        return this._archivedOn;
    }

    set archivedOn(value: Date | undefined) {
        this._archivedOn = value;
    }

    get thumbnail(): string | undefined {
        return this._thumbnail;
    }

    set thumbnail(value: string | undefined) {
        this._thumbnail = value;
    }

    get origin(): ResourceOriginDTO {
        return this._origin;
    }

    set origin(value: ResourceOriginDTO) {
        this._origin = value;
    }

    get composite(): ResourceComposite | undefined {
        return this._composite;
    }

    set composite(value: ResourceComposite | undefined) {
        this._composite = value;
    }

    get TYPE(): EntityKind {
        return this._TYPE;
    }

    set TYPE(value: EntityKind) {
        this._TYPE = value;
    }
}

export const ResourceKindFromStr = (kind: string): ResourceKind => {
    switch (kind.toLowerCase()) {
        case "book":
            return ResourceKind.BOOK;
        case "website":
            return ResourceKind.WEBSITE;
        case "journal":
            return ResourceKind.JOURNAL;
        case "textbook" || "text book":
            return ResourceKind.TEXTBOOK;
        case "unknown":
            return ResourceKind.UNKNOWN;
        case "magazine":
            return ResourceKind.MAGAZINE;
        default:
            return ResourceKind.UNKNOWN;
    }
}

export const ResourceKinds: ResourceKindObj[] = [
    {title: "Book", kind: ResourceKind.BOOK},
    {title: "Journal", kind: ResourceKind.JOURNAL},
    {title: "Website", kind: ResourceKind.WEBSITE},
    {title: "Text Book", kind: ResourceKind.TEXTBOOK},
    {title: "Magazine", kind: ResourceKind.MAGAZINE},
    {title: "Unknown", kind: ResourceKind.UNKNOWN},
];

export const ResourceKindMap: Map<ResourceKind, String> = new Map<
    ResourceKind,
    String
>([
    [ResourceKind.BOOK, "Book"],
    [ResourceKind.JOURNAL, "Journal"],
    [ResourceKind.WEBSITE, "Website"],
    [ResourceKind.TEXTBOOK, "Text Book"],
    [ResourceKind.MAGAZINE, "Magazine"],
    [ResourceKind.UNKNOWN, "Unknown"],
]);