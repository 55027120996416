import React, {createContext, useEffect} from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
// project import
import Loader from 'components/Loader';
import {AuthProps, FirebaseContextType} from 'types/auth';
import {useStores} from "hooks/useStores";
import {AuthenticationStateEnum} from "stores/AuthenticationStore";
import {convertFirebaseGoogleSignInResponseToIGoogleOAuthTokenResponse} from "model/auth";
import {User} from "model/user";

// firebase initialize
export const CARTA_FIREBASE_APP = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

// const
const initialState: AuthProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({children}: { children: React.ReactElement }) => {
    const {authStore} = useStores();

    useEffect(() => {
        console.log("[firebase]: initialize firebase")
            init()
                .then(() => {
                    console.log("[firebase]: init complete")
                })
        }, [authStore]
    );

    const init = async () => {
        const isValid = await authStore.AuthCheck()

        if (!isValid) {
            try {
                const userCredential = await firebaseGoogleSignIn()

                const resp = convertFirebaseGoogleSignInResponseToIGoogleOAuthTokenResponse(userCredential)

                const res = await authStore.AuthPersistGoogleOAuth2Details(resp)
                if (res.ok) {
                    console.log("successfully logged in with backend: ", res.value)
                    authStore.SaveAuthState(res.value)
                } else {
                    console.error("failed to login with backend: ", res.error)
                    authStore.InvalidateAuthState()
                }
            } catch (err) {
                console.log("AuthPersistGoogleOAuth2Details: ", err)
                authStore.InvalidateAuthState()
            }
        } else {
            console.log("user session is valid")
        }
    }


    const firebaseEmailPasswordSignIn = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = async () => {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('openid');

        return await firebase.auth().signInWithPopup(provider)
    };

    const firebaseTwitterSignIn = () => {
        const provider = new firebase.auth.TwitterAuthProvider();
        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseFacebookSignIn = () => {
        const provider = new firebase.auth.FacebookAuthProvider();
        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email: string, password: string) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email: string) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {
    };

    if (authStore.authenticationState.state === AuthenticationStateEnum.LoggedIn && authStore.authenticationState.payload !== null
        // && !state.isInitialized
    ) {
        console.log("Auth loader: ", authStore.authenticationState)
        return <Loader/>;
    } else {
        console.log("Auth: ", authStore.authenticationState)
    }

    return (
        <FirebaseContext.Provider
            value={{
                authenticationState: authStore.authenticationState,
                // firebaseRegister,
                // firebaseEmailPasswordSignIn,
                login: () => {
                    console.log("Welcome!")
                },
                firebaseGoogleSignIn,
                // firebaseTwitterSignIn,
                // firebaseFacebookSignIn,
                logout,
                // resetPassword,
                // updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseContext;
