/**
 * @fileoverview gRPC-Web generated client stub for reviewSM2
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: reviewSM2.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var card_pb = require('./card_pb.js')

var cardLang_pb = require('./cardLang_pb.js')

var stats_pb = require('./stats_pb.js')

var utils_pb = require('./utils_pb.js')
const proto = {};
proto.reviewSM2 = require('./reviewSM2_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reviewSM2.ReviewSM2ServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.CreateReviewSM2Request,
 *   !proto.reviewSM2.CreateReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_create = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/create',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.CreateReviewSM2Request,
  proto.reviewSM2.CreateReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.CreateReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.CreateReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.CreateReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.CreateReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.CreateReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/create',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_create,
      callback);
};


/**
 * @param {!proto.reviewSM2.CreateReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.CreateReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/create',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.GetReviewSM2Request,
 *   !proto.reviewSM2.GetReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_get = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/get',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.GetReviewSM2Request,
  proto.reviewSM2.GetReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.GetReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.GetReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.GetReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.GetReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.GetReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/get',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_get,
      callback);
};


/**
 * @param {!proto.reviewSM2.GetReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.GetReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/get',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.ListReviewSM2Request,
 *   !proto.reviewSM2.ListReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_list = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/list',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.ListReviewSM2Request,
  proto.reviewSM2.ListReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.ListReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.ListReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.ListReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.ListReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.ListReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/list',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_list,
      callback);
};


/**
 * @param {!proto.reviewSM2.ListReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.ListReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/list',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.ListReviewSM2ByIdsRequest,
 *   !proto.reviewSM2.ListReviewSM2ByIdsResponse>}
 */
const methodDescriptor_ReviewSM2Service_listByIds = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/listByIds',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.ListReviewSM2ByIdsRequest,
  proto.reviewSM2.ListReviewSM2ByIdsResponse,
  /**
   * @param {!proto.reviewSM2.ListReviewSM2ByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.ListReviewSM2ByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.ListReviewSM2ByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.ListReviewSM2ByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/listByIds',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_listByIds,
      callback);
};


/**
 * @param {!proto.reviewSM2.ListReviewSM2ByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.ListReviewSM2ByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/listByIds',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.DeleteReviewSM2Request,
 *   !proto.reviewSM2.DeleteReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_delete = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/delete',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.DeleteReviewSM2Request,
  proto.reviewSM2.DeleteReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.DeleteReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.DeleteReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.DeleteReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.DeleteReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.DeleteReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/delete',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_delete,
      callback);
};


/**
 * @param {!proto.reviewSM2.DeleteReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.DeleteReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/delete',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.SaveReviewSM2Request,
 *   !proto.reviewSM2.SaveReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_save = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/save',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.SaveReviewSM2Request,
  proto.reviewSM2.SaveReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.SaveReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.SaveReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.SaveReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.SaveReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.SaveReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.save =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/save',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_save,
      callback);
};


/**
 * @param {!proto.reviewSM2.SaveReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.SaveReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.save =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/save',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_save);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.SaveReviewSM2SessionCardsRequest,
 *   !proto.reviewSM2.SaveReviewSM2SessionCardsResponse>}
 */
const methodDescriptor_ReviewSM2Service_saveReviewSM2SessionCards = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/saveReviewSM2SessionCards',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.SaveReviewSM2SessionCardsRequest,
  proto.reviewSM2.SaveReviewSM2SessionCardsResponse,
  /**
   * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.SaveReviewSM2SessionCardsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.SaveReviewSM2SessionCardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.SaveReviewSM2SessionCardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.saveReviewSM2SessionCards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/saveReviewSM2SessionCards',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_saveReviewSM2SessionCards,
      callback);
};


/**
 * @param {!proto.reviewSM2.SaveReviewSM2SessionCardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.SaveReviewSM2SessionCardsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.saveReviewSM2SessionCards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/saveReviewSM2SessionCards',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_saveReviewSM2SessionCards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.CompleteReviewSM2Request,
 *   !proto.reviewSM2.CompleteReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_completeReviewSM2 = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/completeReviewSM2',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.CompleteReviewSM2Request,
  proto.reviewSM2.CompleteReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.CompleteReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.CompleteReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.CompleteReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.CompleteReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.CompleteReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.completeReviewSM2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/completeReviewSM2',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_completeReviewSM2,
      callback);
};


/**
 * @param {!proto.reviewSM2.CompleteReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.CompleteReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.completeReviewSM2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/completeReviewSM2',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_completeReviewSM2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.UpdateReviewSM2Request,
 *   !proto.reviewSM2.UpdateReviewSM2Response>}
 */
const methodDescriptor_ReviewSM2Service_update = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/update',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.UpdateReviewSM2Request,
  proto.reviewSM2.UpdateReviewSM2Response,
  /**
   * @param {!proto.reviewSM2.UpdateReviewSM2Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.UpdateReviewSM2Response.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.UpdateReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.UpdateReviewSM2Response)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.UpdateReviewSM2Response>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/update',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_update,
      callback);
};


/**
 * @param {!proto.reviewSM2.UpdateReviewSM2Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.UpdateReviewSM2Response>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/update',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.ListCardsToReviewByConfigRequest,
 *   !proto.reviewSM2.ListCardsToReviewByConfigResponse>}
 */
const methodDescriptor_ReviewSM2Service_listCardsToReviewByConfig = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/listCardsToReviewByConfig',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.ListCardsToReviewByConfigRequest,
  proto.reviewSM2.ListCardsToReviewByConfigResponse,
  /**
   * @param {!proto.reviewSM2.ListCardsToReviewByConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.ListCardsToReviewByConfigResponse.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.ListCardsToReviewByConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.ListCardsToReviewByConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.listCardsToReviewByConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/listCardsToReviewByConfig',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_listCardsToReviewByConfig,
      callback);
};


/**
 * @param {!proto.reviewSM2.ListCardsToReviewByConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.ListCardsToReviewByConfigResponse>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.listCardsToReviewByConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/listCardsToReviewByConfig',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_listCardsToReviewByConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.GetReviewSM2StatsRequest,
 *   !proto.reviewSM2.GetReviewSM2StatsResponse>}
 */
const methodDescriptor_ReviewSM2Service_getReviewSM2Stats = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/getReviewSM2Stats',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.GetReviewSM2StatsRequest,
  proto.reviewSM2.GetReviewSM2StatsResponse,
  /**
   * @param {!proto.reviewSM2.GetReviewSM2StatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.GetReviewSM2StatsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.GetReviewSM2StatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.GetReviewSM2StatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.GetReviewSM2StatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.getReviewSM2Stats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/getReviewSM2Stats',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_getReviewSM2Stats,
      callback);
};


/**
 * @param {!proto.reviewSM2.GetReviewSM2StatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.GetReviewSM2StatsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.getReviewSM2Stats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/getReviewSM2Stats',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_getReviewSM2Stats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.ListSM2CardsRequest,
 *   !proto.reviewSM2.ListSM2CardsResponse>}
 */
const methodDescriptor_ReviewSM2Service_listSM2Cards = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/listSM2Cards',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.ListSM2CardsRequest,
  proto.reviewSM2.ListSM2CardsResponse,
  /**
   * @param {!proto.reviewSM2.ListSM2CardsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.ListSM2CardsResponse.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.ListSM2CardsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.ListSM2CardsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.ListSM2CardsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.listSM2Cards =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/listSM2Cards',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_listSM2Cards,
      callback);
};


/**
 * @param {!proto.reviewSM2.ListSM2CardsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.ListSM2CardsResponse>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.listSM2Cards =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/listSM2Cards',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_listSM2Cards);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reviewSM2.GetMostRecentReviewRequest,
 *   !proto.reviewSM2.GetMostRecentReviewResponse>}
 */
const methodDescriptor_ReviewSM2Service_getMostRecentReview = new grpc.web.MethodDescriptor(
  '/reviewSM2.ReviewSM2Service/getMostRecentReview',
  grpc.web.MethodType.UNARY,
  proto.reviewSM2.GetMostRecentReviewRequest,
  proto.reviewSM2.GetMostRecentReviewResponse,
  /**
   * @param {!proto.reviewSM2.GetMostRecentReviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reviewSM2.GetMostRecentReviewResponse.deserializeBinary
);


/**
 * @param {!proto.reviewSM2.GetMostRecentReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reviewSM2.GetMostRecentReviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reviewSM2.GetMostRecentReviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reviewSM2.ReviewSM2ServiceClient.prototype.getMostRecentReview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/getMostRecentReview',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_getMostRecentReview,
      callback);
};


/**
 * @param {!proto.reviewSM2.GetMostRecentReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reviewSM2.GetMostRecentReviewResponse>}
 *     Promise that resolves to the response
 */
proto.reviewSM2.ReviewSM2ServicePromiseClient.prototype.getMostRecentReview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reviewSM2.ReviewSM2Service/getMostRecentReview',
      request,
      metadata || {},
      methodDescriptor_ReviewSM2Service_getMostRecentReview);
};


module.exports = proto.reviewSM2;

