import {action, makeObservable, observable, ObservableMap, runInAction, toJS,} from "mobx";
import {
    convertDTOToTopicGraph,
    convertTopicRelationDataToDTO,
    convertTopicToDTO,
    sanitizeTopic,
    Topic,
    TopicStats,
    validateTopic,
} from "../model/topic";
import {
    ActionType,
    InternalError,
    InternalErrorTypes,
    isError,
    IUIError,
    LogError,
    NewInternalError,
    NewUIError,
    NewUIErrorV2, UIErrorV2,
} from "../service/cartaError";
import {ListOptionsRequestDTO, SearchFieldsDTO, UUID_DTO,} from "../proto/utils_pb";
import {RelationshipAction, TopicGraph, TopicRelation, TopicRelationshipData,} from "../model/graph";
import {
    CreateCardRelationRequest,
    CreateTopicRelationshipRequest,
    CreateTopicRelationshipResponse, GetTopicGlobalStatsRequest,
    GetTopicGraphRequest,
    GetTopicGraphResponse,
    GetTopicStatRequest,
    GetTopicStatResponse,
    ListTopicForCardRequest,
    ListTopicForCardResponse,
    ListTopicRelationshipRequest,
    ListTopicRelationshipResponse,
    RelTopicDSDTO, StatPerformanceTopicDTO,
    TopicDTO,
    TopicRelationshipEnumDTO,
    TopicRelationshipGraphDTO,
    UpdateTopicRequest,
    UpdateTopicResponse,
} from "../proto/topic_pb";
import {Resource} from "../model/resource/Resource";
import {Card} from "../model/Card";
import {
    CARTA_PROXY_URL,
    DEFAULT_TOPIC_RELATIONSHIP_DEPTH,
    IsUUIDValid,
    ListItem,
    MAX_TOPIC_RELATIONSHIP_DEPTH, NewUUID,
    sanitizeListOptions,
    UUIDDTOToID
} from "../utils/utils";
import {getAuthToken, getUserId} from "../service/AuthService";
import {TopicServicePromiseClient} from "../proto/topic_grpc_web_pb";
import {cardClient, CardStore, StatPerformanceCard} from "./CardStore";
import {IFromDTO} from "../model/model";
import {RelTopic, RelTopicDepth} from "../model/RelTopic";
import {TopicGRPCImpl, TopicService} from "../service/TopicService";
import {ListCardsForTopicRequest, ListCardsForTopicResponse} from "../proto/card_pb";
import BaseStore from "./BaseStore";
import {Err, Ok, Result} from "../utils/result";
import {EntityKind, ValidListable} from "../model/BaseModel";
import {GetGeneralStatsRequest, StatOptsDTO} from "proto/stats_pb";
import {GeneralStat} from "stores/StatsStore";

const SimpleUnaryInterceptor = function () {
};

/** @override */
SimpleUnaryInterceptor.prototype.intercept = function (request: any, invoker: any) {
    // Update the request message before the RPC.
    const reqMsg = request.getRequestMessage();
    reqMsg.setMessage('[Intercept request]' + reqMsg.getMessage());


    // After the RPC returns successfully, update the response.
    return invoker(request).then((response: any) => {
        // You can also do something with response metadata here.


        // Update the response message.
        const responseMsg = response.getResponseMessage();
        responseMsg.setMessage('[Intercept response]' + responseMsg.getMessage());

        return response;
    });
};

// message StatPerformanceTopicDTO {
//     topic.TopicDTO topic = 1;
//     int32 totalReviewCount = 2;
//     int32 totalManualReviewCount = 3;
//     int32 totalSM2ReviewCount = 4;
//     int32 avgConfidence = 5;
// }
export class StatPerformanceTopic implements IFromDTO<StatPerformanceTopicDTO>, ValidListable {
    public topic: Topic
    public totalReviewCount: number
    public totalManualReviewCount: number
    public totalSM2ReviewCount: number
    public avgConfidence: number

    constructor() {
        this.topic = new Topic()
        this.totalReviewCount = 0
        this.totalManualReviewCount = 0
        this.totalSM2ReviewCount = 0
        this.avgConfidence = 0
        this.id = NewUUID()
    }

    id: string;

    toListItem(): ListItem {
        return {
            id: this.topic.id,
            title: this.topic.topic,
            metadata1: `${this.totalReviewCount}`,
            metadata2: `${this.totalManualReviewCount}`,
            metadata3: `${this.totalSM2ReviewCount}`,
            metadata4: `${this.avgConfidence}`,
        } as ListItem
    }

    fromDTO(t: StatPerformanceTopicDTO): void | IUIError {
        this.topic.fromDTO(t.getTopic()!)
        this.totalReviewCount = t.getTotalreviewcount()
        this.totalManualReviewCount = t.getTotalmanualreviewcount()
        this.totalSM2ReviewCount = t.getTotalsm2reviewcount()
        this.avgConfidence = t.getAvgconfidence()

    }
}

interface TopicGlobalStats {
    mostReviewed: StatPerformanceTopic[]
    strongest: StatPerformanceTopic[]
    weakest: StatPerformanceTopic[]
}

const topicClient = new TopicServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {'withCredentials': true}
);

export class TopicStore extends BaseStore<Topic, TopicDTO, TopicGRPCImpl, TopicService> {
    // public topics: Topic[] = [];
    // topic - Resource[]
    public topicCardMap: ObservableMap<string, Card[]> = new ObservableMap<
        string,
        Card[]
    >();
    // public topicMap: ObservableMap<string, Topic> = new ObservableMap<
    // 	string,
    // 	Topic
    // 	>();
    public resourcesMap: ObservableMap<string, Resource[]> = new ObservableMap<
        string,
        Resource[]
    >();
    public statsMap: ObservableMap<string, TopicStats> = new ObservableMap<
        string,
        TopicStats
    >();
    public selectedTopic: Topic | null = null;
    public selectedTopicRelations: TopicRelationshipData | null = null;
    public relations: TopicRelationshipData = new TopicRelationshipData();
    public service: TopicService;
    cardStore: CardStore;

    public mostReviewed: StatPerformanceTopic[] = [];
    public strongest: StatPerformanceTopic[] = [];
    public weakest: StatPerformanceTopic[] = [];
    
    constructor(service: TopicService, cardStore: CardStore, graph?: TopicRelationshipData) {
        super(service, Topic);
        
        makeObservable(this, {
            // topics: observable,
            relations: observable,
            selectedTopic: observable,
            statsMap: observable,
            topicCardMap: observable,
            resourcesMap: observable,
            mostReviewed: observable,
            strongest: observable,
            weakest: observable,
            // getGraph: computed,
            
            getTopicGraph: action,
            createTopicRelationship: action,
            deleteRelationship: action,
            setSelectedTopic: action,
            getTopicStats: action,
            handleTopicCardChange: action,
        });
        
        this.service = new TopicService();
        this.cardStore = cardStore;
        // this.resourcesMap = new Map<string, Resource[]>();
        // this.statsMap = new Map<string, TopicStats>();
        
        if (graph) {
            this.relations = graph;
        }
    }
    
    public setSelectedTopic = (topic: Topic | null) => {
        runInAction(() => {
            this.selectedTopic = topic;
        });
    };
    
    public getTopicGraph = async (
        limit: number,
        offset: number
    ): Promise<TopicRelationshipData | IUIError> => {
        const errorKind = InternalErrorTypes.GetTopicGraph;
        const origin = "getTopicGraph";
        
        let req: GetTopicGraphRequest = new GetTopicGraphRequest();
        req.setOffset(offset);
        req.setLimit(limit);
        
        try {
            const response: GetTopicGraphResponse = await topicClient.getTopicGraph(
                req,
            );
            
            const res = convertDTOToTopicGraph(
                response.getGraph() as TopicRelationshipGraphDTO
            );
            
            if (isError(res)) {
                return NewUIError(
                    "GetTopicGraph",
                    InternalErrorTypes.ConvertTopicGraph,
                    "Failed to parse/convert topic graph",
                    undefined,
                    res as InternalError
                );
            }
            
            const graph = res as TopicRelationshipData;
            
            runInAction(() => {
                this.relations = graph;
            });
            
            return graph;
        } catch (e) {
            return NewUIError(origin, errorKind, `failed to create entity: %v ${e}`);
        }
    };
    
    public newTopicFromStr = (topicStr: string): Result<Topic, IUIError> => {
        if (!topicStr) {
            return Err(
                NewUIError(
                    "newTopicFromStr",
                    InternalErrorTypes.CreateTopic,
                    "topic string is empty"
                )
            )
        }
        
        let topic = new Topic();
        topic.userId = getUserId();
        topic.topic = topicStr;
        
        return Ok(topic)
    }

    async GetGlobalStats(): Promise<Result<TopicGlobalStats, IUIError>> {
        let req: GetTopicGlobalStatsRequest = new GetTopicGlobalStatsRequest();

        const userId = getUserId()
        if (userId) {
            req.setUserid(new UUID_DTO().setValue(userId))
        } else {
            throw new UIErrorV2(ActionType.GetStats, EntityKind.Stats, "User ID not found")
        }

        try {
            let res = await topicClient.getTopicGlobalStats(req)

            let mostReviewed: StatPerformanceTopic[] = [];
            let strongest: StatPerformanceTopic[] = [];
            let weakest: StatPerformanceTopic[] = [];

            res.getMostreviewedList().forEach((dto) => {
                let stat = new StatPerformanceTopic();
                stat.fromDTO(dto)

                mostReviewed.push(stat)
            })

            runInAction(() => {
                this.mostReviewed = mostReviewed;
            });

            res.getStrongestList().forEach((dto) => {
                let stat = new StatPerformanceTopic();
                stat.fromDTO(dto)

                strongest.push(stat)
            })

            runInAction(() => {
                this.strongest = strongest;
            });

            res.getWeakestList().forEach((dto) => {
                let stat = new StatPerformanceTopic();
                stat.fromDTO(dto)

                weakest.push(stat)
            })

            runInAction(() => {
                this.weakest = weakest;
            });

            return Ok(
                {
                    mostReviewed: mostReviewed,
                    strongest: strongest,
                    weakest: weakest
                }
            )
        } catch (err) {
            return Err(NewUIErrorV2(ActionType.GetStats, EntityKind.Stats, err));
        }
    }
    
    // public createTopic = async (topic: Topic): Promise<Topic | IUIError> => {
    // 	const errorKind = InternalErrorTypes.CreateTopic;
    // 	const origin = "createTopic";
    //
    // 	topic.userId = getUserId();
    // 	let validation = validateTopic(topic);
    //
    // 	if (isError(validation)) {
    // 		return validation as IUIError;
    // 	}
    // 	let dto = convertTopicToDTO(validation as Topic);
    //
    // 	this.service.
    //
    // 	let req = new CreateTopicRequest();
    // 	req.setTopic(dto);
    //
    // 	let token = getAuthToken();
    // 	let meta = { "x-grpc-authorization": token };
    //
    // 	try {
    // 		const response: CreateTopicResponse = await topicClient.Create(
    // 			req,
    // 			meta
    // 		);
    //
    // 		if (response.getTopic() === undefined) {
    // 			return NewUIError(
    // 				origin,
    // 				errorKind,
    // 				`created entity response is undefined`
    // 			);
    // 		}
    //
    // 		const topic = new Topic();
    // 		const err = topic.fromDTO(response.getTopic() as TopicDTO);
    //
    // 		if (!err) {
    // 			runInAction(() => {
    // 				this.topicMap.set(topic.id, topic);
    // 				this.relations.modify(RelationshipAction.INSERT, [topic]);
    // 			});
    //
    // 			return topic;
    // 		} else {
    // 			return NewUIError(
    // 				origin,
    // 				errorKind,
    // 				`failed to convert returned entity: ${topic}`,
    // 				undefined,
    // 				err as IUIError
    // 			);
    // 		}
    // 	} catch (err) {
    // 		return NewUIError(
    // 			origin,
    // 			errorKind,
    // 			`failed to create entity: %v ${err}`
    // 		);
    // 	}
    // };
    
    public createTopicRelationship = async (
        focusTopic: Topic,
        relationships: TopicRelationshipData
    ): Promise<void | IUIError> => {
        let dto = convertTopicRelationDataToDTO(relationships);
        
        focusTopic = sanitizeTopic(focusTopic);
        
        let req = new CreateTopicRelationshipRequest();
        req.setGraph(dto);
        
        let token = getAuthToken();
        let meta = {"x-grpc-authorization": token};
        
        try {
            const response: CreateTopicRelationshipResponse =
                await topicClient.createTopicRelationship(req, meta);
            
            if (response.getGraph() === undefined) {
                return NewUIErrorV2(
                    ActionType.Save,
                    EntityKind.RelTopic,
                    undefined,
                        `returned topic graph is undefined`,
                    undefined,
                );
            }
            
            let res = convertDTOToTopicGraph(
                response.getGraph() as TopicRelationshipGraphDTO
            );
            
            if (!isError(res)) {
                const relationshipData = res as TopicRelationshipData;
                
                runInAction(() => {
                    const topicArr = Array.from(relationshipData.topics.values());
                    this.relations.modify(
                        RelationshipAction.UPDATE,
                        Array.from(relationshipData.topics.values()),
                        relationshipData.graph
                    );
                    
                    topicArr.forEach((topic) => {
                        this.map.set(topic.id, topic);
                    });
                });
            } else {
                return NewUIErrorV2(
                    ActionType.Save,
                    EntityKind.RelTopic,
                    res as InternalError,
                    `failed to create topic relationship: base topic (Value = topic: ${focusTopic})`,
                    undefined,
                );
            }
        } catch (err) {
            return NewUIErrorV2(
                ActionType.Save,
                EntityKind.RelTopic,
                `[panic]: failed to create topic relationship: base topic (Value = topic: ${focusTopic}) - relationship: ${relationships}`
            );
        }
    };
    
    
    public fetchLocalTopicsByIds = (ids: string[]): Topic[] => {
        let topics: Topic[] = [];
        
        ids.forEach((x) => {
            if (this.map.has(x)) {
                topics.push(this.map.get(x)!)
            }
        })
        
        return topics
    }
    

    public deleteRelationship = async (
        id1: string,
        id2: string,
        rel: TopicRelationshipEnumDTO
    ): Promise<void | IUIError> => {
        try {
            let res = await this.service.DeleteRelationship(id1, id2, rel);
            
            if (res === null) {
                runInAction(() => {
                    if (this.relations) {
                        let id1Rels = this.relations.graph.get(id1);
                        
                        if (id1Rels) {
                            let index = id1Rels.findIndex(
                                (x) => x.id === id2 && x.relationship === rel
                            );
                            id1Rels.splice(index, 1);
                        }
                    }
                    return;
                });
            }
            
            return res as IUIError;
        } catch (err) {
            return NewUIError(
                "deleteRelationship",
                InternalErrorTypes.ListTopics,
                `failed to delete relationship: ${err}`
            );
        }
    };
    
    public updateTopic = async (topic: Topic): Promise<Topic | IUIError> => {
        const errorKind = InternalErrorTypes.UpdateTopic;
        const origin = "updateTopic";
        
        topic.userId = getUserId();
        
        let validation = validateTopic(topic);
        if (isError(validation)) {
            return validation as IUIError;
        }
        
        let dto = convertTopicToDTO(validation as Topic);
        
        let req = new UpdateTopicRequest();
        req.setTopic(dto);
        
        let token = getAuthToken();
        let meta = {"x-grpc-authorization": token};
        
        try {
            const response: UpdateTopicResponse = await topicClient.update(
                req,
                meta
            );
            
            if (response.getTopic() === undefined) {
                return NewUIError(
                    origin,
                    errorKind,
                    `updated topic response is undefined`
                );
            }
            
            const topic = new Topic();
            const err = topic.fromDTO(response.getTopic() as TopicDTO);
            
            if (!err) {
                runInAction(() => {
                    this.relations.modify(RelationshipAction.UPDATE, [topic]);
                    this.map.set(topic.id, topic);
                });
                
                return topic;
            }
            return NewUIError(
                origin,
                errorKind,
                `failed to convert topic: ${topic}`,
                undefined,
                err as IUIError
            );
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `failed to delete relationship: ${err}`
            );
        }
    };
    
    public fetchCardsForTopic = async (
        topicId: string,
        pageLimit: number,
        pageNumber: number,
        searchText?: string
    ): Promise<Card[] | IUIError> => {
        const errorKind = InternalErrorTypes.TopicCardRelation;
        const origin = "fetchCardsForTopic";
        
        let opts: ListOptionsRequestDTO = new ListOptionsRequestDTO();
        opts.setLimit(pageLimit);
        opts.setOffset(pageNumber);
        
        const newOpts = sanitizeListOptions(opts);
        
        const req = new ListCardsForTopicRequest();
        req.setTopicid(new UUID_DTO().setValue(topicId));
        req.setOpts(newOpts);
        
        const token = getAuthToken();
        const meta = {"x-grpc-authorization": token};
        
        try {
            const response: ListCardsForTopicResponse =
                await cardClient.listCardsForTopic(req, meta);
            
            const cardDTOS = response.getCardsList();
            
            if (!cardDTOS) {
                return NewUIError(
                    origin,
                    errorKind,
                    "returned value undefined",
                    "failed to fetch cards"
                );
            }
            
            let cards: Card[] = [];
            
            cardDTOS.forEach((dto) => {
                let card = new Card();
                const err = card.fromDTO(dto)
                if (err) {
                    LogError(
                        origin,
                        errorKind,
                        "failed to validate object",
                        err
                    );
                    
                } else {
                    cards.push(card as Card);
                }
            });
            
            runInAction(() => {
                this.topicCardMap.set(topicId, cards);
            });
            
            return cards;
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `store: failed to fetch cards: Err(Value = ${err}`
            );
        }
    };
    
    public addCardForTopic = async (
        topicId: string,
        card: Card
    ): Promise<Card | IUIError> => {
        const errorKind = InternalErrorTypes.CreateCard;
        const origin = "addCardForTopic";
        
        const parse = toJS(card);
        
        try {
            ;
            let res = await this.cardStore.createCardWithTopicRelation(
                parse,
                topicId
            );
            
            if (!isError(res)) {
                const card = res as Card;
                
                runInAction(() => {
                    let cardsMap = this.topicCardMap.get(topicId);
                    if (cardsMap) {
                        cardsMap.push(res as Card);
                    }
                });
                return card;
            } else {
                return res as IUIError;
            }
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `store: failed to fetch cards: Err(Value = ${err})`
            );
        }
    };
    
    public linkCardToTopic = async (topicId: string, cardId: string) => {
        const errorKind = InternalErrorTypes.TopicCardRelation;
        const origin = "linkCardToTopic";
        
        if (!IsUUIDValid(topicId)) {
            return NewUIError(
                origin,
                errorKind,
                `supplied topicId: ${topicId} is invalid`
            );
        }
        if (!IsUUIDValid(cardId)) {
            return NewUIError(
                origin,
                errorKind,
                `supplied cardId: ${cardId} is invalid`
            );
        }
        
        try {
            const req = new CreateCardRelationRequest();
            req.setTopicid(new UUID_DTO().setValue(topicId));
            req.setCardid(new UUID_DTO().setValue(cardId));
            
            const token = getAuthToken();
            const meta = {"x-grpc-authorization": token};
            
            await topicClient.createCardRelationship(req, meta);
        } catch (err) {
            NewInternalError(
                "addCardForTopic",
                InternalErrorTypes.CreateCard,
                `store: failed to fetch cards: Err(Value = ${err})`
            );
        }
    };
    
    public listTopicRelationships = async (
        topicIds: string[], depth?: number
    ): Promise<RelTopicDS | IUIError> => {
        const errorKind = InternalErrorTypes.TopicRelation;
        const origin = "listTopicRelationships";

        console.log("topicIds", topicIds)

        if (topicIds.length < 1) {
            return NewUIError(origin, errorKind, `no supplied topicIDs`);
        }

        const req = new ListTopicRelationshipRequest();
        
        if (depth && depth > MAX_TOPIC_RELATIONSHIP_DEPTH) {
            depth = MAX_TOPIC_RELATIONSHIP_DEPTH
        }
        if (depth === undefined) {
            depth = DEFAULT_TOPIC_RELATIONSHIP_DEPTH
        }
        
        topicIds.forEach((topicId) => {
            if (!IsUUIDValid(topicId)) {
                return NewUIError(
                    origin,
                    errorKind,
                    `supplied topicId: ${topicId} is invalid`
                );
            }
            let newId = new UUID_DTO().setValue(topicId);
            req.addTopicids(newId);
        });
        req.setDepth(depth)
        
        try {
            let res: ListTopicRelationshipResponse =
                await topicClient.listTopicRelationship(req);
            
            if (!res.getRelationships()) {
                return NewUIError(
                    origin,
                    errorKind,
                    `topic relationships is undefined for topic ${topicIds}`,
                    undefined
                );
            }
            
            const ds = new RelTopicDS();
            const err = ds.fromDTO(res.getRelationships()!);
            if (err) {
                return NewUIError(
                    origin,
                    errorKind,
                    `topic relationships is undefined for topic ${topicIds}`,
                    undefined
                );
            }
            

            runInAction(() => {
                ds.topicMap.forEach((topic, key) => {
                    this.map.set(key, topic);
                });
            });
            
            return ds;
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `store: failed to fetch cards: Err(Value = ${JSON.stringify(err)})`
            );
        }
    };
    
    public getTopicStats = async (
        topicId: string
    ): Promise<TopicStats | IUIError> => {
        if (!IsUUIDValid(topicId)) {
            return NewUIError(
                "getTopicStats",
                InternalErrorTypes.CardTagRelation,
                `supplied topicId: ${topicId} is invalid`
            );
        }
        
        let req: GetTopicStatRequest = new GetTopicStatRequest();
        req.setTopicid(new UUID_DTO().setValue(topicId));
        
        let token = getAuthToken();
        let meta = {"x-grpc-authorization": token};
        try {
            const response: GetTopicStatResponse = await topicClient.getTopicStats(
                req,
                meta
            );
            
            if (!response.getStats()) {
                return NewUIError(
                    "GetTopicStats",
                    InternalErrorTypes.GetTopicStat,
                    `topic stat is undefined for topic ${topicId}`,
                    undefined
                );
            }
            let stats = new TopicStats();
            stats.fromDTO(response.getStats()!);
            
            runInAction(() => {
                if (this.relations) {
                    this.statsMap.set(topicId, stats);
                    
                    ;
                }
            });
            
            return stats;
        } catch (err) {
            return NewUIError(
                "deleteTopic",
                InternalErrorTypes.DeleteTopic,
                `failed to delete relationship: ${err}`
            );
        }
    };
    
    public listTopicsForCard = async (
        card_id: string
    ): Promise<Topic[] | IUIError> => {
        const errorKind = InternalErrorTypes.ListTopicsForCard;
        const origin = "listTopicsForCard";
        
        let req: ListTopicForCardRequest = new ListTopicForCardRequest();
        
        if (!IsUUIDValid(card_id)) {
            return NewUIError(
                origin,
                errorKind,
                `supplied cardId: ${card_id} is invalid`
            );
        }
        
        let token = getAuthToken();
        let meta = {"x-grpc-authorization": token};
        
        try {
            const response: ListTopicForCardResponse = await topicClient.listTopicsForCard(
                req,
                meta
            );
            
            let topics: Topic[] = [];
            
            (response.getTopicsList() as TopicDTO[]).forEach((item, index) => {
                const topic = new Topic();
                const err = topic.fromDTO(item);
                
                if (!err) {
                    topics.push(topic as Topic);
                } else {
                    return NewUIError(
                        origin,
                        errorKind,
                        `failed to convert ${topic}`,
                        undefined,
                        err as IUIError
                    );
                }
            });
            
            runInAction(() => {
                topics.forEach((topic) => {
                    this.map.set(topic.id, topic);
                });
            });
            
            return topics;
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `failed to list topic(s) for card: %v: ${JSON.stringify(err)}`
            );
        }
    };
    
    concatRelationshipData(
        graph: TopicRelationshipData,
        graph2: TopicRelationshipData
    ): TopicRelationshipData {
        let newGraph = new TopicRelationshipData();
        
        const createMapKey = (relation: TopicRelation): string =>
            `${relation.id}+${relation.relationship}`;
        
        ///////////////////// Handle Graph Map ///////////////////
        // Add the first graphs items
        graph.graph.forEach((relations, key) => {
            newGraph.graph.set(key, relations);
        });
        
        // Logic for 2nd graph
        graph2.graph.forEach((relations, key) => {
            // load graph2 and newGraph into map to search for duplicates
            let graphMap: Map<string, TopicRelation> = new Map();
            relations.forEach((relation) => {
                graphMap.set(createMapKey(relation), relation);
            });
            
            newGraph.graph.forEach((relations, key) => {
                relations.forEach((relation) => {
                    graphMap.set(createMapKey(relation), relation);
                });
            });
            
            newGraph.graph.set(key, Array.from(graphMap.values()));
        });
        
        ///////////////////// Handle Topic Map ///////////////////
        graph.topics.forEach((topic, key) => {
            newGraph.topics.set(key, topic);
        });
        
        graph2.topics.forEach((topic, key) => {
            newGraph.topics.set(key, topic);
        });
        
        return newGraph;
    }
    
    populateFromTopics = (topic: string, data: Card[]) => {
        if (!this.topicCardMap.has(topic)) {
            this.topicCardMap.set(topic, data);
        }
    };
    
    public handleTopicCardChange(topicId: string, card: Card) {
        const existingTopic = this.topicCardMap.get(topicId);
        if (existingTopic) {
            const index = existingTopic.findIndex((x) => x.id === card.id);
            if (index > -1) {
                existingTopic[index] = card;
            } else {
                existingTopic.push(card);
            }
        }
    }
    
    get getRelations(): TopicRelationshipData {
        return this.relations;
    }
    
    get topics(): Map<string, Topic> {
        return this.relations.topics;
    }
    
    get getTopicsArray(): Topic[] {
        return Array.from(this.map.values());
    }
    
    get getGraph(): TopicGraph {
        const topicGraph = TopicGraph.new();
        topicGraph.fromRelationshipData(this.relations);
        
        return topicGraph;
    }
}

/**
 * `RelTopicDS` represents the underlying Topic Graph Datastructures containing the root topics, as well as the
 * topics related to the root topics. This is most useful for visualization.
 *
 * The `topicMap` provides a mapping between the ids in the other fields and the topic models themselves.
 */
export class RelTopicDS implements IFromDTO<RelTopicDSDTO> {
    // `_roots` is a list of the root topics, this can be empty, but if you are trying to show a graph or subgraph with a definite
    // root, it is helpful to have this populated
    private _roots: Topic[] = [];
    // _relTopicList is good.
    private _relTopicList: RelTopicDepth[] = [];
    // This relates a topic id to the topic Topic itself
    private _topicMap: Map<string, Topic> = new Map<string, Topic>();
    
    fromDTO(t: RelTopicDSDTO): void | IUIError {
        t.getTopicmapMap().forEach((value: TopicDTO, key: string) => {
            const topic = new Topic();
            const err = topic.fromDTO(value);
            
            if (!err) {
                this._topicMap.set(key, topic);
            } else {
                LogError(
                    "fromDTO",
                    InternalErrorTypes.InvalidTopic,
                    "",
                    err as IUIError
                );
            }
        });
        
        t.getReltopicsList().forEach((x) => {
            let relTopicDepth = new RelTopicDepth();
            
            if (x.getReltopics()) {
                const err = relTopicDepth.fromDTO(x);
                if (err) {
                    LogError("fromDTO", InternalErrorTypes.InvalidTopic, "", err);
                } else {
                    const topic1 = this.topicMap.get(relTopicDepth.relTopic.topic1Id)
                    const topic2 = this.topicMap.get(relTopicDepth.relTopic.topic2Id)
                    if (topic1) {
                        relTopicDepth.relTopic.topic1 = topic1;
                    }
                    if (topic2) {
                        relTopicDepth.relTopic.topic2 = topic2;
                    }
                    // relTopicDepth.setTopics(this._topicMap) // This is how we populated the topic fields with actual topics instead of ids ... may not be necessary
                    this._relTopicList.push(relTopicDepth);
                }
            }
        });
        
        t.getRootidsList().forEach((x) => {
            const id = UUIDDTOToID(x);
            const root = this._topicMap.get(id);
            
            if (root) {
                this._roots.push(root as Topic);
            } else {
                LogError(
                    "fromDTO",
                    InternalErrorTypes.InvalidTopic,
                    `unknown error: failed to get rel topic root: id ${id} from topic map`
                );
            }
        });
    }
    
    public fromTopicRelationshipData(relData: TopicRelationshipData, roots: Topic[]): IUIError | void {
        this._topicMap = relData.topics;
        this._roots = roots
        
        let userId = ""
        
        let relTopicDepths: RelTopicDepth[] = []
        
        Array.from(relData._graph.entries())
            .forEach(([protagonistId, topicRelations]) => {
                let depth = new RelTopicDepth();
                depth.depth = -1;
                depth.parent_id = protagonistId;
                
                
                topicRelations.forEach((supportingActorId) => {
                    let relTopic = new RelTopic();
                    relTopic.topic1Id = protagonistId
                    relTopic.topic2Id = supportingActorId.id
                    relTopic.relationship = supportingActorId.relationship;
                    
                    const protagonist = relData.topics.get(protagonistId);
                    if (!protagonist) {
                        return NewUIError("fromTopicRelationshipData", InternalErrorTypes.InvalidTopicGraph, `protagonist topic ${protagonistId} not found in topic map`)
                    }
                    
                    const supportingActor = relData.topics.get(supportingActorId.id);
                    if (!supportingActor) {
                        return NewUIError("fromTopicRelationshipData", InternalErrorTypes.InvalidTopicGraph, `supporting actor topic ${supportingActorId.id} not found in topic map`)
                    }
                    
                    relTopic.topic1 = protagonist
                    relTopic.topic2 = supportingActor
                    relTopic.userId = userId;
                    depth.relTopic = relTopic;
                    
                    relTopicDepths.push(depth)
                })
            })
        
        this._relTopicList = relTopicDepths;
    }
    
    get roots(): Topic[] {
        return this._roots;
    }
    
    set roots(value: Topic[]) {
        this._roots = value;
    }
    
    get relTopicList(): RelTopicDepth[] {
        return this._relTopicList;
    }
    
    set relTopicList(value: RelTopicDepth[]) {
        this._relTopicList = value;
    }
    
    get topicMap(): Map<string, Topic> {
        return this._topicMap;
    }
    
    set topicMap(value: Map<string, Topic>) {
        this._topicMap = value;
    }
}
