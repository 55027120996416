import {action, computed, makeObservable, observable, ObservableMap, runInAction,} from "mobx";
import {ActionType, IUIError, NewUIErrorV2} from "../service/cartaError";
import {BaseService} from "../service/BaseService";

import {
    BaseModel,
    DTOCreatorRequestType,
    DTOCreatorResponseType,
    IGRPCService,
    ListResponse,
} from "../model/BaseModel";
import {Err, Ok, Result} from "../utils/result";
import {ListOptionsRequestDTO, UUID_DTO} from "../proto/utils_pb";
import {IsUUIDValid} from "../utils/utils";

export interface IBaseStore<MODEL> {
    Create(model: MODEL): Promise<Result<MODEL, IUIError>>;
    
    List(opts: ListOptionsRequestDTO): Promise<Result<ListResponse<MODEL>, IUIError>>;
    
    ListT?(
        opts: ListOptionsRequestDTO
    ): Promise<Result<ListResponse<MODEL>, IUIError>>;
    
    Get(id: string, m: MODEL): Promise<Result<MODEL | undefined, IUIError>>;
    
    //
    // Update(model: Model): Promise<Model>;
    //
    Delete(id: string): Promise<Result<void, IUIError>>;
}


export abstract class BaseStore<
    MODEL extends BaseModel<MODEL, MODEL_DTO>,
    MODEL_DTO,
    MODEL_CLIENT extends IGRPCService<
        MODEL_DTO,
        DTOCreatorRequestType,
        DTOCreatorResponseType<MODEL_DTO>
    >,
    MODEL_SERVICE extends BaseService<MODEL, MODEL_DTO, MODEL_CLIENT>
> implements IBaseStore<MODEL> {
    public map: ObservableMap<string, MODEL> = new ObservableMap<string, MODEL>();
    
    service: MODEL_SERVICE;
    
    constructor(
        service: MODEL_SERVICE,
        private modelConstructor: new () => MODEL
    ) {
        makeObservable(this, {
            map: observable,
            
            Create: action,
            List: action,
            Get: action,
            Delete: action,
            Update: action,
            GetOneOrFetch: action,
            
            GetList: computed,
        });
        
        this.service = service;
    }
    
    async Create(model: MODEL): Promise<Result<MODEL, IUIError>> {
        const actionType = ActionType.Create;
        
        
        try {
            let res = await this.service.Create(model);
            
            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
            }
            
            const mRes = res.value as MODEL;
            
            runInAction(() => {
                this.map.set(mRes.id, mRes);
            });
            
            return Ok(mRes);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err));
        }
    }
    
    // async CreateFromString(str: string): Promise<Result<MODEL, IUIError>> {
    //     const actionType = ActionType.Create;
    //
    //     
    //     try {
    //         let res = await this.service.Create(model);
    //
    //         if (!res.ok) {
    //             return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
    //         }
    //
    //         const mRes = res.value as MODEL;
    //
    //         runInAction(() => {
    //             this.map.set(mRes.id, mRes);
    //         });
    //
    //         return Ok(mRes);
    //     } catch (err) {
    //         return Err(NewUIErrorV2(actionType, model.TYPE, err));
    //     }
    // }
    
    async Update(model: MODEL): Promise<Result<MODEL, IUIError>> {
        const actionType = ActionType.Update;
        
        try {
            let res = await this.service.Update(model);
            
            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
            }
            
            const mRes = res.value as MODEL;
            
            runInAction(() => {
                this.map.set(mRes.id, mRes);
            });
            
            return Ok(mRes);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err));
        }
    }
    
    async List(opts: ListOptionsRequestDTO): Promise<Result<ListResponse<MODEL>, IUIError>> {
        const actionType = ActionType.List;

        let model: MODEL = new this.modelConstructor();
        try {
            let res = await this.service.List(opts, model);

            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
            }
            
            const mRes = res.value.items as MODEL[];
            
            runInAction(() => {
                mRes.forEach((m: MODEL) => {
                    this.map.set(m.id, m);
                });
            });
            
            return Ok(res.value);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err));
        }
    }

    // GetNextPage = async (opts: ListOptionsRequestDTO): Promise<Result<MODEL[], IUIError>> => {
    //     const mostRecent = this.GetList[this.GetList.length - 1];
    //     opts.setCursorlastseen(convertDateToTimestamp(mostRecent.createdOn));
    //     opts.setCursorlastseencomparator(CursorLastSeenComparator.CURSORLASTSEENCOMPARATOR_AFTER)
    //
    //     return this.List(opts)
    // }
    //
    // GetPreviousPage = async (opts: ListOptionsRequestDTO): Promise<Result<MODEL[], IUIError>> => {
    //     const mostRecent = this.GetList[0];
    //     opts.setCursorlastseen(convertDateToTimestamp(mostRecent.createdOn));
    //     opts.setCursorlastseencomparator(CursorLastSeenComparator.CURSORLASTSEENCOMPARATOR_BEFORE)
    //
    //     return this.List(opts)
    // }
    
    async ListTest(
        opts: ListOptionsRequestDTO
    ): Promise<Result<ListResponse<MODEL>, IUIError>> {
        const actionType = ActionType.List;
        
        let model: MODEL = new this.modelConstructor();
        // model.init();
        
        
        try {
            let res = await this.service.ListT(opts, model);
            
            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
            }
            
            
            
            const mRes = res.value.items as MODEL[];
            
            runInAction(() => {
                mRes.forEach((m: MODEL) => {
                    this.map.set(m.id, m);
                });
            });
            
            return Ok({
                info: res.value.info,
                items: mRes,
            });
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err));
        }
    }
    
    async ListByIDs(ids: string[]): Promise<Result<ListResponse<MODEL>, IUIError>> {
        const actionType = ActionType.ListByIDs;

        let uuids: UUID_DTO[] = []

        ids.forEach((id) => {
            if (!IsUUIDValid(id)) {
                return NewUIErrorV2(actionType, model.TYPE, `invalid uuid: ${id}`)
            }
            uuids.push(new UUID_DTO().setValue(id))
        })

        let model: MODEL = new this.modelConstructor();

        try {
            let res = await this.service.ListByIDs(uuids, model);
            
            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
            }
            
            const mRes = res.value.items as MODEL[];
            
            runInAction(() => {
                mRes.forEach((m: MODEL) => {
                    this.map.set(m.id, m);
                });
            });
            
            return Ok(res.value);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err));
        }
    }
    
    async Delete(id: string): Promise<Result<void, IUIError>> {
        const actionType = ActionType.Delete;
        if (!id) {
            return Promise.reject("id is empty");
        }
        if (!IsUUIDValid(id)) {
            return Promise.reject("id is not valid");
        }
        
        let model: MODEL = new this.modelConstructor();
        // model.init();
        
        
        try {
            let res = await this.service.Delete(id);
            
            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, model.TYPE, res.error));
            }
            
            runInAction(() => {
                this.map.delete(id);
            });
            
            return Ok(undefined);
        } catch (err) {
            return Err(NewUIErrorV2(actionType, model.TYPE, err));
        }
    }
    
    async Get(id: string): Promise<Result<MODEL | undefined, IUIError>> {
        const actionType = ActionType.Get;
        if (!id) {
            return Promise.reject("id is empty");
        }
        if (!IsUUIDValid(id)) {
            return Promise.reject("id is not valid");
        }
        
        let type: MODEL = new this.modelConstructor();
        
        // type.init()
        
        try {
            let res = await this.service.Get(id);
            
            
            
            if (!res.ok) {
                return Err(NewUIErrorV2(actionType, type.TYPE, res.error));
            }
            
            
            
            const mRes = res.value as MODEL;
            
            runInAction(() => {
                this.map.set(mRes.id, mRes);
            });
            
            
            
            return Ok(mRes);
        } catch (err) {
            
            return Err(NewUIErrorV2(actionType, type.TYPE, err));
        }
    }
    
    // This will return the model if it exists in the map, otherwise it will fetch it from the server
    GetOneOrFetch(id: string): Promise<Result<MODEL | undefined, IUIError>> {
        const val = this.map.get(id);
        
        if (val === undefined) {
            return this.Get(id);
        }
        
        return Promise.resolve(Ok(val!));
    }
    
    // This will return the model if it exists in the map, otherwise it will fetch it from the server
    // ListAllOrFetch(
    //     opts: ListOptionsRequestDTO
    // ): Promise<Result<ListResponse<MODEL>, IUIError>> {
    //     const all = this.GetList;
    //     if (all.length === 0) {
    //         return this.List(opts);
    //     }
    //
    //     return Promise.resolve(Ok(this.GetList));
    // }
    
    // This will return the model if it exists in the map, otherwise it will fetch the missing ones from the server
    public ListLocalByIdsOrFetch = async (ids: string[]): Promise<Result<MODEL[], IUIError>> => {
        let models: MODEL[] = [];
        
        let missing = ids.filter((x) => !this.map.has(x));
        
        
        
        ids.forEach((x) => {
            if (this.map.has(x)) {
                models.push(this.map.get(x)!);
            }
        });
        
        if (missing.length > 0) {
            this.ListByIDs(missing)
                .then((res) => {
                    if (res.ok) {
                      models = models.concat(res.value.items);
                      
                      runInAction(() => {
                            res.value.items.forEach((res) => {
                                this.map.set(res.id, res);
                            })
                      })
                    } else {
                        console.log("failed to fetch missing: ", res.error)
                        return Err(NewUIErrorV2(ActionType.ListByIDs, new this.modelConstructor().TYPE, res.error));
                    }
                })
                .catch((err) => {
                    console.log("panic: failed to fetch missing: ", err)
                    return Err(NewUIErrorV2(ActionType.ListByIDs, new this.modelConstructor().TYPE, err));
                });
        }
        
        return Ok(models);
    };
    
    GetById(id: string): MODEL | undefined {
        return this.map.get(id);
    }
    
    get GetList(): MODEL[] {
        return Array.from(this.map.values());
    }
}

export default BaseStore;
