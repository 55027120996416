import {CARTA_PROXY_URL} from "utils/utils";
import {GrpcGoogleAuthServicePromiseClient} from "proto/auth_grpc_web_pb";
import {AuthObj, IGoogleOAuthTokenResponse, JWTToken} from "model/auth";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2} from "service/cartaError";
import {
    GoogleOAuthInfoRequest,
    GoogleOAuthInfoResponse,
    GoogleOAuthTokenDTO,
    GoogleProfileDTO,
    HeartbeatRequest, LogoutRequest
} from "proto/auth_pb";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IFromDTO, IIntoDTO, IValidator} from "model/model";
import {Err, Ok, Result} from "utils/result";
import {EntityKind} from "model/BaseModel";
import {User} from "model/user";
import {
    getUser,
    removeAuthInfoFromLocalStorage,
    retrieveUserFromLocalStorage,
    storeAuthObjInLocalStorage
} from "service/AuthService";
import {UserStore} from "stores/UserStore";

const authClient = new GrpcGoogleAuthServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {'withCredentials': true}
);

export class AuthenticationStore {
    authenticationState: AuthenticationState = {
        state: AuthenticationStateEnum.LoggedOff,
        payload: null
    }

    userStore: UserStore;

    constructor(useStore: UserStore) {
        makeObservable(this, {
            authenticationState: observable,

            AuthPersistGoogleOAuth2Details: action,
            HeartBeat: action,
            AuthCheck: action,
            SetAuthenticationState: action,

            GetUser: computed,
            GetAuthenticationState: computed
        });

        this.userStore = useStore
    }

    SetAuthenticationState = (state: AuthenticationState) => {
        this.authenticationState = state;
    }

    /**
     * Sends the completed OAuth2 details to the backend server to either login/sign-up
     * @param token
     * @constructor
     */
    AuthPersistGoogleOAuth2Details = async (token: IGoogleOAuthTokenResponse): Promise<Result<AuthObj, IUIError>> => {
        const origin = "sendOAuthInfoToBackend"

        let profile = token.profileObj.intoDTO()
        if (isError(profile)) {
            return Err(NewUIError(
                origin,
                InternalErrorTypes.AuthenticationGoogle,
                `failed to convert GoogleProfile to DTO - Err(Value = ${JSON.stringify(profile)})`
            ));
        }

        let tokenReq = token.tokenObj.intoDTO()
        if (isError(tokenReq)) {
            return Err(NewUIError(
                origin,
                InternalErrorTypes.AuthenticationGoogle,
                `failed to convert GoogleOAuthToken to DTO - Err(Value = ${JSON.stringify(tokenReq)})`
            ));
        }

        let oauthInfoReq = new GoogleOAuthInfoRequest();
        oauthInfoReq.setProfile(profile as GoogleProfileDTO);
        oauthInfoReq.setToken(tokenReq as GoogleOAuthTokenDTO);

        try {
            const response: GoogleOAuthInfoResponse =
                await authClient.handleUserOAuthInfo(oauthInfoReq, undefined);

            if (!response.getJwt()) {
                return Err(NewUIError(
                    origin,
                    InternalErrorTypes.AuthenticationGoogle,
                    `authentication JWT response from backend is nil`
                ));
            }

            if (!response.getUserId()) {
                return Err(NewUIError(
                    origin,
                    InternalErrorTypes.AuthenticationGoogle,
                    `authentication userId response from backend is nil`
                ));
            }

            if (!response.getUser()) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "user is empty")
            }

            const token: JWTToken = new JWTToken();
            token.fromDTO(response.getJwt()!)

            if (isError(token)) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.JWTToken, token as unknown as IUIError)
            }

            let user = new User();
            user.fromDTO(response.getUser()!);

            if (isError(user)) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, user as unknown as IUIError)
            }

            runInAction(() => {
                this.SaveAuthState({
                    jwt: token,
                    userId: response.getUserId()!.getValue(),
                    user: user
                });
            })

            return Ok({
                jwt: token,
                userId: response.getUserId()!.getValue(),
                user: user
            } as AuthObj)
        } catch (e) {
            console.error(e)
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                e,
                `failed to send Google OAuth Information to Backend`,
            ));
        }
    }

    AuthCheck = async (): Promise<boolean> => {
        try {
            const resp = await this.HeartBeat();
            if (resp.ok) {
                const heartbeat = resp.value

                if (heartbeat) {
                    // We can confirm the user object is still in storage, if not a call to getMe should work

                    let user = retrieveUserFromLocalStorage();
                    if (user == null) {
                        console.warn("User object not found in local storage despite successful heartbeat ... fetching getMe user.")

                        const fetchedUser = await this.userStore.GetMe();
                        if (fetchedUser.ok) {
                            user = fetchedUser.value;
                        } else {
                            throw new Error("Failed to fetch user from backend");
                        }
                    }

                    runInAction(() => {
                        this.SetAuthenticationState({
                            state: AuthenticationStateEnum.LoggedIn,
                            payload: {
                                user: user,
                            }
                        });
                    })

                    return true;
                } else {
                    return false
                }
            } else {
                // Logout
                console.error("Heartbeat failed, logging out user: ", resp.error)
                this.InvalidateAuthState();

                await this.Logout();

                return false
            }
        } catch (err) {
            console.error(err);

            this.InvalidateAuthState();

            await this.Logout();

            return false
        }
    };

    HeartBeat = async (): Promise<Result<boolean, IUIError>> => {
        let req = new HeartbeatRequest();

        try {
            const response = await authClient.heartbeat(req)

            console.log("Heartbeat response: ", response.getIsAlive())
            return Ok(response.getIsAlive())
        } catch (e) {
            return Err(NewUIErrorV2(
                ActionType.HeartBeat,
                EntityKind.Auth,
                `failed to send heartbeat to backend - Err(Value = ${JSON.stringify(e)})`
            ));
        }
    }

    Logout = async () => {
        let req = new LogoutRequest();
        try {
            await authClient.logout(req)
            removeAuthInfoFromLocalStorage()
            console.log("Successfully logged out")
        } catch (e) {
            console.error("Failed to logout: ", e)

            return Err(NewUIErrorV2(
                ActionType.Logout,
                EntityKind.Auth,
                `failed to logout to backend - Err(Value = ${JSON.stringify(e)})`
            ));
        }
    }

    SaveAuthState = (auth: AuthObj) => {
        this.SetAuthenticationState({
            state: AuthenticationStateEnum.LoggedIn,
            payload: {
                user: auth.user,
            }
        });

        storeAuthObjInLocalStorage(auth);
    }

    InvalidateAuthState = () => {
        this.SetAuthenticationState({
            state: AuthenticationStateEnum.LoggedOff,
            payload: null
        });

        removeAuthInfoFromLocalStorage();
    }

    get GetUser(): User | null {
        if (this.authenticationState.state === AuthenticationStateEnum.LoggedIn) {
            return retrieveUserFromLocalStorage()
        }

        console.log("authenticationState is not logged in", this.authenticationState)

        return null
    }

    get GetAuthenticationState(): AuthenticationState {
        return this.authenticationState
    }
}

export enum AuthenticationStateEnum {
    LoggedIn,
    LoggedOff
}

export interface AuthenticationState {
    state: AuthenticationStateEnum,
    payload: AuthPayload | null
}

interface AuthPayload {
    user: User,
}

export class GoogleOAuthToken implements IFromDTO<GoogleOAuthTokenDTO>, IIntoDTO<GoogleOAuthTokenDTO>, IValidator<GoogleOAuthToken> {
    private _accessToken: string = ""
    private _expiresAt: number = 0
    private _expiresIn: number = 0;
    private _idToken: string = "";
    private _firstIssuedAt: number = 0;
    private _idpId: string = "";
    private _tokenType: string = "";
    private _refreshToken: string = "";

    fromDTO(t: GoogleOAuthTokenDTO): void | IUIError {
        this._accessToken = t.getAccessToken();
        this._expiresAt = t.getExpiresAt();
        this._expiresIn = t.getExpiresIn();
        this._firstIssuedAt = t.getFirstIssuedAt();
        this._idpId = t.getIdpid();
        this._tokenType = t.getTokenType();
        this._refreshToken = t.getRefreshToken();
        this._idToken = t.getIdToken();

        return;
    }

    intoDTO(): GoogleOAuthTokenDTO | IUIError {
        let dto = new GoogleOAuthTokenDTO();
        dto.setAccessToken(this._accessToken);
        dto.setExpiresAt(this._expiresAt);
        dto.setExpiresIn(this._expiresIn);
        dto.setFirstIssuedAt(this._firstIssuedAt);
        dto.setIdToken(this._idToken);
        dto.setIdpid(this._idpId);
        dto.setTokenType(this._tokenType);
        dto.setRefreshToken(this._refreshToken);

        return dto;
    }

    validate(): IUIError | GoogleOAuthToken {
        if (this._accessToken === "") {
            return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "access_token is empty")
        }

        return this
    }

    sanitize(): IUIError | GoogleOAuthToken {
        return this
    }

    get accessToken(): string {
        return this._accessToken;
    }

    set accessToken(value: string) {
        this._accessToken = value;
    }

    get expiresAt(): number {
        return this._expiresAt;
    }

    set expiresAt(value: number) {
        this._expiresAt = value;
    }

    get expiresIn(): number {
        return this._expiresIn;
    }

    set expiresIn(value: number) {
        this._expiresIn = value;
    }

    get idToken(): string {
        return this._idToken;
    }

    set idToken(value: string) {
        this._idToken = value;
    }

    get firstIssuedAt(): number {
        return this._firstIssuedAt;
    }

    set firstIssuedAt(value: number) {
        this._firstIssuedAt = value;
    }

    get idpId(): string {
        return this._idpId;
    }

    set idpId(value: string) {
        this._idpId = value;
    }

    get tokenType(): string {
        return this._tokenType;
    }

    set tokenType(value: string) {
        this._tokenType = value;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    set refreshToken(value: string) {
        this._refreshToken = value;
    }
}

export interface IGoogleAdditionalUserInfo {
    isNewUser: boolean,
    providerId: string,
    profile: {
        email: string,
        family_name: string,
        given_name: string,
        id: string,
        locale: string,
        name: string,
        picture: string,
        verified_email: boolean
    }
}

export interface IGoogleOAuthCredential {
    accessToken: string,
    idToken: string,
    providerId: string,
    signInMethod: string,
    token: string
}

export class GoogleProfile implements IFromDTO<GoogleProfileDTO>, IValidator<GoogleProfile> {

    private _googleId: string = "";
    private _email: string = "";
    private _familyName: string = "";
    private _name: string = "";
    private _givenName: string = "";
    private _imageUrl: string = "";
    private _locale: string = "";
    private _isVerified: boolean = false;
    private _isNewUser: boolean = false;

    fromDTO(t: GoogleProfileDTO): void | IUIError {
        this._googleId = t.getGoogleid();
        this._email = t.getEmail();
        this._familyName = t.getFamilyname();
        this._name = t.getName();
        this._givenName = t.getGivenname();
        this._imageUrl = t.getImageurl();
        return;
    }

    intoDTO(): IUIError | GoogleProfileDTO {
        let dto = new GoogleProfileDTO();
        dto.setGoogleid(this._googleId);
        dto.setEmail(this._email);
        dto.setFamilyname(this._familyName);
        dto.setName(this._name);
        dto.setGivenname(this._givenName);
        dto.setImageurl(this._imageUrl);
        dto.setLocale(this._locale);
        dto.setIsverified(this._isVerified);

        return dto;
    }

    validate(): IUIError | GoogleProfile {
        if (this._googleId === "") {
            return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "google_id is empty")
        }
        if (this._email === "") {
            return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "email is empty")
        }

        return this
    }

    sanitize(): IUIError | GoogleProfile {
        return this
    }

    get googleId(): string {
        return this._googleId;
    }

    set googleId(value: string) {
        this._googleId = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get familyName(): string {
        return this._familyName;
    }

    set familyName(value: string) {
        this._familyName = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get givenName(): string {
        return this._givenName;
    }

    set givenName(value: string) {
        this._givenName = value;
    }

    get imageUrl(): string {
        return this._imageUrl;
    }

    set imageUrl(value: string) {
        this._imageUrl = value;
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get isVerified(): boolean {
        return this._isVerified;
    }

    set isVerified(value: boolean) {
        this._isVerified = value;
    }

    get isNewUser(): boolean {
        return this._isNewUser;
    }

    set isNewUser(value: boolean) {
        this._isNewUser = value;
    }
}

interface InfoRequest {
    token_id: string
    profile: GoogleProfile,
    token: GoogleOAuthToken
}

