// source: cardLang.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var tag_pb = require('./tag_pb.js');
goog.object.extend(proto, tag_pb);
var topic_pb = require('./topic_pb.js');
goog.object.extend(proto, topic_pb);
var resource_pb = require('./resource_pb.js');
goog.object.extend(proto, resource_pb);
var language_pb = require('./language_pb.js');
goog.object.extend(proto, language_pb);
goog.exportSymbol('proto.cardLang.CardLangCompositeDTO', null, global);
goog.exportSymbol('proto.cardLang.CardLangDTO', null, global);
goog.exportSymbol('proto.cardLang.CardReviewFormatDTO', null, global);
goog.exportSymbol('proto.cardLang.CreateCardLangRequest', null, global);
goog.exportSymbol('proto.cardLang.CreateCardLangResponse', null, global);
goog.exportSymbol('proto.cardLang.DeleteCardLangRequest', null, global);
goog.exportSymbol('proto.cardLang.DeleteCardLangResponse', null, global);
goog.exportSymbol('proto.cardLang.GetCardLangRequest', null, global);
goog.exportSymbol('proto.cardLang.GetCardLangResponse', null, global);
goog.exportSymbol('proto.cardLang.ListCardLangByIdsRequest', null, global);
goog.exportSymbol('proto.cardLang.ListCardLangByIdsResponse', null, global);
goog.exportSymbol('proto.cardLang.ListCardLangRequest', null, global);
goog.exportSymbol('proto.cardLang.ListCardLangResponse', null, global);
goog.exportSymbol('proto.cardLang.UpdateCardLangRequest', null, global);
goog.exportSymbol('proto.cardLang.UpdateCardLangResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.CardLangDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.CardLangDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.CardLangDTO.displayName = 'proto.cardLang.CardLangDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.CardLangCompositeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cardLang.CardLangCompositeDTO.repeatedFields_, null);
};
goog.inherits(proto.cardLang.CardLangCompositeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.CardLangCompositeDTO.displayName = 'proto.cardLang.CardLangCompositeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.GetCardLangRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.GetCardLangRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.GetCardLangRequest.displayName = 'proto.cardLang.GetCardLangRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.GetCardLangResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.GetCardLangResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.GetCardLangResponse.displayName = 'proto.cardLang.GetCardLangResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.ListCardLangRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.ListCardLangRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.ListCardLangRequest.displayName = 'proto.cardLang.ListCardLangRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.ListCardLangResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cardLang.ListCardLangResponse.repeatedFields_, null);
};
goog.inherits(proto.cardLang.ListCardLangResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.ListCardLangResponse.displayName = 'proto.cardLang.ListCardLangResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.CreateCardLangRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.CreateCardLangRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.CreateCardLangRequest.displayName = 'proto.cardLang.CreateCardLangRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.CreateCardLangResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.CreateCardLangResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.CreateCardLangResponse.displayName = 'proto.cardLang.CreateCardLangResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.DeleteCardLangRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.DeleteCardLangRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.DeleteCardLangRequest.displayName = 'proto.cardLang.DeleteCardLangRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.DeleteCardLangResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.DeleteCardLangResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.DeleteCardLangResponse.displayName = 'proto.cardLang.DeleteCardLangResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.UpdateCardLangRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.UpdateCardLangRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.UpdateCardLangRequest.displayName = 'proto.cardLang.UpdateCardLangRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.UpdateCardLangResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cardLang.UpdateCardLangResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.UpdateCardLangResponse.displayName = 'proto.cardLang.UpdateCardLangResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.ListCardLangByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cardLang.ListCardLangByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.cardLang.ListCardLangByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.ListCardLangByIdsRequest.displayName = 'proto.cardLang.ListCardLangByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cardLang.ListCardLangByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cardLang.ListCardLangByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.cardLang.ListCardLangByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cardLang.ListCardLangByIdsResponse.displayName = 'proto.cardLang.ListCardLangByIdsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.CardLangDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.CardLangDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.CardLangDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CardLangDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    lang1: (f = msg.getLang1()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    lang2: (f = msg.getLang2()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    lang1Text: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lang2Text: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reviewFormat: jspb.Message.getFieldWithDefault(msg, 7, 0),
    composite: (f = msg.getComposite()) && proto.cardLang.CardLangCompositeDTO.toObject(includeInstance, f),
    createdon: (f = msg.getCreatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedon: (f = msg.getUpdatedon()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.CardLangDTO}
 */
proto.cardLang.CardLangDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.CardLangDTO;
  return proto.cardLang.CardLangDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.CardLangDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.CardLangDTO}
 */
proto.cardLang.CardLangDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setLang1(value);
      break;
    case 4:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setLang2(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang1Text(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang2Text(value);
      break;
    case 7:
      var value = /** @type {!proto.cardLang.CardReviewFormatDTO} */ (reader.readEnum());
      msg.setReviewFormat(value);
      break;
    case 8:
      var value = new proto.cardLang.CardLangCompositeDTO;
      reader.readMessage(value,proto.cardLang.CardLangCompositeDTO.deserializeBinaryFromReader);
      msg.setComposite(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedon(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.CardLangDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.CardLangDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.CardLangDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CardLangDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getLang1();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getLang2();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getLang1Text();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLang2Text();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReviewFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getComposite();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.cardLang.CardLangCompositeDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedon();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedon();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.CardLangDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.CardLangDTO.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.UUID_DTO lang1 = 3;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.CardLangDTO.prototype.getLang1 = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 3));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setLang1 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearLang1 = function() {
  return this.setLang1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasLang1 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional utils.UUID_DTO lang2 = 4;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.CardLangDTO.prototype.getLang2 = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 4));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setLang2 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearLang2 = function() {
  return this.setLang2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasLang2 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string lang1_text = 5;
 * @return {string}
 */
proto.cardLang.CardLangDTO.prototype.getLang1Text = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.setLang1Text = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lang2_text = 6;
 * @return {string}
 */
proto.cardLang.CardLangDTO.prototype.getLang2Text = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.setLang2Text = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional CardReviewFormatDTO review_format = 7;
 * @return {!proto.cardLang.CardReviewFormatDTO}
 */
proto.cardLang.CardLangDTO.prototype.getReviewFormat = function() {
  return /** @type {!proto.cardLang.CardReviewFormatDTO} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.cardLang.CardReviewFormatDTO} value
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.setReviewFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional CardLangCompositeDTO composite = 8;
 * @return {?proto.cardLang.CardLangCompositeDTO}
 */
proto.cardLang.CardLangDTO.prototype.getComposite = function() {
  return /** @type{?proto.cardLang.CardLangCompositeDTO} */ (
    jspb.Message.getWrapperField(this, proto.cardLang.CardLangCompositeDTO, 8));
};


/**
 * @param {?proto.cardLang.CardLangCompositeDTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setComposite = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearComposite = function() {
  return this.setComposite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasComposite = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO createdOn = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.cardLang.CardLangDTO.prototype.getCreatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setCreatedon = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearCreatedon = function() {
  return this.setCreatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasCreatedon = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO updatedOn = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.cardLang.CardLangDTO.prototype.getUpdatedon = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.cardLang.CardLangDTO} returns this
*/
proto.cardLang.CardLangDTO.prototype.setUpdatedon = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangDTO} returns this
 */
proto.cardLang.CardLangDTO.prototype.clearUpdatedon = function() {
  return this.setUpdatedon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangDTO.prototype.hasUpdatedon = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cardLang.CardLangCompositeDTO.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.CardLangCompositeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.CardLangCompositeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.CardLangCompositeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CardLangCompositeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    supertypeId: (f = msg.getSupertypeId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    ord: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lang1: (f = msg.getLang1()) && language_pb.LanguageDTO.toObject(includeInstance, f),
    lang2: (f = msg.getLang2()) && language_pb.LanguageDTO.toObject(includeInstance, f),
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    topic_pb.TopicDTO.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    tag_pb.TagDTO.toObject, includeInstance),
    resourcesList: jspb.Message.toObjectList(msg.getResourcesList(),
    resource_pb.ResourceDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.CardLangCompositeDTO}
 */
proto.cardLang.CardLangCompositeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.CardLangCompositeDTO;
  return proto.cardLang.CardLangCompositeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.CardLangCompositeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.CardLangCompositeDTO}
 */
proto.cardLang.CardLangCompositeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setSupertypeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrd(value);
      break;
    case 3:
      var value = new language_pb.LanguageDTO;
      reader.readMessage(value,language_pb.LanguageDTO.deserializeBinaryFromReader);
      msg.setLang1(value);
      break;
    case 4:
      var value = new language_pb.LanguageDTO;
      reader.readMessage(value,language_pb.LanguageDTO.deserializeBinaryFromReader);
      msg.setLang2(value);
      break;
    case 5:
      var value = new topic_pb.TopicDTO;
      reader.readMessage(value,topic_pb.TopicDTO.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    case 6:
      var value = new tag_pb.TagDTO;
      reader.readMessage(value,tag_pb.TagDTO.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 7:
      var value = new resource_pb.ResourceDTO;
      reader.readMessage(value,resource_pb.ResourceDTO.deserializeBinaryFromReader);
      msg.addResources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.CardLangCompositeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.CardLangCompositeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.CardLangCompositeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CardLangCompositeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupertypeId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getOrd();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLang1();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      language_pb.LanguageDTO.serializeBinaryToWriter
    );
  }
  f = message.getLang2();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      language_pb.LanguageDTO.serializeBinaryToWriter
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      topic_pb.TopicDTO.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      tag_pb.TagDTO.serializeBinaryToWriter
    );
  }
  f = message.getResourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      resource_pb.ResourceDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO supertype_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getSupertypeId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
*/
proto.cardLang.CardLangCompositeDTO.prototype.setSupertypeId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.clearSupertypeId = function() {
  return this.setSupertypeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangCompositeDTO.prototype.hasSupertypeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 ord = 2;
 * @return {number}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getOrd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.setOrd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional language.LanguageDTO lang1 = 3;
 * @return {?proto.language.LanguageDTO}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getLang1 = function() {
  return /** @type{?proto.language.LanguageDTO} */ (
    jspb.Message.getWrapperField(this, language_pb.LanguageDTO, 3));
};


/**
 * @param {?proto.language.LanguageDTO|undefined} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
*/
proto.cardLang.CardLangCompositeDTO.prototype.setLang1 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.clearLang1 = function() {
  return this.setLang1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangCompositeDTO.prototype.hasLang1 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional language.LanguageDTO lang2 = 4;
 * @return {?proto.language.LanguageDTO}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getLang2 = function() {
  return /** @type{?proto.language.LanguageDTO} */ (
    jspb.Message.getWrapperField(this, language_pb.LanguageDTO, 4));
};


/**
 * @param {?proto.language.LanguageDTO|undefined} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
*/
proto.cardLang.CardLangCompositeDTO.prototype.setLang2 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.clearLang2 = function() {
  return this.setLang2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CardLangCompositeDTO.prototype.hasLang2 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated topic.TopicDTO topics = 5;
 * @return {!Array<!proto.topic.TopicDTO>}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.topic.TopicDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, topic_pb.TopicDTO, 5));
};


/**
 * @param {!Array<!proto.topic.TopicDTO>} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
*/
proto.cardLang.CardLangCompositeDTO.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.topic.TopicDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.topic.TopicDTO}
 */
proto.cardLang.CardLangCompositeDTO.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.topic.TopicDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};


/**
 * repeated tag.TagDTO tags = 6;
 * @return {!Array<!proto.tag.TagDTO>}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.tag.TagDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, tag_pb.TagDTO, 6));
};


/**
 * @param {!Array<!proto.tag.TagDTO>} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
*/
proto.cardLang.CardLangCompositeDTO.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.tag.TagDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tag.TagDTO}
 */
proto.cardLang.CardLangCompositeDTO.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.tag.TagDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated resource.ResourceDTO resources = 7;
 * @return {!Array<!proto.resource.ResourceDTO>}
 */
proto.cardLang.CardLangCompositeDTO.prototype.getResourcesList = function() {
  return /** @type{!Array<!proto.resource.ResourceDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, resource_pb.ResourceDTO, 7));
};


/**
 * @param {!Array<!proto.resource.ResourceDTO>} value
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
*/
proto.cardLang.CardLangCompositeDTO.prototype.setResourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.resource.ResourceDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.resource.ResourceDTO}
 */
proto.cardLang.CardLangCompositeDTO.prototype.addResources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.resource.ResourceDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cardLang.CardLangCompositeDTO} returns this
 */
proto.cardLang.CardLangCompositeDTO.prototype.clearResourcesList = function() {
  return this.setResourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.GetCardLangRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.GetCardLangRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.GetCardLangRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.GetCardLangRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.GetCardLangRequest}
 */
proto.cardLang.GetCardLangRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.GetCardLangRequest;
  return proto.cardLang.GetCardLangRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.GetCardLangRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.GetCardLangRequest}
 */
proto.cardLang.GetCardLangRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.GetCardLangRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.GetCardLangRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.GetCardLangRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.GetCardLangRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.GetCardLangRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.GetCardLangRequest} returns this
*/
proto.cardLang.GetCardLangRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.GetCardLangRequest} returns this
 */
proto.cardLang.GetCardLangRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.GetCardLangRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.GetCardLangResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.GetCardLangResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.GetCardLangResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.GetCardLangResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.cardLang.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.GetCardLangResponse}
 */
proto.cardLang.GetCardLangResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.GetCardLangResponse;
  return proto.cardLang.GetCardLangResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.GetCardLangResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.GetCardLangResponse}
 */
proto.cardLang.GetCardLangResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.GetCardLangResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.GetCardLangResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.GetCardLangResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.GetCardLangResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardLangDTO result = 1;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.cardLang.GetCardLangResponse.prototype.getResult = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.cardLang.GetCardLangResponse} returns this
*/
proto.cardLang.GetCardLangResponse.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.GetCardLangResponse} returns this
 */
proto.cardLang.GetCardLangResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.GetCardLangResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.ListCardLangRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.ListCardLangRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.ListCardLangRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    opts: (f = msg.getOpts()) && utils_pb.ListOptionsRequestDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.ListCardLangRequest}
 */
proto.cardLang.ListCardLangRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.ListCardLangRequest;
  return proto.cardLang.ListCardLangRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.ListCardLangRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.ListCardLangRequest}
 */
proto.cardLang.ListCardLangRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.ListOptionsRequestDTO;
      reader.readMessage(value,utils_pb.ListOptionsRequestDTO.deserializeBinaryFromReader);
      msg.setOpts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.ListCardLangRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.ListCardLangRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.ListCardLangRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.ListOptionsRequestDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.ListOptionsRequestDTO opts = 1;
 * @return {?proto.utils.ListOptionsRequestDTO}
 */
proto.cardLang.ListCardLangRequest.prototype.getOpts = function() {
  return /** @type{?proto.utils.ListOptionsRequestDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsRequestDTO, 1));
};


/**
 * @param {?proto.utils.ListOptionsRequestDTO|undefined} value
 * @return {!proto.cardLang.ListCardLangRequest} returns this
*/
proto.cardLang.ListCardLangRequest.prototype.setOpts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.ListCardLangRequest} returns this
 */
proto.cardLang.ListCardLangRequest.prototype.clearOpts = function() {
  return this.setOpts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.ListCardLangRequest.prototype.hasOpts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cardLang.ListCardLangResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.ListCardLangResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.ListCardLangResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.ListCardLangResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.cardLang.CardLangDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.ListCardLangResponse}
 */
proto.cardLang.ListCardLangResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.ListCardLangResponse;
  return proto.cardLang.ListCardLangResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.ListCardLangResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.ListCardLangResponse}
 */
proto.cardLang.ListCardLangResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.ListCardLangResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.ListCardLangResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.ListCardLangResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardLangDTO items = 1;
 * @return {!Array<!proto.cardLang.CardLangDTO>}
 */
proto.cardLang.ListCardLangResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.cardLang.CardLangDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {!Array<!proto.cardLang.CardLangDTO>} value
 * @return {!proto.cardLang.ListCardLangResponse} returns this
*/
proto.cardLang.ListCardLangResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cardLang.CardLangDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cardLang.CardLangDTO}
 */
proto.cardLang.ListCardLangResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cardLang.CardLangDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cardLang.ListCardLangResponse} returns this
 */
proto.cardLang.ListCardLangResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.cardLang.ListCardLangResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.cardLang.ListCardLangResponse} returns this
*/
proto.cardLang.ListCardLangResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.ListCardLangResponse} returns this
 */
proto.cardLang.ListCardLangResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.ListCardLangResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.CreateCardLangRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.CreateCardLangRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.CreateCardLangRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CreateCardLangRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dto: (f = msg.getDto()) && proto.cardLang.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.CreateCardLangRequest}
 */
proto.cardLang.CreateCardLangRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.CreateCardLangRequest;
  return proto.cardLang.CreateCardLangRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.CreateCardLangRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.CreateCardLangRequest}
 */
proto.cardLang.CreateCardLangRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.setDto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.CreateCardLangRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.CreateCardLangRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.CreateCardLangRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CreateCardLangRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDto();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardLangDTO dto = 1;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.cardLang.CreateCardLangRequest.prototype.getDto = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.cardLang.CreateCardLangRequest} returns this
*/
proto.cardLang.CreateCardLangRequest.prototype.setDto = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CreateCardLangRequest} returns this
 */
proto.cardLang.CreateCardLangRequest.prototype.clearDto = function() {
  return this.setDto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CreateCardLangRequest.prototype.hasDto = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.CreateCardLangResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.CreateCardLangResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.CreateCardLangResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CreateCardLangResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dto: (f = msg.getDto()) && proto.cardLang.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.CreateCardLangResponse}
 */
proto.cardLang.CreateCardLangResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.CreateCardLangResponse;
  return proto.cardLang.CreateCardLangResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.CreateCardLangResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.CreateCardLangResponse}
 */
proto.cardLang.CreateCardLangResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.setDto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.CreateCardLangResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.CreateCardLangResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.CreateCardLangResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.CreateCardLangResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDto();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardLangDTO dto = 1;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.cardLang.CreateCardLangResponse.prototype.getDto = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.cardLang.CreateCardLangResponse} returns this
*/
proto.cardLang.CreateCardLangResponse.prototype.setDto = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.CreateCardLangResponse} returns this
 */
proto.cardLang.CreateCardLangResponse.prototype.clearDto = function() {
  return this.setDto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.CreateCardLangResponse.prototype.hasDto = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.DeleteCardLangRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.DeleteCardLangRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.DeleteCardLangRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.DeleteCardLangRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.DeleteCardLangRequest}
 */
proto.cardLang.DeleteCardLangRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.DeleteCardLangRequest;
  return proto.cardLang.DeleteCardLangRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.DeleteCardLangRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.DeleteCardLangRequest}
 */
proto.cardLang.DeleteCardLangRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.DeleteCardLangRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.DeleteCardLangRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.DeleteCardLangRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.DeleteCardLangRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.cardLang.DeleteCardLangRequest.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.cardLang.DeleteCardLangRequest} returns this
*/
proto.cardLang.DeleteCardLangRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.DeleteCardLangRequest} returns this
 */
proto.cardLang.DeleteCardLangRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.DeleteCardLangRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.DeleteCardLangResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.DeleteCardLangResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.DeleteCardLangResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.DeleteCardLangResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.DeleteCardLangResponse}
 */
proto.cardLang.DeleteCardLangResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.DeleteCardLangResponse;
  return proto.cardLang.DeleteCardLangResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.DeleteCardLangResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.DeleteCardLangResponse}
 */
proto.cardLang.DeleteCardLangResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.DeleteCardLangResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.DeleteCardLangResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.DeleteCardLangResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.DeleteCardLangResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.UpdateCardLangRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.UpdateCardLangRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.UpdateCardLangRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.UpdateCardLangRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dto: (f = msg.getDto()) && proto.cardLang.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.UpdateCardLangRequest}
 */
proto.cardLang.UpdateCardLangRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.UpdateCardLangRequest;
  return proto.cardLang.UpdateCardLangRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.UpdateCardLangRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.UpdateCardLangRequest}
 */
proto.cardLang.UpdateCardLangRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.setDto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.UpdateCardLangRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.UpdateCardLangRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.UpdateCardLangRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.UpdateCardLangRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDto();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardLangDTO dto = 1;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.cardLang.UpdateCardLangRequest.prototype.getDto = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.cardLang.UpdateCardLangRequest} returns this
*/
proto.cardLang.UpdateCardLangRequest.prototype.setDto = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.UpdateCardLangRequest} returns this
 */
proto.cardLang.UpdateCardLangRequest.prototype.clearDto = function() {
  return this.setDto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.UpdateCardLangRequest.prototype.hasDto = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.UpdateCardLangResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.UpdateCardLangResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.UpdateCardLangResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.UpdateCardLangResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dto: (f = msg.getDto()) && proto.cardLang.CardLangDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.UpdateCardLangResponse}
 */
proto.cardLang.UpdateCardLangResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.UpdateCardLangResponse;
  return proto.cardLang.UpdateCardLangResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.UpdateCardLangResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.UpdateCardLangResponse}
 */
proto.cardLang.UpdateCardLangResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.setDto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.UpdateCardLangResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.UpdateCardLangResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.UpdateCardLangResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.UpdateCardLangResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDto();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional CardLangDTO dto = 1;
 * @return {?proto.cardLang.CardLangDTO}
 */
proto.cardLang.UpdateCardLangResponse.prototype.getDto = function() {
  return /** @type{?proto.cardLang.CardLangDTO} */ (
    jspb.Message.getWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {?proto.cardLang.CardLangDTO|undefined} value
 * @return {!proto.cardLang.UpdateCardLangResponse} returns this
*/
proto.cardLang.UpdateCardLangResponse.prototype.setDto = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.UpdateCardLangResponse} returns this
 */
proto.cardLang.UpdateCardLangResponse.prototype.clearDto = function() {
  return this.setDto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.UpdateCardLangResponse.prototype.hasDto = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cardLang.ListCardLangByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.ListCardLangByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.ListCardLangByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.ListCardLangByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    utils_pb.UUID_DTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.ListCardLangByIdsRequest}
 */
proto.cardLang.ListCardLangByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.ListCardLangByIdsRequest;
  return proto.cardLang.ListCardLangByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.ListCardLangByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.ListCardLangByIdsRequest}
 */
proto.cardLang.ListCardLangByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.ListCardLangByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.ListCardLangByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.ListCardLangByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated utils.UUID_DTO ids = 1;
 * @return {!Array<!proto.utils.UUID_DTO>}
 */
proto.cardLang.ListCardLangByIdsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.utils.UUID_DTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {!Array<!proto.utils.UUID_DTO>} value
 * @return {!proto.cardLang.ListCardLangByIdsRequest} returns this
*/
proto.cardLang.ListCardLangByIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.utils.UUID_DTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.utils.UUID_DTO}
 */
proto.cardLang.ListCardLangByIdsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.utils.UUID_DTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cardLang.ListCardLangByIdsRequest} returns this
 */
proto.cardLang.ListCardLangByIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cardLang.ListCardLangByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cardLang.ListCardLangByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cardLang.ListCardLangByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.cardLang.CardLangDTO.toObject, includeInstance),
    info: (f = msg.getInfo()) && utils_pb.ListOptionsResponseDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cardLang.ListCardLangByIdsResponse}
 */
proto.cardLang.ListCardLangByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cardLang.ListCardLangByIdsResponse;
  return proto.cardLang.ListCardLangByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cardLang.ListCardLangByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cardLang.ListCardLangByIdsResponse}
 */
proto.cardLang.ListCardLangByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cardLang.CardLangDTO;
      reader.readMessage(value,proto.cardLang.CardLangDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new utils_pb.ListOptionsResponseDTO;
      reader.readMessage(value,utils_pb.ListOptionsResponseDTO.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cardLang.ListCardLangByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cardLang.ListCardLangByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cardLang.ListCardLangByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cardLang.CardLangDTO.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.ListOptionsResponseDTO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardLangDTO items = 1;
 * @return {!Array<!proto.cardLang.CardLangDTO>}
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.cardLang.CardLangDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cardLang.CardLangDTO, 1));
};


/**
 * @param {!Array<!proto.cardLang.CardLangDTO>} value
 * @return {!proto.cardLang.ListCardLangByIdsResponse} returns this
*/
proto.cardLang.ListCardLangByIdsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cardLang.CardLangDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cardLang.CardLangDTO}
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cardLang.CardLangDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cardLang.ListCardLangByIdsResponse} returns this
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional utils.ListOptionsResponseDTO info = 2;
 * @return {?proto.utils.ListOptionsResponseDTO}
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.getInfo = function() {
  return /** @type{?proto.utils.ListOptionsResponseDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.ListOptionsResponseDTO, 2));
};


/**
 * @param {?proto.utils.ListOptionsResponseDTO|undefined} value
 * @return {!proto.cardLang.ListCardLangByIdsResponse} returns this
*/
proto.cardLang.ListCardLangByIdsResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cardLang.ListCardLangByIdsResponse} returns this
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cardLang.ListCardLangByIdsResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.cardLang.CardReviewFormatDTO = {
  CARDREVIEWFORMAT_UNI: 0,
  CARDREVIEWFORMAT_BIDI: 1
};

goog.object.extend(exports, proto.cardLang);
