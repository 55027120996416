import {ResourceCompositeDTO, ResourceMetadataDTO} from "proto/resource_pb";
import {IResourceMetadata, ResourceMetadata} from "model/resource/ResourceMetadata";
import {IFromDTO, IIntoDTO} from "model/model";
import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {EntityKind} from "model/BaseModel";
import {IComposite} from "model/CardComposite";

export interface IResourceComposite extends IComposite {
    metadata: IResourceMetadata[];
}

export class ResourceComposite implements IIntoDTO<ResourceCompositeDTO>, IFromDTO<ResourceCompositeDTO> {
    private _id: string = "";
    private _ord: number = 0;
    private _metadata: ResourceMetadata[] = [];

    constructor() {
    }

    fromDTO(t: ResourceCompositeDTO): void | IUIError {
        const actionType = ActionType.ConvertFromDTO;
        const entityKind = EntityKind.ResourceComposite;

        t.getMetadataList().forEach((dto) => {
            let metadata = new ResourceMetadata()
            const err = metadata.fromDTO(dto);

            if (err) {
                throw NewUIErrorV2(actionType, entityKind, `failed to convert metadata list`)
            }
            this._metadata.push(metadata)
        })
    }

    intoDTO(): IUIError | ResourceCompositeDTO {
        let metadata_dto: ResourceMetadataDTO[] = []

        this.metadata.forEach((tag) => {
            const dto = tag.intoDTO();
            if (isError(dto)) {
                throw NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.ResourceComposite, dto as IUIError, "invalid ResourceMetadataDTO")
            }
            metadata_dto.push(dto as ResourceMetadataDTO)
        })

        let dto = new ResourceCompositeDTO();

        dto.setMetadataList(metadata_dto)

        return dto
    }


    validate(): ResourceComposite | IUIError {
        this.metadata.forEach((metadata) => {
            const err = metadata.validate();

            if (isError(err)) {
                return err
            }
        });

        return this
    }

    sanitize() {
        this.metadata = this.metadata.map((tag) => tag.sanitize());
    }

    clone(): ResourceComposite {
        let newComposite = new ResourceComposite();

        // Manually copy properties from the current instance to the new instance
        newComposite.id = this.id;
        newComposite.ord = this.ord;

        // Perform a deep copy for the metadata object if it exists
        if (this.metadata) {
            newComposite.metadata =  [...this.metadata ];
        }

        return newComposite
    }

    static fromJSON(json: IResourceComposite): ResourceComposite {
        let composite = new ResourceComposite();
        composite.id = json.id;
        composite.ord = json.ord;

        if (json.metadata && Array.isArray(json.metadata)) {
            composite.metadata = json.metadata.map((tag: any) => ResourceMetadata.fromJSON(tag));
        } else {
            composite.metadata = []
        }

        return composite
    }

    toJSON(): IResourceComposite {
        return {
            id: this.id,
            ord: this.ord,
            metadata: this.metadata.map((metadata) => metadata.toJSON())
        }
    }

    get id() {
        return this._id
    }

    set id(value: string) {
        this._id = value
    }

    get metadata(): ResourceMetadata[] {
        return this._metadata;
    }

    set metadata(value: ResourceMetadata[]) {
        this._metadata = value;
    }

    get ord(): number {
        return this._ord;
    }

    set ord(value: number) {
        this._ord = value;
    }

}
