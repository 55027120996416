/**
 * @fileoverview gRPC-Web generated client stub for card
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: card.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var tag_pb = require('./tag_pb.js')

var topic_pb = require('./topic_pb.js')

var resource_pb = require('./resource_pb.js')

var utils_pb = require('./utils_pb.js')

var cardMedia_pb = require('./cardMedia_pb.js')
const proto = {};
proto.card = require('./card_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.card.CardServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.card.CardServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.CreateCardRequest,
 *   !proto.card.CreateCardResponse>}
 */
const methodDescriptor_CardService_create = new grpc.web.MethodDescriptor(
  '/card.CardService/create',
  grpc.web.MethodType.UNARY,
  proto.card.CreateCardRequest,
  proto.card.CreateCardResponse,
  /**
   * @param {!proto.card.CreateCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.CreateCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.CreateCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.CreateCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.CreateCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/create',
      request,
      metadata || {},
      methodDescriptor_CardService_create,
      callback);
};


/**
 * @param {!proto.card.CreateCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.CreateCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/create',
      request,
      metadata || {},
      methodDescriptor_CardService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.GetCardRequest,
 *   !proto.card.GetCardResponse>}
 */
const methodDescriptor_CardService_get = new grpc.web.MethodDescriptor(
  '/card.CardService/get',
  grpc.web.MethodType.UNARY,
  proto.card.GetCardRequest,
  proto.card.GetCardResponse,
  /**
   * @param {!proto.card.GetCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.GetCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.GetCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.GetCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.GetCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/get',
      request,
      metadata || {},
      methodDescriptor_CardService_get,
      callback);
};


/**
 * @param {!proto.card.GetCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.GetCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/get',
      request,
      metadata || {},
      methodDescriptor_CardService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.ListCardRequest,
 *   !proto.card.ListCardResponse>}
 */
const methodDescriptor_CardService_list = new grpc.web.MethodDescriptor(
  '/card.CardService/list',
  grpc.web.MethodType.UNARY,
  proto.card.ListCardRequest,
  proto.card.ListCardResponse,
  /**
   * @param {!proto.card.ListCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.ListCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.ListCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.ListCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.ListCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/list',
      request,
      metadata || {},
      methodDescriptor_CardService_list,
      callback);
};


/**
 * @param {!proto.card.ListCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.ListCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/list',
      request,
      metadata || {},
      methodDescriptor_CardService_list);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.ListCardByIdsRequest,
 *   !proto.card.ListCardByIdsResponse>}
 */
const methodDescriptor_CardService_listByIds = new grpc.web.MethodDescriptor(
  '/card.CardService/listByIds',
  grpc.web.MethodType.UNARY,
  proto.card.ListCardByIdsRequest,
  proto.card.ListCardByIdsResponse,
  /**
   * @param {!proto.card.ListCardByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.ListCardByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.card.ListCardByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.ListCardByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.ListCardByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.listByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/listByIds',
      request,
      metadata || {},
      methodDescriptor_CardService_listByIds,
      callback);
};


/**
 * @param {!proto.card.ListCardByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.ListCardByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.listByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/listByIds',
      request,
      metadata || {},
      methodDescriptor_CardService_listByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.DeleteCardRequest,
 *   !proto.card.DeleteCardResponse>}
 */
const methodDescriptor_CardService_delete = new grpc.web.MethodDescriptor(
  '/card.CardService/delete',
  grpc.web.MethodType.UNARY,
  proto.card.DeleteCardRequest,
  proto.card.DeleteCardResponse,
  /**
   * @param {!proto.card.DeleteCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.DeleteCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.DeleteCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.DeleteCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.DeleteCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/delete',
      request,
      metadata || {},
      methodDescriptor_CardService_delete,
      callback);
};


/**
 * @param {!proto.card.DeleteCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.DeleteCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/delete',
      request,
      metadata || {},
      methodDescriptor_CardService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.UpdateCardRequest,
 *   !proto.card.UpdateCardResponse>}
 */
const methodDescriptor_CardService_update = new grpc.web.MethodDescriptor(
  '/card.CardService/update',
  grpc.web.MethodType.UNARY,
  proto.card.UpdateCardRequest,
  proto.card.UpdateCardResponse,
  /**
   * @param {!proto.card.UpdateCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.UpdateCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.UpdateCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.UpdateCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.UpdateCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/update',
      request,
      metadata || {},
      methodDescriptor_CardService_update,
      callback);
};


/**
 * @param {!proto.card.UpdateCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.UpdateCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/update',
      request,
      metadata || {},
      methodDescriptor_CardService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.CreateCardWithTopicRelationshipRequest,
 *   !proto.card.CreateCardWithTopicRelationshipResponse>}
 */
const methodDescriptor_CardService_createCardWithTopicRelationship = new grpc.web.MethodDescriptor(
  '/card.CardService/createCardWithTopicRelationship',
  grpc.web.MethodType.UNARY,
  proto.card.CreateCardWithTopicRelationshipRequest,
  proto.card.CreateCardWithTopicRelationshipResponse,
  /**
   * @param {!proto.card.CreateCardWithTopicRelationshipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.CreateCardWithTopicRelationshipResponse.deserializeBinary
);


/**
 * @param {!proto.card.CreateCardWithTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.CreateCardWithTopicRelationshipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.CreateCardWithTopicRelationshipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.createCardWithTopicRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/createCardWithTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_createCardWithTopicRelationship,
      callback);
};


/**
 * @param {!proto.card.CreateCardWithTopicRelationshipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.CreateCardWithTopicRelationshipResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.createCardWithTopicRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/createCardWithTopicRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_createCardWithTopicRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.ArchiveCardRequest,
 *   !proto.card.ArchiveCardResponse>}
 */
const methodDescriptor_CardService_archive = new grpc.web.MethodDescriptor(
  '/card.CardService/archive',
  grpc.web.MethodType.UNARY,
  proto.card.ArchiveCardRequest,
  proto.card.ArchiveCardResponse,
  /**
   * @param {!proto.card.ArchiveCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.ArchiveCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.ArchiveCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.ArchiveCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.ArchiveCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.archive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/archive',
      request,
      metadata || {},
      methodDescriptor_CardService_archive,
      callback);
};


/**
 * @param {!proto.card.ArchiveCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.ArchiveCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.archive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/archive',
      request,
      metadata || {},
      methodDescriptor_CardService_archive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.ImportCardRequest,
 *   !proto.card.ImportCardResponse>}
 */
const methodDescriptor_CardService_importCard = new grpc.web.MethodDescriptor(
  '/card.CardService/importCard',
  grpc.web.MethodType.UNARY,
  proto.card.ImportCardRequest,
  proto.card.ImportCardResponse,
  /**
   * @param {!proto.card.ImportCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.ImportCardResponse.deserializeBinary
);


/**
 * @param {!proto.card.ImportCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.ImportCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.ImportCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.importCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/importCard',
      request,
      metadata || {},
      methodDescriptor_CardService_importCard,
      callback);
};


/**
 * @param {!proto.card.ImportCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.ImportCardResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.importCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/importCard',
      request,
      metadata || {},
      methodDescriptor_CardService_importCard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.ListCardsForTopicRequest,
 *   !proto.card.ListCardsForTopicResponse>}
 */
const methodDescriptor_CardService_listCardsForTopic = new grpc.web.MethodDescriptor(
  '/card.CardService/listCardsForTopic',
  grpc.web.MethodType.UNARY,
  proto.card.ListCardsForTopicRequest,
  proto.card.ListCardsForTopicResponse,
  /**
   * @param {!proto.card.ListCardsForTopicRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.ListCardsForTopicResponse.deserializeBinary
);


/**
 * @param {!proto.card.ListCardsForTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.ListCardsForTopicResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.ListCardsForTopicResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.listCardsForTopic =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/listCardsForTopic',
      request,
      metadata || {},
      methodDescriptor_CardService_listCardsForTopic,
      callback);
};


/**
 * @param {!proto.card.ListCardsForTopicRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.ListCardsForTopicResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.listCardsForTopic =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/listCardsForTopic',
      request,
      metadata || {},
      methodDescriptor_CardService_listCardsForTopic);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.CreateResourceRelationRequest,
 *   !proto.card.CreateResourceRelationResponse>}
 */
const methodDescriptor_CardService_createResourceRelationship = new grpc.web.MethodDescriptor(
  '/card.CardService/createResourceRelationship',
  grpc.web.MethodType.UNARY,
  proto.card.CreateResourceRelationRequest,
  proto.card.CreateResourceRelationResponse,
  /**
   * @param {!proto.card.CreateResourceRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.CreateResourceRelationResponse.deserializeBinary
);


/**
 * @param {!proto.card.CreateResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.CreateResourceRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.CreateResourceRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.createResourceRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/createResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_createResourceRelationship,
      callback);
};


/**
 * @param {!proto.card.CreateResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.CreateResourceRelationResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.createResourceRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/createResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_createResourceRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.CreateTagRelationRequest,
 *   !proto.card.CreateTagRelationResponse>}
 */
const methodDescriptor_CardService_createTagRelationship = new grpc.web.MethodDescriptor(
  '/card.CardService/createTagRelationship',
  grpc.web.MethodType.UNARY,
  proto.card.CreateTagRelationRequest,
  proto.card.CreateTagRelationResponse,
  /**
   * @param {!proto.card.CreateTagRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.CreateTagRelationResponse.deserializeBinary
);


/**
 * @param {!proto.card.CreateTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.CreateTagRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.CreateTagRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.createTagRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/createTagRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_createTagRelationship,
      callback);
};


/**
 * @param {!proto.card.CreateTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.CreateTagRelationResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.createTagRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/createTagRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_createTagRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.DeleteTagRelationRequest,
 *   !proto.card.DeleteTagRelationResponse>}
 */
const methodDescriptor_CardService_deleteTagRelationship = new grpc.web.MethodDescriptor(
  '/card.CardService/deleteTagRelationship',
  grpc.web.MethodType.UNARY,
  proto.card.DeleteTagRelationRequest,
  proto.card.DeleteTagRelationResponse,
  /**
   * @param {!proto.card.DeleteTagRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.DeleteTagRelationResponse.deserializeBinary
);


/**
 * @param {!proto.card.DeleteTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.DeleteTagRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.DeleteTagRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.deleteTagRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/deleteTagRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_deleteTagRelationship,
      callback);
};


/**
 * @param {!proto.card.DeleteTagRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.DeleteTagRelationResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.deleteTagRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/deleteTagRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_deleteTagRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.DeleteResourceRelationRequest,
 *   !proto.card.DeleteResourceRelationResponse>}
 */
const methodDescriptor_CardService_deleteResourceRelationship = new grpc.web.MethodDescriptor(
  '/card.CardService/deleteResourceRelationship',
  grpc.web.MethodType.UNARY,
  proto.card.DeleteResourceRelationRequest,
  proto.card.DeleteResourceRelationResponse,
  /**
   * @param {!proto.card.DeleteResourceRelationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.DeleteResourceRelationResponse.deserializeBinary
);


/**
 * @param {!proto.card.DeleteResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.DeleteResourceRelationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.DeleteResourceRelationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.deleteResourceRelationship =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/deleteResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_deleteResourceRelationship,
      callback);
};


/**
 * @param {!proto.card.DeleteResourceRelationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.DeleteResourceRelationResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.deleteResourceRelationship =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/deleteResourceRelationship',
      request,
      metadata || {},
      methodDescriptor_CardService_deleteResourceRelationship);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.GetCardStatRequest,
 *   !proto.card.GetCardStatResponse>}
 */
const methodDescriptor_CardService_getCardStat = new grpc.web.MethodDescriptor(
  '/card.CardService/getCardStat',
  grpc.web.MethodType.UNARY,
  proto.card.GetCardStatRequest,
  proto.card.GetCardStatResponse,
  /**
   * @param {!proto.card.GetCardStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.GetCardStatResponse.deserializeBinary
);


/**
 * @param {!proto.card.GetCardStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.GetCardStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.GetCardStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.getCardStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/getCardStat',
      request,
      metadata || {},
      methodDescriptor_CardService_getCardStat,
      callback);
};


/**
 * @param {!proto.card.GetCardStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.GetCardStatResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.getCardStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/getCardStat',
      request,
      metadata || {},
      methodDescriptor_CardService_getCardStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.ListCardStatsByIdsRequest,
 *   !proto.card.ListCardStatsByIdsResponse>}
 */
const methodDescriptor_CardService_listCardStatsByIds = new grpc.web.MethodDescriptor(
  '/card.CardService/listCardStatsByIds',
  grpc.web.MethodType.UNARY,
  proto.card.ListCardStatsByIdsRequest,
  proto.card.ListCardStatsByIdsResponse,
  /**
   * @param {!proto.card.ListCardStatsByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.ListCardStatsByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.card.ListCardStatsByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.ListCardStatsByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.ListCardStatsByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.listCardStatsByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/listCardStatsByIds',
      request,
      metadata || {},
      methodDescriptor_CardService_listCardStatsByIds,
      callback);
};


/**
 * @param {!proto.card.ListCardStatsByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.ListCardStatsByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.listCardStatsByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/listCardStatsByIds',
      request,
      metadata || {},
      methodDescriptor_CardService_listCardStatsByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.card.GetCardGlobalStatsRequest,
 *   !proto.card.GetCardGlobalStatsResponse>}
 */
const methodDescriptor_CardService_statsGetCardGlobalStats = new grpc.web.MethodDescriptor(
  '/card.CardService/statsGetCardGlobalStats',
  grpc.web.MethodType.UNARY,
  proto.card.GetCardGlobalStatsRequest,
  proto.card.GetCardGlobalStatsResponse,
  /**
   * @param {!proto.card.GetCardGlobalStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.card.GetCardGlobalStatsResponse.deserializeBinary
);


/**
 * @param {!proto.card.GetCardGlobalStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.card.GetCardGlobalStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.card.GetCardGlobalStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.card.CardServiceClient.prototype.statsGetCardGlobalStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/card.CardService/statsGetCardGlobalStats',
      request,
      metadata || {},
      methodDescriptor_CardService_statsGetCardGlobalStats,
      callback);
};


/**
 * @param {!proto.card.GetCardGlobalStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.card.GetCardGlobalStatsResponse>}
 *     Promise that resolves to the response
 */
proto.card.CardServicePromiseClient.prototype.statsGetCardGlobalStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/card.CardService/statsGetCardGlobalStats',
      request,
      metadata || {},
      methodDescriptor_CardService_statsGetCardGlobalStats);
};


module.exports = proto.card;

