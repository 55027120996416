import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {BaseModel, EntityKind} from "model/BaseModel";
import {UserAttributeDTO, UserDTO} from "proto/user_pb";
import {convertDateToTimestamp, ListItem} from "utils/utils";
import {IDisplayItem} from "./interfaces";
import {convertFromDTOToDate, convertFromDTOToID} from "model/CardLang";
import {UUID_DTO} from "proto/utils_pb";
import {IFromDTO, IIntoDTO} from "model/model";

// A user will onboard the first time they land on the website. They may choose to immediately cancel all
// onboarding in which case we log the `hasOnboarded` time and set all fields to false. If they choose to continue with
// onboarding we will show them the different onboarding flows when they land on those pages. Once we are done with theindividual
// onboarding we mark each of them as true, and once all are marked true we set `hasOnboarded` to the current time.
//
// A user at any time may choose to cancel the onboarding entirely, in which case we will set `hasOnboarded` to the current time.
interface UserIntroTour {
    isComplete: boolean;
    showCardPage: boolean;
    showTopicPage: boolean;
    showDashboardPage: boolean;
    showResourcePage: boolean;
    showReviewPage: boolean
}

export class UserAttributes implements IFromDTO<UserAttributeDTO>, IIntoDTO<UserAttributeDTO>{
    introTour: UserIntroTour;

    constructor() {
        this.introTour = {
            isComplete: false,
            showCardPage: false,
            showTopicPage: false,
            showDashboardPage: false,
            showResourcePage: false,
            showReviewPage: false
        };
    }

    fromDTO(dto: UserAttributeDTO): void | IUIError {
        this.introTour = {
            isComplete: dto.getHascompletedintrotour(),
            showCardPage: false,
            showTopicPage: false,
            showDashboardPage: false,
            showResourcePage: false,
            showReviewPage: false,
        }
    }

    intoDTO(): UserAttributeDTO | IUIError {
        let dto = new UserAttributeDTO()
        dto.setHascompletedintrotour(this.introTour.isComplete)
        return dto
    }
}

export class User extends BaseModel<User, UserDTO> {
    id: string;
    displayName: string;
    givenName: string;
    lastName: string;
    email: string;
    picture: string;
    createdOn: Date;
    lastLogin?: Date;
    private _attrs: UserAttributes;
    updatedOn: Date;
    archivedOn?: Date;

    constructor() {
        super();
        this.id = "";
        this.displayName = "";
        this.givenName = "";
        this.lastName = "";
        this.email = "";
        this.picture = "";
        this._attrs = new UserAttributes();
        this.createdOn = new Date();
        this.updatedOn = new Date();
    }

    fromDTO(dto: UserDTO): void | IUIError {
        let val = new User()
        val.id = convertFromDTOToID(this.TYPE, dto.getId());
        val.displayName = dto.getDisplayname()
        val.givenName = dto.getGivenname()
        val.lastName = dto.getLastname()
        val.email = dto.getEmail()
        val.picture = dto.getPicture()

        let createdOn = convertFromDTOToDate(this.TYPE, dto.getCreatedOn())
        if (!createdOn || isError(createdOn)) {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, createdOn as IUIError)
        }
        val.createdOn = createdOn as Date

        let updatedOn = convertFromDTOToDate(this.TYPE, dto.getUpdatedOn())
        if (!createdOn || isError(updatedOn)) {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, updatedOn as IUIError)
        }
        val.updatedOn = updatedOn as Date

        let lastLogin = convertFromDTOToDate(this.TYPE, dto.getLastLogin(), true)
        if (lastLogin && isError(lastLogin)) {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, lastLogin as IUIError)
        }
        val.lastLogin = lastLogin as Date | undefined

        let attrs = new UserAttributes()
        if (dto.hasAttr()) {
            attrs.fromDTO(dto.getAttr()!)
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, `UserDTO: ${val.id} does not have attributes`)
        }

        this.id = val.id
        this.displayName = val.displayName
        this.givenName = val.givenName
        this.lastName = val.lastName
        this.email = val.email
        this.picture = val.picture
        this.createdOn = val.createdOn
        this._attrs = attrs
        this.updatedOn = val.updatedOn
        this.lastLogin = val.lastLogin
    }

    intoDTO(): UserDTO | IUIError {
        let dto = new UserDTO()

        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setDisplayname(this.displayName)
        dto.setGivenname(this.givenName)
        dto.setLastname(this.lastName)
        dto.setEmail(this.email)
        dto.setPicture(this.picture)
        dto.setCreatedOn(convertDateToTimestamp(this.createdOn));
        dto.setUpdatedOn(convertDateToTimestamp(this.updatedOn));

        let attrs = this._attrs.intoDTO()
        if (isError(attrs)) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, attrs as IUIError)
        }
        dto.setAttr(attrs as UserAttributeDTO)
        if (this.lastLogin) dto.setLastLogin(convertDateToTimestamp(this.lastLogin))

        return dto
    }

    toJSON() {
        return {
            id: this.id,
            displayName: this.displayName,
            givenName: this.givenName,
            lastName: this.lastName,
            email: this.email,
            picture: this.picture,
            attrs: this._attrs,
            createdOn: this.createdOn,
            updatedOn: this.updatedOn,
            lastLogin: this.lastLogin,
            archivedOn: this.archivedOn
        }
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.displayName,
            metadata1: this.email,
            imageUrl: this.picture
        }
    }

    to1LineString(): String {
        return this.displayName + " (" + this.email + ")"
    }

    TYPE: EntityKind = EntityKind.User

    clone(): User {
        let temp = Object.assign({}, this);
        let newUser = new User()

        newUser.id = temp.id
        newUser.displayName = temp.displayName
        newUser.givenName = temp.givenName
        newUser.lastName = temp.lastName
        newUser.email = temp.email
        newUser._attrs = temp.attrs
        newUser.picture = temp.picture
        newUser.createdOn = temp.createdOn
        newUser.updatedOn = temp.updatedOn
        newUser.lastLogin = temp.lastLogin
        newUser.archivedOn = temp.archivedOn

        return newUser
    }

    sanitize(): User {
        this.displayName = this.displayName.trim()
        this.email = this.email.trim()
        this.givenName = this.givenName.trim()
        this.lastName = this.lastName.trim()
        this.picture = this.picture.trim()

        return this
    }

    get attrs(): UserAttributes {
        return this._attrs;
    }

    set attrs(value: UserAttributes) {
        this._attrs = value;
    }

    validate(): User | IUIError {
        if (this.id === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "id is empty")
        }
        if (this.displayName === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "displayName is empty")
        }
        if (this.email === "") {
            return NewUIErrorV2(ActionType.Validate, this.TYPE, "email is empty")
        }

        return this.sanitize()
    }

    toListItem(): ListItem {
        return {
            id: this.id, title: this.displayName, imageUrl: this.picture
        }
    }
}
